// Local dependencies.
import Core from '../../modules/core/core.module';
import moduleLoader from '../../modules/core/core.module-loader';
import legalAcceptanceModalTemplate from '../../components/core/modal/modal.legal-acceptance.template.html';
import { isInternalRole } from '../../constants/core/roles';
import AuthResponseModel from '../../models/core/auth-response.model';
import SessionStateModel from '../../models/core/session-state.model';

/**
 * @name Session
 * @memberof CoreBundle.Core
 * @class
 * 
 * @classdesc
 * AngularJS service.
 */
class Session {
    constructor(
        $q,
        $injector,
        $window,
        aclService,
        browserService,
        eventService,
        modalService,
        storageService,
        GLOBALS,
        signalRAccountHub,
        signalRAnnouncementHub,
        signalRNotificationHub,
        wsFileUpload,
        sessionTimeoutService,
        authService,
        SIGNALR_CONSTANTS,
        WS_CONSTANTS,
        jwtService,
        wsAnnouncement,
        signalRProcessingHub ) {
            
        this.$q = $q;
        this.$injector = $injector;
        this.$window = $window;
        this.aclService = aclService;
        this.browserService = browserService;
        this.eventService = eventService;
        this.modalService = modalService;
        this.storageService = storageService;
        this.GLOBALS = GLOBALS;
        this.signalRAccountHub = signalRAccountHub;
        this.signalRAnnouncementHub = signalRAnnouncementHub;
        this.signalRNotificationHub = signalRNotificationHub;
        this.wsFileUpload = wsFileUpload;
        this.wsAnnouncement = wsAnnouncement;
        this.sessionTimeoutService = sessionTimeoutService;
        this.authService = authService;
        this.SIGNALR_CONSTANTS = SIGNALR_CONSTANTS;
        this.WS_CONSTANTS = WS_CONSTANTS;
        this.jwtService = jwtService;
        this.signalRProcessingHub = signalRProcessingHub;

        // IDS/CLA role flags.
        this.isCLAAdmin = false;
        this.isCLASuperAdmin = false;
        this.isCLASupportAdmin = false;

        // Basic user information.
        this.userId                 = null;
        this.username               = null;
        this.firstName              = null;
        this.lastName               = null;
        this.displayName            = null;
        this.roles                  = [];
        this.defaultApp             = null;
        this.currentApp             = null;
        
        // Impersonation information.
        this.isImpersonatingUser    = false;
        this.impersonatingUser      = {};

        // Processing and product access flags.
        this.isSafariUser           = false;
        this.isMultiPortalAccess    = false;
        this.pbAccess               = false;
        this.pbAuthenticated        = false;
        this.pbValid                = false;
        this.mmAccess               = false;
        this.mmAuthenticated        = false;
        this.mmValid                = false;

        this.productKeys            = [];
    }

    /**
     * Sets the current application, provided the user has access to
     * that particular product key.
     * 
     * @param {string} productKey The product key to set as current.
     * @param {Boolean} triggerSessionChange Whether this call should trigger a session change event if successful.
     */
    setCurrentApp( productKey, triggerSessionChange = false ) {
        productKey = getTypeCheckedValue( productKey, 'string', null );
        triggerSessionChange = getTypeCheckedValue( triggerSessionChange, 'boolean', false );

        console.debug( '[core.session] :: setCurrentApp', productKey, triggerSessionChange );

        if ( productKey !== null ) {
            let flagProp = `${productKey}Access`;

            if ( this[ flagProp ] === true ) {
                this.defaultApp = productKey;
                this.storageService.set( this.GLOBALS.SUPPORTED_PRODUCT_STORAGE_KEYS.defaultApp, productKey );

                if ( triggerSessionChange ) {
                    this.eventService.broadcastSessionChanged();
                }
                return true;
            }
        }

        return false;
    }

    /**
     * Runs a basic product check for keys stored by the authentication
     * interstitial, which will set the product access flags. We call this
     * first, so the application controller can then dynamically load and
     * inject product modules beyond "core".
     */
    checkProductAccess() {
        Object.keys( this.GLOBALS.SUPPORTED_PRODUCT_STORAGE_KEYS ).forEach( productKey => {
            const storageKey = this.GLOBALS.SUPPORTED_PRODUCT_STORAGE_KEYS[ productKey ];
            const flagProp = `${productKey}Access`;
            const storageItem = this.storageService.get( storageKey );

            if ( storageItem !== null ) {
                this[ flagProp ] = true;
            }
        });
    }

    /**
     * Initializes the user session from data previously stored in the browser's
     * LocalStorage by the authentication interstitial. The key by which we fetch
     * this data depends on the product we're looking for.
     * 
     * mp.userData is ProspectBase-specific user data.
     * mp.tokens is for all products from Market Magnifier and later.
     */
    bootstrap() {
        let defer = this.$q.defer();

        console.debug( '[core.session] :: bootstrap starting' );

        try {
            // Fetch session state from browser storage as a string, and cast
            // it to an instance of AuthResponseModel.
            let browserSessionString = this.storageService.get( this.GLOBALS.SUPPORTED_PRODUCT_STORAGE_KEYS.session ),
                globalSession = AuthResponseModel.fromStr( browserSessionString );

            console.debug( '[core.session] :: bootstrap found preloaded auth data', browserSessionString, globalSession );

            // Fetch the user's previous default product selection, if it's
            // been set.
            let userDefaultApp = this.storageService.get( this.GLOBALS.SUPPORTED_PRODUCT_STORAGE_KEYS.defaultApp, false );

            console.debug( '[core.session] :: bootstrap found default app', userDefaultApp );

            // Determine which product libraries need to be resolved/loaded.
            let productsToLoad = [];
            if ( globalSession !== null ) {
                if ( globalSession.hasMarketMagnifierData() )
                    productsToLoad.push( 'mm' );

                if ( globalSession.hasProspectBaseData() )
                    productsToLoad.push( 'pb' );

                console.debug( '[core.session] :: bootstrap will load product libs', productsToLoad );

                // We can go ahead and grab some basic metadata from
                // the global session data.
                if ( globalSession.sessionState !== null ) {

                    // User info.
                    if ( globalSession.sessionState.user !== null &&
                        globalSession.sessionState.user.isValid() ) {
                        
                        let userMeta = globalSession.sessionState.user;
                        this.userId = userMeta.userId;
                        this.username = userMeta.loginId;
                        this.firstName = userMeta.firstName;
                        this.lastName = userMeta.lastName;
                        this.displayName = `${this.firstName} ${this.lastName}`;
                    }

                    // Impersonation.
                    if ( globalSession.sessionState.impersonatingUser !== null ) {

                        let impersonatingUserMeta = globalSession.sessionState.impersonatingUser,
                        impersonatingUserFirstName = impersonatingUserMeta.firstName || null,
                        impersonatingUserLastName = impersonatingUserMeta.lastName || null;

                        if ( impersonatingUserFirstName !== null &&
                            impersonatingUserLastName !== null ) {
                            impersonatingUserMeta.displayName = `${impersonatingUserFirstName} ${impersonatingUserLastName}`;
                        }

                        this.isImpersonatingUser = true;
                        this.impersonatingUser = impersonatingUserMeta;
                    }

                    // CLA roles.
                    if ( globalSession.sessionState.claRoles.length > 0 ) {
                        Object.keys( this.GLOBALS.CLA_ROLES ).forEach( claRoleKey => {

                            let claPropName = `isCLA${claRoleKey}`,
                                claRoleCode = this.GLOBALS.CLA_ROLES[ claRoleKey ],
                                hasClaRole = globalSession.sessionState.claRoles
                                    .find( claRole => claRole.code === claRoleCode );

                            this[ claPropName ] = ( hasClaRole !== undefined );
                        });
                    }

                    // Safari users.
                    this.isSafariUser = this.browserService.isSafari;
                }
            }

            // If we have product libraries to load, we know we have a valid
            // instance of global session with tokens. Before loading the
            // product libraries, we'll kickstart the JWT service.
            if ( productsToLoad.length > 0 ) {

                console.debug( '[core.session] :: bootstrap kicking off jwtService' );

                this.bootstrapJwtService().then( isJwtInitialized => {
                    if ( !isJwtInitialized ) {
                        console.debug( '[core.session] :: bootstrap jwtService returned in failed state' );
                        defer.resolve( [] );
                        return;
                    }

                    // Universal session state.
                    let sessionState = this.jwtService.getSessionState();

                    // Load the product libraries.
                    this.$q.all( productsToLoad.map( productKey => this._loadBundle( productKey )))
                        .then( loadedLibraries => {

                            // Product libraries that failed to load will
                            // have returned null. From here-on, we're
                            // only operating against the successfully
                            // loaded libraries.
                            loadedLibraries = loadedLibraries.filter( loadedLibrary =>
                                loadedLibrary !== null );

                            console.debug( '[core.session] :: bootstrap finished loading product libs', loadedLibraries );

                            // Now that the product libraries have loaded,
                            // they can be bootstrapped with sessionState.
                            loadedLibraries.forEach( productKey => {

                                // Bootstrapping will return an instance
                                // of the product session service if
                                // valid, otherwise null.
                                let productSession = this._getBootstrappedProductSession( productKey,
                                    sessionState, userDefaultApp );

                                // Generic access flags.
                                this[ `${productKey}Access` ] = true;
                                this[ `${productKey}Authenticated` ] = productSession.isAuthenticated();
                                this[ `${productKey}Valid` ] = productSession.isValidProduct();

                                console.debug( '[core.session] :: bootstrap setting access for product', productKey,
                                    this[ `${productKey}Access` ], this[ `${productKey}Authenticated` ], this[ `${productKey}Valid` ]);

                                // Default product is set as such:
                                //
                                // 1. User previously changed the product during
                                // this session.
                                if ( userDefaultApp !== null && userDefaultApp === productKey )
                                    this.setCurrentApp( productKey );

                                // 2. Fresh session; this product key was set
                                // during SSO.
                                else if ( this.defaultApp === null && productSession.isDefault === true )
                                    this.setCurrentApp( productKey );
                            });

                            // All libraries are now loaded, meaning that all
                            // products are prepped for consumption. We're
                            // going to initialize and start the sessionTimeout
                            // service. Note: this might appear verbose, but
                            // we're going to be very explicit about this.
                            let expirationDate = this.jwtService.getExpiration();
                            this.sessionTimeoutService.onTimeoutChanged( this.timeoutSession.bind( this ));
                            this.sessionTimeoutService.setExpiration( expirationDate );
                            this.sessionTimeoutService.start();

                            // Check legal acceptance; if it's already done,
                            // we can start the socket connections.
                            if ( this.storageService.get( this.GLOBALS.LEGAL_ACCEPTANCE_STORAGE_KEY, false ) === '1' )
                                this._connectToSignalRHubs();

                            // Loaded libraries are simply the product keys
                            // that have been successfully loaded.
                            this.productKeys = loadedLibraries;
                            
                            // Note for the newbs: nothing will finish loading
                            // until this is called.
                            this.eventService.broadcastGlobalSessionLoaded();

                            // Watch for changes to browser storage triggered
                            // from another tab.
                            window.addEventListener( 'storage', this.onBrowserStorageChanged.bind( this ));

                            // We resolve the loaded library keys.
                            defer.resolve( loadedLibraries );
                        });
                });
            }

            // Nothing to load... odd.
            else {
                defer.resolve( [] );
            }
        } catch ( err ) {
            defer.resolve( [] );
        }

        return defer.promise;
    }

    /**
     * Re-bootstraps session as an authenticated guest, resolving to whether
     * or not the provided product key is found in the bootstrap method's
     * response array.
     * 
     * @param {string} productKey 
     * @param {AuthResponseModel} authResponse 
     */
    bootstrapAsAuthenticatedGuest( productKey, authResponse ) {
        productKey = getTypeCheckedValue( productKey, 'string', null );
        authResponse = getInstanceCheckedValue( authResponse, AuthResponseModel, null );

        let defer = this.$q.defer();

        // We require a product key...
        if ( productKey === null ) {
            console.debug( '[core.session] :: bootstrapAsAuthenticatedGuest() :: Expected productKey to be a string; received null instead. Resolving false.' );
            defer.resolve( false );
        }

        // And a proper auth response.
        else if ( authResponse === null ) {
            console.debug( '[core.session] :: bootstrapAsAuthenticatedGuest() :: Expected authResponse to be instance of AuthResponseModel; received null instead. Resolving false.' );
            defer.resolve( false );
        }

        // Otherwise, we're good to go.
        else {
            console.debug( `[core.session] :: bootstrapAsAuthenticatedGuest() :: Starting for product key "${productKey}" and auth response.`, authResponse );

            // Set the default app.
            this.storageService.set( this.GLOBALS.SUPPORTED_PRODUCT_STORAGE_KEYS.defaultApp, productKey );
            console.debug( `[core.session] :: bootstrapAsAuthenticatedGuest() :: Set default app to "${productKey}" in browser storage.` );

            // Persist it to localStorage.
            this.storageService.set( this.GLOBALS.SUPPORTED_PRODUCT_STORAGE_KEYS.session, JSON.stringify( authResponse ));
            console.debug( '[core.session] :: bootstrapAsAuthenticatedGuest() :: Set session in browser storage; calling core.session.bootstrap().' );

            // Bootstrap.
            this.bootstrap()
                .then( loadedKeys => {
                    console.debug( '[core.session] :: bootstrapAsAuthenticatedGuest() :: core.session.bootstrap returned with loaded keys.', loadedKeys );

                    let productKeyLoaded = ( Array.isArray( loadedKeys ) &&
                        loadedKeys.indexOf( productKey ) !== -1 );
                    
                    console.debug( `[core.session] :: bootstrapAsAuthenticatedGuest() :: Resolving ${productKeyLoaded.toString()}.` );
                    defer.resolve( productKeyLoaded );
                });
        }

        return defer.promise;
    }

    /**
     * Bootstraps the JWT provider, and checks for whether its tokens are
     * set to expire or in need of refresh. If so, refreshes using the
     * refresh token before returning a boolean value for whether the
     * bootstrap operation was successful.
     * 
     * This should only be called from core.session's bootstrap.
     */
    bootstrapJwtService() {
        let defer = this.$q.defer();

        console.debug( '[core.session] :: bootstrapJwtService() :: Starting; calling core.jwtService.bootstrap().' );

        this.jwtService.bootstrap();

        // We expect access and refresh to be set.
        if ( this.jwtService.hasAccessToken() &&
            this.jwtService.hasRefreshToken() ) {

            console.debug( '[core.session] :: bootstrapJwtService() :: core.jwtService has access and refresh tokens.' );
            
            // We're immediately bootstrapped if:
            // - access token isn't expired
            // - access token isn't expiring within
            //   X seconds
            if ( !this.jwtService.isAccessExpired() &&
                !this.jwtService.shouldRefresh() ) {
                console.debug( '[core.session] :: bootstrapJwtService() :: Access token not expiring or close to expiring; resolving true.' );
                defer.resolve( true );
            }

            // We can immediately refresh if the
            // refresh token isn't expired.
            else if ( !this.jwtService.isRefreshExpired() ) {
                console.debug( '[core.session] :: bootstrapJwtService() :: Access token expired or expiring; calling refreshSession().' );

                this.refreshSession()
                    .then( expirationDate => {

                        console.debug( '[core.session] :: bootstrapJwtService() :: refreshSession() returned with new expiration date; resolving whether not null.', expirationDate );

                        // Expiration date will either be Date or null,
                        // so success is whether it's not null.
                        defer.resolve( expirationDate !== null );
                    })
            }

            // Both the access and refresh tokens
            // are expired; restoration not possible.
            else {
                console.debug( '[core.session] :: bootstrapJwtService() :: Both tokens are expired; resolving false.' );
                defer.resolve( false );
            }
        }

        // Otherwise, resolve falsy.
        else {
            console.debug( '[core.session] :: bootstrapJwtService() :: core.jwtService is missing access and/or refresh tokens; resolving false.' );
            defer.resolve( false );
        }

        console.debug( '[core.session] :: bootstrapJwtService() :: Returning promise.' );
        return defer.promise;
    }

    /**
     * Calls APIv3 to refresh the session at the server level, which involves
     * regenerating access and refresh tokens before resolving the new access
     * token expiration back to the caller.
     */
    refreshSession() {
        let defer = this.$q.defer();

        console.debug( '[core.session] :: refreshSession() :: Starting.' );

        // We send both tokens for easy validation by the API when
        // tracking down the record to update.
        let accessToken = this.jwtService.getAccessToken(),
            refreshToken = this.jwtService.getRefreshToken();

        console.debug( '[core.session] :: refreshSession() :: Calling core.authService.refresh() with access and refresh tokens.', accessToken, refreshToken );

        this.authService.refresh( accessToken, refreshToken )
            .then( result => {
                try {
                    result = getInstanceCheckedValue( result, AuthResponseModel, null );

                    console.debug( '[core.session] :: refreshSession() :: core.authService.refresh() returned with result.', result );

                    if ( result === null )
                        throw new Error( 'Result is not an instance of AuthResponseModel.' );

                    if ( result.accessToken === null )
                        throw new Error( 'Result does not contain an access token.' );

                    console.debug( '[core.session] :: refreshSession() :: Calling core.jwtService.setTokens(), and passing in result.' );

                    // Set the new tokens.
                    this.jwtService.setTokens( result );

                    // Reset sessionTimeout, and resolve with the new expiration
                    // date for the caller (which might be the session-timeout
                    // component).
                    let expirationDate = this.jwtService.getExpiration();

                    console.debug( '[core.session] :: refreshSession() :: Received new expiration date from core.jwtService.getExpiration().', expirationDate );
                    console.debug( '[core.session] :: refreshSession() :: Calling core.sessionTimeoutService.extend(), and passing new expiration date.' );
                    this.sessionTimeoutService.extend( expirationDate );

                    console.debug( '[core.session] :: refreshSession() :: Calling signalR Hub Providers disconnect().' );
                    this.signalRAccountHub.disconnect();
                    this.signalRAnnouncementHub.disconnect();
                    this.signalRNotificationHub.disconnect();
                    this.signalRProcessingHub.disconnect();
                    console.debug( '[core.session] :: refreshSession() :: Calling _connectToSignalRHubs().' );
                    this._connectToSignalRHubs();

                    console.debug( '[core.session] :: refreshSession() :: Resolving with expiration date.' );
                    defer.resolve( expirationDate );
                } catch ( err ) {
                    console.warn( '[core.session] :: refreshSession() :: core.authService.refresh() returned in an invalid state.', err );
                    defer.resolve( null );
                }
            });

        console.debug( '[core.session] :: refreshSession() :: Returning promise.' );
        return defer.promise;
    }

    /**
     * Calls APIv3 to end the session at the server level, which involves
     * setting the SessionEnd to now and the IsRevoked flag to prevent
     * restarting with a refresh token.
     */
    logoutSession( isTimedOut = false ) {
        let defer = this.$q.defer();
    
        // We send the existing access token - even if it's expired - to
        // the API, so that the IsRevoked flag is set.
        let accessToken = this.jwtService.getAccessToken();
        this.authService.logout( accessToken )
            .then( result => {
                result = getInstanceCheckedValue( result, AuthResponseModel, null );

                // Whether the API response indicated a successful
                // logout.
                let complete = ( result !== null );

                // We're destroying everything in sessionTimeout.
                this.sessionTimeoutService.destroy( isTimedOut );

                console.debug( '[core.session] :: logoutSession done destroying sessionTimeout' );

                // We're destroying everything in the front-end.
                this.destroy();

                // Now resolve back to wherever we called from, which is
                // likely the sessionTimeout service.
                defer.resolve( complete );
            });

        return defer.promise;
    }

    /**
     * Listens to the sessionTimeoutService's timeout event, and - if expired -
     * stops listening and initiates the logout.
     * 
     * @param {boolean} isExpired 
     */
    timeoutSession( isTimedOut = false ) {
        isTimedOut = getTypeCheckedValue( isTimedOut, 'boolean', false );

        // We only care if the user timed out.
        if ( isTimedOut ) {

            // Unsubscribe first or sessionTimeout will keep calling this.
            this.sessionTimeout.unsubscribeTimeoutChanged( this.timeoutSession.bind( this ));

            // Initiate the logout.
            this.logoutSession( isTimedOut )
                .then( isSuccessful => {
                    console.log( '[core.session] :: timeoutSession post-API result', isSuccessful );
                });
        }
    }

    /**
     * Handles potential token changes initiated from another browser tab
     * with an active session. This handler will not fire for changes to
     * browser storage by the current tab, only other tabs.
     * 
     * WARNING: There exists an edge case in which a user logs in twice
     * across multiple tabs. This is not the same as copy-pasting the URL
     * from the current logged-in tab into another tab. We do not support
     * multiple logins by a single user. Any changes to session that are
     * initiated by two distinct tab sessions will be caught here, and
     * the odds are good that both will become corrupted.
     * 
     * @param {StorageEvent} e 
     */
    onBrowserStorageChanged( e ) {
        e = getInstanceCheckedValue( e, StorageEvent, null );
        if ( e !== null ) {
            if ( e.isTrusted && e.key === this.GLOBALS.SUPPORTED_PRODUCT_STORAGE_KEYS.session ) {

                let oldSession = AuthResponseModel.fromStr( e.oldValue ),
                    newSession = AuthResponseModel.fromStr( e.newValue );
                
                // Is old session's access token different from new session's
                // access token?
                if ( oldSession.accessToken !== newSession.accessToken ) {

                    // Did we logout?
                    if ( newSession.accessToken === null ) {

                        // We're destroying everything in sessionTimeout.
                        this.sessionTimeoutService.destroy( false );

                        // We're destroying everything in the front-end.
                        this.destroy();
                    }

                    // Did we refresh?
                    else {

                        // The access token that we currently have.
                        let currentToken = this.jwtService.getAccessToken();

                        // Presumably, oldSession should match current. We're
                        // just going to debug message if they're different.
                        if ( oldSession.accessToken !== currentToken ) {
                            console.debug( '[core.session] :: onBrowserStorageChanged expected event\'s old token to match the current jwtService.', oldSession.accessToken, currentToken );
                        }

                        // If newSession doesn't match the current token, we
                        // should extend sessionTimeoutService.
                        if ( newSession.accessToken !== currentToken ) {

                            // Set the new tokens.
                            this.jwtService.setTokens( newSession );

                            // Reset sessionTimeout, and resolve with the new expiration
                            // date for the caller (which might be the session-timeout
                            // component).
                            let expirationDate = this.jwtService.getExpiration();
                            this.sessionTimeoutService.extend( expirationDate );
                        }
                    }
                }
            }
        }
    }

    /**
     * Calls APIv3 to update the product's currently selected account. The
     * response will contain new access/refresh tokens and session state.
     * We'll update the jwtService and sessionTimeoutService before
     * resolving with sessionState itself. If this fails, we resolve with
     * null.
     * 
     * This is likely to be called primarily by getMultipleAccountsSelection
     * here in core.session, but both products contain scenarios in which
     * this can be called directly.
     * 
     * @param {*} productCode 
     * @param {*} newAccountId
     * @returns {SessionStateModel|null} 
     */
    switchSessionAccount( productCode, newAccountId ) {
        productCode = getTypeCheckedValue( productCode, 'string', null );
        newAccountId = getTypeCheckedValue( newAccountId, 'int', null );

        console.debug( '[core.session] :: switchSessionAccount starting; calling APIv3', productCode, newAccountId );

        let defer = this.$q.defer();

        this.authService.setAccount( productCode, newAccountId )
            .then( result => {
                result = getInstanceCheckedValue( result, AuthResponseModel, null );

                console.debug( '[core.session] :: switchSessionAccount APIv3 call finished', result );

                // We didn't receive an instance of AuthResponseModel.
                if ( result === null ) {
                    console.warn( '[core.session] :: switchSessionAccount expected instanceof AuthResponseModel', result );
                    defer.resolve( null );
                }

                // We're good... we're going to update the JWT service,
                // and restart sessionTimeout.
                else {
                    this.jwtService.setTokens( result );
                    let expirationDate = this.jwtService.getExpiration();
                    this.sessionTimeoutService.extend( expirationDate );

                    console.debug( '[core.session] :: switchSessionAccount updated jwtService and sessionTimeoutService with new expiration; resolving sessionState', expirationDate, result.sessionState );

                    // Resolve with session state.
                    defer.resolve( result.sessionState );
                }
            });

        return defer.promise;
    }

    /**
     * Shows the account selection modal, resolving back to the product session
     * only after updating session state in APIv3.
     * 
     * @param {string} productCode 
     * @param {object} modalDefaults
     * @param {object} modalOptions 
     * @param {boolean} isModalCancellable 
     * @param {number} currentAccountId 
     */
    getMultipleAccountsSelection( productCode, modalDefaults, modalOptions, isModalCancellable, currentAccountId ) {
        productCode = getTypeCheckedValue( productCode, 'string', null );
        modalDefaults = getTypeCheckedValue( modalDefaults, 'object', {} );
        modalOptions = getTypeCheckedValue( modalOptions, 'object', {} );
        isModalCancellable = getTypeCheckedValue( isModalCancellable, 'boolean', false );
        currentAccountId = getTypeCheckedValue( currentAccountId, 'int', null );

        console.debug( '[core.session] :: getMultipleAccountsSelection starting; firing modal', productCode, modalDefaults, modalOptions, isModalCancellable, currentAccountId );

        let defer = this.$q.defer();

        this.modalService.showModal( modalDefaults, modalOptions )
            .then( result => {
                result = getTypeCheckedValue( result, 'object', {} );
                let account = getTypeCheckedValue( result.account, 'object', {} ),
                    persist = getTypeCheckedValue( result.persist, 'boolean', false );

                console.debug( '[core.session] :: getMultipleAccountsSelection modal closed', account, persist );

                // Cancelling the modal does nothing, so if we've landed
                // here we have an actual selection. Products do not allow
                // for re-selecting the currently selected account, so
                // we check for that and resolve null if so.
                let accountIdProp = ( productCode === 'pb' )
                    ? 'pb_account_id'
                    : 'accountId';
                let newAccountId = getTypeCheckedValue( account[ accountIdProp ], 'int', null );

                // We selected an account that was different; call APIv3
                // and bring back the updated session state.
                if ( newAccountId !== null && newAccountId !== currentAccountId ) {
                    console.debug( '[core.session] :: getMultipleAccountsSelection calling switchSessionAccount', productCode, newAccountId );

                    this.switchSessionAccount( productCode, newAccountId )
                        .then( sessionState => {
                            console.debug( '[core.session] :: getMultipleAccountsSelection finished switchSessionAccount; received sessionState', sessionState );
                            defer.resolve({ newAccountId: newAccountId, sessionState: sessionState, persist: persist });
                        });
                }

                // Otherwise, we resolve nulls of:
                // first param: selected account ID
                // second param: session state from response
                // third param: whether the user elected to persist
                //              this account as their default product
                //              account
                else {
                    console.debug( '[core.session] :: getMultipleAccountsSelection selected the same account; resolving all null' );
                    defer.resolve({ newAccountId: null, sessionState: null, persist: null });
                }
            });

        return defer.promise;
    }

    /**
     * Helper method used primarily by utility-nav to check for whether the user
     * has access to a given product in the UI.
     * 
     * @param {string} productCode 
     * @param {boolean} checkValid 
     */
    hasProductAccess( productCode, checkValid = false ) {
        let accessProp = `${productCode}Access`,
            authenticatedProp = `${productCode}Authenticated`,
            validProp = `${productCode}Valid`;

        let hasProductAccess = false;

        if ( this.hasOwnProperty( accessProp ) &&
            this.hasOwnProperty( authenticatedProp ) &&
            this.hasOwnProperty( validProp )) {
            
            let hasAccess = this[ accessProp ] === true,
                authenticated = this[ authenticatedProp ] === true,
                valid = this[ validProp ] === true;

            hasProductAccess = ( hasAccess && authenticated );

            if ( checkValid ) {
                hasProductAccess = ( hasProductAccess && valid );
            }
        }

        return hasProductAccess;
    }

    /**
     * Helper method used primarily by utility-nav to check for whether the user
     * has access to ProspectBase in the UI.
     * 
     * @param {boolean} checkValid 
     */
    hasProspectBaseAccess( checkValid = false ) {
        return this.hasProductAccess( 'pb', checkValid );
    }

    /**
     * Helper method used primarily by utility-nav to check for whether the user
     * has access to ProspectBase in the UI.
     * 
     * @param {boolean} checkValid 
     */
    hasMarketMagnifierAccess( checkValid = false ) {
        return this.hasProductAccess('mm', checkValid);
    }

    /**
     * Kills the global application session, trickles down through all
     * product bundles for the same, clears the browser's localStorage,
     * unsets the ACL role, closes any web socket channels, and
     * broadcasts the global session change event. 
     */
    destroy() {
        const productSessionDestroy = ( productKey ) => {
            const productSession = this._getProductSessionService( productKey );
            if ( productSession !== null && typeof productSession.destroy === 'function' ) {
                productSession.destroy();
            }
        };

        console.debug( '[core.session] :: destroying core and product session services' );

        // Stop listening for browser storage changes.
        window.removeEventListener( 'storage', this.onBrowserStorageChanged.bind( this ));

        // Disconnect from socket services.
        if ( this.userId !== null ) {
            this.signalRAccountHub.disconnect();
            this.signalRAnnouncementHub.disconnect();
            this.signalRNotificationHub.disconnect();
        }

        // Destroy the ProspectBase session.
        if ( this.pbAccess ) {
            productSessionDestroy( 'pb' );
        }

        // Destroy the Market Magnifier session.
        if ( this.mmAccess ) {
            productSessionDestroy( 'mm' );
        }

        console.log( 'Destroying global session.' );

        // Destroy global stored values unless exempted.
        this.storageService.destroyByKeys([
            this.GLOBALS.SUPPORTED_PRODUCT_STORAGE_KEYS.session,
            this.GLOBALS.SUPPORTED_PRODUCT_STORAGE_KEYS.mpTimeout,
            this.GLOBALS.LEGAL_ACCEPTANCE_STORAGE_KEY
        ]);

        // Reset all basic user variables.
        this.userId                 = null;
        this.username               = null;
        this.firstName              = null;
        this.lastName               = null;
        this.displayName            = null;
        this.roles                  = [];
        this.defaultApp             = null;

        // Reset impersonation flag/data
        this.isImpersonatingUser    = false;
        this.impersonatingUser      = {};

        // Reset all proc/flag/toggle status variables.
        this.isSafariUser           = false;
        this.isMultiPortalAccess    = false;
        this.pbAccess               = false;
        this.pbAuthenticated        = false;
        this.pbValid                = false;
        this.mmAccess               = false;
        this.mmAuthenticated        = false;
        this.mmValid                = false;

        // Reset the ACL role.
        this.aclService.setRole( null );

        // Broadcast global session change.
        this.eventService.broadcastSessionChanged();
    }

    /**
     * Fetches the user's "display name," a space-separated concatenation of
     * their first and last names.
     * 
     * @return {string} The user's full "first last" name.
     */
    getDisplayName() {
        return ( this.firstName !== null && this.lastName !== null )
            ? `${this.firstName} ${this.lastName}`
            : '';
    }

    /**
     * Get the active account for the default app
     */
    getActiveAccount(){
        let productSession = this._getProductSessionService( this.defaultApp );
        return productSession.getActiveAccount();
    }

    /**
     * Uses the defaultApp key to launch a product's multi-account select modal, 
     * as triggered by the utility-nav component. If the defaultApp key is not
     * set or the product does not have multiple accounts to choose from, this
     * returns false.
     * 
     * @param {boolean} isCancellable Whether the multi-account select should enable
     * cancel functionality.
     * @return {boolean|promise} The product-specific account selection modal promise,
     * or false.
     */
    getMultipleAccountSelectModal( isCancellable ) {
        if ( this.isMultipleAccountsAvailable() ) {
            let productSession = this._getProductSessionService( this.defaultApp );
            return productSession.getMultipleAccountsSelection( isCancellable );
        }

        return false;
    }

    /**
     * Determines whether the user has multiple accounts to choose from, either
     * multiple products or multiple accounts within a product. Uses the defaultApp
     * key to determine which product to check for multiple accounts.
     * 
     * @return {boolean} Whether the user has multiple accounts within the portal.
     */
    isMultipleAccountsAvailable() {
        if ( this.defaultApp !== null ) {
            let productSession = this._getProductSessionService( this.defaultApp );
            if ( productSession !== null && productSession.isMultipleAccountsAvailable() ) {
                return true;
            }
        }

        return false;
    }

    isGuestUser() {
        if ( !this.pbAccess && !this.mmAccess ){
            return true;
        }

        if ( this.pbAccess && !this.mmAccess ){
            let productSession = this._getProductSessionService( 'pb' );
            if ( productSession !== null && productSession.isGuest ){
                return true;
            }
        } 

        return false;
    }

    /**
     * Determines whether the user is a guest with elevated privileges. Only
     * applies to ProspectBase.
     * 
     * @return {Boolean} Whether the user is an "authenticated guest".
     */
    isAuthenticatedGuestUser() {
        if ( this.pbAccess ) {
            let productSession = this._getProductSessionService( 'pb' );
            if ( productSession !== null && productSession.isAuthenticatedGuest ) {
                return true;
            }
        }

        return false;
    }

    /**
     * Parses the roles of the loaded products to determine if the
     * user is an internal (system) LN user.
     * 
     * morrro01@11/02/20: This is used in place of the "showLegalAcceptance"
     * function in the aclService when checking for whether to show
     * the Barry Litigation modal.
     * 
     * @returns {boolean}
     */
    isInternalUser() {
        let isInternal = false

        if ( this.pbAccess || this.mmAccess || this.impersonatingUser !== null ) {
            let roles = [];

            let getProductRole = ( productCode ) => {
                let productRole = null,
                    productSession = this._getProductSessionService( productCode );

                if ( productSession != null && productSession.role !== null )
                    productRole = productSession.role;

                return productRole;
            };

            if ( this.pbAccess ) {
                let pbRole = getProductRole( 'pb' );
                if ( pbRole !== null && roles.indexOf( pbRole ) === -1 )
                    roles.push( pbRole );
            }

            if ( this.mmAccess ) {
                let mmRole = getProductRole( 'mm' );
                if ( mmRole !== null && roles.indexOf( mmRole ) === -1 )
                    roles.push( mmRole );
            }

            if ( this.impersonatingUser !== null &&
                this.impersonatingUser.roles &&
                this.impersonatingUser.roles !== null ) {

                Object.keys( this.impersonatingUser.roles )
                    .forEach( roleKey => {
                        let impersonatingRole = this.impersonatingUser.roles[ roleKey ];
                        if ( impersonatingRole !== null && roles.indexOf( impersonatingRole ) === -1 )
                            roles.push( impersonatingRole );
                    });
            }
            
            roles.forEach( role => {
                if ( isInternalRole( role )) {
                    isInternal = true;
                }
            });
        }

        return isInternal;
    }

    hasAccess() {
        if (!this.pbAccess && !this.mmAccess) {
            return true;
        }

        return false;
    }

    /**
     * Determines whether the user has access to multiple products within A&R, which
     * means just checking whether one or more "authenticated" props are set to true.
     * 
     * @return {boolean} Whether the user has multiple products within the portal.
     */
    isMultipleProductAccess() {
        return ( Object.keys( this ))
            .filter( sessionKey => sessionKey.indexOf( 'Authenticated' ) !== -1 )
            .filter( sessionKey => this[ sessionKey ] === true )
            .length > 1;
    }

    /**
     * Determines whether the user is authenticated for use within any product.
     * 
     * @return {boolean} Whether the user has a valid session (is authenticated).
     */
    isAuthenticated() {
        return ( this.pbAuthenticated || this.mmAuthenticated );
    }

    /**
     * In the event that a user has no valid session data to parse for bundle
     * loading, we'll need to intervene and bootstrap a fallback in order to
     * present a guest interface.
     */
    bootstrapFallback() {
        let productKey = this.GLOBALS.FALLBACK_BUNDLE.productKey,
            defer = this.$q.defer();

        console.debug( '[core.session] :: bootstrapFallback firing for PB guest' );

        let productSession = null;

        this._loadBundle( productKey )
            .then( loadedModuleKey => {
                try {
                    productSession = this._getProductSessionService( productKey );    
                    if ( productSession === null || typeof productSession.bootstrap !== 'function' ) {
                        defer.reject( `"${serviceName}" is not a valid or registered product session service, and/or is missing the required "bootstrap()" method.` );
                        return;
                    }

                    let defaultAsGuest = AuthResponseModel.defaultAsGuest();
                    console.debug( '[core.session] :: bootstrapFallback generated default guest model', defaultAsGuest );

                    productSession.bootstrap( defaultAsGuest.sessionState, productKey );
                    console.debug( `[core.session] :: bootstrapFallback called ${productKey}'s session bootstrap` );

                    defer.resolve([ productKey ]);
                } catch ( e ) {
                    console.warn( e );
                    defer.reject();
                }
            });

        return defer.promise;
    }

    /**
     * Ensure the user has accepted the Berry Litigation modal.
     */
    getLegalAcceptance() {
        //console.log('get Legal Acceptance');
        const self = this;

        let defer = this.$q.defer(),
            previouslyAcknowleged = this.storageService.get( self.GLOBALS.LEGAL_ACCEPTANCE_STORAGE_KEY, false ),
            legalAccepatanceModals = document.getElementsByClassName('modal legal-acceptance');

        // Note: any truthy condition of the following block - if it intends to
        // simply resolve true while expecting the site to actually load (e.g.,
        // valid authenticated user), MUST manually call _connectToSignalRHubs.
        // Failing to do so will result in the user appearing not authenticated
        // in the utility-nav. This currently only needs to be done for internal
        // system users to bypass the modal.

        if ( legalAccepatanceModals.length > 0 ){
            console.log( '    modal already displayed' );
            defer.resolve( true );            
        } else if ( this.isInternalUser() ) {
            console.log( '    internal users do not need the legal stuff' );
            self.storageService.set( self.GLOBALS.LEGAL_ACCEPTANCE_STORAGE_KEY, '1', false );
            self._connectToSignalRHubs();
            defer.resolve( true );
        } else if ( previouslyAcknowleged === '1' ){
            console.log( '    previously accepted' );
            defer.resolve( true );
        } else if ( this.isGuestUser() ){
            console.log( '    guests do not need the see the legal stuff' );
            self.storageService.set( self.GLOBALS.LEGAL_ACCEPTANCE_STORAGE_KEY, '1', false );
            defer.resolve( true );
        } else if ( this.hasAccess() ){
            console.log( '    un-authenticated users are taken to the guest page, no legal modal to display (yet)' );
            defer.resolve( true );
        } else {
            console.log('    show modal');
            let modalDefaults = {
                template: legalAcceptanceModalTemplate,
                keyboard: false,
                windowClass: 'legal-acceptance'
            },
            modalOptions = {
                headerText: 'components.global.modal.legalAcceptance.header',
                closeButtonShown: false,
                closeButtonEnabled: true,
                closeButtonText: 'components.global.modal.legalAcceptance.buttons.decline', 
                actionButtonEnabled: true,
                actionButtonText: 'components.global.modal.legalAcceptance.buttons.accept',
                bodyText: 'components.global.modal.legalAcceptance.body',
                products: this.productKeys
            };

            // Pop the modal.
            this.modalService.showModal( modalDefaults, modalOptions )
                .then( () => {                   
                    self.authService.acceptLegal( self.userId, self.username, self.firstName, self.lastName )
                        .then( ( response ) => {
                            self.storageService.set( self.GLOBALS.LEGAL_ACCEPTANCE_STORAGE_KEY, '1', false );
                            self._connectToSignalRHubs();
                        })
                        .catch( () => {
                            //do nothing, but the modal will continue to appear
                        })
                        .finally( () => {
                            //allow user to enter site                            
                            defer.resolve( true );
                        });
                })
                .catch( ()=> {
                    self.logoutSession( false )
                        .then( isComplete => {
                            isComplete = getTypeCheckedValue( isComplete, 'boolean', false );
                            console.debug( '[core.session] :: getLegalAcceptance() :: core.session.logoutSession() returned.', isComplete );

                            window.location.assign( self.GLOBALS.ENDPOINTS.logout );
                        });
                });

        }

        return defer.promise;
    }

    /**
     * Fetches an Angular service reference for a given product after bootstrapping
     * it. Each product session is responsible for initializing itself from the
     * provided sessionState and defaultApp values.
     * 
     * @param {string} productKey
     * @param {SessionStateModel} sessionState 
     * @param {string} defaultApp
     */
    _getBootstrappedProductSession( productKey, sessionState, defaultApp ) {
        productKey = getTypeCheckedValue( productKey, 'string', 'pb' );
        sessionState = getInstanceCheckedValue( sessionState, SessionStateModel, null );
        defaultApp = getTypeCheckedValue( defaultApp, 'string', null );

        let bootstrappedProductSession = null;

        try {

            // Dynamically pull the product session service from Angular.
            let productSession = this._getProductSessionService( productKey );
            if ( productSession === null || typeof productSession.bootstrap !== 'function' )
                throw new Error( `"${serviceName}" is not a valid or registered product session service, and/or is missing the required "bootstrap()" method.` );
            
            // Bootstrap; this returns nothing, and isn't async.
            productSession.bootstrap( sessionState, defaultApp );

            // We're good.
            bootstrappedProductSession = productSession;
        } catch ( err ) {
            console.warn( `Error while fetching bootstrapped product session for ${productKey}.`, err );
        }

        return bootstrappedProductSession;
    }

    _getProductSessionService( productKey ) {
        let productSession = null;

        try {
            if ( !this.GLOBALS.SUPPORTED_PRODUCTS.includes( productKey )) {
                throw new Error( `"${productKey}" is not a valid or supported product key.` );
            }

            const serviceName = `${productKey}Session`;
            if ( this.$injector.has( serviceName )) {
                productSession = this.$injector.get( serviceName );
            }
        } catch ( e ) {
            console.error( e );
            productSession = null;
        } finally {
            return productSession;
        }
    }

    _loadBundle( productKey ) {
        let defer = this.$q.defer();

        try {
            const isBundleLoaded = ( bundleUrl ) => {
                bundleUrl = getTypeCheckedValue( bundleUrl, 'string', null );

                console.debug( '[core.session] :: _loadBundle.isBundleLoaded() :: Checking for existing script reference.', bundleUrl );

                if ( bundleUrl !== null ) {
                    bundleUrl = `${window.location.protocol}//${window.location.host}${bundleUrl}`;

                    let scripts = document.getElementsByTagName( 'script' );

                    for ( let i = scripts.length; i--; ) {
                        let loadedSrc = scripts[ i ].src;

                        if ( typeof loadedSrc === 'string' &&
                            loadedSrc !== '' &&
                            loadedSrc.toLowerCase() === bundleUrl.toLowerCase() ) {

                            console.debug( '[core.session] :: _loadBundle.isBundleLoaded() :: Bundle URL exists. Returning true.' );
                            return true;
                        }
                    }
                }

                console.debug( '[core.session] :: _loadBundle.isBundleLoaded() :: Bundle URL not found. Returning false.' );
                return false;
            };

            const createDynamicAssetElem = ( elemType, assetUrl, callback ) => {
                let assetElem = document.createElement( elemType );
                assetElem.onload = function() {
                    if ( elemType === 'script' ) {
                        moduleLoader.loadModule( `marketing-portal.${productKey}` );
                    }

                    callback();
                };

                if ( elemType === 'script' )
                    assetElem.src = assetUrl;
                    
                if ( elemType === 'link' ) {
                    assetElem.href = assetUrl;
                    assetElem.rel = 'stylesheet';
                }

                document.head.appendChild( assetElem );
            };

            let scriptUrl = `/assets/js/mp.${productKey}.js`,
                stylesUrl = `/assets/css/mp.${productKey}.css`;

            console.debug( `[core.session] :: _loadBundle() :: Starting for "${productKey}" with script and style URLs.`, scriptUrl, stylesUrl );

            // We've already loaded this bundle...
            if ( isBundleLoaded( scriptUrl )) {
                console.debug( `[core.session] :: _loadBundle() :: Bundle already loaded; resolving product key of "${productKey}."` );
                defer.resolve( productKey );
            }
            
            // We need to load it.
            else {
                console.debug( `[core.session] :: _loadBundle() :: Bundle not already loaded; creating script ref to "${scriptUrl}".` );

                createDynamicAssetElem( 'script', scriptUrl, () => {

                    console.debug( `[core.session] :: _loadBundle() :: Bundle not already loaded; creating stylesheet ref to "${stylesUrl}".` );
                    createDynamicAssetElem( 'link', stylesUrl, () => {

                        console.debug( `[core.session] :: _loadBundle() :: Bundle now loaded; resolving product key of "${productKey}".` );
                        defer.resolve( productKey );
                    });
                });
            }
        } catch ( err ) {
            console.warn( err );
            defer.resolve( null );
        }

        return defer.promise;
    }

    /**
     * Initializes WebSocket channel connections.
     */
    _connectToSignalRHubs() {
        // Socket connections.
        if ( this.userId !== null ) {

            const canConnectToHub = ( requiredProducts ) => {
                let canConnect = false;

                if ( Array.isArray( requiredProducts )) {

                    // All product account allocations can use it.
                    if ( requiredProducts.length === 1 &&
                        requiredProducts[ 0 ] === '*' )
                        canConnect = true;

                    else {
                        requiredProducts.forEach( pk => {
                            const accessProp = `${pk.toLowerCase()}Access`;
                            const authenticatedProp = `${pk.toLowerCase()}Authenticated`;

                            const hasAccess = (
                                this.hasOwnProperty( accessProp ) &&
                                typeof this[ accessProp ] === 'boolean' &&
                                this[ accessProp ] === true
                            );

                            const isAuthenticated = (
                                this.hasOwnProperty( authenticatedProp ) &&
                                typeof this[ authenticatedProp ] === 'boolean' &&
                                this[ authenticatedProp ] === true
                            );

                            if ( hasAccess && isAuthenticated )
                                canConnect = true;
                        });
                    }
                }

                return canConnect;
            };
            
            if ( canConnectToHub( this.SIGNALR_CONSTANTS.PRODUCTS_ACCOUNT_HUB ))
                this.signalRAccountHub.connect();

            if ( canConnectToHub( this.SIGNALR_CONSTANTS.PRODUCTS_ANNOUNCEMENT_HUB ))
                this.signalRAnnouncementHub.connect();

            if ( canConnectToHub( this.SIGNALR_CONSTANTS.PRODUCTS_NOTIFICATION_HUB ))
                this.signalRNotificationHub.connect();

            if ( canConnectToHub( this.SIGNALR_CONSTANTS.PRODUCTS_PROCESSING_HUB ))
                this.signalRProcessingHub.connect();
        }

        this.eventService.broadcastSessionChanged();
    }
}

Core.service( 'Session', Session );
