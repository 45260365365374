// Local dependencies.
import Core from '../../../modules/core/core.module';
import template from './breadcrumb-header.template.html';
import './breadcrumb-header.styles.scss';

/**
 * @name breadcrumbHeaderView
 * @memberof CoreBundle
 * @class
 * 
 * @classdesc
 * AngularJS component.
 */
class BreadcrumbHeaderController {
    constructor( $scope, $state, $timeout, $translate, eventService, GLOBALS, Session ) {
        this.$scope = $scope;
        this.$state = $state;
        this.$timeout = $timeout;
        this.$translate = $translate;
        this.eventService = eventService;
        this.GLOBALS = GLOBALS;
        this.Session = Session;
    }

    $onInit() {
        this.$scope.productKey = null;
        this.$scope.productName = '';
        this.$scope.productRoute = null;
        this.$scope.initialize = this.initialize.bind( this );
        this.$scope.returnToProductHome = this.returnToProductHome.bind( this );

        this.eventService.listenGlobalSessionLoaded( this.initialize.bind( this ));
    }

    /**
     * Resolves the session's default app, and populates a translated
     * product name and associated route for the UI.
     * 
     * Called twice:
     * - Once on init (HTML attribute) in case we navigated here.
     * - Once on global session loading in case we refreshed here.
     */
    initialize() {
        let productKey = this.$scope.productKey,
            translate = false;

        this.$timeout(() => {

            if ( this.Session.defaultApp !== productKey ) {
                productKey = this.Session.defaultApp;
                translate = true;
            }

            let productRoute = ( productKey !== null )
                ? this.GLOBALS.PRODUCT_LANDINGS.fromBreadcrumb[ productKey ]
                : undefined;

            if ( productKey !== null && translate ) {
                this.$translate( `global.products.${productKey}` )
                    .then( productName => {
                        this.$scope.productName = productName;
                        this.$scope.productRoute = productRoute || null;
                    });
            }
        }, 250 );
    }

    /**
     * Click handler that returns the user to the product session home.
     */
    returnToProductHome() {
        if ( this.$scope.productRoute !== null ) {
            this.$state.go( this.$scope.productRoute );
        }
    }
}

const breadcrumbHeaderView = {
    template,
    controller: BreadcrumbHeaderController
};

Core.component( 'breadcrumbHeaderView', breadcrumbHeaderView );