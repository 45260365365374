import Core from '../../../modules/core/core.module';

/**
 * @name NumericBinding
 * @memberof CoreBundle.Core
 * @class
 * 
 * @classdesc
 * AngularJS directive. Form elements with this attributes will ensure a
 * consistent numeric formatting.
 */
class NumericBinding {
    constructor() {
        this.restrict = 'A';
        this.require = 'ngModel';
    }

    link( scope, element, attrs, ngModel ) {
        ngModel.$parsers.push( function( value ) {
            return '' + value;
        });

        ngModel.$formatters.push( function( value ) {
            return parseFloat( value );
        });
    }
}

Core.directive( 'numericBinding', () => new NumericBinding );
