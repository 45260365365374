import Core from './core.module';

/**
 * @name routeConfig
 * @memberof CoreBundle.Core
 * @kind function
 * 
 * @description
 * Route configuration for all shared or centralized views and/or states
 * within the Acquisition & Retention portal.
 */
function routeConfig( $stateProvider, $locationProvider, $urlRouterProvider ) {
    $locationProvider.html5Mode( true );

    const getLegalAcceptance = ( Session, $q ) => {
        let defer = $q.defer();

        Session.getLegalAcceptance( ).then( ( response ) => {
            defer.resolve( response );
        })

        return defer.promise;
    }

    $stateProvider
        .state( 'core', {
            url: '/',
            data: {
                requireLogin: true
            }
        })

        .state( 'mp', {
            abstract: true,
            url: '',
            resolve: {
                legalAcceptance: getLegalAcceptance,
                product: () => { return 'core';}
            }
        })

        .state( 'mp.announcements', {
            url: '/announcements',
            data: {
                requireLogin: true,
                bodyClass: 'ar'
            },
            views: {
                'header@': {
                    component: 'breadcrumbHeaderView'
                },
                'footer@': {
                    component: 'footerView'
                },
                'workspace@': {
                    component: 'announcementsView'
                }
            }
        })

        .state( 'mp.landing', {
            url: '/landing',
            data: {
                requireLogin: true,
                bodyClass: 'ar',
            },
            views: {
                'header@': {
                        
                },
                'footer@': {
                    component: 'footerView'                    
                },                    
                'workspace@': {
                    component: 'landingView'
                }
            }
        })

        .state( 'mp.notification-history', {
            url: '/notification-history',
            data: {
                //requireLogin: true,
                bodyClass: 'ar'
            },
            views: {
                'header@': {},
                'footer@': { component: 'footerView' },
                'workspace@': { component: 'notificationHistoryView' }
            }
        })

        .state( 'mp.unauthorized', {
            url: '/unauthorized',
            views: {
                'workspace@': {
                    component: 'unauthorizedView'
                }
            }
        });
}

Core.config( routeConfig );