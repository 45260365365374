import Core from '../../modules/core/core.module';
import { SignalRHubBaseModel } from '../../models/core';

class SignalRAnnouncementHub extends SignalRHubBaseModel {
    constructor( SIGNALR_CONSTANTS ) {
        'ngInject';
        super( SIGNALR_CONSTANTS, 'announcement' );

        this.subscriptions.onUnseenCountReceived = [];
        this.subscriptions.onSeenComplete = [];
        this.subscriptions.onAllSeenComplete = [];
    }

    /**
     * Callback fired once connection has been established with the hub. In this
     * instance we fetch a count of unseen announcements.
     */
    _onConnected() {
        this.log( '_onConnected', 'Requesting unseen announcement count.' );
        this._unseenCount();
    }

    //
    // CLIENT-TO-SERVER HANDLERS
    //
    _unseenCount() {
        this.log( '_unseenCount', 'Calling...' );
        this.client.invoke( 'UnseenCount' );
    }

    _viewAllUserAnnouncements() {
        this.log( '_viewAllUserAnnouncements', 'Calling...' );
        this.client.invoke( 'ViewAllUserAnnouncements' );
    }

    _viewUserAnnouncement( announcementId ) {
        this.log( '_viewUserAnnouncement', `Calling with announcement ID ${announcementId}...` );
        this.client.invoke( 'ViewUserAnnouncement', announcementId );
    }

    //
    // SERVER-TO-CLIENT HANDLERS
    //
    _handleAllSeenComplete( reply ) {
        this.log( '_handleAllSeenComplete', 'Received.', reply );

        reply = getTypeCheckedValue( reply, 'object', {} );
        const unseenCount = getTypeCheckedValue( reply.unseenCount, 'int', null );

        this._handleSubscription( 'onAllSeenComplete', { unseenCount });
        this._handleSubscription( 'onUnseenCountReceived', { unseenCount });
    }

    _handleSeenComplete( reply ) {
        this.log( '_handleSeenComplete', 'Received.', reply );

        reply = getTypeCheckedValue( reply, 'object', {} );
        const unseenCount = getTypeCheckedValue( reply.unseenCount, 'int', null );
        const announcementId = getTypeCheckedValue( reply.announcementId, 'int', null );

        this._handleSubscription( 'onSeenComplete', { unseenCount, announcementId });
        this._handleSubscription( 'onUnseenCountReceived', { unseenCount });
    }

    _handleUnseenCountReceived( reply ) {
        this.log( '_handleUnseenCountReceived', 'Received.', reply );

        reply = getTypeCheckedValue( reply, 'object', {} );
        const unseenCount = getTypeCheckedValue( reply.unseenCount, 'int', 0 );

        this._handleSubscription( 'onUnseenCountReceived', { unseenCount });
    }

    _get() {
        return {
            allAnnouncementsSeen: () =>
                this._viewAllUserAnnouncements(),

            announcementSeen: ( announcementId ) =>
                this._viewUserAnnouncement( announcementId ),

            getUnseenCount: () =>
                this._unseenCount(),
        };
    }
}

Core.provider( 'signalRAnnouncementHub', SignalRAnnouncementHub );