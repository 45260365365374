import angular from 'angular';
import Core from './core.module';

/**
 * @name containsFilter
 * @memberof CoreBundle.Core
 * @kind function
 * 
 * @description
 * AngularJS filter. Determines whether a value is contained within
 * an object array.
 * 
 * @param {array} items An array of objects to check.
 * @param {string} field The name of a field in each object of the array to check.
 * @param {mixed} containsValue The value to check for.
 * @return {boolean}
 */
Core.filter( 'containsFilter', () => {
    return ( items, field, containsValue ) => {
        let out = [],
            searchText = containsValue.toLowerCase();

        if ( field === false || containsValue === false ) {
            return items;
        }

        angular.forEach( items, function( item ) {
            try {
                if ( item[ field ].toLowerCase().indexOf( searchText ) >= 0 ) {
                    out.push( item );
                }
            } catch ( _ ) {
                // Do nothing, as value will just not be included in the filtered
                // result.
            }
        });

        return out;
    };
});

/**
 * @name propsFilter
 * @memberof CoreBundle.Core
 * @kind function
 * 
 * @description
 * AngularJS filter. Returns an array filtered on the provided properties object.
 * 
 * @param {array} items An array of objects to filter.
 * @param {object} props The properties object to filter by.
 * @return {array}
 */
Core.filter( 'propsFilter', () => {
    return ( items, props ) => {
        let out = [];
            
        if ( angular.isArray( items ) ) {
            items.forEach( function( item ) {
                let itemMatches = false;
                
                let keys = Object.keys( props );
                for ( let i = 0; i < keys.length; i++ ) {
                    let prop = keys[ i ];
                    let text = props[ prop ].toLowerCase();
                    if ( item[ prop ] === undefined || item[ prop ] === null ) {
                        break;
                    }
                    
                    if ( item[ prop ].toString().toLowerCase().indexOf( text ) !== -1 ) {
                        itemMatches = true;
                        break;
                    }
                }
                
                if ( itemMatches ) {
                    out.push( item );
                }
            });
        } else {
            out = items;
        }
        
        return out;        
    };
});

/**
 * @name uniqueFilter
 * @memberof CoreBundle.Core
 * @kind function
 * 
 * @description
 * AngularJS filter. Returns an array containing only unique item instances.
 * 
 * @param {array} items An array of objects to filter.
 * @param {object} filterOn The properties object to filter by.
 * @return {array}
 */
Core.filter( 'uniqueFilter', () => {
    return ( items, filterOn ) => {
        if (filterOn === false) {
        return items;
        }

        if ((filterOn || angular.isUndefined(filterOn)) && angular.isArray(items)) {
        let hashCheck = {}, newItems = [];

        let extractValueToCompare = function (item) {
            if (angular.isObject(item) && angular.isString(filterOn)) {
            return item[filterOn];
            } else {
            return item;
            }
        };

        angular.forEach(items, function (item) {
            let valueToCheck, isDuplicate = false;

            for (let i = 0; i < newItems.length; i++) {
            if (angular.equals(extractValueToCompare(newItems[i]), extractValueToCompare(item))) {
                isDuplicate = true;
                break;
            }
            }
            if (!isDuplicate) {
            newItems.push(item);
            }

        });
        items = newItems;
        }
        return items;        
    };
});

/**
 * @name startsWithFilter
 * @memberof CoreBundle.Core
 * @kind function
 * 
 * @description
 * AngularJS filter. Filters an object array by one or more properties, and
 * whether those properties start with a value.
 * 
 * @param {array} items An array of objects to filter.
 * @param {string} searchValue The value to check.
 * @param {string} searchProperty The property to check.
 * @param {object} properties An object of properties used as configuration.
 * @return {array}
 */
Core.filter( 'startsWithFilter', ( $filter ) => {
    return ( items, searchValue, searchProperty, properties ) => {
        if (searchValue === undefined || searchValue == '' || searchProperty === undefined || searchProperty == '') {
            return $filter('orderBy')(items, properties);
        }

        searchValue = searchValue.toLowerCase();
        let searchValueLength = searchValue.length;
        let startsWith = [];
        let contains = [];
        let extractValueToCompare = function (item) {
            if (angular.isObject(item) && angular.isString(searchProperty)) {
                return item[searchProperty];
            } else {
                return item;
            }
        };            
        
        angular.forEach(items, function(item){
            let itemValue = extractValueToCompare(item);
            if (itemValue.substring(0, searchValueLength).toLowerCase() == searchValue){
                startsWith.push(item);
            } else {
                contains.push(item);
            }
        });
        if (startsWith.length > 0){
            startsWith = $filter('orderBy')(startsWith, properties);
        }
        
        if (contains.length > 0){
            contains = $filter('orderBy')(contains, properties);
        }
        return startsWith.concat(contains);        
    };
});

/**
 * @name formatDate
 * @memberof CoreBundle.Core
 * @kind function
 * 
 * @description
 * AngularJS filter. Consumes a date/time from PHP, and returns it as a valid
 * JavaScript Date object.
 * 
 * @param {string} phpDate The PHP date string to parse.
 * @return {Date}
 */
Core.filter( 'formatDate', () => {
    return ( phpDate ) => {
        if( phpDate != null ){
            //removing the T from APIv3 DateTime. Ex: "2019-09-01T20:00:00"
            phpDate = phpDate.replace('T',' ');

            let date = phpDate.split( '-' ).join( '/' );
            return new Date( date );       
        } 
    };
});

/**
 * @name formatString
 * @memberof CoreBundle.Core
 * @kind function
 * 
 * @description
 * AngularJS filter. Consumes a tokenized string, and returns that string
 * after replacing the tokens with the provided values.
 * 
 * @param {string} format The tokenized string literal.
 * @param {object} values A key-value object mapping token to value.
 * @return {string}
 */
Core.filter( 'formatString', () => {
    return ( format, values ) => {
        if ( !values || !values.length || !format ) {
            return format;
        }

        let result = '';

        for ( let i = 0; ; ) {
            // Find the next opening/closing brace.
            let open = format.indexOf( '{', i ),
                closed = format.indexOf( '}', i );
            
            if (( open < 0 ) && ( closed < 0 )) {
                // Not found. Copy the end of the string, and break.
                result += format.slice( i );
                break;
            }

            if (( closed > 0 ) && (( closed < open ) || ( open < 0 ))) {
                if ( format.charAt( closed + 1 ) !== '}' ) {
                    throw new Error( 'format stringFormatBraceMismatch' );
                }

                result += format.slice( i, closed + 1 );
                i = closed + 2;
                continue;
            }

            // Copy the string before the brace.
            result += format.slice( i, open );
            i = open + 1;

            // Check for double braces (which display as one; not arguments).
            if ( format.charAt( i ) === '{' ) {
                result += '{';
                i++;
                continue;
            }

            if ( closed < 0 ) {
                throw new Error( 'format stringFormatBraceMismatch' );
            }

            // Find the closing brace.
            let brace = format.substring( i, closed ),
                colonIdx = brace.indexOf( ':' ),
                argNum = parseInt(( colonIdx < 0 ) ? brace : brace.substring( 0, colonIdx ), 10 );

            if ( isNaN( argNum )) {
                throw new Error( 'format stringFormatInvalid' );
            }

            let argFormat = ( colonIdx < 0 ) ? '' : brace.substring( colonIdx + 1 ),
                arg = values[ argNum ];
            if ( typeof( arg ) === 'undefined' || arg === null ) {
                arg = '';
            }

            if ( arg.formatString ) {
                result += arg.formatString( argFormat );
            } else if ( arg.format ) {
                result += arg.format( argFormat );
            } else {
                result += arg.toString();
            }

            i = closed + 1;
        }

        return result;
    };
});

/**
 * @name formatNumber
 * @memberof CoreBundle.Core
 * @kind function
 * 
 * @description
 * AngularJS filter. Consumes a string, and parses it into a number when possible.
 * 
 * @param {string} input The number string.
 * @return {number}
 */
Core.filter( 'formatNumber', ( input ) => {
    return parseInt( input, 10 );
});

/**
 * @name formatLocalizedDate
 * @memberof CoreBundle.Core
 * @kind function
 * 
 * @description
 * AngularJS filter. Consumes a date and format string, and returns a translated
 * variant according to the internationalization configuration.
 * 
 * @param {Date} date The date to localize.
 * @param {string} format The translation key to use.
 * @return {string}
 */
Core.filter( 'formatLocalizedDate', ( $translate, $filter ) => {
    let isWaiting = false,
        translation = null,
        cache = {};

    const dateFilter = ( date, format ) => {
        let filteredDate = "Loading..."
        if(cache.hasOwnProperty(format)){
            filteredDate = $filter('date')( convertUTCDateToLocalDate( new Date(date) ), cache[format]);
        } else {
            if (isWaiting === false){
                isWaiting = true;

                $translate(format).then(function(translatedText) {
                    cache[format] = translatedText;
                    filteredDate = $filter('date')(convertUTCDateToLocalDate( new Date(date) ), translation) 
                    isWaiting = false;
                });                        
            }
        }
        return filteredDate;
    };

    dateFilter.$stateful = true;
    return dateFilter;
});

Core.filter( 'formatLocalizedDateMoment', ( $translate, $filter ) => {
    let isWaiting = false,
        cache = {};

    const execFilter = ( date, format ) =>
        momentConvertUTCDateToLocal( date, format );

    const dateFilter = ( date, format ) => {
        let filteredDate = 'Loading...';

        if ( cache.hasOwnProperty( format )) {
            filteredDate = execFilter( date, cache[ format ]);
        } else {
            if ( !isWaiting ) {
                isWaiting = true;

                $translate( format ).then( trans => {
                    cache[ format ] = trans;
                    filteredDate = execFilter( date, trans );
                    isWaiting = false;
                });
            }
        }

        return filteredDate;
    };

    dateFilter.$stateful = true;
    return dateFilter;
});

Core.filter( 'formatLocalDateTime', ( $translate, $filter ) => {
    let isWaiting = false,
        translation = null,
        cache = {};

    const dateFilter = ( date, format ) => {
        let filteredDate = "Loading..."
        if(cache.hasOwnProperty(format)){
            filteredDate = $filter('date')(date, cache[format]);
        } else {
            if (isWaiting === false){
                isWaiting = true;

                $translate(format).then(function(translatedText) {
                    cache[format] = translatedText;
                    filteredDate = $filter('date')(date, translation)
                    isWaiting = false;
                });                        
            }
        }
        return filteredDate;
    };

    dateFilter.$stateful = true;
    return dateFilter;
});


/**
 * @name formatTelephoneNumber
 * @memberof CoreBundle.Core
 * @kind function
 * 
 * @description
 * AngularJS filter. Consumes a telephone number, parses it into its
 * composite segments, and omits the country code.
 * 
 * @param {string} tel The telephone number to parse.
 * @return {string}
 */
Core.filter( 'formatTelephoneNumber', () => {
    return ( tel ) => {
        if (!tel) { return ''; }

        let value = tel.toString().trim().replace(/^\+/, '');

        if (value.match(/[^0-9]/)) {
            return tel;
        }

        let country, city, number;

        switch (value.length) {
            case 10: // +1PPP####### -> C (PPP) ###-####
                country = 1;
                city = value.slice(0, 3);
                number = value.slice(3);
                break;

            case 11: // +CPPP####### -> CCC (PP) ###-####
                country = value[0];
                city = value.slice(1, 4);
                number = value.slice(4);
                break;

            case 12: // +CCCPP####### -> CCC (PP) ###-####
                country = value.slice(0, 3);
                city = value.slice(3, 5);
                number = value.slice(5);
                break;

            default:
                return tel;
        }

        if (country == 1) {
            country = "";
        }

        number = number.slice(0, 3) + '-' + number.slice(3);

        return ( city + "-" + number).trim();
    };
});

/**
 * Uses the moment library to handle conversion of a date-time string
 * to a formatted date-time string matching the user's locale.
 * 
 * Note: moment.js does not provide time zone codes out of the box anymore,
 * so it's necessary to fetch the user's preferred locale from the browser.
 * 
 * Also note: moment.js accounts for DST, so if the date string param falls
 * within the user's locale DST range it will output with a +/- 1 hour
 * adjustment automatically.
 * 
 * @param {string} dateStr 
 * @param {string} format
 * @returns {string} 
 */
function momentConvertUTCDateToLocal( dateStr, format = 'MM-DD-YYYY' ) {
    if ( typeof dateStr !== 'string' || dateStr.trim().length === 0 || !window.moment )
        return dateStr;

    if ( dateStr.charAt( dateStr.length - 1 ) !== 'Z' )
        dateStr = `${dateStr}Z`;

    let zone = '';

    try {
        if ( format !== 'MM-DD-YYYY' ) {
            let locale = navigator.language;
            if ( navigator.languages !== undefined )
                locale = navigator.languages[ 0 ];

            zone = ' ' + new Date()
                .toLocaleTimeString( locale, { timeZoneName: 'short' })
                .split( ' ' )[ 2 ];
        }
    } catch ( err ) {
        console.warn( 'Unable to resolve browser timezone.', err );
    }

    return window.moment( dateStr ).local().format( format ) + zone;
}

// Helper function for converting the UTC date to a local time.
function convertUTCDateToLocalDate(date) {
    var newDate = date;

    var offset = date.getTimezoneOffset() / 60;
    var hours = date.getHours();
    var offsetHours = hours - offset;
    offsetHours = offsetHours >= 12 ? offsetHours % 12 : offsetHours; 

    newDate.setHours( offsetHours );
    
    return newDate;   
}
