import Core from '../../modules/core/core.module';
import NotificationModel from '../../models/core/notification.model';

/**
 * @name NotificationService
 * @memberof CoreBundle.Core
 * @class
 * 
 * @classdesc
 * AngularJS service that facilitates three different types of visual user
 * notifications within A&R: immediate "toast"-style notifications that appear
 * immediately without user interaction, a set or "tray" containing a subset
 * of their notification history, and a single "persistent" notification
 * used under limited circumstances (such as when a file upload is in-progress,
 * and they leave the upload screen).
 */
class NotificationService {
    constructor( $rootScope, signalRNotificationHub, fileUpload, Notification ) {
        this.$rootScope = $rootScope;
        this.signalRNotificationHub = signalRNotificationHub;
        this.fileUpload = fileUpload;
        this.Notification = Notification;

        this.notificationTray = [];
        this.unseenCount = 0;
        this.persistentNotification = null;

        this.notificationTrayUpdated = null;
        this.persistentNotificationUpdated = null;
    }

    /**
     * Initializes the application-wide notification service by connecting to the
     * web socket service's "notification" channel, subscribing to events, and
     * handling the output. This should be fired from the primary application
     * Session service after bootstrapping all product bundles, and acquiring
     * a user ID from one of them.
     * 
     * @param {Number} userId The user ID to connect to the socket service with.
     */
    bootstrap( userId ) {
        userId = parseInt( userId ) || null;
        if ( userId !== null ) {
            this.fileUpload.onUploadProgress( this._onFileUploadProgress.bind( this ));
            this.fileUpload.onUploadComplete( this._onFileUploadComplete.bind( this ));
            this.fileUpload.onMappingComplete( this._onFileUploadMappingComplete.bind( this ));
            this.fileUpload.onUploadCancelled( this._onFileUploadCancelled.bind( this ));
            this.signalRNotificationHub.onListReceived( this._onListReceived.bind( this ));
            this.signalRNotificationHub.onSeenComplete( this._onSeenComplete.bind( this ));
            this.signalRNotificationHub.onNotification( this._onNotification.bind( this ));
            this.signalRNotificationHub.connect();
        }
    }

    /**
     * Destroys the web socket connection to the notification channel, and should
     * only be called on logout.
     */
    destroy() {
        this.signalRNotificationHub.disconnect();
    }

    /**
     * Takes a NotificationModel, and generates an instant toast-style element
     * shown to the user immediately without interaction with the notification
     * try. This uses the default template overridden in the ui-notification
     * runtime override component.
     * 
     * @param {NotificationModel} notificationModel The notification model used
     * to create the scope for the ui-notification library.
     */
    showImmediate( notificationModel ) {
        if ( notificationModel instanceof NotificationModel ) {
            let scope = this.$rootScope.$new();

            Object.keys( notificationModel ).forEach( propKey => {
                scope[ propKey ] = notificationModel[ propKey ];
            });

            this.Notification({ scope });
        }
    }

    /**
     * Registers a callback for a front-end component when the socket service
     * has sent a list of notifications to display in the tray.
     * 
     * @param {function} callback The event handler to register.
     */
    onNotificationTrayUpdated( callback ) {
        if ( typeof callback === 'function' ) {
            this.notificationTrayUpdated = callback;
        }
    }

    /**
     * Registers a callback for a front-end component when the notification service's
     * persistent notification has been updated.
     * 
     * @param {function} callback The event handler to register.
     */
    onPersistentNotificationUpdated( callback ) {
        if ( typeof callback === 'function' ) {
            this.persistentNotificationUpdated = callback;
        }
    }

    /**
     * Typically fired on the open event of the notification tray component, this
     * broadcasts a message to the socket service with a list of user notification
     * IDs, and marks them as "seen" in the backend.
     * 
     * @param {Array} userNotificationIds An array of user notification IDs to update
     * as "seen" in the backend.
     */
    setNotificationListSeen( userNotificationIds ) {
        userNotificationIds = ( Array.isArray( userNotificationIds ))
            ? userNotificationIds.filter( unid => unid !== null && typeof parseInt( unid ) === 'number' )
            : null;

        if ( Array.isArray( userNotificationIds ) && userNotificationIds.length > 0 ) {
            this.signalRNotificationHub.notificationsSeen( userNotificationIds );
        }
    }

    /**
     * wsFileUpload event handler for upload progress. We capture this in order to
     * keep a persistent notification available in the event that the user leaves
     * the upload screen itself.
     * 
     * @param {Number} progress The current progress of the file upload.
     */
    _onFileUploadProgress( progress ) {
        progress = parseInt( progress ) || null;

        if ( progress !== null ) {
            if ( progress >= 90 ) {
                progress = 90;
            }

            const currentProduct = this.fileUpload.currentProduct;
            const titleKey = `core.notification.${currentProduct}.file-upload-title`;
            const summaryKey = `core.notification.${currentProduct}.file-uploading-message`;
            const filename = this.fileUpload.currentFile.name;

            this._setPersistentUploadingNotification( titleKey, summaryKey, filename, progress );
        }
    }

    /**
     * wsFileUpload event handler for cancelling an upload. We capture this in order
     * to get rid of the persistent notification used in the event that the user
     * leaves the upload screen itself.
     */
    _onFileUploadCancelled() {
        if ( this.persistentNotification !== null ) {
            this.persistentNotification = null;
            this._persistentNotificationUpdated();
        }
    }

    /**
     * wsFileUpload event handler for a completed upload. Note that the file upload
     * may not yet be "complete," as in the case of Market Magnifier additional
     * processing is needed. Currently, this is our only use case, thus we're going
     * to update the persistent notification accordingly.
     */
    _onFileUploadComplete() {
        const progress = 90;
        const currentProduct = this.fileUpload.currentProduct;
        const titleKey = `core.notification.${currentProduct}.file-upload-title`;
        const summaryKey = `core.notification.${currentProduct}.file-uploaded-message`;
        const filename = this.fileUpload.currentFile.name;

        this._setPersistentUploadingNotification( titleKey, summaryKey, filename, progress );
    }

    /**
     * wsFileUpload event handler for a completed AND MAPPED upload. In this case,
     * we're actually "complete," so we need to get rid of the persistent notification.
     * The socket service is already going to be sending a notification that the
     * mapping is complete, which contains the action URL to get back to the
     * next step in the process. Handling it here would be stepping on our own toes.
     */
    _onFileUploadMappingComplete() {
        const filename = this.fileUpload.currentFile.name;

        if ( this.persistentNotification !== null && this.persistentNotification.detailText === filename ) {
            this._setPersistentNotification( null );
        }
    }

    /**
     * Sets a file upload-specific persistent notification using a title key, summary
     * key, the filename, and current progress.
     * 
     * @param {string} titleKey The i18n title key.
     * @param {string} summaryKey The i18n summary key.
     * @param {string} filename The name of the upload(ed/ing) file to use as the detail text.
     * @param {Number} progress The progress percentage.
     */
    _setPersistentUploadingNotification( titleKey, summaryKey, filename, progress ) {
        if ( this.persistentNotification === null ) {
            this._setPersistentNotification( new NotificationModel (
                'fa-refresh',
                'primary',
                titleKey,
                null,
                summaryKey,
                null,
                null,
                filename,
                null,
                null,
                null,
                'progress',
                progress
            ));
        } else {
            if ( this.persistentNotification.detailText === filename ) {
                this.persistentNotification.persistenceValue = progress;
                this._persistentNotificationUpdated();
            }
        }
    }

    
    /**
     * Sets the notification service's persistent notification property, and 
     * publishes a change notification to all listeners (does not send the
     * persistent notification, just the change event). This method acts as both
     * the set AND reset, so if passing anything except an instance of
     * NotificationModel, the service will reset the persistent notification.
     * 
     * @param {NotificationModel|*} notificationModel An instance of NotificationModel,
     * if displaying a persistent notification is desired; anything else removes
     * the display.
     */
    _setPersistentNotification( notificationModel ) {
        this.persistentNotification = ( notificationModel instanceof NotificationModel )
            ? notificationModel
            : null;
        this._persistentNotificationUpdated();
    }

    /**
     * Simple helper function to fire any listeners of the persistent
     * notification. Note that this does not send the persistent
     * notification object, but simply bubbles an event for the listener
     * to then fetch the persistent notification as needed.
     */
    _persistentNotificationUpdated() {
        if ( this.persistentNotificationUpdated !== null ) {
            this.persistentNotificationUpdated.apply();
        }
    }

    /**
     * Web socket event handler for receiving a new real-time notification. The
     * payload will be parsed into a NotificationModel, added to the notification
     * tray, which will then be sorted by freshness (newest display at the top).
     * 
     * Additionally, as this is a real-time notification, creates an immediate
     * toast-style notification to appear immediately without user interaction
     * with the notification tray.
     * 
     * @param {Object} notification A generic notification object.
     */
    _onNotification( notification ) {
        const notificationModel = NotificationModel.fromSocketServiceObject( notification );
        if ( notificationModel !== null ) {
            this.notificationTray.push( notificationModel );
            this._notificationTrayUpdate();
        }
    }

    /**
     * Web socket event handler for receiving a user's last X notifications.
     * 
     * @param {Array} notifications An array of notification generic objects.
     */
    _onListReceived( notifications ) {
        
        // Best spot to run tests.
        //this._createDummyImmediateNotification();
        //this._createDummyPersistentNotification();

        const notificationModels = NotificationModel.fromSocketServiceArray( notifications );
        this.notificationTray = notificationModels;
        this._notificationTrayUpdate();
    }

    /**
     * Helper function to ensure the notification tray is sorted by creation
     * time in descending order. Should be called after _onNotification and
     * _onListReceived.
     */
    _notificationTrayUpdate() {
        this.notificationTray.sort(( a, b ) => {
            const aCreated = ( a.createTime instanceof Date ) ? a.createTime.getTime() : 1;
            const bCreated = ( b.createTime instanceof Date ) ? b.createTime.getTime() : 2;
            return bCreated - aCreated;
        });

        this.unseenCount = this.notificationTray.filter( n => n.isViewed === false ).length;

        if ( this.notificationTrayUpdated !== null ) {
            this.notificationTrayUpdated.apply();
        }
    }

    /**
     * Web socket event handler for receiving confirmation that one or more
     * user notifications have been updated in the backend as "seen". Updates
     * the applicable notifications in the notification tray, as well as the
     * count of notifications not already seen.
     * 
     * @param {Array} userNotificationIds The array of user notification IDs
     * marked as "seen" in the backend.
     */
    _onSeenComplete( userNotificationIds ) {
        this.notificationTray = this.notificationTray.map( nt => {
            if (( Array.isArray( userNotificationIds ) && userNotificationIds.includes( nt.userNotificationId )) ||
                ( typeof userNotificationIds === 'number' && nt.userNotificationId === userNotificationIds )) {
                nt.isViewed = true;
            }

            return nt;
        });
        this.unseenCount = this.notificationTray.filter( n => n.isViewed === false ).length;

        if ( this.notificationTrayUpdated !== null ) {
            this.notificationTrayUpdated.apply();
        }
    }    

    /**
     * TEST METHOD ONLY. Creates a dummy notification as though fired from a
     * non-socket service source within the application.
     */
    _createDummyImmediateNotification() {
        this.showImmediate( new NotificationModel(
            'fa-rocket',
            'primary',
            null,
            'Real-Time Test',
            null,
            'This is a test of the notification system.',
            null,
            'This is only a test.',
            null,
            null,
            null,
            null,
            null
        ));
    }

    /**
     * TEST METHOD ONLY. Creates a dummy notification as though fired from a
     * single real-time socket service notification event.
     */
    _createDummySocketNotification() {
        this._onNotification({
            userNotificationId: 1234,
            insertTime: new Date(),
            isViewed: false,
            isAck: false,
            ackDate: null,
            notification: {
                notificationType: 'success',
                titleKey: null,
                title: 'Web Socket Test',
                messageKey: null,
                message: 'This is a test of the notification system.'
            }
        });
    }

    /**
     * TEST METHOD ONLY. Creates a dummy persistent notification, which for now
     * is limited to the file upload progress variant.
     */
    _createDummyPersistentNotification() {
        this._setPersistentUploadingNotification(
            'core.notification.mm.file-upload-title',
            'core.notification.mm.file-uploading-message',
            'some-super-really-ultra-silly-long-filename.csv',
            32
        );
    }

    /**
     * TEST METHOD ONLY. Updates a dummy persistent notification, which for now
     * is limited to the file upload progress variant.
     */
    _updateDummyPersistentNotification() {
        this._setPersistentUploadingNotification(
            'core.notification.mm.file-upload-title',
            'core.notification.mm.file-uploading-message',
            'some-super-really-ultra-silly-long-filename.csv',
            100
        );
    }
}

Core.service( 'notificationService', NotificationService );
