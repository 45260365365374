// Local dependencies.
import Core from '../../../modules/core/core.module';
import template from './announcement-menu.template.html';
import { UserAnnouncementBase, UserAnnouncementDetail } from '../../../models/core/announcement-response.model';
import { UserAnnouncementCategory } from '../../../models/core/announcement-category.model';
import './announcement-menu.styles.scss';

class AnnouncementMenuController {
    constructor( $scope, $timeout, signalRAnnouncementHub ) {
        this.$scope = $scope;
        this.$timeout = $timeout;
        this.signalRAnnouncementHub = signalRAnnouncementHub;
    }

    $onInit() {
        this.$scope.expandedCategoryKey = null;
        this.$scope.selectedAnnouncementId = null;
        this.$scope.announcementCategories = [];
        this.$scope.announcements = [];
        this.$scope.loading = true;
        this.$scope.viewType = 'list';
        this.$scope.sortDir = 'desc';
        this.$scope.total = 0;
        this.$scope.pages = -1;
        this.$scope.page = 1;
        this.$scope.count = 0;

        this._announcementsReceived = this._announcementsReceived.bind( this );
        this._announcementSelected = this._announcementSelected.bind( this );
        this.$scope.handleAnnouncementSelect = this.handleAnnouncementSelect.bind( this );
        this.$scope.handleSelect = this.handleSelect.bind( this );
        this.$scope.handleSortChange = this.handleSortChange.bind( this );
        this.$scope.handlePageChange = this.handlePageChange.bind( this );
        this.$scope.handleViewChange = this.handleViewChange.bind( this );
        this.$scope.handleCategoryToggle = this.handleCategoryToggle.bind( this );
        this.$scope.isCategoryExpanded = this.isCategoryExpanded.bind( this );
        this.$scope.getPagingLabel = this.getPagingLabel.bind( this );
        this.$scope.getPrevLabel = this.getPrevLabel.bind( this );
        this.$scope.getNextLabel = this.getNextLabel.bind( this );
        this.$scope.canPageNext = this.canPageNext.bind( this );
        this.$scope.canPagePrev = this.canPagePrev.bind( this );
        this.signalRAnnouncementHub.onSeenComplete( this._onAnnouncementViewed.bind( this ));
        this.signalRAnnouncementHub.onAllSeenComplete( this._onAllAnnouncementsViewed.bind( this ));

        this.$scope.$watch( () => this.loading,
            ( loading ) => this.$scope.loading = loading );

        this.$scope.$watch( () => this.sortDir,
            ( sortDir ) => this.$scope.sortDir = sortDir );

        this.$scope.$watch( () => this.total,
            ( total ) => this.$scope.total = total );

        this.$scope.$watch( () => this.pages,
            ( pages ) => this.$scope.pages = pages );

        this.$scope.$watch( () => this.page,
            ( page ) => this.$scope.page = page );

        this.$scope.$watch( () => this.count,
            ( count ) => this.$scope.count = count );
    }

    /**
     * Listens for changes against the properties of the component as set by
     * a parent.
     * 
     * @param {object} changes 
     */
    $onChanges( changes ) {
        const { announcement, announcements } = changes;

        if ( announcements !== undefined ) {

            const { currentValue, previousValue } = announcements;
            if ( Array.isArray( currentValue ) &&
                Array.isArray( previousValue ) &&
                currentValue !== previousValue ) {
                
                this._announcementsReceived( currentValue );
            }
        }

        if ( announcement !== undefined ) {

            const { currentValue, previousValue } = announcement;
            if ( currentValue !== previousValue &&
                typeof currentValue === 'object' &&
                currentValue !== null ) {
                
                this._announcementSelected( currentValue );
            }
        }
    }

    /**
     * Whether the provided announcement category is currently expanded in the UI.
     * 
     * @param {UserAnnouncementCategory} announcementCategory 
     * @returns {boolean}
     */
    isCategoryExpanded( announcementCategory ) {
        return ( announcementCategory.announcementCategoryKey ===
            this.$scope.expandedCategoryKey );
    }

    /**
     * Gets interpolation values for the translated pagination label.
     * 
     * @returns {object}
     */
    getPagingLabel() {
        const { count, page, total } = this.$scope;

        let start = ( page === 1 )
            ? 1
            : (( page - 1 ) * count );
        let end = ( page === 1 )
            ? count
            : ( start + count );

        if ( end > total )
            end = total;
            
        return {
            start,
            end,
            total
        };
    }

    /**
     * Gets interpolation values for the translated previous button label.
     */
    getPrevLabel() {
        const { count, page, pages } = this.$scope;

        let prevPage = ( page - 1 ),
            value = null;

        if ( prevPage > 0 )
            value = count;

        return {
            count: ( value !== null ) ? value : ''
        };
    }

    /**
     * Gets interpolation values for the translated next button label.
     */
    getNextLabel() {
        const { count, page, pages, total } = this.$scope;

        let nextPage = ( page + 1 ),
            value = null;

        if ( nextPage === pages ) {
            let remainder = ( total % count );
            if ( remainder !== 0 )
                value = remainder;
        } else if ( nextPage < pages ) {
            value = count;
        }

        return {
            count: ( value !== null ) ? value : ''
        };
    }

    /**
     * Whether the previous pagination button is enabled.
     * 
     * @returns {boolean}
     */
    canPagePrev() {
        const { page } = this.$scope;
        return (( page - 1 ) > 0 );
    }

    /**
     * Whether the next pagination button is enabled.
     * 
     * @returns {boolean}
     */
    canPageNext() {
        const { page, pages } = this.$scope;
        return (( page + 1 ) <= pages );
    }

    /**
     * Click-handler that toggles an announcement category's expansion. If the
     * provided category is already expanded, it's collapsed.
     * 
     * @param {UserAnnouncementCategory} announcementCategory 
     */
    handleCategoryToggle( announcementCategory ) {
        let expandedCategoryKey = announcementCategory.announcementCategoryKey;

        if ( announcementCategory.announcementCategoryKey === this.$scope.expandedCategoryKey )
            expandedCategoryKey = null;

        this.$scope.expandedCategoryKey = expandedCategoryKey;
    }

    /**
     * Click-handler that calls back to the parent component to reissue a
     * request for announcements sorted by startDate in asc/desc order.
     * 
     * @param {string} sortDir 
     */
    handleSortChange( sortDir ) {
        if ( sortDir !== this.$scope.sortDir ) {
            this.handleSort({ sortDir });
        }
    }

    /**
     * Click-handler that calls back to the parent component to reissue a
     * request for announcements with pagination changes.
     * 
     * @param {string} pageDir 
     */
    handlePageChange( pageDir ) {
        const { page, pages } = this.$scope;

        let isValid = false;
        if ( pageDir === 'prev' && ( page - 1 ) > 0 )
            isValid = true;

        else if ( pageDir === 'next' && ( page + 1 ) <= pages )
            isValid = true;

        if ( isValid )
            this.handlePaging({ pageDir });
    }

    /**
     * Click-handler that toggles the menu view between category and list.
     * 
     * @param {string} viewType 
     */
    handleViewChange( viewType ) {
        if ( viewType !== this.$scope.viewType ) {
            this.$scope.viewType = viewType;
        }
    }

    /**
     * Handles the click event for an announcement selected in the menu
     * by calling back to the parent component which then requests and renders
     * the full announcement record.
     * 
     * @param {UserAnnouncementBase} announcement 
     */
    handleAnnouncementSelect( announcement ) {
        if ( announcement instanceof UserAnnouncementBase ) {
            if ( !announcement.isSeen && !announcement.isFuture )
                this.signalRAnnouncementHub.announcementSeen( announcement.announcementId );

            this.handleSelect({ announcement });
        }
    }

    /**
     * wsAnnouncement handler responding to a return payload following all
     * announcements being set as viewed in the database.
     * 
     * @param {object} payloadObj
     */
    _onAllAnnouncementsViewed( payloadObj ) {
        payloadObj = getTypeCheckedValue( payloadObj, 'object', {} );

        let unseenCount = getTypeCheckedValue( payloadObj.unseenCount, 'number', null );
        if ( unseenCount !== null && unseenCount === 0 ) {

            this.$timeout(() => {

                // Loop announcements to set all as seen.
                for ( let i = 0; i < this.$scope.announcements.length; i++ ) {
                    if ( !this.$scope.announcements[ i ].isFuture )
                        this.$scope.announcements[ i ].isSeen = true;
                }

                // Loop announcement categories to set the "isSeen" and count.
                for ( let i = 0; i < this.$scope.announcementCategories.length; i++ )
                    this.$scope.announcementCategories[ i ].setAllAnnouncementsViewed();
            });
        }
    }

    /**
     * wsAnnouncement handler responding to a return payload following an
     * announcement being set as viewed in the database.
     * 
     * @param {object} payloadObj 
     */
    _onAnnouncementViewed( payloadObj ) {
        payloadObj = getTypeCheckedValue( payloadObj, 'object', {} );

        let announcementId = getTypeCheckedValue( payloadObj.announcementId, 'number', null );
        if ( announcementId !== null ) {

            this.$timeout(() => {

                // Loop announcements to set the "isSeen".
                for ( let i = 0; i < this.$scope.announcements.length; i++ ) {
                    if ( this.$scope.announcements[ i ].announcementId === announcementId )
                        this.$scope.announcements[ i ].isSeen = true;
                }

                // Loop announcement categories to set the "isSeen" and count.
                for ( let i = 0; i < this.$scope.announcementCategories.length; i++ ) {
                    if ( this.$scope.announcementCategories[ i ].hasAnnouncement( null, announcementId )) {
                        this.$scope.announcementCategories[ i ].setAnnouncementViewed( announcementId );
                    }
                }
            });
        }
    }

    /**
     * Handles the change event for the announcement component property, and
     * sets the selected ID and parent category in scope.
     * 
     * @param {UserAnnouncementDetail} announcement 
     */
    _announcementSelected( announcement ) {
        announcement = getTypeCheckedValue( announcement, 'object', {} );
        
        let announcementId = getTypeCheckedValue( announcement.announcementId, 'number', null );
        if ( announcementId !== null ) {

            // Make sure we use the expanded category for this announcement.
            let expandedCategoryKey = null;
            let expandedCategory = this.$scope.announcementCategories
                .find( ac => ac.hasAnnouncement( null, announcementId ));

            if ( expandedCategory ) {
                expandedCategoryKey = expandedCategory.announcementCategoryKey;
            }

            this.$timeout(() => {
                this.$scope.selectedAnnouncementId = announcementId;
                this.$scope.expandedCategoryKey = expandedCategoryKey;
            });
        }
    }

    /**
     * Handles the change event for the announcements component property, and
     * initializes both the announcements and announcement category collections
     * in scope.
     * 
     * @param {UserAnnouncementBase[]} announcements 
     */
    _announcementsReceived( announcements ) {
        console.log( 'announcementsReceived' );
        announcements = getTypeCheckedValue( announcements, 'array', [] );

        let announcementCategories = UserAnnouncementCategory.fromArr( announcements ),
            expandedCategoryKey = this.$scope.expandedCategoryKey,
            selectedAnnouncement = null;
        
        if ( announcementCategories.length > 0 &&
            announcementCategories[ 0 ].announcements.length > 0 ) {

            if ( announcements.filter( a => a.isSelected ).length === 0 )
                selectedAnnouncement = announcementCategories[ 0 ].announcements[ 0 ];

            if ( announcementCategories[ 0 ].announcementCategoryKey !== expandedCategoryKey )
                expandedCategoryKey = announcementCategories[ 0 ].announcementCategoryKey;
        }

        this.$timeout(() => {
            this.$scope.expandedCategoryKey = expandedCategoryKey;
            this.$scope.announcements = announcements;
            this.$scope.announcementCategories = announcementCategories;

            if ( selectedAnnouncement !== null )
                this.$scope.handleAnnouncementSelect( selectedAnnouncement );
        });
    }
}

const announcementMenu = {
    template,
    controller: AnnouncementMenuController,
    bindings: {
        announcements: '<?',
        announcement: '<?',
        handleSelect: '&',
        sortDir: '=',
        handleSort: '&',
        total: '=',
        pages: '=',
        page: '=',
        count: '=',
        handlePaging: '&',
        loading: '='
    }
};

Core.component( 'announcementMenu', announcementMenu );