// Local dependencies.
import Core from '../../../modules/core/core.module';

/**
 * @name NumericQuantity
 * @memberof CoreBundle.Core
 * @class
 * 
 * @classdesc
 * AngularJS directive. Form elements with this attributes will ensure a
 * consistent numeric formatting.
 */
class ValidationNumericQuantity {
    constructor() {
        this.restrict = 'A';
        this.require = 'ngModel';
        this.scope = {
            maxValue: '='
        }
    }

    link( scope, element, attrs, ngModel ) {

        // Bind to keypress.
        element.bind( 'keyup', function( event ) { 
            let value = element.val().replace( /[^0-9]/g, '' ),
                maxValue = getTypeCheckedValue( scope.maxValue, 'int', null );

            value = getTypeCheckedValue( value, 'int', null );


            if ( value === null ){
                element.val( value );
            } else {
                element.val(( attrs.hasOwnProperty( 'commaNotation' ))
                    ? parseInt( value ).toLocaleString()
                    : parseInt( value ));
            }

            let valid = ( maxValue === null || ( value <= maxValue ));
            element.parent().toggleClass( 'has-error', !valid );
            
        });

        // User-supplied text (view -> model).
        ngModel.$parsers.push( function( value ) {
            if ( value === undefined || value === null || value === '' ){
                return null;
            }

            value = value.replace( /,/g, '' );
            return parseInt( value );
        });

        // Format the value to include the comma (model -> view).
        ngModel.$formatters.push( function( value ) {
            if ( value === undefined || value === null || value === '' ){
                return null;
            }

            value = ( attrs.hasOwnProperty( 'commaNotation' ))
                ? parseInt( value ).toLocaleString()
                : parseInt( value );

            return value;
        });

        // Validate that the value does not exceed the maximum.
        ngModel.$validators.validMaximum = ( modelValue, viewValue ) => {
            let value = modelValue || viewValue,
                maxValue = getTypeCheckedValue( scope.maxValue, 'int', null );

            let valid = ( maxValue === null || ( value <= maxValue ));
            return valid;
        };
    }
}

Core.directive( 'validationNumericQuantity', () => new ValidationNumericQuantity );