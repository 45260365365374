import Core from '../../../modules/core/core.module';
import textAreaTemplate from './text-area.template.html';

import './text-area.styles.scss';

/**
 * @name textArea
 * @memberof CoreBundle.Core
 * @class
 * 
 * @classdesc
 * AngularJS component. stylized text area
 */
class TextAreaController {
    constructor( $scope, $timeout ) {
        this.$scope = $scope;
        this.$timeout = $timeout;   
    }

    $onInit() {
        this._setConfiguration(this.configurationObject);

        this.$scope.onTextAreaChange = this.onTextAreaChange.bind( this );    
        this.ngModelCtrl.$render = this.ngModelRender.bind( this );
    }

    $onChanges( changes ){
        if ( changes.configurationObject && !changes.configurationObject.isFirstChange() ){
            this._setConfiguration( changes.configurationObject.currentValue );
        }
    }

    ngModelRender(){
        this.$scope.model = this.ngModelCtrl.$viewValue;
        this.$scope.charsLeft = this._setCharsLeft();
    }

    onTextAreaChange(){
        if ( this.$scope.model === undefined ){
            this.$scope.model = null;
        }
        this.ngModelCtrl.$setViewValue(this.$scope.model);
        if ( this.checkMaxLength ){
            this.$scope.charsLeft = this._setCharsLeft();
        }
    }

    _setConfiguration(configObj){
        this.$scope.allowResizeHeight = ( angular.isDefined(configObj.allowResizeHeight) ) ? configObj.allowResizeHeight : false;
        this.$scope.allowResizeWidth = ( angular.isDefined(configObj.allowResizeWidth) ) ? configObj.allowResizeWidth : false;
        this.$scope.isRequired = ( angular.isDefined(configObj.isRequired) ) ? configObj.isRequired : false;
        this.$scope.isDisabled = ( angular.isDefined(configObj.isDisabled) ) ? configObj.isDisabled : false;
        this.$scope.labelKey = configObj.labelKey;
        this.$scope.fieldName = configObj.fieldName;
        this.$scope.maxLength = ( angular.isDefined(configObj.maxLength) ) ? configObj.maxLength : null;
        this.$scope.customErrorMessageKey = ( angular.isDefined(configObj.customErrorMessageKey) ) ? configObj.customErrorMessageKey : '';
        this.$scope.requiredMessageKey = ( angular.isDefined(configObj.requiredMessageKey ) ) ? configObj.requiredMessageKey : '';
         
        this.checkMaxLength = (this.$scope.maxLength !== null);
        this.$scope.charsLeft = this._setCharsLeft();

        if ( angular.isDefined(this.$scope.textAreaForm) ){
            var isValid = ( this.$scope.customErrorMessageKey === '');
            this.$scope.textAreaForm[this.$scope.fieldName].$setValidity('customValidation', isValid);
        }
    }

    _setCharsLeft(){
        if ( !this.checkMaxLength ){
            return 0;
        } else if (this.$scope.model !== undefined && this.$scope.model !== null){
            return this.$scope.maxLength - this.$scope.model.length;
        } else {
            return this.$scope.maxLength;
        }
    }
}

const textArea = {
    template: textAreaTemplate,
    controller: TextAreaController,
    bindings: {
        configurationObject: '<',
        ngModel: '='
    },
    require: { 
        ngModelCtrl: "ngModel" 
    },
};

Core.component( 'textArea', textArea );