import Core from '../../../modules/core/core.module';

/**
 * @name ValidationPositiveWholeNumber
 * @memberof CoreBundle.Core
 * @class
 * 
 * @classdesc
 * AngularJS directive. Ensures the input is within the provided range
 */
class ValidationPositiveWholeNumber {
    constructor() {
        this.restrict = 'A';
        this.require = 'ngModel';
        this.scope = true;
    }

    link( scope, element, attrs, ctrl ) {
        element.on('keypress', function(event) {  
            if (attrs.maxlength !== undefined && ( event.which >= 48 && event.which <= 57 ) || ( event.which >= 96 && event.which <= 105 ) ){
                console.log(attrs.maxlength);
                console.log(element.val());
                if ( element.val().length >= attrs.maxlength ){
                    return false;
                }
            }
            
            
            switch ( event.which ) {
                case 46: //period
                case 45: //negative
                    return false;
                    break;
                
                default:
                    return true;
            }   
        });

        
    }
}

Core.directive( 'validationPositiveWholeNumber', () => new ValidationPositiveWholeNumber());
