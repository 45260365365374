import Core from '../../modules/core/core.module';

/**
 * @name supportFactory
 * @memberof CoreBundle.Core
 * @class
 * 
 * @classdesc
 * AngularJS factory.
 */
class SupportFactory {
    constructor( $http, $q, Core_API ) {
        this.$http = $http;
        this.$q = $q;
        this.Core_API = Core_API;
    }

    getSessionErrors(){
        let defer = this.$q.defer();

        const onSuccess = ( response ) => {
            defer.resolve( response );
        };
    
        const onError = ( err, status ) => {
            defer.reject( err );
        };

        this.$http.get( this.Core_API.ROUTE_SESSION_ERROR ).then ( onSuccess, onError);

        return defer.promise;
    }

    sendSupportEmail( contactData, context, accountName, accountNum, userId, username, errorIds, supportData ){
        let defer = this.$q.defer();
        let payload = {
            userId: userId,
            loginId: username,
            accountName: accountName,
            accountNum: accountNum,
            context: context,
            firstName: contactData.firstName, 
            lastName: contactData.lastName, 
            phone: contactData.phone, 
            message: contactData.message, 
            errorIds: errorIds, 
            supportData: supportData
        }

        const onSuccess = ( response ) => {
            defer.resolve( response.data );
        };
    
        const onError = ( err, status ) => {
            defer.reject( err );
        };

        this.$http.post( this.Core_API.ROUTE_SUPPORT_CONTACT, payload ).then ( onSuccess, onError);

        return defer.promise;
    }


}

Core.service( 'supportFactory', SupportFactory );