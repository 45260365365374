import Core from '../../../modules/core/core.module';
import ToggleSectionHeaderTemplate from './toggle-section-header.template.html';

import './toggle-section-header.styles.scss';

/**
 * @name toggleSectionHeader
 * @memberof CoreBundle.Core
 * @class
 * 
 * @classdesc
 * AngularJS component. Visiblity toggling section header
 */
class ToggleSectionHeaderController {
    constructor( $scope ) {
        this.$scope = $scope;
    }

    $onInit() {
        this.$scope.obj = this.obj;

        //angularJS.extend broke data-binding, so check for properties manually
        this._checkForObjProperty( 'isExpanded', false );
        this._checkForObjProperty( 'title', null );
        this._checkForObjProperty( 'titleKey', null );
        this._checkForObjProperty( 'description', null );
        this._checkForObjProperty( 'descriptionKey', null );
        this._checkForObjProperty( 'hasOverview', false );
        this._checkForObjProperty( 'allowExpansion', true );

        this.$scope.toggleExpansion = this.toggleExpansion.bind( this );
    }

    _checkForObjProperty( property, defaultValue ){
        if ( !this.$scope.obj.hasOwnProperty(property) ){
            this.$scope.obj[property] = defaultValue;
        }
    }

    toggleExpansion(){
        if ( this.$scope.obj.allowExpansion ){
            this.$scope.obj.isExpanded = !this.$scope.obj.isExpanded;
        }
    }
}

const toggleSectionHeader = {
    template: ToggleSectionHeaderTemplate,
    controller: ToggleSectionHeaderController,
    bindings: {
        obj: '=',
    }
};

Core.component( 'toggleSectionHeader', toggleSectionHeader );