// Local dependencies.
import Core from '../../modules/core/core.module';
import {
    UnseenUserAnnouncementsResponse,
    UserAnnouncementsResponse,
    UserAnnouncementResponse
} from '../../models/core/announcement-response.model';

class AnnouncementFactory {
    constructor( $http, $q, Core_API ) {
        this.$http = $http;
        this.$q = $q;
        this.Core_API = Core_API;
    }

    /**
     * Fetches a single announcement with content for this user.
     * 
     * @param {number} announcementId 
     */
    getUserAnnouncement( announcementId ) {
        let result = UserAnnouncementResponse.default(),
            defer = this.$q.defer();

        let url = this.Core_API.ROUTE_ANNOUNCEMENT.replace( '{announcementId}', announcementId );
        this.$http.get( url )
            .then(
                // Success...
                ( response ) => {
                    result = new UserAnnouncementResponse( response.data );
                    defer.resolve( result );
                },

                // Failure...
                ( err, status ) => {
                    console.warn( status, err );
                    defer.reject( result );
                }
            );

        return defer.promise;
    }

    /**
     * Fetches this user's announcement "history".
     * 
     * @param {*} sortBy 
     * @param {*} sortDir 
     * @param {*} page 
     * @param {*} count 
     */
    getUserAnnouncements( sortBy = null, sortDir = null, page = null, count = null ) {
        let result = UserAnnouncementsResponse.default(),
            defer = this.$q.defer();

        // Build query params.
        let requestParams = [],
            requestQuery = '';

        if ( sortBy !== null ) requestParams.push( `sortBy=${sortBy}` );
        if ( sortDir !== null ) requestParams.push( `sortDir=${sortDir}` );
        if ( page !== null ) requestParams.push( `page=${page}` );
        if ( count !== null ) requestParams.push( `count=${count}` );

        if ( requestParams.length > 0 )
            requestQuery = `?${requestParams.join( '&' )}`;

        this.$http.get( this.Core_API.ROUTE_ANNOUNCEMENTS + requestQuery )
            .then(
                // Success...
                ( response ) => {
                    result = new UserAnnouncementsResponse( response.data );
                    defer.resolve( result );
                },

                // Failure...
                ( err, status ) => {
                    console.warn( status, err );
                    defer.reject( result );
                }
            )

        return defer.promise;
    }
}

Core.service( 'announcementFactory', AnnouncementFactory );