import Core from '../../../modules/core/core.module';

/**
 * @name ValidationDateRange
 * @memberof CoreBundle.Core
 * @class
 * 
 * @classdesc
 * AngularJS directive. Ensures the input is within the provided range
 */
class ValidationDateRange {
    constructor() {
        this.restrict = 'A';
        this.require = 'ngModel';
        this.scope = false;
    }

    link( scope, element, attrs, ctrl ) {

        let _isInvalidDate = function ( value ){
            return isNaN( Date.parse( value ) );
        }

        // Validate the range minimum.
        ctrl.$validators.validMinimum = function( modelValue, viewValue ) {
            let value = modelValue || viewValue;
            if ( !angular.isDefined( attrs.minDate ) || value === null || value === undefined || value === '' ){
                return true;
            }

            value = new Date(value);
            let minDate = new Date(attrs.minDate.replace(/"/g, ''));
            if ( _isInvalidDate( value ) || _isInvalidDate( minDate ) ){
                return true; //unable to check validity of a non-date value
            }

            let isValid = ( value >= minDate );
            return isValid;
        };

        // Validate the range maximum.
        ctrl.$validators.validMaximum = function( modelValue, viewValue ) {
            let value = modelValue || viewValue;
            if ( !angular.isDefined( attrs.maxDate ) || value === null || value === undefined || value === '' ){
                return true;
            }

            value = new Date(value);
            let maxDate = new Date(attrs.maxDate.replace(/"/g, ''));
            if ( _isInvalidDate( value ) || _isInvalidDate( maxDate ) ){
                return true; //unable to check validity of a non-date value
            }

            let isValid = ( value <= maxDate );
            return isValid;
        };
    }
}

Core.directive( 'validationDateRange', () => new ValidationDateRange());
