import angular from 'angular';
import Core from '../../../modules/core/core.module';

/**
 * @name FillWorkspaceHeight
 * @memberof CoreBundle.Core
 * @class
 * 
 * @classdesc
 * AngularJS directive. Elements with this attribute will automatically
 * self-adjust their rendered height within the browser in order to fill the
 * "workspace" ui-view container. This is a product-agnostic global directive
 * within the Acquisition & Retention portal.
 * 
 * @property {number}   scope.minHeight         - Minimum height. Optional.
 * @property {number}   scope.maxHeight         - Maximum height. Optional.
 * @property {number}   scope.verticalPadding   - Y-axis padding. Optional.
 * @property {array}    scope.nonGridRows       - Non-grid rows. Optional.
 * @property {number}   scope.nonGridRowHeight  - Non-grid row height. Optional.
 * @property {boolean}  scope.updateWatch       - Update watch? Optional.
 * @property {boolean}  scope.enforceMaxHeight  - Enforce maximum height? Optional.
 */
class FillWorkspaceHeight {
    constructor( $window, $timeout ) {
        this.$window = $window;
        this.$timeout = $timeout;

        this.restrict = 'A';
        this.scope = {
            minHeight: '=?',
            maxHeight: '=?',
            verticalPadding: '=?',
            nonGridRows: '=?',
            nonGridRowHeight: '=?',
            updateWatch: '=?',
            enforceMaxHeight: '=?'
        };
    }    

    link( scope, element ) {
        const self = this;
        const getTotalElementHeight = ( selector ) => {
            const obj = element[ 0 ].querySelector( selector );
            if ( obj === null ) {
                return 0;
            }

            let offsetHeight = obj.offsetHeight,
                cssObj = self.$window.getComputedStyle( obj, null ),
                marginTop = Number( cssObj.getPropertyValue( 'margin-top' ).replace( 'px', '' )),
                marginBottom = Number( cssObj.getPropertyValue( 'margin-bottom' ).replace( 'px', '' ));

            return offsetHeight + marginTop + marginBottom;
        };

        const setNonGridRowHeight = () => {
            scope.nonGridRowHeight = 0;
            angular.forEach( scope.nonGridRows, ( value, key ) => {
                scope.nonGridRowHeight += getTotalElementHeight( value );
            });
        };

        scope.onResize = () => {
            element.addClass( 'fill-workspace-height' );
            scope.enforceMaxHeight = ( angular.isDefined( scope.enforceMaxHeight ))
                ? scope.enforceMaxHeight
                : true;

            let windowHeight = self.$window.innerHeight,
                navHeight = angular.element( document.querySelector( 'data-utility-nav > *' )).prop( 'offsetHeight' ),
                headerHeight = angular.element( document.querySelector( 'div[data-ui-view="header"]' )).prop( 'offsetHeight' ),
                footerHeight = angular.element( document.querySelector( 'div[data-ui-view="footer"] footer' )).prop( 'offsetHeight' ),
                workspaceMarginTop = Number( angular.element( document.querySelector( 'div[data-ui-view="workspace"]' )).css( 'marginTop' ).replace( 'px', '' )),
                elemMargin = Number( $( element ).css( 'marginTop' ).replace( 'px', '' )) + Number( $( element ).css( 'marginBottom' ).replace( 'px', '' )),
                elemBorder = Number( $( element ).css( 'border-top-width' ).replace( 'px', '' )) + Number( $( element ).css( 'border-bottom-width' ).replace( 'px', '' )),
                cssObj = self.$window.getComputedStyle( element[ 0 ], null ),
                paddingTop = Number( cssObj.getPropertyValue( 'padding-top' ).replace( 'px', '' )),
                paddingBottom = Number( cssObj.getPropertyValue( 'padding-bottom' ).replace( 'px', '' ));

            // Initial calculation.
            let elMinHeight = windowHeight - navHeight - headerHeight - workspaceMarginTop - elemMargin - elemBorder - footerHeight,
                elMaxHeight = windowHeight - navHeight - headerHeight - workspaceMarginTop - elemMargin - elemBorder;

            if ( elMinHeight < 0 ) {
                elMinHeight = 0;
            }

            if ( elMaxHeight < 0 ) {
                elMaxHeight = 0;
            }

            scope.minHeight = elMinHeight;
            element.css( 'minHeight', `${elMinHeight}px` );

            scope.maxHeight = elMaxHeight;
            if ( scope.enforceMaxHeight ) {
                element.css( 'maxHeight', `${elMaxHeight}px` );
            }

            scope.verticalPadding = paddingTop + paddingBottom;
        };

        scope.$watch(
            () => { return scope.updateWatch; },
            ( newVal ) => {
                self.$timeout( () => {
                    setNonGridRowHeight();
                });
            }
        );

        element.ready( () => {
            self.$timeout( scope.onResize, 500 );
        });

        angular.element(this.$window).bind('resize', scope.onResize);
    }
}

Core.directive( 'fillWorkspaceHeight', ( $window, $timeout ) => new FillWorkspaceHeight( $window, $timeout ));
