import angular from 'angular';

/**
 * @name addQuerySelectorAll
 * @memberof CoreBundle.Core
 * @kind function
 * 
 * @description
 * Adds querySelectorAll to jqLite within AngularJS. jqLite find() is limited to
 * lookups by tag name.
 *
 * @see {@link https://github.com/angular/angular.js/issues/3586|jqLite.find - why not use querySelectorAll?}
 * @see {@link https://github.com/angular/angular.js/pull/3598|feat(jqLite): use querySelectorAll instead of getElementsByTagName in jqLite.find}
 */
const addQuerySelectorAll = () => {
    if (angular.element.prototype.querySelectorAll === undefined) {
        angular.element.prototype.querySelectorAll = function (selector) {
            return angular.element(this[0].querySelectorAll(selector));
        };
    }
};

/**
 * @name addClosest
 * @memberof CoreBundle.Core
 * @kind function
 * 
 * @description
 * Adds closest to AngularJS.
 */
const addClosest = () => {
    if (angular.element.prototype.closest === undefined) {
        angular.element.prototype.closest = function (selector) {
            var elem = this[0];
            var matchesSelector = elem.matches || elem.webkitMatchesSelector || elem.mozMatchesSelector || elem.msMatchesSelector;

            while (elem) {
                if (matchesSelector.bind(elem)(selector)) {
                    return elem;
                } else {
                    elem = elem.parentElement;
                }
            }
            return false;
        };
    }
};

/**
 * @name addStartsWith
 * @memberof CoreBundle.Core
 * @kind function
 * 
 * @description
 * Adds startsWith to AngularJS for non-ES6 browsers.
 *
 * @see {@link https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/String/startsWith|MDN}
 */
const addStartsWith = () => {
    if (!String.prototype.startsWith) {
        String.prototype.startsWith = function (searchString, position) {
            position = position || 0;
            return this.substr(position, searchString.length) === searchString;
        };
    }
};

/**
 * @name addArrayFind
 * @memberof CoreBundle.Core
 * @kind function
 * 
 * @description
 * Adds Array.includes to AngularJS for non-ES6 browsers.
 *
 * @see {@link https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Array/find|MDN}
 */
const AddArrayFind = () => {
    if (!Array.prototype.find) {
        Object.defineProperty(Array.prototype, 'find', {
            value: function(predicate) {
            // 1. Let O be ? ToObject(this value).
                if (this == null) {
                throw new TypeError('"this" is null or not defined');
                }
        
                var o = Object(this);
        
                // 2. Let len be ? ToLength(? Get(O, "length")).
                var len = o.length >>> 0;
        
                // 3. If IsCallable(predicate) is false, throw a TypeError exception.
                if (typeof predicate !== 'function') {
                throw new TypeError('predicate must be a function');
                }
        
                // 4. If thisArg was supplied, let T be thisArg; else let T be undefined.
                var thisArg = arguments[1];
        
                // 5. Let k be 0.
                var k = 0;
        
                // 6. Repeat, while k < len
                while (k < len) {
                // a. Let Pk be ! ToString(k).
                // b. Let kValue be ? Get(O, Pk).
                // c. Let testResult be ToBoolean(? Call(predicate, T, « kValue, k, O »)).
                // d. If testResult is true, return kValue.
                var kValue = o[k];
                if (predicate.call(thisArg, kValue, k, o)) {
                    return kValue;
                }
                // e. Increase k by 1.
                k++;
                }
        
                // 7. Return undefined.
                return undefined;
            },
            configurable: true,
            writable: true
        });
    }
}

const addObjTypeChecks = () => {
    if ( window.getTypeCheckedValue === undefined ) {
        window.getTypeCheckedValue = ( val, valType, otherwise ) => {
            let retVal = otherwise;

            switch ( valType.toLowerCase() ) {
                case 'string':
                    if ( typeof val === 'string' && val.length > 0 ) retVal = val;
                    break;
                case 'bool':
                case 'boolean':
                    if ( typeof val === 'boolean' ) retVal = val;
                    break;
                case 'number':
                case 'int':
                    if ( /^(\-|\+)?([0-9]+|Infinity)$/.test( val )) retVal = Number( val );
                    break;
                case 'array':
                    if ( Array.isArray( val )) retVal = val;
                    break;
                case 'date':
                    if ( val instanceof Date ) retVal = val;
                    break;
                case 'object':
                    if ( typeof val === 'object' && val !== null ) retVal = val;
                    break;
                case 'function':
                    if ( typeof val === 'function' ) retVal = val;
                    break;
                default:
                    break;
            }

            return retVal;
        };
    }

    if ( window.getInstanceCheckedValue === undefined ) {
        window.getInstanceCheckedValue = ( val, valInstance, otherwise ) => {
            let retVal = otherwise;

            if ( typeof val === 'object' && val !== null ) {
                let valPrototype = Object.getPrototypeOf( val ),
                    valKeys = Object.keys( valPrototype ).sort(),
                    insPrototype = Object.getPrototypeOf( valInstance ),
                    insKeys = Object.keys( insPrototype ).sort();

                if ( valPrototype === insPrototype ||
                    ( JSON.stringify( valKeys ) === JSON.stringify( insKeys )) ||
                    val instanceof valInstance ) {
                    retVal = val;
                }
            }

            return retVal;
        };
    }
};

/**
 * @name addArrayIncludes
 * @memberof CoreBundle.Core
 * @kind function
 * 
 * @description
 * Adds Array.includes to AngularJS for non-ES6 browsers.
 *
 * @see {@link https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Array/includes|MDN}
 */
const addArrayIncludes = () => {
    if (!Array.prototype.includes) {
        Object.defineProperty(Array.prototype, 'includes', {
            value: function(searchElement, fromIndex) {
      
                if (this == null) {
                    throw new TypeError('"this" is null or not defined');
                }
        
                // 1. Let O be ? ToObject(this value).
                var o = Object(this);
        
                // 2. Let len be ? ToLength(? Get(O, "length")).
                var len = o.length >>> 0;
        
                // 3. If len is 0, return false.
                if (len === 0) {
                    return false;
                }
        
                // 4. Let n be ? ToInteger(fromIndex).
                //    (If fromIndex is undefined, this step produces the value 0.)
                var n = fromIndex | 0;
        
                // 5. If n ≥ 0, then
                //  a. Let k be n.
                // 6. Else n < 0,
                //  a. Let k be len + n.
                //  b. If k < 0, let k be 0.
                var k = Math.max(n >= 0 ? n : len - Math.abs(n), 0);
        
                function sameValueZero(x, y) {
                    return x === y || (typeof x === 'number' && typeof y === 'number' && isNaN(x) && isNaN(y));
                }
        
                // 7. Repeat, while k < len
                while (k < len) {
                    // a. Let elementK be the result of ? Get(O, ! ToString(k)).
                    // b. If SameValueZero(searchElement, elementK) is true, return true.
                    if (sameValueZero(o[k], searchElement)) {
                        return true;
                    }
                    // c. Increase k by 1. 
                    k++;
                }
        
                // 8. Return false
                return false;
            }
        });
    }
};

/**
 * @name addObjectAssign
 * @memberof CoreBundle.Core
 * @kind function
 * 
 * @description
 * Adds Array.includes to AngularJS for non-ES6 browsers.
 *
 * @see {@link https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Object/assign|MDN}
 */
const addObjectAssign = () => {
    if (typeof Object.assign != 'function') {
        // Must be writable: true, enumerable: false, configurable: true
        Object.defineProperty(Object, "assign", {
            value: function assign(target, varArgs) { // .length of function is 2
                'use strict';
                if (target == null) { // TypeError if undefined or null
                    throw new TypeError('Cannot convert undefined or null to object');
                }
  
                var to = Object(target);
  
                for (var index = 1; index < arguments.length; index++) {
                    var nextSource = arguments[index];
  
                    if (nextSource != null) { // Skip over if undefined or null
                        for (var nextKey in nextSource) {
                            // Avoid bugs when hasOwnProperty is shadowed
                            if (Object.prototype.hasOwnProperty.call(nextSource, nextKey)) {
                                to[nextKey] = nextSource[nextKey];
                            }
                        }
                    }
                }
                return to;
            },
            writable: true,
            configurable: true
        });
    }
};


/**
 * @name addStringIncludes
 * @memberof CoreBundle.Core
 * @kind function
 * 
 * @description
 * Adds String.includes to AngularJS for non-ES6 browsers.
 *
 * @see {@link https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/String/includes|MDN}
 */
const addStringIncludes = () => {
    if (!String.prototype.includes) {
        String.prototype.includes = function(search, start) {
            'use strict';
            if (typeof start !== 'number') {
                start = 0;
            }
          
            if (start + search.length > this.length) {
                return false;
            } else {
                return this.indexOf(search, start) !== -1;
            }
        };
    }
};

/**
 * @name addArrayForEach
 * @memberof CoreBundle.Core
 * @kind function
 * 
 * @description
 * Adds Array.forEach to AngularJS for non-ES6 browsers.
 *
 * @see {@link https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Array/forEach|MDN}
 */
const addArrayForEach = () => {
    if (!Array.prototype.forEach) {

        Array.prototype.forEach = function(callback/*, thisArg*/) {

            var T, k;

            if (this == null) {
            throw new TypeError('this is null or not defined');
            }

            // 1. Let O be the result of calling toObject() passing the
            // |this| value as the argument.
            var O = Object(this);

            // 2. Let lenValue be the result of calling the Get() internal
            // method of O with the argument "length".
            // 3. Let len be toUint32(lenValue).
            var len = O.length >>> 0;

            // 4. If isCallable(callback) is false, throw a TypeError exception. 
            // See: http://es5.github.com/#x9.11
            if (typeof callback !== 'function') {
                throw new TypeError(callback + ' is not a function');
            }

            // 5. If thisArg was supplied, let T be thisArg; else let
            // T be undefined.
            if (arguments.length > 1) {
                T = arguments[1];
            }

            // 6. Let k be 0.
            k = 0;

            // 7. Repeat while k < len.
            while (k < len) {

                var kValue;

                // a. Let Pk be ToString(k).
                //    This is implicit for LHS operands of the in operator.
                // b. Let kPresent be the result of calling the HasProperty
                //    internal method of O with argument Pk.
                //    This step can be combined with c.
                // c. If kPresent is true, then
                if (k in O) {

                    // i. Let kValue be the result of calling the Get internal
                    // method of O with argument Pk.
                    kValue = O[k];

                    // ii. Call the Call internal method of callback with T as
                    // the this value and argument list containing kValue, k, and O.
                    callback.call(T, kValue, k, O);
                }
                // d. Increase k by 1.
                k++;
            }
            // 8. return undefined.
        };
    }
};

/**
 * @name addNodeListForEach
 * @memberof CoreBundle.Core
 * @kind function
 * 
 * @description
 * Adds NodeList.forEach to AngularJS for non-ES6 browsers.
 *
 * @see {@link https://developer.mozilla.org/en-US/docs/Web/API/NodeList/forEach|MDN}
 */
const addNodeListForEach = () => {
    if (window.NodeList && !NodeList.prototype.forEach) {
        NodeList.prototype.forEach = function (callback, thisArg) {
            thisArg = thisArg || window;
            for (var i = 0; i < this.length; i++) {
                callback.call(thisArg, this[i], i, this);
            }
        };
    }
}

const addArrayLast = () => {
    if ( !Array.prototype.last ) {
        Array.prototype.last = function() {
            return this[ this.length - 1 ];
        };
    }
};

/**
 * @name addArrayFindIndex
 * @memberof CoreBundle.Core
 * @kind function
 * 
 * @description
 * Adds array.findIndex to AngularJS for non-ES6 browsers.
 *
 * @see {@link https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Array/findIndex|MDN}
 */
const addArrayFindIndex = () => {
    if (!Array.prototype.findIndex) {
        Object.defineProperty(Array.prototype, 'findIndex', {
            value: function(predicate) {
            // 1. Let O be ? ToObject(this value).
            if (this == null) {
                throw new TypeError('"this" is null or not defined');
            }
      
            var o = Object(this);
      
            // 2. Let len be ? ToLength(? Get(O, "length")).
            var len = o.length >>> 0;
      
            // 3. If IsCallable(predicate) is false, throw a TypeError exception.
            if (typeof predicate !== 'function') {
                throw new TypeError('predicate must be a function');
            }
      
            // 4. If thisArg was supplied, let T be thisArg; else let T be undefined.
            var thisArg = arguments[1];
      
            // 5. Let k be 0.
            var k = 0;
      
            // 6. Repeat, while k < len
            while (k < len) {
                // a. Let Pk be ! ToString(k).
                // b. Let kValue be ? Get(O, Pk).
                // c. Let testResult be ToBoolean(? Call(predicate, T, « kValue, k, O »)).
                // d. If testResult is true, return k.
                var kValue = o[k];
                if (predicate.call(thisArg, kValue, k, o)) {
                    return k;
                }
                // e. Increase k by 1.
                k++;
            }
      
            // 7. Return -1.
            return -1;
          },
          configurable: true,
          writable: true
        });
    }    
}

addQuerySelectorAll();
addClosest();
addStartsWith();
AddArrayFind();
addArrayForEach()
addArrayIncludes();
addObjectAssign();
addStringIncludes();
addObjTypeChecks();
addNodeListForEach();
addArrayLast();
addArrayFindIndex();