import Core from '../../../modules/core/core.module';
import footerTemplate from './footer.template.html';

import './footer.styles.scss';

/**
 * @name footerView
 * @memberof CoreBundle.Core
 * @class
 * 
 * @classdesc
 * AngularJS component.
 */
class FooterController {
    constructor( $scope, GLOBALS, modalService, Session ) {
        this.$scope = $scope;
        this.modalService = modalService;
        this.GLOBALS = GLOBALS;
        this.Session = Session;
    }

    $onInit(){
        this.$scope.vm = {
            copyrightDate: new Date(),
            aboutLink: 'views.global.footer.aboutLink',
            aboutText: 'views.global.footer.aboutText',
            termsLink: 'views.global.footer.termsLink',
            termsText: 'views.global.footer.termsText',
            privacyLink: 'views.global.footer.privacyLink',
            privacyText: 'views.global.footer.privacyText',
            contactSupportText: 'views.global.footer.contactSupportText',
            message: null,
            isGuestUser: false
        }

        if ( this.product === 'pb' ){
            this.$scope.vm.message = 'views.pb.footer.message';
            this.$scope.vm.isGuestUser = this.Session.isGuestUser();
        }

        this.$scope.launchContactSupport = this.launchContactSupport.bind( this );
    }

    launchContactSupport(){
        this.modalService.showModal( 
            this.GLOBALS.MODAL.CONTACT_SUPPORT.DEFAULTS, 
            this.GLOBALS.MODAL.CONTACT_SUPPORT.OPTIONS);
    }
}

const FooterView = {
    template: footerTemplate,
    controller: FooterController,
    bindings: {
        product: '<'
    }
};

Core.component( 'footerView', FooterView );
