// Local dependencies.
import Core from '../../modules/core/core.module';
import SessionStateModel from '../../models/core/session-state.model';
import AuthResponseModel from '../../models/core/auth-response.model';

class JWT {
    constructor( GLOBALS, jwtHelperProvider ) {
        'ngInject';

        this.refreshThresholdSeconds = GLOBALS.SESSION.REFRESH_THRESHOLD / 1000;
        this.refreshBufferSeconds = GLOBALS.SESSION.REFRESH_BUFFER / 1000;
        this.storageKey = GLOBALS.SUPPORTED_PRODUCT_STORAGE_KEYS.session;
        this.jwtHelper = jwtHelperProvider.$get();

        this.accessToken = null;
        this.refreshToken = null;
        this.sessionState = null;
        this.tokenSetTimestamp = null;
    }

    /**
     * Helper method that abstracts the checking for whether the provider recognizes
     * a property for a particular JWT.
     * 
     * @param {string} tokenType JWT type to check; e.g., "access" or "refresh".
     * @returns {boolean}
     */
    _valid( tokenType ) {
        tokenType = getTypeCheckedValue( tokenType, 'string', null );

        return ( tokenType !== null && this.hasOwnProperty( `${tokenType}Token` ));
    }
    
    /**
     * Whether the provider provides a property for a particular JWT, and whether
     * that property is populated.
     * 
     * @param {string} tokenType JWT type to check; e.g., "access" or "refresh".
     * @returns {boolean}
     */
    hasToken( tokenType ) {
        return ( this._valid( tokenType ) && this[ `${tokenType}Token` ] !== null );
    }

    /**
     * Fetches a JWT from the provider.
     * 
     * @param {string} tokenType JWT type to get; e.g., "access" or "refresh".
     * @returns {string|null}
     */
    getToken( tokenType ) {
        return ( this._valid( tokenType ))
            ? this[ `${tokenType}Token` ]
            : null;
    }

    /**
     * Sets a JWT within the provider.
     * 
     * @param {string} tokenType JWT type to set; e.g., "access" or "refresh".
     * @param {string} token Encrypted JWT value.
     */
    setToken( tokenType, token ) {
        token = getTypeCheckedValue( token, 'string', null );

        if ( this._valid( tokenType ) && token !== null ) {
            this[ `${tokenType}Token` ] = token;
        }
    }

    /**
     * Fetches the decoded payload held within a JWT. As of 5/14/20, this should
     * only return an object containing the session user's ID regardless of type.
     * 
     * @param {string} tokenType JWT type to check; e.g., "access" or "refresh".
     * @returns {object|null}
     */
    getPayload( tokenType ) {
        if ( this._valid( tokenType )) {
            return ( this[ `${tokenType}Token` ] !== null )
                ? this.jwtHelper.decodeToken( this[ `${tokenType}Token` ])
                : null;
        }

        return null;
    }
    
    /**
     * Whether a JWT is expired.
     * 
     * @param {string} tokenType JWT type to check; e.g., "access" or "refresh".
     * @returns {boolean}
     */
    isExpired( tokenType ) {
        return ( this._valid( tokenType ) &&
            this[ `${tokenType}Token` ] !== null &&
            this.jwtHelper.isTokenExpired( this.getToken( tokenType )));
    }

    /**
     * Expiration date and time before a JWT will expire.
     * 
     * @param {string} tokenType JWT type to check; e.g., "access" or "refresh".
     * @returns {Date|null}
     */
    expires( tokenType ) {
        if ( this._valid( tokenType ) && this[ `${tokenType}Token` ] !== null ) {
            return this.jwtHelper.getTokenExpirationDate( this[ `${tokenType}Token` ]);
        }

        return null;
    }

    /**
     * Number of seconds remaining before a JWT will expire.
     * 
     * @param {string} tokenType JWT type to check; e.g., "access" or "refresh".
     * @returns {number}
     */
    expiresSeconds( tokenType ) {
        let expires = this.expires( tokenType );

        if ( expires !== null ) {
            let expiresDiff = Math.abs( expires.getTime() - ( new Date().getTime() )),
                expiresSecs = Math.floor( expiresDiff / 1000 );

            return ( expiresSecs > 0 ) ? expiresSecs : 0;
        }

        return 0;
    }

    /**
     * Whether a JWT is within the threshold of expiration.
     * 
     * @param {string} tokenType JWT type to check; e.g., "access" or "refresh".
     * @returns {boolean}
     */
    isExpiring( tokenType ) {
        if ( this._valid( tokenType ) && !this.isExpired( tokenType )) {
            //console.log( '[jwt provider - isExpiring]: valid and not expired' );
            let tokenExpires = this.expiresSeconds( tokenType ),
                tokenExpiresBuffer = tokenExpires - this.refreshBufferSeconds;

            //console.log( '[jwt provider - isExpiring]: expiration, buffered expiration', tokenExpires, tokenExpiresBuffer );

            return tokenExpiresBuffer <= this.refreshThresholdSeconds;
        }

        //console.log( '[jwt provider - isExpiring]: not valid or expired' );
        return false;
    }

    /**
     * Whether the provider's session state has been populated with an instance
     * of SessionStateModel.
     * 
     * @returns {boolean}
     */
    hasSessionState() {
        return ( this.sessionState !== null &&
            this.sessionState instanceof SessionStateModel );
    }

    /**
     * Sets session state using a previously initialized instance of SessionStateModel,
     * or defaults to an empty instance.
     * 
     * @param {SessionStateModel} sessionState Session state as extrapolated from
     * browser storage or API response.
     */
    setSessionState( sessionState ) {
        this.sessionState = ( sessionState instanceof SessionStateModel )
            ? sessionState
            : new SessionStateModel();
    }

    /**
     * Returns the populated value of session state if it's been set.
     * 
     * @returns {SessionStateModel|null}
     */
    getSessionState() {
        return this.sessionState;
    }

    /**
     * Primary entry point for provider interaction.
     */
    $get( storageService ) {
        'ngInject';

        let jwt = {};

        /**
         * Initializes the provider, and attempts to populate itself based on
         * data previously stored in browser storage from auth interstitial.
         * 
         * @returns {Promise} Resolves when complete.
         */
        jwt.bootstrap = () => {
            console.debug( '[jwt-provider] :: bootstrap starting' );

            try {
                let storedObj = storageService.get( this.storageKey, true )
                storedObj = getTypeCheckedValue( storedObj, 'object', null );

                if ( storedObj === null )
                    throw new Error( 'Expected object in browser storage; received null.' );

                let storedModel = AuthResponseModel.fromObj( storedObj );
                jwt.setTokens( storedModel, true );
            } catch ( err ) {
                console.warn( '[jwt-provider] :: Unable to bootstrap.', err );
            }          
        };

        /**
         * Fetches the current Date object that describes the date-time at
         * which the access token will expire.
         * 
         * @returns {Date|null}
         */
        jwt.getExpiration = () => {
            return this.expires( 'access' );
        };

        /**
         * Helper method to manually execute a "dumb" bootstrap of the provider;
         * unlike bootstrap, this will not auto-detect and refresh/restore the
         * session based on the JWTs.
         */
        jwt.synchronize = () => {
            jwt.setTokens( storageService.get( this.storageKey, true ));
        };

        /**
         * Whether the access JWT has been populated.
         * 
         * @returns {boolean}
         */
        jwt.hasAccessToken = () => {
            return this.hasToken( 'access' );
        };

        /**
         * Whether the refresh JWT has been populated.
         * 
         * @returns {boolean}
         */
        jwt.hasRefreshToken = () => {
            return this.hasToken( 'refresh' );
        };

        /**
         * Whether the user session state contains specific product data.
         * 
         * @param {string} productKey Product identifier to check for; e.g., "mm", "pb"
         * @returns {boolean}
         */
        jwt.hasProductData = ( productKey ) => {
            return ( this.hasSessionState() &&
                this.sessionState.hasProductData( productKey ));
        };

        /**
         * Whether the access JWT is within the threshold of expiration, and
         * a candidate for renewal prior to the next API call.
         * 
         * @returns {boolean}
         */
        jwt.shouldRefresh = () => {
            return this.isExpiring( 'access' );
        };

        /**
         * Fetches a previously populated access JWT.
         * 
         * @returns {string|null}
         */
        jwt.getAccessToken = () => {
            return this.getToken( 'access' );
        };

        /**
         * Fetches a previously populated refresh JWT.
         * 
         * @returns {string|null}
         */
        jwt.getRefreshToken = () => {
            return this.getToken( 'refresh' );
        };

        /**
         * Consumes an object - either from browser storage or API response -
         * and extrapolates JWTs and session state with which to populate
         * the provider.
         * 
         * @param {AuthResponseModel} authModel
         */
        jwt.setTokens = ( authModel, isBootstrap = false ) => {
            authModel = getInstanceCheckedValue( authModel, AuthResponseModel, null );

            console.debug( '[jwt-provider] :: setTokens starting with auth model', authModel );
            
            if ( authModel !== null ) {

                // Access token.
                this.setToken( 'access', authModel.accessToken );

                // Refresh token.
                this.setToken( 'refresh', authModel.refreshToken );

                // Session state.
                this.setSessionState( authModel.sessionState );

                console.debug( '[jwt-provider] :: access/refresh tokens and sessionState set' );

                // There are two occasions in which setTokens is called:
                // 1. On bootstrap here in the service.
                // 2. From core.session. If it's being called from
                //    core.session, we save it to storage no matter
                //    what.
                if ( !isBootstrap ) {
                    console.debug( '[jwt-provider] :: non-bootstrap set; persisting to browser storage' );
                    storageService.set( this.storageKey, JSON.stringify( authModel ));
                }
            }
        };

        /**
         * Fetches the current session state.
         * 
         * @returns {SessionStateModel|null}
         */
        jwt.getSessionState = () => {
            return this.getSessionState();
        };

        /**
         * Fetches the data payload held within the access JWT. This may be
         * deprecated in the future, as we no longer store session state
         * within the access JWT. It may still have some use, however, as
         * it does contain a user ID.
         * 
         * @returns {object|null}
         */
        jwt.getAccessPayload = () => {
            return this.getPayload( 'access' );
        };

        /**
         * Fetches the data payload held within the refresh JWT, which
         * consists of the session user's ID.
         * 
         * @returns {object|null}
         */
        jwt.getRefreshPayload = () => {
            return this.getPayload( 'refresh' );
        };

        /**
         * Whether the access JWT is expired.
         * 
         * @returns {boolean}
         */
        jwt.isAccessExpired = () => {
            return this.isExpired( 'access' );
        };

        /**
         * Whether the refresh JWT is expired.
         * 
         * @returns {boolean}
         */
        jwt.isRefreshExpired = () => {
            return this.isExpired( 'refresh' );
        };

        /**
         * Number of seconds remaining before the access JWT will expire.
         * 
         * @returns {number}
         */
        jwt.accessExpires = () => {
            return this.expires( 'access' );
        };

        /**
         * Number of seconds remaining before the refresh JWT will expire.
         * 
         * @returns {number}
         */
        jwt.refreshExpires = () => {
            return this.expires( 'refresh' );
        };

        return jwt;
    }
}

Core.provider( 'jwtService', JWT );