import angular from 'angular';
import Core from '../../modules/core/core.module';

/**
 * @name $productModules
 * @memberof CoreBundle.Core
 * @class
 * 
 * @classdesc
 * AngularJS provider.
 */
class ProductModules {
    constructor( $compileProvider, $controllerProvider, $filterProvider, $injector, $provide ) {
        'ngInject';

        this.instanceInjector = null;
        this.providers = {
            $compileProvider,
            $controllerProvider,
            $filterProvider,
            $injector,
            $provide,
            
            getInstanceInjector: () => {
                return this.instanceInjector !== null
                    ? this.instanceInjector
                    : ( this.instanceInjector = angular.injector() );
            }
        };
        this.registeredModules = [
            'ng',
            'marketing-portal',
            ...Core.requires
        ];
        this.runBlocks = {};
    }

    $get() {
        const providers = this.providers;

        let registeredModules = this.registeredModules,
            runBlocks = this.runBlocks;

        return {
            registerModule: function( moduleName ) {                
                let module = angular.module( moduleName );
                if ( Array.isArray( module.requires )) {
                    for ( let i = 0; i < module.requires.length; i++ ) {
                        if ( !registeredModules.includes( module.requires[ i ])) {
                            this.registerModule( module.requires[ i ]);
                            registeredModules.push( module.requires[ i ]);
                        }
                    }
                }

                if ( Array.isArray( module._invokeQueue )) {
                    module._invokeQueue.forEach( invokeArgs => {
                        let provider = providers[ invokeArgs[ 0 ]];
                        provider[ invokeArgs[ 1 ]].apply( provider, invokeArgs[ 2 ]);
                    })
                }

                if ( Array.isArray( module._configBlocks )) {
                    module._configBlocks.forEach( configArgs => {
                        let provider = providers[ configArgs[ 0 ]];
                        provider[ configArgs[ 1 ]].apply( provider, configArgs[ 2 ]);
                        /*if ( typeof fn === 'function' ) {
                            $injector.invoke( fn );
                        } else {
                            if ( Array.isArray( fn ) && fn.length === 3 ) {
                                $injector.invoke( ...fn[ 2 ]);
                            }
                        }*/
                    })
                }

                if ( Array.isArray( module._runBlocks )) {
                    module._runBlocks.forEach( fn => {
                        runBlocks[ moduleName ] = fn;
                    })
                }

                let instanceInjector = providers.getInstanceInjector();
                Object.keys( runBlocks ).forEach( runBlockModule => {
                    instanceInjector.invoke( runBlocks[ runBlockModule ]);
                })
            }
        };
    }
}

Core.provider( '$productModules', ProductModules );
