// Local dependencies.
import Core from '../../../modules/core/core.module';
import utilityNavTemplate from './utility-nav.template.html';
import NotificationModel from '../../../models/core/notification.model';
import { NOTIFICATION_DELAY } from '../../../constants/core/variables';
import './utility-nav.styles.scss';

/**
 * @name utilityNav
 * @memberof CoreBundle.Core
 * @class
 * 
 * @classdesc
 * AngularJS component. The top-most bar shown across all products of the
 * Acquisition & Retention portal, containing global or shared functions.
 */
class UtilityNavController {
    constructor( $rootScope, $scope, $state, $window, $transitions, GLOBALS, Session, Notification,
        modalService, eventService, analyticsService, announcementFactory, $timeout, signalRAnnouncementHub, 
        signalRNotificationHub) {

        this.$rootScope = $rootScope;
        this.$scope = $scope;
        this.$state = $state;
        this.$window = $window;
        this.$transitions = $transitions;
        this.GLOBALS = GLOBALS;
        this.Session = Session;
        this.Notification = Notification;
        this.modalService = modalService;
        this.eventService = eventService;
        this.analyticsService = analyticsService;
        this.announcementFactory = announcementFactory;
        this.$timeout = $timeout;
        this.signalRAnnouncementHub = signalRAnnouncementHub;
        this.signalRNotificationHub = signalRNotificationHub;

    }

    $onInit() {
        const self = this;

        this.dashboardUrl = this.GLOBALS.ENDPOINTS.dashboard;
        this.loginUrl = this.GLOBALS.ENDPOINTS.login;
        this.logoutUrl = this.GLOBALS.ENDPOINTS.logout,
        this.profileUrl = this.GLOBALS.ENDPOINTS.profile,
        this.workspaceUrl = this.GLOBALS.ENDPOINTS.workspace;
        this.usermanagementUrl = this.GLOBALS.ENDPOINTS.usermanagement;

        this.$scope.navbarContainerElem = angular.element( document.querySelector( '#navbar-container' ));
        this.$scope.ui = {
            isMultiPortalAccess: false,
            isAuthenticated: false,
            isAuthenticatedGuest: false,
            isMultipleAccounts: false,
            isMultipleProducts: false,
            isPbEnabled: false,
            isMmEnabled: false,
            isImpersonatingUser: false,
            isCLAAdmin: false,
            isCLASupportAdmin: false,
            isCLASuperAdmin: false,
            currentProduct: null,
            displayName: null,
            displayUserId: null,
            impersonatingUser: {},
            dashboardUrl: this.dashboardUrl,
            workspaceUrl: this.workspaceUrl,
            profileUrl: this.profileUrl,
            userManagementUrl: this.usermanagementUrl,
            productUrls: {
                pbSearch: this.GLOBALS.PRODUCT_LANDINGS.fromUtility.pbSearch,
                pbAdmin: this.GLOBALS.PRODUCT_LANDINGS.fromUtility.pbAdmin,
                mmDefault: this.GLOBALS.PRODUCT_LANDINGS.fromUtility.mmDefault
            },
            persistentNotificationDisplayed: false,
            persistentNotification: null,
            notifications: [],
            notificationCount: 0,
            notificationTrayVisibleCount: 5,
            notificationTrayOpen: false,
            notificationTrayShown: true,
            announcementFirstLoad: true,
            announcementCount: 0
        };

        this._populateSessionData();

        this.$scope.login = this.login.bind( this );
        this.$scope.logout = this.logout.bind( this );
        this.$scope.dashboard = this.dashboard.bind( this );
        this.$scope.selectAccount = this.selectAccount.bind( this );
        this.$scope.launchChat = this.launchChat.bind( this );
        this.$scope.launchHelp = this.launchHelp.bind( this );
        this.$scope.userProfile = this.userProfile.bind( this );
        this.$scope.handleMultipleProductSelect = this.handleMultipleProductSelect.bind( this );
        this.$scope.handleNotificationReduceCount = this.handleNotificationReduceCount.bind( this );
        this.$scope.returnToProductRoot = this.returnToProductRoot.bind( this );
        this._checkState = this._checkState.bind( this );

        // Notification web socket subscriptions.
        this.signalRNotificationHub.onListReceived( this._onNotificationListReceived.bind( this ));
        this.signalRNotificationHub.onSeenComplete( this._onNotificationListSeenComplete.bind( this ));
        this.signalRNotificationHub.onNotification( this._onNotificationReceived.bind( this ));

        // Announcement web socket subscriptions.
        this.signalRAnnouncementHub.onUnseenCountReceived( this._onAnnouncementCountReceived.bind( this ));

        this.eventService.listenSessionChanged( this.onSessionChange.bind( this ));
        this.eventService.listenPersistentNotification( this._onPersistentNotification.bind( this ));
        this.eventService.listenPersistentNotificationReset( this._onPersistentNotificationReset.bind( this ));
        this.eventService.listenHttpResponseNotification( this._onHttpResponseNotification.bind( this ));
        this.eventService.listenGenericNotification( this._onGenericNotification.bind( this ));
        this.$transitions.onStart( {}, ( transition ) => self._checkState( transition.to().name ));
    }

    /**
     * Handles the click for the "Acquisition and Retention" header title,
     * which should dynamically return the user to the product root to
     * which they're currently configured. This is to accommodate global
     * views like announcements that are product-agnostic, and don't have
     * a product header or other means to get back to the actual current
     * product interface.
     */
    returnToProductRoot() {
        let currentApplication = this.Session.defaultApp;

        if ( currentApplication !== null ) {

            let currentApplicationUrlKey = ( currentApplication === 'pb' )
                ? 'pbSearch'
                : 'mmDefault';

            this.handleMultipleProductSelect( currentApplication, currentApplicationUrlKey );
        }
    }

    login() {
        this.Session.destroy();
        window.location.assign( this.loginUrl );
    }

    logout() {
        this.Session.logoutSession()
            .then( isSuccess => {
                isSuccess = getTypeCheckedValue( isSuccess, 'boolean', false );
                console.debug( '[utility-nav.component] :: post-api logout result', isSuccess );

                // Redirect.
                window.location.assign( this.logoutUrl );
            });
    }

    selectAccount() {
        // Pass true, so we know it's safe to show the cancel button.
        this.Session.getMultipleAccountSelectModal( true );
    }

    launchChat() {
        this.analyticsService.create("CHAT");

        let currentApplication = this.Session.defaultApp;
        
        if ( currentApplication !== null ) {
            let helpUrl = this.GLOBALS.HELP[ currentApplication ];

            if ( currentApplication === 'mm' ) {
                window.open(this.GLOBALS.CUSTOMER_SUPPORT_ROUTE_MM, '_blank');
            } else if ( currentApplication === 'pb' ) {
                window.open(this.GLOBALS.CUSTOMER_SUPPORT_ROUTE_PB, '_blank');
            }    
        }
    }

    launchHelp() {
        let currentApplication = this.Session.defaultApp;

        if ( currentApplication !== null ) {
            let helpUrl = this.GLOBALS.HELP[ currentApplication ];

            if ( currentApplication === 'mm' ) {
                this.analyticsService.create("MARKET_MAGNIFIER/HELP");
            } else if ( currentApplication === 'pb' ) {
                this.analyticsService.create("PROSPECT_BASE/HELP");
            }            

            if (currentApplication !== null && helpUrl) {
                this.$window.open( 
                    helpUrl, 
                    '_blank', 
                    'width=' + this.$window.screen.availWidth * 75 / 100 + ',height=' + this.$window.screen.availHeight * 75 / 100 
                );
            }
        }
    }

    /**
     * Manually navigates to the dashboard, as ui-router is prone to check the
     * path and attempt to resolve it within AngularJS.
     */
    dashboard() {
        window.location.assign( this.dashboardUrl );
    }

    userProfile() {
        let profileData = {
            action: 'profile',
            user: this.Session
        };
        
        let modalDefaults = {
            size: 'lg',
            component: 'userProfileModal',
            resolve: {
                profileData: profileData
            }
        };
        
        this.modalService.showModal( modalDefaults, {} );
    }

    handleMultipleProductSelect( productKey, urlKey ) {
        if ( this.Session.setCurrentApp( productKey, true )) {
            this.$scope.ui.currentProduct = productKey;
            this.$state.go( this.$scope.ui.productUrls[ urlKey ]);
        }
    }

    /**
     * Fired by the notification tray component when non-database notifications
     * should be marked as "seen", and the count needs to be updated.
     * 
     * @param {Number} reduceCount The count to deduct from the current count.
     */
    handleNotificationReduceCount( reduceCount ) {
        reduceCount = getTypeCheckedValue( reduceCount, 'int', 0 );
        this.$scope.ui.notificationCount -= reduceCount;
    }

    _setNotificationCount(){
        this.$scope.ui.notificationCount = this.$scope.ui.notifications
            .filter( n => !n.isViewed )
            .length;
    }

    onSessionChange() {
        this._populateSessionData();
    }

    /**
     * Handles an event emitted from wsAnnouncement with an updated count
     * of unseen user announcements.
     * 
     * @param {object} responseObj 
     */
    _onAnnouncementCountReceived( responseObj ) {
        responseObj = getTypeCheckedValue( responseObj, 'object', {} );

        let announcementCount = getTypeCheckedValue( responseObj.unseenCount, 'number', 0 ),
            announcementFirstLoad = this.$scope.ui.announcementFirstLoad;

        this.$timeout(() => {

            console.log( 'announcementCount', announcementCount );
            
            this.$scope.ui.announcementCount = announcementCount;
            this.$scope.ui.announcementFirstLoad = false;

            // We have unseen announcements, and this is the first
            // page load.
            if ( announcementCount > 0 && announcementFirstLoad ) {

                let faIcon = 'fa-bullhorn',
                    i18nPrefix = 'core.notification.announcements.unread';
    
                this.eventService.broadcastGenericNotification( new NotificationModel(
                    faIcon,
                    'success',
                    `${i18nPrefix}-title`,
                    null,
                    null,
                    `${i18nPrefix}-message`,
                    null,
                    { count: announcementCount },
                    null,
                    null,
                    null,
                    'mp.announcements',
                    `${i18nPrefix}-link`,
                    null,
                    null,
                    null,
                    false,
                    false,
                    NOTIFICATION_DELAY,
                    true
                ));
            }
        });
    }

    _populateSessionData() {
        this.$scope.ui.isMultiPortalAccess = this.Session.isMultiPortalAccess;
        this.$scope.ui.isAuthenticated = this.Session.isAuthenticated();
        this.$scope.ui.isAuthenticatedGuest = this.Session.isAuthenticatedGuestUser();
        this.$scope.ui.isMultipleAccounts = this.Session.isMultipleAccountsAvailable();
        this.$scope.ui.isMultipleProducts = this.Session.isMultipleProductAccess();
        this.$scope.ui.isPbAccess = this.Session.hasProspectBaseAccess();
        this.$scope.ui.isPbEnabled = this.Session.hasProspectBaseAccess( true );
        this.$scope.ui.isMmAccess = this.Session.hasMarketMagnifierAccess();
        this.$scope.ui.isMmEnabled = this.Session.hasMarketMagnifierAccess( true );
        this.$scope.ui.currentProduct = this.Session.defaultApp;
        this.$scope.ui.displayName = this.Session.displayName;
        this.$scope.ui.displayUserId = ( this.Session.username !== null )
            ? `(${this.Session.username})`
            : null;

        this.$scope.ui.impersonatingUser = this.Session.impersonatingUser;
        this.$scope.ui.isImpersonatingUser = this.Session.isImpersonatingUser;
        this.$scope.ui.impersonatingUser.displayName = this.Session.impersonatingUser.displayName;
        this.$scope.ui.impersonatingUser.displayUserId = ( this.Session.impersonatingUser.loginId !== null )
        ? `(${this.Session.impersonatingUser.loginId})`
        : null;

        this.$scope.ui.isCLAAdmin = this.Session.isCLAAdmin;
        this.$scope.ui.isCLASupportAdmin = this.Session.isCLASupportAdmin;
        this.$scope.ui.isCLASuperAdmin = this.Session.isCLASuperAdmin;
    }

    _checkState( stateName ) {
        stateName = getTypeCheckedValue( stateName, 'string', '' );

        this.$scope.ui.persistentNotificationDisplayed = (
            stateName.length > 0 &&
            !this.GLOBALS.PERSISTENT_NOTIFICATION_STATES.includes( stateName ) &&
            this.$scope.ui.persistentNotification !== null
        );
    }

    /**
     * Event handler for receiving a new notification instance from the
     * event service. Launches a toast notification without impacting the
     * notification tray.
     * @param {*} event 
     * @param {*} notification 
     */
    _onGenericNotification( event, notification ) {
        this._popNotification( notification );
    }

    /**
     * Event handler for receiving a new notification instance from the
     * event service. Launches a toast notification.
     * 
     * @param {Object} event
     * @param {NotificationModel} notification 
     */
    _onHttpResponseNotification( event, notification ) {
        if ( NotificationModel.isInstanceOf( notification )) {
            let notifications = this.$scope.ui.notifications.slice( 0 );
            notifications.push( notification );

            // Sort models by createTime descending.
            notifications.sort(( a, b ) => {
                const aCreated = ( a.createTime instanceof Date ) ? a.createTime.getTime() : 1;
                const bCreated = ( b.createTime instanceof Date ) ? b.createTime.getTime() : 2;
                return bCreated - aCreated;
            });

            // Set the tray notifications.
            this.$scope.ui.notifications = notifications.slice( 0, this.$scope.ui.notificationTrayVisibleCount );

            // Set the unseen indicator.
            this._setNotificationCount();

            // Pop the "toast".
            let notificationScope = this.$rootScope.$new();
            Object.keys( notification ).forEach( propKey => {
                notificationScope[ propKey ] = notification[ propKey ];
            });

            // Bind a function to launch the support modal.
            if ( notification.isSupport ) {
                const self = this;
                notificationScope.launchSupportModal = () => {
                    self.modalService.showModal(
                        self.GLOBALS.MODAL.CONTACT_SUPPORT.DEFAULTS,
                        self.GLOBALS.MODAL.CONTACT_SUPPORT.OPTIONS
                    );
                };
            }

            this.Notification({ scope: notificationScope, delay: notification.delay });
        }
    }

    /**
     * WS event handler for receiving a new notification instance, either
     * user-centric or globally broadcast. This casts the received object
     * into a NotificationModel, and then launches a toast notification.
     * 
     * @param {object} notification The received notification object.
     */
    _onNotificationReceived( notification ) {
        // Cast it.
        notification = NotificationModel.fromSocketServiceObject( notification );

        // If it's valid, push it to the notification tray and pop it
        // as a toast notification.
        if ( notification !== null ) {
            
            // "Copy" the existing array.
            let notifications = this.$scope.ui.notifications.slice( 0 );
            if ( notifications.find( n => n.userNotificationId === notification.userNotificationId ) === undefined ) {
                notifications.push( notification );
            } else {
                let idx = notifications.findIndex( np => np.userNotificationId === notification.userNotificationId );
                notifications[ idx ].isViewed = true;
            }

            // Sort models by createTime descending.
            notifications.sort(( a, b ) => {
                const aCreated = ( a.createTime instanceof Date ) ? a.createTime.getTime() : 1;
                const bCreated = ( b.createTime instanceof Date ) ? b.createTime.getTime() : 2;
                return aCreated - bCreated;
            });

            // Set the tray notifications.
            this.$scope.ui.notifications = notifications;

            // Set the unseen indicator.
            this._setNotificationCount();

            // Pop the "toast".
            let notificationScope = this.$rootScope.$new();
            Object.keys( notification ).forEach( propKey => {
                notificationScope[ propKey ] = notification[ propKey ];
            });

            // Bind a function to launch the support modal.
            if ( notification.isSupport ) {
                const self = this;
                notificationScope.launchSupportModal = () => {
                    self.modalService.showModal(
                        self.GLOBALS.MODAL.CONTACT_SUPPORT.DEFAULTS,
                        self.GLOBALS.MODAL.CONTACT_SUPPORT.OPTIONS
                    );
                };
            }

            this.Notification({ scope: notificationScope });
        }
    }

    /**
     * WS event handler for receiving acknowledgement that one or more user
     * notifications have been updated in the backend as "seen". Updates the
     * applicable notifications in the notification tray, as well as the
     * count indicator.
     * 
     * @param {Array} userNotificationIds 
     */
    _onNotificationListSeenComplete( userNotificationIds ) {
        // Type check.
        userNotificationIds = getTypeCheckedValue( userNotificationIds, 'array', [] );
        userNotificationIds = userNotificationIds
            .map( uni => {
                if ( typeof uni === 'object' ) {
                    uni = getTypeCheckedValue( uni.UserNotificationId, 'int', null );
                    return uni;
                }

                uni = getTypeCheckedValue( uni, 'int', null );
                return uni;
            })
            .filter( uni => uni !== null );

        // Loop through the notification tray, and set matching notifications as
        // "seen".
        this.$scope.ui.notifications.forEach( notification => {
            if ( userNotificationIds.includes( notification.userNotificationId )) {
                notification.isViewed = true;
            }
        });

        // Set the unseen indicator.
        this._setNotificationCount();
    }

    /**
     * WS event handler for receiving a user's last X quantity notifications. This
     * casts the JSON objects into notification models, and selectively adds them
     * to the notification tray as needed.
     * 
     * @param {object} resObj An object containing notification total count and generic objects.
     */
    _onNotificationListReceived( resObj ) {
        this.$scope.$apply( function() {
            console.log( resObj );

            // Type check.
            resObj = getTypeCheckedValue( resObj, 'object', {} );
            let count = getTypeCheckedValue( resObj.count, 'int', 0 ),
                notificationsNew = NotificationModel.fromSocketServiceArray( getTypeCheckedValue( resObj.notifications, 'array', [] )),
                notifications = this.$scope.ui.notifications.slice( 0 );

            // Blend the new with the old.
            notificationsNew.forEach( notification => {
                if ( notifications.find( np => np.userNotificationId === notification.userNotificationId ) === undefined ) {
                    notifications.push( notification );
                } else {
                    let idx = notifications.findIndex( np => np.userNotificationId === notification.userNotificationId );
                    notifications[ idx ].isViewed = true;
                }
            });

            // Sort models by createTime descending.
            notifications.sort(( a, b ) => {
                const aCreated = ( a.createTime instanceof Date ) ? a.createTime.getTime() : 1;
                const bCreated = ( b.createTime instanceof Date ) ? b.createTime.getTime() : 2;
                return aCreated - bCreated;
            });

            // Set the tray notifications.
            this.$scope.ui.notifications = notifications;

            // Set the unseen indicator.
            this.$scope.ui.notificationCount = count;
        }.bind( this ));
    }

    /**
     * Event handler for a global persistent notification being set. This determines
     * whether a static notification is appended to the utility navigation when
     * navigating away from routes that need it.
     */
    _onPersistentNotification( event, persistentObj ) {
        this.$scope.ui.persistentNotification = ( persistentObj instanceof NotificationModel )
            ? persistentObj
            : null;
    }

    /**
     * Event handler for a global persistent notification being reset. This removes
     * a static notification appended to the utility navigation if one is set.
     */
    _onPersistentNotificationReset( event ) {
        this.$scope.ui.persistentNotification = null;
        this.$scope.ui.persistentNotificationDisplayed = false;
    }

    /**
     * Abstracted method that pushes a toast notification to the UI.
     * 
     * @param {NotificationModel} notification 
     */
    _popNotification( notification ) {
        if ( NotificationModel.isInstanceOf( notification )) {

            // Each notification needs a dedicated scope.
            let notificationScope = this.$rootScope.$new();
            Object.keys( notification ).forEach( notificationKey =>
                notificationScope[ notificationKey ] = notification[ notificationKey ]);

            // If it's a support modal, bind a function to launch the
            // support modal window.
            if ( notification.isSupport ) {
                notificationScope.launchSupportModal = () =>
                    this.modalService.showModal(
                        this.GLOBALS.MODAL.CONTACT_SUPPORT.DEFAULTS,
                        this.GLOBALS.MODAL.CONTACT_SUPPORT.OPTIONS
                    );
            }

            // If we need to call the announcements service, bind a
            // function to do so.
            if ( notification.isSetAllAnnouncementsSeen ) {
                notificationScope.setAllAnnouncementsSeen = () =>
                    this.signalRAnnouncementHub.allAnnouncementsSeen();
            }

            // Show it.
            this.Notification({
                scope: notificationScope,
                delay: notification.delay
            });
        }
    }
}

const utilityNav = {
    template: utilityNavTemplate,
    controller: UtilityNavController
};

Core.component( 'utilityNav', utilityNav );
