// Library dependencies.
import get from 'lodash/get';

// Local dependencies.
import APIv3ResponseModel from './apiv3-response.model';

/**
 * Defines a single user announcement, excluding content.
 */
export class UserAnnouncementBase {
    constructor( announcementId, announcementCategoryId, announcementCategoryKey,
        announcementCategoryName, title, startDate, isSeen, isStale, isFuture ) {
        
        this.announcementId = getTypeCheckedValue( announcementId, 'number', null );
        this.announcementCategoryId = getTypeCheckedValue( announcementCategoryId, 'number', null );
        this.announcementCategoryKey = getTypeCheckedValue( announcementCategoryKey, 'string', null );
        this.announcementCategoryName = getTypeCheckedValue( announcementCategoryName, 'string', null );
        this.title = getTypeCheckedValue( title, 'string', null );
        this.startDate = getTypeCheckedValue( startDate, 'string', null );
        this.isSeen = getTypeCheckedValue( isSeen, 'bool', false );
        this.isStale = getTypeCheckedValue( isStale, 'bool', false );
        this.isFuture = getTypeCheckedValue( isFuture, 'boolean', false );

        try {
            if ( window.moment && this.startDate !== null )
                this.startDate = window.moment( this.startDate ).format( 'll' );
        } catch ( err ) {
            console.warn( err );
        }

        this.isValid = (
            this.announcementId !== null &&
            this.announcementCategoryId !== null &&
            this.announcementCategoryKey !== null &&
            this.announcementCategoryName !== null &&
            this.title !== null &&
            this.startDate !== null
        );
    }

    /**
     * Helper method to parse a JSON object into an instance of
     * UserAnnouncementBase.
     * 
     * @param {object} obj 
     * @returns {UserAnnouncementBase}
     */
    static fromObj( obj ) {
        obj = getTypeCheckedValue( obj, 'object', {} );

        return new UserAnnouncementBase(
            obj.announcementId,
            obj.announcementCategoryId,
            obj.announcementCategoryKey,
            obj.announcementCategoryName,
            obj.title,
            obj.startDate,
            obj.isSeen,
            obj.isStale,
            obj.isFuture
        );
    }

    /**
     * Helper method to parse a collection of JSON objects into
     * an array of UserAnnouncementBase.
     * 
     * @param {object[]} arr 
     * @returns {UserAnnouncementBase[]}
     */
    static fromArr( arr ) {
        arr = getTypeCheckedValue( arr, 'array', [] );

        return arr.map( obj => UserAnnouncementBase.fromObj( obj ));
    }
}

/**
 * Defines a single user announcement, including content.
 */
export class UserAnnouncementDetail extends UserAnnouncementBase {
    constructor( announcementId, announcementCategoryId, announcementCategoryKey,
        announcementCategoryName, title, startDate, isSeen, isStale, isFuture, content ) {
        
        super( announcementId, announcementCategoryId, announcementCategoryKey,
            announcementCategoryName, title, startDate, isSeen, isStale, isFuture );

        content = getTypeCheckedValue( content, 'string', null );
        try {
            if ( content !== null )
                content = atob( content );
        } catch ( err ) {
            console.warn( err );
        } finally {
            this.content = content;
        }

        this.isValid = ( this.isValid && this.content !== null );
    }

    /**
     * Helper method to parse a JSON object into an instance of
     * UserAnnouncementBase.
     * 
     * @param {object} obj 
     * @returns {UserAnnouncementBase}
     */
    static fromObj( obj ) {
        obj = getTypeCheckedValue( obj, 'object', {} );

        return new UserAnnouncementDetail(
            obj.announcementId,
            obj.announcementCategoryId,
            obj.announcementCategoryKey,
            obj.announcementCategoryName,
            obj.title,
            obj.startDate,
            obj.isSeen,
            obj.isStale,
            obj.isFuture,
            obj.content
        );
    }
}

/**
 * Defines a model describing the response data structure of a request
 * for unseen user announcement count from APIv3.
 */
export class UnseenUserAnnouncementsResponse extends APIv3ResponseModel {
    constructor( responseData ) {
        super( responseData );

        this.unseenCount = getTypeCheckedValue( get( responseData, 'unseenCount' ), 'number', 0 );
    }

    static default() {
        var result = new UnseenUserAnnouncementsResponse();
        result.status.code = -1;
        result.status.message = 'system-error';
        result.unseenCount = 0;

        return result;
    }
}

/**
 * Defines a model describing the response data structure of a request
 * for announcements applicable to the user from APIv3.
 */
export class UserAnnouncementsResponse extends APIv3ResponseModel {
    constructor( responseData ) {
        super( responseData );

        this.totalCount = getTypeCheckedValue( get( responseData, 'totalCount' ), 'int', 0 );
        this.announcements = UserAnnouncementBase.fromArr( get( responseData, 'announcements' ));
    }

    static default() {
        var result = new UserAnnouncementsResponse();
        result.status.code = -1;
        result.status.message = 'system-error';
        result.totalCount = 0;
        result.announcements = [];

        return result;
    }
}

/**
 * Defines a model describing the response data structure of a request
 * for a single announcement applicable to the user from APIv3.
 */
export class UserAnnouncementResponse extends APIv3ResponseModel {
    constructor( responseData ) {
        super( responseData );

        this.announcement = UserAnnouncementDetail.fromObj( get( responseData, 'announcement' ));
    }

    static default() {
        var result = new UserAnnouncementResponse();
        result.status.code = -1;
        result.status.message = 'system-error';
        result.announcement = null;

        return result;
    }
}