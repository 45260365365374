import Core from '../../../modules/core/core.module';
import unauthorizedTemplate from './unauthorized.template.html';

/**
 * @name unauthorizedView
 * @memberof CoreBundle.Core
 * @class
 * 
 * @classdesc
 * AngularJS component.
 */
class UnauthorizedController {
    constructor( $scope, $state, eventService ) {
        this.$scope = $scope;
        this.$state = $state;
        this.eventService = eventService;
    }

    $onInit() {
        this.$scope.backToLandingPage = this.backToLandingPage.bind( this );
        this.eventService.broadcastViewLoaded();
    }

    backToLandingPage() {
        this.$state.go( 'mp.landing' );
    }
}

const unauthorizedView = {
    template: unauthorizedTemplate,
    controller: UnauthorizedController
};

Core.component( 'unauthorizedView', unauthorizedView );
