import Core from '../../../../modules/core/core.module';
import contactSupportTemplate from './contact-support.template.html';
import NotificationModel from '../../../../models/core/notification.model';
import './contact-support.styles.scss';

/**
 * @name contactSupportModal
 * @memberof CoreBundle.Core
 * @class
 * 
 * @classdesc
 * AngularJS component. Modal for contacting application / product support
 */
class ContactSupportModalController {
    constructor( $scope, $q, Session, GLOBALS, supportFactory, eventService, $translate ) {
        this.$scope = $scope;
        this.$q = $q;
        this.Session = Session;
        this.GLOBALS = GLOBALS;
        this.supportFactory = supportFactory;
        this.eventService = eventService;
        this.$translate = $translate;
        this.accountName = '';
    }

    $onInit() {
        this.getSessionErrors();

        this.fallbackFormData = {
            company: ''
        };

        this.account =  this.Session.getActiveAccount();
        
        this.app = this.Session.defaultApp;
        this.$scope.emailData = {
            errorIds: [],
            supportData: null,
        };

        this.$scope.display = {
            isFormLoading: false,
            isGettingErrors: true,
            salesPhoneKey: null,
            salesEmailKey: null,
            supportPhoneKey: null,
            supportEmailKey: null
        };

        this.$scope.display.salesPhoneKey = 'ui.' + this.app + '.contact.sales.phone';
        this.$scope.display.salesEmailKey = 'ui.' + this.app + '.contact.sales.email';
        this.$scope.display.supportPhoneKey = 'ui.' + this.app + '.contact.support.phone';
        this.$scope.display.supportEmailKey = 'ui.' + this.app + '.contact.support.email';

        const self = this;
        this.$translate([
            'components.global.modal.contactSupport.section.support.emailForm.fallback.company'
        ]).then( translations => {
            console.log( translations );
            self.fallbackFormData.company = translations[ 'components.global.modal.contactSupport.section.support.emailForm.fallback.company' ]
            this.resetEmailFormValues(null);
        });

        this.$scope.display.textAreaConfig = {
            labelKey: 'components.global.modal.contactSupport.section.support.emailForm.placeholder.message',
            fieldName: 'email-message',
            isRequired: true,
            maxLength: 1000
        };

        this.$scope.doCancel = this.doCancel.bind( this );
        this.$scope.doChat = this.doChat.bind( this );
        this.$scope.doEmail = this.doEmail.bind( this );
        this.$scope.resetEmailFormValues = this.resetEmailFormValues.bind( this );
    }

    getSessionErrors(){
        let deferred = this.$q.defer();
        let self = this;

        function onSuccess( response ){
            self.$scope.emailData.errorIds = [];
            //response.forEach( x => self.$scope.emailData.errorIds.push( x.logId ) );
            self.$scope.display.isGettingErrors = false;
        }

        function onError(){
            self.$scope.display.isGettingErrors = false;
        }

        this.supportFactory.getSessionErrors( ).then( onSuccess, onError );

        return deferred.promise;        
    }


    resetEmailFormValues(form){
        let company = ( this.account !== null && this.account.activeAccount !== null )
            ? this.account.getAccountName()
            : this.fallbackFormData.company;

        this.$scope.emailFormData = {
            firstName: this.Session.firstName,
            lastName: this.Session.lastName,
            company,
            phone: '',
            message: ''
        };

        if (form){
            form.$setUntouched();
            form.$setPristine();
        }
    }

    doChat(){
        this.dismiss({ $value: 'cancel' });
        this.Session.modalService.showModal( 
            this.GLOBALS.MODAL.CHAT.DEFAULTS, 
            this.GLOBALS.MODAL.CHAT.OPTIONS);
    }

    doCancel() {
        this.dismiss({ $value: 'cancel' });
    }

    doEmail() {
        let deferred = this.$q.defer();
        let self = this;

        function onSuccess( response ){
            self.resetEmailFormValues(self.$scope.emailForm);
            self.$scope.display.isFormLoading = false;
            let { request } = response;
            request = ( typeof request === 'boolean' ) ? request : false;

            // Notification props.
            let faIcon = 'fa-envelope',
                i18nPrefix = `core.notification.support.${request ? 'request-receipt-sent' : 'request-only-sent'}`;

            self.eventService.broadcastHttpResponseNotification( new NotificationModel(
                faIcon,
                ( request ) ? 'success' : 'warning',
                `${i18nPrefix}-title`,
                null,
                null,
                `${i18nPrefix}-message`,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                false
            ));
        }

        function onError(){
            self.$scope.display.isFormLoading = false;
            // No need to show a notification; the error interceptor handles it.
        }

        let accountName = ( this.account !== null && this.activeAccount !== null )
                ? this.account.getAccountName()
                : this.fallbackFormData.company,
            accountNum = ( this.account !== null && this.activeAccount !== null )
                ? this.account.getAccountNum() + this.account.getAccountSuffix()
                : this.fallbackFormData.company;

        this.$scope.display.isFormLoading = true;
        this.supportFactory.sendSupportEmail( 
            this.$scope.emailFormData, 
            this.app,
            accountName,
            accountNum,
            this.Session.userId,
            this.Session.username,
            this.$scope.emailData.errorIds,
            this.$scope.emailData.supportData
        ).then( onSuccess, onError );

        return deferred.promise;
    }
}

const contactSupportModal = {
    template: contactSupportTemplate,
    controller: ContactSupportModalController,
    bindings: {
        resolve: '<',
        close: '&',
        dismiss: '&'
    }
};

Core.component( 'contactSupportModal', contactSupportModal );
