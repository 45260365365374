// Local dependencies.
import {
    UserAnnouncementBase,
    UserAnnouncementDetail
} from './announcement-response.model';

/**
 * Defines a single announcement category - with child announcements.
 */
export class UserAnnouncementCategory {
    constructor( announcementCategoryKey, announcements ) {
        this.announcementCategoryKey = getTypeCheckedValue( announcementCategoryKey, 'string', 'general' );
        this.categoryKey = `views.global.announcements.categories.${this.announcementCategoryKey}`;
        this.announcements = getTypeCheckedValue( announcements, 'array', [] );
        this.unseen = 0;
        this.expanded = false;
    }

    /**
     * Sets all non-future announcements within this category as viewed, and
     * updates the count of announcements not already seen or stale (should
     * be 0).
     */
    setAllAnnouncementsViewed() {
        for ( let i = 0; i < this.announcements.length; i++ ) {
            if ( !this.announcements[ i ].isFuture )
                this.announcements[ i ].isSeen = true;
        }

        this.unseen = this._getUnviewedCount();
    }

    /**
     * Sets an announcement within this category as viewed, and updates the
     * count of announcements not already seen or stale.
     * 
     * @param {number} announcementId 
     */
    setAnnouncementViewed( announcementId ) {
        for ( let i = 0; i < this.announcements.length; i++ ) {
            if ( this.announcements[ i ].announcementId === announcementId )
                this.announcements[ i ].isSeen = true;
        }

        this.unseen = this._getUnviewedCount();
    }

    /**
     * Whether the provided announcement instance of identifier
     * is contained in this category's collection of announcements.
     * 
     * @param {UserAnnouncementDetail|null} announcement 
     * @param {number|null} announcementId 
     * @returns {boolean}
     */
    hasAnnouncement( announcement = null, announcementId = null ) {
        announcement = getInstanceCheckedValue( announcement, UserAnnouncementDetail, null );
        announcementId = getTypeCheckedValue( announcementId, 'number', null );

        if ( announcement !== null )
            announcementId = announcement.announcementId;

        let hasAnnouncement = false;
        
        if ( announcementId !== null ) {
            let existingAnnouncement = this.announcements.find( a =>
                a.announcementId === announcementId );

            hasAnnouncement = ( existingAnnouncement !== undefined );
        }

        return hasAnnouncement;
    }

    /**
     * Returns a count of announcements in this category that are
     * not previously viewed, not stale, and not future start dated.
     * 
     * @returns {number}
     */
    _getUnviewedCount() {
        return this.announcements
            .filter( a =>
                !a.isSeen &&
                !a.isStale &&
                !a.isFuture
            )
            .length;
    }

    /**
     * Splits a collection of announcements into a collection of
     * categories containing those announcements.
     * 
     * @param {UserAnnouncementBase[]|UserAnnouncementDetail[]} announcementArr 
     * @returns {AnnouncementCategory[]}
     */
    static fromArr( announcementArr ) {
        announcementArr = getTypeCheckedValue( announcementArr, 'array', [] );
        announcementArr = announcementArr
            .filter( announcement =>
                ( announcement instanceof UserAnnouncementBase ||
                announcement instanceof UserAnnouncementDetail ) &&
                announcement.isValid
            );

        let announcementCategoryMap = {};
        for ( let i = 0; i < announcementArr.length; i++ ) {

            let announcement = announcementArr[ i ],
                categoryKey = announcement.announcementCategoryKey;

            let announcementCategory = ( announcementCategoryMap.hasOwnProperty( categoryKey ))
                ? announcementCategoryMap[ categoryKey ]
                : new UserAnnouncementCategory( categoryKey, [] );

            announcementCategory.announcements.push( announcement );
            announcementCategoryMap[ categoryKey ] = announcementCategory;
        }

        // Calculate the new/unseen announcements.
        let announcementCategories = Object.values( announcementCategoryMap );
        for ( let i = 0; i < announcementCategories.length; i++ )
            announcementCategories[ i ].unseen = announcementCategories[ i ]._getUnviewedCount();

        return announcementCategories;
    }
}