// Local dependencies.
import Core from '../../modules/core/core.module';
import WSChannelBaseModel from '../../models/core/ws-channel-base.model';

/**
 * @name WSAnnouncement
 * @memberof CoreBundle.Core
 * @class
 * 
 * @classdesc
 * AngularJS provider that manages and maintains WebSocket interaction with the
 * FileUploadNotification service's "announcement" channel using socket.io.
 */
class WSAnnouncement extends WSChannelBaseModel {
    constructor( GLOBALS, WS_CONSTANTS, WS_EVENTS ) {
        'ngInject';
        super( 'announcement', GLOBALS, WS_CONSTANTS, WS_EVENTS );

        // Announcement-specific callback registrations.
        this.eventSubscriptions.onUnseenCountReceived = [];
        this.eventSubscriptions.onSeenComplete = [];
        this.eventSubscriptions.onAllSeenComplete = [];
    }

    /**
     * Manually fetches the access token from localStorage, as the
     * jwtService cannot be injected outside of our _get method.
     * 
     * @returns {string}
     */
    _getAccessToken() {
        let accessToken = '',
            arSession = window.localStorage.getItem( this.GLOBALS.SUPPORTED_PRODUCT_STORAGE_KEYS.session );

        try {
            if ( arSession !== null )
                arSession = JSON.parse( arSession );
            accessToken = arSession.accessToken || '';
        } catch ( err ) {
            console.warn( err );
        }

        return accessToken;
    }

    /**
     * Channel-specific connection event handler.
     */
    _onChannelConnect() {

        // Get a count of announcements that the user hasn't yet seen.
        this._getUnseenCount();
    }

    /**
     * Emits an event to FUN to call APIv3 to fetch the count
     * of unseen announcements.
     */
    _getUnseenCount() {

        // Access token is required.
        let accessToken = this._getAccessToken();

        // Emit.
        this.socket.emit( this.WS_EVENTS.Announcement.Publish.GetUnseenCount, {
            accessToken
        });
    }

    /**
     * Handles the callback from FUN containing an APIv3 response
     * from a request for unseen user announcements.
     * 
     * @param {object} payloadObj 
     */
    _handleUnseenCount( payloadObj ) {
        this._log( '_handleUnseenCount response.', payloadObj );
        payloadObj = getTypeCheckedValue( payloadObj, 'object', {} );

        let unseenCount = getTypeCheckedValue( payloadObj.unseenCount, 'int', 0 );
        this._handleSubscription( 'onUnseenCountReceived', { unseenCount });
    }

    /**
     * Emits an event to FUN to call APIv3 to set an announcement
     * as viewed by this user.
     * 
     * @param {number} announcementId 
     */
    _setAnnouncementSeen( announcementId ) {
        
        // Access token is required.
        let accessToken = this._getAccessToken();

        // Emit.
        this.socket.emit( this.WS_EVENTS.Announcement.Publish.Seen, {
            accessToken,
            announcementId
        });
    }

    /**
     * Handles the callback from FUN containing an APIv3 response
     * from a request to set an announcement as viewed.
     * 
     * @param {object} payloadObj 
     */
    _handleSeenComplete( payloadObj ) {
        this._log( '_handleSeenComplete response.', payloadObj );
        payloadObj = getTypeCheckedValue( payloadObj, 'object', {} );

        let unseenCount = getTypeCheckedValue( payloadObj.unseenCount, 'int', null ),
            announcementId = getTypeCheckedValue( payloadObj.announcementId, 'int', null );

        this._handleSubscription( 'onSeenComplete', { unseenCount, announcementId });
        this._handleSubscription( 'onUnseenCountReceived', { unseenCount });
    }

    /**
     * Emits an event to FUN to call APIv3 to set all unseen
     * announcements as viewed by this user.
     */
    _setAllAnnouncementsSeen() {

        // Access token is required.
        let accessToken = this._getAccessToken();

        // Emit.
        this.socket.emit( this.WS_EVENTS.Announcement.Publish.AllSeen, {
            accessToken
        });
    }

    /**
     * Handles the callback from FUN containing an APIv3 response
     * from a request to set all announcements as viewed.
     * 
     * @param {object} payloadObj 
     */
    _handleAllSeenComplete( payloadObj ) {
        this._log( '_handleAllSeenComplete response.', payloadObj );
        payloadObj = getTypeCheckedValue( payloadObj, 'object', {} );

        let unseenCount = getTypeCheckedValue( payloadObj.unseenCount, 'int', null );

        this._handleSubscription( 'onAllSeenComplete', { unseenCount });
        this._handleSubscription( 'onUnseenCountReceived', { unseenCount });
    }

    /**
     * Returns an object when called by the WSChannelBaseModel parent's
     * $get method, which is the primary entry point to expose the
     * provider's properties and methods to Angular.
     */
    _get() {
        return {
            
            /**
             * Sends an announcementId to the FileUploadNotification's
             * "announcement" channel to be marked as "seen" in the
             * database.
             * 
             * @param {number} announcementId announcementId to set as viewed.
             */
            announcementSeen: ( announcementId ) =>
                this._setAnnouncementSeen( announcementId ),

            /**
             * Sends a request to the FileUploadNotification's "announcement"
             * channel for a count of unseen user announcements.
             */
            getUnseenCount: () => this._getUnseenCount(),

            /**
             * Sends a request to the FileUploadNotification's "announcement"
             * channel to mark all announcements as "seen" in the database.
             */
            allAnnouncementsSeen: () => this._setAllAnnouncementsSeen()
        };
    }
}

Core.provider( 'wsAnnouncement', WSAnnouncement );
