import Core from '../../../modules/core/core.module';
import sessionTimeoutTemplate from './session-timeout.template.html';
import './session-timeout.styles.scss';

/**
 * @name sessionTimeout
 * @memberof CoreBundle.Core
 * @class
 * 
 * @classdesc
 * AngularJS component. Displays a visual indicator to the user that their
 * global application session is about to - or has already - expired.
 */
class SessionTimeoutController {
    constructor( $scope, $translate, sessionTimeoutService, modalService, GLOBALS, Session, $timeout ) {
        this.$scope = $scope;
        this.$translate = $translate;
        this.sessionTimeoutService = sessionTimeoutService;
        this.modalService = modalService;
        this.GLOBALS = GLOBALS;
        this.Session = Session;
        this.$timeout = $timeout;
    }

    $onInit() {
        this.logoutUrl = this.GLOBALS.ENDPOINTS.logout;

        this.i18nStrings = {
            timingOut: null,
            timedOut: null,
            default: null
        };

        this.$scope.ui = {
            visible: false,
            countdown: {
                minutes: '0',
                seconds: '00'
            }
        };

        this.$scope.extend = this.extend.bind( this );
        this.$scope.logout = this.logout.bind( this );

        this.sessionTimeoutService.onWarningChanged( this._handleWarningChanged.bind( this ));
        this.sessionTimeoutService.onTimeoutChanged( this._handleTimeoutChanged.bind( this ));

        this.$translate([ 'global.title.basic', 'global.title.timingout', 'global.title.loggedout' ]).then( function( translated ) {
            this.i18nStrings.default = translated[ 'global.title.basic' ];
            this.i18nStrings.timingOut = translated[ 'global.title.timingout' ];
            this.i18nStrings.timedOut = translated[ 'global.title.loggedout' ];
        }.bind( this ));
    }

    /**
     * Handles the callback for the eventService subscription listener for
     * timeout warning state change. This includes whether to show the warning
     * bar, as well as the minutes and seconds remaining.
     * 
     * @param {Boolean} visible Whether the UI state should be visible.
     * @param {Object} timeRemaining An object containing minutes and seconds remaining before timeout.
     */
    _handleWarningChanged( visible = false, timeRemaining = null ) {
        visible = getTypeCheckedValue( visible, 'boolean', false );
        timeRemaining = getTypeCheckedValue( timeRemaining, 'object', {} );

        console.debug( '[session-timeout.component] :: _handleWarningChanged started', visible, timeRemaining );

        let minutes = getTypeCheckedValue( timeRemaining.minutes, 'string', '0' ),
            seconds = getTypeCheckedValue( timeRemaining.seconds, 'string', '00' );
        
        this.$timeout( () => {
            this.$scope.ui.visible = visible;
            this.$scope.ui.countdown.minutes = minutes;
            this.$scope.ui.countdown.seconds = seconds;

            document.title = ( visible )
                ? this.i18nStrings.timingOut.replace( ':', `${minutes}:${seconds}` )
                : this.i18nStrings.default;
            console.debug( '[session-timeout.component] :: _handleWarningChanged end of internal $timeout' );
        });

        console.debug( '[session-timeout.component] :: _handleWarningChanged end of method' );
    }

    /**
     * Handles the callback for the eventService subscription listener for
     * session timeout state change. This includes whether the session has
     * expired, in which case we show the modal and begin the process of
     * destroying Session.
     * 
     * @param {Boolean} expired Whether the session has fully expired.
     */
    _handleTimeoutChanged( expired = false ) {
        const self = this;
        
        expired = getTypeCheckedValue( expired, 'boolean', false );

        console.debug( '[session-timeout.component] :: _handleTimeoutChanged started', expired );

        // Show the modal.
        if ( expired ) {
            let expiredModalDefaults = {
                    keyboard: false,
                    backdropClass: 'backdrop-80'
                },
                expiredModalOptions = {
                    closeButtonShown: false,
                    actionButtonText: 'global.sessionTimeout.modal.button',
                    headerText: 'global.sessionTimeout.modal.header',
                    bodyText: 'global.sessionTimeout.modal.message',
                    faIcon: 'fa-clock-o modal-icon-orange'
                };

            console.debug( '[session-timeout.component] :: _handleTimeoutChanged showing modal' );
            
            this.modalService.showModal( expiredModalDefaults, expiredModalOptions )
                .then( result => {
                    console.debug( '[session-timeout.component] :: _handleTimeoutChanged modal closed; redirecting' );
                    window.location.assign( self.logoutUrl );
                });
        }

        document.title = ( expired )
            ? this.i18nStrings.timedOut
            : this.i18nStrings.default;

        console.debug( '[session-timeout.component] :: _handleTimeoutChanged end of method' );
    }

    /**
     * Button click handler that calls core.session's refreshSession method
     * which returns the new token expiration. There is no need to interact
     * with sessionTimeout service here. That was already done by core.session.
     */
    extend() {
        this.Session.refreshSession()
            .then( newExpirationDate => {
                newExpirationDate = getInstanceCheckedValue( newExpirationDate, Date, null );

                if ( newExpirationDate === null ) {
                    console.warn( 'Received new expiration of null in session timeout component when attempting to extend.' );
                    this._handleTimeoutChanged( true );
                }
            });
    }

    /**
     * Button click handler that calls core.session's logoutSession method
     * which returns true/false signifying success. There is no need to
     * interact with sessionTimeout service here. That was already done
     * by core.session.
     */
    logout() {
        this.Session.logoutSession()
            .then( isSuccess => {
                isSuccess = getTypeCheckedValue( isSuccess, 'boolean', false );
                console.debug( '[session-timeout.component] :: post-api logout result', isSuccess );

                // Redirect.
                window.location.assign( this.logoutUrl );
            });
    }
}

const sessionTimeout = {
    template: sessionTimeoutTemplate,
    controller: SessionTimeoutController
};

Core.component( 'sessionTimeout', sessionTimeout );
