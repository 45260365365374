import Core from '../../modules/core/core.module';
import { SignalRHubBaseModel } from '../../models/core';

class SignalRNotificationHub extends SignalRHubBaseModel {
    constructor( SIGNALR_CONSTANTS ) {
        'ngInject';
        super( SIGNALR_CONSTANTS, 'notification' );

        this.canGetMore = true;
        this.offset = 0;
        this.limit = 5;

        this.subscriptions.onAcknowledgementComplete = [];
        this.subscriptions.onListReceived = [];
        this.subscriptions.onNotification = [];
        this.subscriptions.onSeenComplete = [];
    }

    /**
     * Callback fired once connection has been established with the hub. In this
     * instance we immediately fetch a list of unseen notifications.
     */
    _onConnected() {
        this.log( '_onConnected', 'Requesting notification list.' );
        this._list();
    }

    //
    // CLIENT-TO-SERVER HANDLERS
    //
    _acknowledgeView( type, userNotificationIds ) {
        if ( Array.isArray( userNotificationIds )) {
            userNotificationIds = userNotificationIds
                .map( unid => getTypeCheckedValue( unid, 'int', null ))
                .filter( unid => unid !== null );
        } else {
            userNotificationIds = getTypeCheckedValue( userNotificationIds, 'int', null );
            userNotificationIds = ( userNotificationIds !== null )
                ? [ userNotificationIds ]
                : [];
        }

        if ( Array.isArray( userNotificationIds ) &&
            userNotificationIds.length > 0 ) {
            
            this.log( '_acknowledgeView', `Calling "${type}"...`, userNotificationIds );
            
            try{                
                this.client.invoke( type, userNotificationIds );
            }
            catch(err){
                console.log(err);
            }
        }
    }

    _acknowledge( userNotificationIds ) {
        this._acknowledgeView( 'Acknowledge', userNotificationIds );
    }

    _getUnseenNotifications() {
        if ( this.canGetMore ) {
            this.canGetMore = true;
            this._list( this.limit, this.offset );
        }
    }

    _list( limit = undefined, offset = undefined ) {
        const message = ( limit !== undefined && offset !== undefined )
            ? `Calling with limit ${limit} and offset ${offset}...`
            : 'Calling...';
        const payload = ( limit !== undefined && offset !== undefined )
            ? { limit, offset }
            : undefined;

        this.log( '_list', message );
        if ( payload !== undefined )
            this.client.invoke( 'List', payload );
        else
            this.client.invoke( 'List', {} );
    }

    _viewNotifications( userNotificationIds ) {
        this._acknowledgeView( 'ViewNotifications', userNotificationIds );
    }

    //
    // SERVER-TO-CLIENT HANDLERS
    //
    _handleAcknowledgementComplete( reply ) {
        this.log( '_handleAcknowledgementComplete', 'Received.', reply );
    }

    /**
     * Channel-specific socket event handler fired when the
     * FileUploadNotification's "notification" channel has
     * returned a list of unseen notifications.
     * 
     * @param {object} reply Object of count and notifications array.
     */
    _handleListReceived( reply ) {
        this.log( '_handleListReceived', 'Received.', reply );

        reply = getTypeCheckedValue( reply, 'object', {} );
        let count = getTypeCheckedValue( reply.count, 'int', 0 ),
            notificationsStr = getTypeCheckedValue( reply.notifications, 'string', '[]' ),
            notifications = JSON.parse( notificationsStr );

        if ( notifications.length >= this.limit ) {
            this.canGetMore = true;
            this.offset += this.limit;
        }

        this._handleSubscription( 'onListReceived', { count, notifications });
    }

    /**
     * Channel-specific socket event handler fired when the
     * FileUploadNotification's "notification" channel has
     * returned a single live notification.
     * 
     * @param {object} reply Object containing a single notification's attributes.
     */
    _handleNotification( reply ) {
        this.log( '_handleNotification', 'Received.', reply );

        reply = getTypeCheckedValue( reply, 'object', null );
        if ( reply !== null ) {
            this._handleSubscription( 'onNotification', reply );
        }
    }

    /**
     * Channel-specific socket event handler fired when the
     * FileUploadNotification's "notification" channel has
     * returned confirmation that one or more notifications
     * have been set as "seen" on the service-side, returning
     * the specific userNotificationIds as additional confirmation.
     * 
     * @param {array} reply Array of processed userNotificationIds.
     */
    _handleSeenComplete( reply ) {
        this.log( '_handleSeenComplete', 'Received.', reply );

        reply = getTypeCheckedValue( reply, 'array', [] );
        this._handleSubscription( 'onSeenComplete', reply );
    }

    _get() {
        return {
            acknowledgeNotification: ( userNotificationId ) =>
                this._acknowledge( userNotificationId ),

            getUnseenNotifications: () =>
                this._getUnseenNotifications(),

            notificationSeen: ( userNotificationId ) =>
                this._viewNotifications( userNotificationId ),

            notificationsSeen: ( userNotificationIds ) =>
                this._viewNotifications( userNotificationIds )
        };
    }
}

Core.provider( 'signalRNotificationHub', SignalRNotificationHub );