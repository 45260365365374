import angular from 'angular';
import './core.styles.scss';

/**
 * @name Core
 * @memberof MarketingPortal
 * @constant
 * 
 * @description
 * The base AngularJS module object for all shared or centralized functionality
 * within the Acquisition & Retention portal.
 */
const Core = angular.module( 'marketing-portal', [
    'angular-jwt',
    'angular-loading-bar',
    'chart.js',
    'LocalStorageModule',
    'ngAnimate',
    'ngCookies',
    'ngFileSaver',
    'ngMessages',
    'ngSanitize',
    'pascalprecht.translate',
    'ui.bootstrap',
    'ui.grid',
    'ui.grid.autoResize',
    'ui.grid.expandable',
    'ui.grid.grouping',
    'ui.grid.moveColumns',
    'ui.grid.pagination',
    'ui.grid.pinning',
    'ui.grid.saveState',
    'ui.grid.resizeColumns',
    'ui.router',    
    'uiGmapgoogle-maps',
    'ui-notification',
    'ui.select',
    'ui.select.infinite.scroll'
]);

/**
 * @name CoreController
 * @memberof CoreBundle.Core
 * @class
 * 
 * @classdesc
 * Core application controller responsible for interrogating the Session
 * service to determine what - if any - additional product bundle JavaScript
 * files should be dynamically loaded.
 */
class CoreController {
    constructor( $scope, $state, GLOBALS, Session ) {
        this.$scope = $scope;
        this.$state = $state;
        this.GLOBALS = GLOBALS;
        this.Session = Session;

        // If they came in from a manually-typed URL, email, etc.
        // then keep this after we bootstrap Session.
        this.returnTo = window.location.pathname;
    }

    $onInit() {
        this.$scope.products = {
            modules: [],
            styles: []
        };

        this.$scope.initialize = this.initialize.bind( this );
    }

    initialize() {
        const self = this;

        console.debug( '[core.module] :: initialize() :: Initializing application.' );

        // Rudimentary check to see which product bundles need to load.
        this.Session.checkProductAccess();

        console.debug( '[core.module] :: initialize() :: Checked product access; calling core.session.bootstrap().' );

        // Product bundles should now be loaded. Populate product-specific sessions.
        this.Session.bootstrap().then( loadedProducts => {
            console.debug( '[core.module] :: initialize() :: core.session.bootstrap() returned with bootstrapped product codes.', loadedProducts );

            let stateGo = null,
                stateParams = {},
                claReturn = false,
                claReturnQueryString = null;

            if ( self.returnTo.split( '/' ).filter( segment => segment.length > 0 ).length > 1 ) {
                let returnToState = self._findStateForReturnTo( self.returnTo );
                if ( returnToState !== null ) {
                    stateGo = returnToState.state.name;
                    stateParams = returnToState.stateParams;
                }

                claReturnQueryString = self._getQueryStringForCLAReturnTo( self.returnTo );
                if ( claReturnQueryString !== null ) {
                    claReturn = true;
                }
            }

            // If we've loaded no products, we do one of two things:
            // 1. If they came in with a returnTo-able URL, direct them back to CLA with a payload.
            // 2. Otherwise, kickstart PB, and send them back to the guest page.
            if ( loadedProducts.length === 0 ) {

                // Re-route them back to CLA for login.
                if ( claReturn ) {
                    window.location.assign( `${self.GLOBALS.ENDPOINTS.login}/${claReturnQueryString}` );
                } else {
                    self.Session.bootstrapFallback()
                        .then( () => {
                            self.$state.go( self.GLOBALS.FALLBACK_BUNDLE.productRoute );
                        });
                }
            }
            
            // If we've loaded, aren't authenticated, and came in with a returnTo-able
            // URL, direct them back to CLA with a payload.
            else if ( !self.Session.isAuthenticated() && claReturn ) {
                window.location.assign( `${self.GLOBALS.ENDPOINTS.login}/${claReturnQueryString}` );
            }
            
            // Otherwise, route based on state calculations.
            else {
                console.debug( '[core.module] :: initialize() :: Routing based on calculations of forwarded state.', stateGo );

                if ( stateGo === null ) {
                    console.debug( '[core.module] :: initialize() :: Routing based on default app.', this.Session.defaultApp );

                    if ( this.Session.defaultApp !== null ) {
                        // If we're refreshing or loading directly into a product-agnostic
                        // page, we'll just go straight back to it.
                        if ( self.GLOBALS.PERSISTENT_LOAD_STATES.indexOf( self.$state.current.name ) !== -1 ) {
                            stateGo = self.$state.current.name;
                        } else {
                            stateGo = self.GLOBALS.PRODUCT_LANDINGS[ self.Session.defaultApp ];
                        }
                    } else if ( !self.Session.isAuthenticated() ) {
                        stateGo = self.GLOBALS.FALLBACK_BUNDLE.productRoute;
                    }
                }
    
                if ( stateGo !== null ) {
                    console.debug( '[core.module] :: initialize() :: Routing to state.', stateGo );
                    self.$state.go( stateGo, stateParams );
                }
            }

            // Invalid. Pop a modal.
            // TODO
        });
    }

    /**
     * As we're basically subverting Angular's natural load and digest processing,
     * we need to accommodate things like refreshes, email links, and manually-
     * manipulated URLs. We do this by finding a matching configured state, including
     * parsed parameters, and returning it. If we can't find one, we return null and
     * let the application figure out what it needs to do on its own.
     * 
     * @param {string} returnTo The "return to" URL, as fetched from the window object.
     * @return {object|null} If a state is found, an object containing the state and
     * stateParams; null otherwise.
     */
    _findStateForReturnTo( returnTo ) {
        let matchedState = null,
            states = this.$state.get();

        for ( let i = 0; i < states.length; i++ ) {
            let state = states[ i ];
            if ( state.abstract ) {
                continue;
            }

            let privatePortion = state.$$state(),
                match = privatePortion.url.exec( returnTo );
            if ( match ) {
                matchedState = { state, stateParams: match };
                break;
            }
        }
        
        if ( matchedState ) {
            return matchedState;
        } else {
            return null;
        }
    }

    /**
     * Using the provided returnTo, checks it against any configured returnTo
     * regex patterns we've defined, and generates an appropriate base64-encoded
     * payload to be used in the auto-redirect to CLA (if the user does not
     * have the product access).
     * 
     * @param {String} returnTo The "return to" URL, as fetched from the window object.
     * @returns {String|null} If we have a pattern match, returns the base64-encoded payload; otherwise, null.
     */
    _getQueryStringForCLAReturnTo( returnTo ) {
        let queryString = null;

        try {
            Object.keys( this.GLOBALS.PRODUCT_RETURNTO )
                .forEach( productKey => {
                    Object.keys( this.GLOBALS.PRODUCT_RETURNTO[ productKey ])
                        .forEach( returnToContext => {
                            let regex = this.GLOBALS.PRODUCT_RETURNTO[ productKey ][ returnToContext ],
                                matches;

                            if (( matches = regex.exec( returnTo )) !== null && queryString === null ) {
                                let protocol = window.location.protocol,
                                    host = window.location.host,
                                    payload = JSON.stringify({
                                        returnTo: `${protocol}//${host}${returnTo}`
                                    });

                                queryString = `?productId=${productKey}&payload=${btoa( payload )}`;
                            }
                        });
                })
        } catch ( err ) {
            console.warn( err );
        } finally {
            return queryString;
        }
    }
}

Core.controller( 'CoreController', CoreController );

export default Core;