/**
 * @name NotificationModel
 * @memberof CoreBundle.Core
 * @class
 * 
 * @classdesc
 * POJO.
 */
class AccountOrderModel {
    constructor( order_id, pb_account_id, pb_account_order_id, pb_user_account_order_id,
        views_quota, user_views_quota, detail_exports_quota, user_detail_exports_quota,
        mail_exports_quota, user_mail_exports_quota, allowed_states, user_allowed_states,
        start_date, stop_date, is_active, is_active_order, view_carrier_group,
        user_is_exports_enabled, is_session_order, is_subscription_changed, is_sync_required,
        user_is_sync_required, is_valid, order_error, sync_reason_codes, user_max ) {

        this.order_id                   = getTypeCheckedValue( order_id, 'string', null );
        this.pb_account_id              = getTypeCheckedValue( pb_account_id, 'int', null );
        this.pb_account_order_id        = getTypeCheckedValue( pb_account_order_id, 'int', null );
        this.pb_user_account_order_id   = getTypeCheckedValue( pb_user_account_order_id, 'int', null );
        this.is_active                  = getTypeCheckedValue( is_active, 'boolean', false );
        this.is_active_order            = getTypeCheckedValue( is_active_order, 'boolean', false );
        this.view_carrier_group         = getTypeCheckedValue( view_carrier_group, 'boolean', false );
        this.user_is_exports_enabled    = getTypeCheckedValue( user_is_exports_enabled, 'boolean', false );
        this.is_session_order           = getTypeCheckedValue( is_session_order, 'boolean', false );
        this.is_subscription_changed    = getTypeCheckedValue( is_subscription_changed, 'boolean', false );
        this.is_valid                   = getTypeCheckedValue( is_valid, 'boolean', false );
        this.order_error                = getTypeCheckedValue( order_error, 'string', null );
        this.sync_reason_codes          = getTypeCheckedValue( sync_reason_codes, 'array', [] );
        this.start_date                 = getTypeCheckedValue( start_date, 'date', null );
        this.stop_date                  = getTypeCheckedValue( stop_date, 'date', null );

        // Account-wide quotas.
        this.allowed_states             = getTypeCheckedValue( allowed_states, 'array', [] );
        this.views_quota                = getTypeCheckedValue( views_quota, 'int', null );
        this.detail_exports_quota       = getTypeCheckedValue( detail_exports_quota, 'int', null );
        this.mail_exports_quota         = getTypeCheckedValue( mail_exports_quota, 'int', null );
        this.is_sync_required           = getTypeCheckedValue( is_sync_required, 'boolean', false );
        this.user_max                   = getTypeCheckedValue( user_max, 'int', null );

        // User-specific quotas.
        this.user_allowed_states        = getTypeCheckedValue( user_allowed_states, 'array', [] );
        this.user_views_quota           = getTypeCheckedValue( user_views_quota, 'int', null );
        this.user_detail_exports_quota  = getTypeCheckedValue( user_detail_exports_quota, 'int', null );
        this.user_mail_exports_quota    = getTypeCheckedValue( user_mail_exports_quota, 'int', null );
        this.user_is_sync_required      = getTypeCheckedValue( user_is_sync_required, 'boolean', false );

        this.isValid = this.isValidOrder();
    }

    /**
     * Whether this order:
     * - has an order id
     * - has a pb account id
     * - has a pb account order id
     * - has a pb user account order id
     * - has a start date
     * - has a stop date
     * - has no order error
     * - has a truthy valid flag (date range)
     * - has a truty active flag (contract status)
     */
    isValidOrder() {
        console.debug( '[pb/account-order.model] :: isValid() :: Checking that product account order is valid.', this );

        let valid = (
            this.order_id !== null &&
            this.pb_account_id !== null &&
            this.pb_account_order_id !== null &&
            this.pb_user_account_order_id !== null &&
            this.start_date !== null &&
            this.stop_date !== null &&
            this.order_error === null &&
            this.is_active === true &&
            this.is_valid === true
        );

        console.debug( `[pb/account-order.model] :: isValid() :: Order ${this.order_id || 'UNKNOWN'} ${valid ? 'is' : 'not'} valid.` );
        
        return valid;
    }

    getOrderSyncCodes() {
        console.log( this.sync_reason_codes );
        return this.sync_reason_codes;
    }

    /**
     * Returns a default order configured for guest use. Values are taken from
     * AR.Core's global.json config file.
     */
    static defaultAsGuest() {
        let states = [ "AK", "AL", "AR", "AZ", "CA", "CO", "CT", "DC", "DE", "FL", "GA", "HI", "IA", "ID", "IL", "IN", "KS", "KY", "LA", "MA", "MD", "ME", "MI", "MN", "MO", "MS", "MT", "NC", "ND", "NE", "NH", "NJ", "NM", "NV", "NY", "OH", "OK", "OR", "PA", "RI", "SC", "SD", "TN", "TX", "UT", "VA", "VT", "WA", "WI", "WV", "WY" ];

        return new AccountOrderModel(
            'GUEST1',
            -1,
            -1,
            -1,
            0,
            0,
            0,
            0,
            0,
            0,
            states,
            states,
            new Date( '1/1/2016 12:00:00 AM' ),
            new Date( '12/31/2050 11:59:00 PM' ),
            true,
            true,
            false,
            false,
            true,
            false,
            false,
            false,
            true,
            null,
            null,
            999999
        );
    }

    static fromJsonObj( jsonObj ) {
        if ( typeof jsonObj !== 'string' && ( typeof jsonObj !== 'object' || jsonObj === null )) {
            return null;
        }

        if ( typeof jsonObj === 'string' ) {
            return new AccountOrderModel( null, null, null, null, null, null, null, null,
                null, null, null, null, null, null, null, null, null, null, null, null,
                null, null, null, null, [ jsonObj ], null );
        }

        const order_id = getTypeCheckedValue( jsonObj.order_id, 'string', null );
        const pb_account_id = getTypeCheckedValue( jsonObj.pb_account_id, 'int', null );
        const pb_account_order_id = getTypeCheckedValue( jsonObj.pb_account_order_id, 'int', null );
        const pb_user_account_order_id = getTypeCheckedValue( jsonObj.pb_user_account_order_id, 'int', null );
        const views_quota = getTypeCheckedValue( jsonObj.views_quota, 'int', null );
        const user_views_quota = getTypeCheckedValue( jsonObj.user_views_quota, 'int', null );
        const detail_exports_quota = getTypeCheckedValue( jsonObj.detail_exports_quota, 'int', null );
        const user_detail_exports_quota = getTypeCheckedValue( jsonObj.user_detail_exports_quota, 'int', null );
        const mail_exports_quota = getTypeCheckedValue( jsonObj.mail_exports_quota, 'int', null );
        const user_mail_exports_quota = getTypeCheckedValue( jsonObj.user_mail_exports_quota, 'int', null );
        const allowed_states = getTypeCheckedValue( jsonObj.allowed_states, 'array', [] );
        const user_allowed_states = getTypeCheckedValue( jsonObj.user_allowed_states, 'array', [] );
        const is_active = getTypeCheckedValue( jsonObj.is_active, 'boolean', false );
        const is_active_order = getTypeCheckedValue( jsonObj.is_active_order, 'boolean', false );
        const is_session_order = getTypeCheckedValue( jsonObj.is_session_order, 'boolean', false );
        const is_subscription_changed = getTypeCheckedValue( jsonObj.is_subscription_changed, 'boolean', false );
        const is_sync_required = getTypeCheckedValue( jsonObj.is_sync_required, 'boolean', false );
        const is_valid = getTypeCheckedValue( jsonObj.is_valid, 'boolean', false );
        const view_carrier_group = getTypeCheckedValue( jsonObj.view_carrier_group, 'boolean', false );
        const user_is_sync_required = getTypeCheckedValue( jsonObj.user_is_sync_required, 'boolean', false );
        const order_error = getTypeCheckedValue( jsonObj.order_error, 'string', null );
        const user_max = getTypeCheckedValue( jsonObj.user_max, 'int', null );
        const user_is_exports_enabled = getTypeCheckedValue( jsonObj.user_is_exports_enabled, 'boolean', false );
        
        const startDateStr = getTypeCheckedValue( jsonObj.start_date, 'string', null );
        const start_date = ( startDateStr !== null ) ? new Date( startDateStr ) : null;

        const stopDateStr = getTypeCheckedValue( jsonObj.stop_date, 'string', null );
        const stop_date = ( stopDateStr !== null ) ? new Date( stopDateStr ) : null;

        // Sync reason codes are created as a key-val dictionary. We need to
        // convert this to an array.
        const sync_reason_codes = AccountOrderModel.getParsedSyncReasonCodes( jsonObj.sync_reason_codes );

        return new AccountOrderModel( order_id, pb_account_id, pb_account_order_id,
            pb_user_account_order_id, views_quota, user_views_quota, detail_exports_quota,
            user_detail_exports_quota, mail_exports_quota, user_mail_exports_quota,
            allowed_states, user_allowed_states, start_date, stop_date, is_active, is_active_order,
            view_carrier_group, user_is_exports_enabled, is_session_order, is_subscription_changed,
            is_sync_required, user_is_sync_required, is_valid, order_error, sync_reason_codes, user_max );
    }

    static getParsedSyncReasonCodes( jsonObj ) {
        jsonObj = getTypeCheckedValue( jsonObj, 'object', {} );

        let syncReasonCodes = [],
            syncReasonKeys = [];

        Object.keys( jsonObj ).forEach( objKey => {
            if ( syncReasonKeys.indexOf( objKey ) === -1 ) {
                let syncReasonObj = {};
                syncReasonObj[ objKey ] = jsonObj[ objKey ];

                syncReasonCodes.push( syncReasonObj );
                syncReasonKeys.push( objKey );
            }
        });

        return syncReasonCodes;
    }

    static fromJsonArray( jsonArr ) {
        if ( !Array.isArray( jsonArr )) {
            return [];
        }

        const orders = jsonArr.map( jsonObj => AccountOrderModel.fromJsonObj( jsonObj ));
        return orders;
    }
}

export default AccountOrderModel;