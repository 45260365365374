import { NOTIFICATION_DELAY } from '../../constants/core/variables';
import Core from './core.module';
import moduleLoader from './core.module-loader';
import coreEnUS from './i18n/core-en_US.json';

const corsDomains = window.arconfig.cors_domains;

/**
 * @name coreConfig
 * @memberof CoreBundle.Core
 * @kind function
 * 
 * @description
 * Core application module configuration for all shared or centralized functions
 * or third-party libraries.
 */
function coreConfig( $injector, $compileProvider, $controllerProvider, $filterProvider,
    $provide, $animateProvider, $httpProvider, $translateProvider, $qProvider,
    jwtOptionsProvider, cfpLoadingBarProvider, uiGmapGoogleMapApiProvider, LOCALES,
    NotificationProvider, ChartJsProvider ) {

    moduleLoader.providers = {
        $injector,
        $compileProvider,
        $controllerProvider,
        $filterProvider,
        $provide
    };

    moduleLoader.setRootApplicationModule( 'marketing-portal' );

    // Turn off spinner icon for loading bar.
    cfpLoadingBarProvider.includeSpinner = false;

    // Only animate elements with the class 'mp-animate'.
    $animateProvider.classNameFilter( /mp-animate/ );

    // Translation configuration.
    $translateProvider.useSanitizeValueStrategy( 'sanitize' );
    $translateProvider.addInterpolation( '$translateMessageFormatInterpolation' );
    $translateProvider.translations( LOCALES.preferredLocale, coreEnUS );
    $translateProvider.preferredLanguage( LOCALES.preferredLocale ); 
    $translateProvider.useCookieStorage();

    // Google Maps API configuration.
    uiGmapGoogleMapApiProvider.configure({
        client: 'gme-lexisnexis1',
        libraries: 'places'
    });

    // ChartJS configuration.
    ChartJsProvider.setOptions({
        responsive: false,
        tooltips: {
            enabled: false
        },
        hover: {
            mode: null
        },
        layout: {
            left: 0,
            right: 0,
            top: 0,
            bottom: 0
        }
    });
    ChartJsProvider.setOptions( 'global', {
        colors: [
            '#002f6c',
            '#c8c9c7'                
        ]
    });
    ChartJsProvider.setOptions( 'doughnutWithPercentage', {
        cutoutPercentage: 70
    });

    // Notifications configuration.
    NotificationProvider.setOptions({
        delay: NOTIFICATION_DELAY,
        maxCount: 1
    });

    // Configure the jwtOptionsProvider.
    jwtOptionsProvider.config({
        tokenGetter: [ 'jwtService', ( jwtService ) => {
            return jwtService.getAccessToken();
        }],
        whiteListedDomains: corsDomains
            .split( ',' )
            .map( s => s.trim() )
            .filter( s => s !== '' )
    });
    $httpProvider.interceptors.push( 'jwtInterceptor' );
    $httpProvider.interceptors.push( 'authInterceptor' );
    $httpProvider.interceptors.push( 'errorInterceptor' );

    // Prevent caching of API calls.
    if ( !$httpProvider.defaults.headers.common ) {
        $httpProvider.defaults.headers.common = {};
    }
    $httpProvider.defaults.headers.common[ 'Cache-Control' ] = 'no-cache';
    $httpProvider.defaults.headers.common[ 'Pragma' ] = 'no-cache';
    $httpProvider.defaults.headers.common[ 'If-Modified-Since' ] = 'Mon, 26 Jul 1997 05:00:00 GMT';

    // Disables errors on unhandled rejections; looking at you ui-router.
    $qProvider.errorOnUnhandledRejections( false );

    // angular-ui modals have some broken code when closing... let's hide the error.
    $provide.decorator( '$exceptionHandler', ( $delegate ) => {
        return ( exception, cause ) => {
            const toIgnore = [
                'Possibly unhandled rejection:',
                'Possibly unhandled rejection: backdrop click',
                'Possibly unhandled rejection: cancel',
                'Possibly unhandled rejection: escape key press'
            ];

            if ( toIgnore.indexOf( exception ) >= 0 ) {
                return;
            }

            $delegate( exception, cause );
        };
    });
}

Core.config( coreConfig );
