import angular from 'angular';

/**
 * SignalR hub base providing properties and functionalities from which all
 * hub providers should extend.
 */
class SignalRHubBaseModel {

    /**
     * Constructor.
     * 
     * SIGNALR_CONSTANTS comes from `modules/core/core.constants.js`.
     * 
     * @param {SIGNALR_CONSTANTS} SIGNALR_CONSTANTS 
     * @param {string} hub 
     */
    constructor( SIGNALR_CONSTANTS, hub ) {
        this.debug = SIGNALR_CONSTANTS.DEBUG;
        this.host = SIGNALR_CONSTANTS.HOST;
        this.hub = hub;
        this.url = `${this.host}/${this.hub}`;

        this.connected = false;
        this.subscriptions = {};

        // Whether this channel has reached the maximum failed connectivity threshold.
        this.unavailable = false;
    }

    /**
     * Returns a given function wrapped by a 0-delay timeout. Intended to
     * be used in cases where an immediate apply of the original function
     * is not possible, so should instead return at the earliest possible
     * point of Angular's digest.
     * 
     * @param {Function} func 
     * @returns {Function}
     */
    _async( func ) {
        func = getTypeCheckedValue( func, 'function', null );
        if ( func === null )
            return angular.noop;

        const $injector = angular.injector([ 'ng' ]);
        const $timeout = $injector.get( '$timeout' );

        return function() {
            const lastIndex = arguments.length - 1;
            const args = arguments[ lastIndex ];

            $timeout( function() {
                func.apply( null, args );
            }, 0 );
        }
    }

    /**
     * Initializes and connects to a SignalR hub using the provided
     * accessTokenFactory function to pull the current access token.
     * This should be `jwtService.getAccessToken` in all extending
     * providers.
     * 
     * @param {Function} accessTokenFactory 
     */
    connect( accessTokenFactory ) {

        // signalR is missing from index.html (and thus window).
        if ( !window.signalR ) {
            this.log( 'connect', 'signalR not found in window.' );
            return;
        }

        // Already initialized and connected.
        if ( this.client && this.client.state === window.signalR.HubConnectionState.Connected ) {
            this.log( 'connect', 'Connection already established.' );
            return;
        }

        accessTokenFactory = getTypeCheckedValue( accessTokenFactory, 'function', () => '' );

        // Initialize the client...
        this.client = new window.signalR.HubConnectionBuilder()
            .withUrl( `${this.host}/${this.hub}`, {
                accessTokenFactory
            })
            .withAutomaticReconnect()
            .configureLogging( this.debug ? signalR.LogLevel.Warning : signalR.LogLevel.Error )
            .build();

        // ...and connect.
        this.client.start()
            .then( () => {
                this.log( 'connect', `Connected with ID "${this.client.connectionId}".` );

                // If the extending hub has this method, call it now.
                if ( typeof this._onConnected === 'function' )
                    this._onConnected();
            });

        // Bind the extending hub's "subscriptions", in which the key is the
        // name of the server-side method returning the response prefixed with
        // "on". The extending class should have similarly named methods prefixed
        // with "_handle".
        //
        // For example: if the CommonAPI hub emits "ReceiveNotification" on return,
        // the key will be "onReceiveNotification", and the expected method within
        // the extending hub will be "_handleReceiveNotification".
        Object.keys( this.subscriptions ).forEach( function( key ) {
            const serverSideEvent = key.replace( 'on', '' );
            const clientSideFunc = `_handle${serverSideEvent}`;

            if ( typeof this[ clientSideFunc ] === 'function' )
                this.client.on( serverSideEvent, this[ clientSideFunc ].bind( this ));
        }.bind( this ));

        this.client.onclose(( err ) => this.log( 'connect', 'Connection closed.', err ));
        this.client.onreconnecting(( err ) => this.log( 'connect', 'Reconnecting...', err ));
        this.client.onreconnected(( connectionId ) => this.log( 'connect', `Reconnected with ID "${connectionId}".` ));
    }

    /**
     * Disconnects the SignalR hub.
     */
    disconnect() {
        if ( this.client && this.client.state === window.signalR.HubConnectionState.Connected ) {
            this.log( 'disconnect', 'Disconnecting...' );
            this.client.stop();
            this.client = null;
        }
    }

    isConnected(){
        if( this.client !== null && this.client.state === window.signalR.HubConnectionState.Connected ){
            return true;
        }
    }

    /**
     * Fires all subscribed callbacks for a subscription.
     * 
     * @param {string} subscription 
     */
    _handleSubscription( subscription ) {
        subscription = getTypeCheckedValue( subscription, 'string', null );

        const args = ( arguments.length > 1 ) ? arguments[ 1 ] : null;
        if ( subscription !== null &&
            Object.keys( this.subscriptions ).includes( subscription )) {
            this.subscriptions[ subscription ]
                .filter( func => typeof func.__ng === 'function' )
                .map( func => func.__ng )
                .forEach( function( func ) {
                    func([ args ]);
                }.bind( this ));
        }
    }

    /**
     * Logs a message to the browser console when debug mode is enabled,
     * including the function from which the log was called and optional
     * data.
     * 
     * @param {string} func 
     * @param {string} message 
     * @param {any} args 
     */
    log( func, message, args ) {
        if ( this.debug ) {
            func = getTypeCheckedValue( func, 'string', 'unknown' );
            message = getTypeCheckedValue( message, 'string', 'Unknown.' );

            const msg = `[SignalR "${this.hub}" Hub] :: ${func} :: ${message}`;
            if ( typeof args !== 'undefined' )
                console.debug( msg, args );
            else
                console.debug( msg );
        }
    }

    /**
     * Registers a hub server event subscription, to be fired when the server-side
     * hub event is triggered.
     * 
     * @param {string} subscription 
     * @param {Function} func 
     */
    _subscribe( subscription, func ) {
        subscription = getTypeCheckedValue( subscription, 'string', null );
        func = getTypeCheckedValue( func, 'function', null );

        if ( subscription !== null &&
            func !== null &&
            Object.keys( this.subscriptions ).includes( subscription ) &&
            this.subscriptions[ subscription ].indexOf( func ) === -1 ) {
            
            func.__ng = this._async( func );
            this.subscriptions[ subscription ].push( func );
        }
    }

    $get( jwtService ) {
        'ngInject';

        let base = {};
        base.connect = () => this.connect( () => jwtService.getAccessToken() );
        base.disconnect = () => this.disconnect();
        base.isUnavailable = () => this.unavailable;
        base.isConnected = () => this.isConnected();
        
        // Expose hub-specific properties and methods.
        if ( typeof this._get === 'function' ) {
            const hub = this._get();

            Object
                .keys( hub )
                .filter( hubProp => !Object.keys( base ).includes( hubProp ))
                .forEach( hubProp => base[ hubProp ] = hub[ hubProp ]);
        }

        // Expose hub subscriptions.
        Object
            .keys( this.subscriptions )
            .forEach( function( subscription ) {
                base[ subscription ] = ( func ) => this._subscribe( subscription, func );
            }.bind( this ));

        return base;
    }
}

export default SignalRHubBaseModel;