import Core from '../../modules/core/core.module';
import defaultTemplate from '../../components/core/modal/modal.default.template.html';

/**
 * @name modalService
 * @memberof CoreBundle.Core
 * @class
 * 
 * @classdesc
 * AngularJS service.
 */
class ModalService {
    constructor( $uibModal ) {
        this.$uibModal = $uibModal;

        this.modalDefaults = {
            backdrop: true,
            keyboard: true,
            modalFade: true,
            template: defaultTemplate
        };

        this.modalOptions = {
            closeButtonShown: true,
            closeButtonText: 'components.global.modal.default.buttons.close',
            actionButtonShown: true,
            actionButtonText: 'components.global.modal.default.buttons.primaryButton',
            linkButtonShown: false,
            linkButtonText: 'components.global.modal.default.buttons.linkButton',
            headerText: 'components.global.modal.default.header',
            bodyText: 'components.global.modal.default.body',
            faIcon: ''
        };
    }

    getDefaultModalDefaults() {
        return this.modalDefaults;
    }

    getDefaultModalOptions() {
        return this.modalOptions;
    }

    showModal( customModalDefaults, customModalOptions ) {
        if ( !customModalDefaults ) {
            customModalDefaults = {};
        }

        customModalDefaults.backdrop = 'static';
        return this.show( customModalDefaults, customModalOptions );
    }

    show( customModalDefaults, customModalOptions ) {
        let tempModalOptions = {},
            tempModalDefaults = {
                controller: [ '$scope', '$uibModalInstance', function ( $scope, $uibModalInstance ) {
                    $scope.modalOptions = tempModalOptions;
                    $scope.modalOptions.doAction = function ( result ) {
                        $uibModalInstance.close( result );
                    };
                    $scope.modalOptions.doClose = function ( result ) {
                        $uibModalInstance.dismiss({ $value: 'cancel' });
                    };
                    $scope.modalOptions.doLink = function ( result ) {
                        $uibModalInstance.dismiss({ $value: 'cancel' });
                    };
                }]
            };

        //Map angular-ui modal custom defaults to modal defaults defined in service
        angular.extend( tempModalDefaults, this.modalDefaults, customModalDefaults );

        //Map modal.html $scope custom properties to defaults defined in service
        angular.extend( tempModalOptions, this.modalOptions, customModalOptions );

        return this.$uibModal.open( tempModalDefaults ).result;
    }
}

Core.service( 'modalService', ModalService );
