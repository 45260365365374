import PBAccountModel from '../pb/account.model';

/**
 * @name SessionStateUserModel
 * @memberof CoreBundle.Core
 * @class
 * 
 * @classdesc Defines the shape of a user's basic metadata and role/product
 * configuration within SessionState.
 */
class SessionStateUserModel {
    constructor( firstName, lastName, loginId, userId, email, lastLogin, isActive,
        isMultiPortal, products, roles, isGuest, isAuthenticatedGuest ) {

        this.firstName = getTypeCheckedValue( firstName, 'string', null );
        this.lastName = getTypeCheckedValue( lastName, 'string', null );
        this.loginId = getTypeCheckedValue( loginId, 'string', null );
        this.userId = getTypeCheckedValue( userId, 'int', null );
        this.email = getTypeCheckedValue( email, 'string', null );
        this.lastLogin = getTypeCheckedValue( lastLogin, 'int', null );
        this.isActive = getTypeCheckedValue( isActive, 'boolean', false );
        this.isMultiPortal = getTypeCheckedValue( isMultiPortal, 'boolean', false );
        this.products = getTypeCheckedValue( products, 'object', null );
        this.roles = getTypeCheckedValue( roles, 'object', null );
        this.isGuest = getTypeCheckedValue( isGuest, 'boolean', false );
        this.isAuthenticatedGuest = getTypeCheckedValue( isAuthenticatedGuest, 'boolean', false );
    }

    /**
     * Whether the user has all basic metadata, and at least one role/product
     * mapping association.
     * 
     * @returns {boolean}
     */
    isValid() {
        return (
            ( this.loginId !== null || this.isAuthenticatedGuest ) &&
            this.firstName !== null &&
            this.lastName !== null &&
            this.userId !== null &&
            this.email !== null &&
            this.products !== null &&
            Object.keys( this.products ).length > 0 &&
            this.roles !== null &&
            Object.keys( this.roles ).length > 0
        );
    }

    /**
     * Whether the user session state contains specific product data.
     * 
     * @param {string} productKey Key to check for; e.g., "mm", "pb".
     */
    hasProductData( productKey ) {
        let hasProductData = false;

        try {
            if ( this.products !== null ) {
                hasProductData = this.products.hasOwnProperty( productKey );
            }
        } catch ( err ) {
            console.warn( `Unable to determine presence of ${productKey} product data.`, err );
        }

        return hasProductData;
    }

    /**
     * Returns a static instance for use as guest. Values are taken from AR.Core's
     * global.json config file.
     */
    static defaultAsGuest() {
        console.debug( '[session-state-user.model] :: defaultAsGuest generating default model' );

        // PB-only.
        let products = {
            pb: [
                PBAccountModel.defaultAsGuest()
            ]
        };

        // Role for PB guest.
        let roles = {
            pb: {
                code: 'MKTPBASE_GUEST'
            }
        };

        let sessionStateUser = new SessionStateUserModel(
            '',
            '',
            '',
            -1,
            '',
            null,
            true,
            false,
            products,
            roles,
            true,
            false
        );

        console.debug( '[session-state-user.model] :: defaultAsGuest returning default model', sessionStateUser );
        return sessionStateUser;
    }

    /**
     * Helper method to construct a session state model from a JSON object as
     * returned from browser storage or API response.
     * 
     * @param {object} jsonObj JSON object to parse.
     * @returns {SessionStateModel}
     */
    static fromJsonObject( jsonObj ) {
        jsonObj = getTypeCheckedValue( jsonObj, 'object', {} );

        return new SessionStateUserModel(
            jsonObj.firstName,
            jsonObj.lastName,
            jsonObj.loginId,
            jsonObj.userId,
            jsonObj.email,
            jsonObj.lastLogin,
            jsonObj.isActive,
            jsonObj.isMultiPortal,
            jsonObj.products,
            jsonObj.roles,
            jsonObj.isGuest,
            jsonObj.isAuthenticatedGuest
        );
    }
}

export default SessionStateUserModel;