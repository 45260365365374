// Local dependencies.
import SessionStateModel from './session-state.model';

/**
 * Defines the expected payload structure of a request to restore, refresh,
 * revoke, or switch account of a user's session.
 */
export default class AuthResponseModel {
    constructor( accessToken, refreshToken, sessionState ) {
        this.accessToken = getTypeCheckedValue( accessToken, 'string', null );
        this.refreshToken = getTypeCheckedValue( refreshToken, 'string', null );
        this.sessionState = getInstanceCheckedValue( sessionState, SessionStateModel, null );
    }

    /**
     * Whether session state contains specific product data.
     * 
     * @param {string} productKey 
     * @returns {boolean}
     */
    hasProductData( productKey ) {
        productKey = getTypeCheckedValue( productKey, 'string', null );
        if ( productKey === null || this.sessionState === null )
            return false;

        return this.sessionState.hasProductData( productKey );
    }

    /**
     * Whether session state contains ProspectBase data.
     * 
     * @returns {boolean}
     */
    hasProspectBaseData() {
        return this.hasProductData( 'pb' );
    }

    /**
     * Whether session state contains Market Magnifier data.
     * 
     * @returns {boolean}
     */
    hasMarketMagnifierData() {
        return this.hasProductData( 'mm' );
    }

    /**
     * Helper method to return a default instance of an auth response, assuming
     * a revoked session state.
     */
    static default() {
        var result = new AuthResponseModel();
        result.accessToken = null;
        result.refreshToken = null;
        result.sessionState = null;

        return result;
    }

    /**
     * Returns a default instance of an auth response, assuming PB-only guest
     * access.
     */
    static defaultAsGuest() {
        console.debug( '[auth-response.model] :: defaultAsGuest generating default model' );

        let sessionState = SessionStateModel.defaultAsGuest(),
            authResponse = new AuthResponseModel(
                null,
                null,
                sessionState
            );

        console.debug( '[auth-response.model] :: defaultAsGuest generated default model', authResponse );

        return authResponse;
    }

    /**
     * Helper method to construct an auth response model from a JSON object as
     * returned from APIv3.
     * 
     * @param {object} obj 
     * @returns {AuthResponseModel}
     */
    static fromObj( obj ) {
        obj = getTypeCheckedValue( obj, 'object', {} );

        var accessToken = obj.accessToken || null,
            refreshToken = obj.refreshToken || null,
            sessionState = obj.sessionState || null;

        if ( sessionState !== null )
            sessionState = SessionStateModel.fromJsonObject( sessionState );

        return new AuthResponseModel( accessToken, refreshToken, sessionState );
    }

    /**
     * Helper method to construct an auth response model from a JSONified
     * string.
     * 
     * @param {string} str 
     * @returns {AuthResponseModel}
     */
    static fromStr( str ) {
        str = getTypeCheckedValue( str, 'string', null );

        let authModel = AuthResponseModel.default();

        try {
            let obj = JSON.parse( str );
            obj = getTypeCheckedValue( obj, 'object', null );

            console.debug( '[auth-response.model] :: parsed object', obj );

            if ( obj !== null )
                authModel = AuthResponseModel.fromObj( obj );
        } catch ( err ) {
            console.warn( '[auth-response.model] :: Unable to parse from string.', err );
        }

        return authModel;
    }
}