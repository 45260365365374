// Library dependencies.
import angular from 'angular';

// Local dependencies.
import Core from '../../../modules/core/core.module';

/**
 * @name InfiniteScroll
 * @memberof CoreBundle.Core
 * @class
 * 
 * @classdesc
 * AngularJS directive.
 * 
 * @example
 * <form>
 *  <input type="text" name="this" data-field-match="that" />
 *  <input type="text" name="that" data-field-match="this" />
 * </form>
 */
class InfiniteScroll {
    constructor( $window, $timeout ) {
        this.$window = $window;
        this.$timeout = $timeout;
        
        this.restrict = 'A';
        this.scope = {
            callback: '&infiniteScroll',
            distance: '=infiniteScrollDistance',
            disabled: '=infiniteScrollDisabled'
        };
    }

    link( scope, elem, attrs ) {
        console.log( 'infinite-scroll' );
        let elm = elem[ 0 ],
            onScroll = function( oldVal, newVal ) {
                if ( scope.disabled ) return;

                let scrollTop = elm.scrollTop,
                    offsetHeight = elm.offsetHeight,
                    scrollHeight = elm.scrollHeight,
                    shouldCallback = ( scrollTop + offsetHeight >= scrollHeight );

                if ( shouldCallback ) {
                    console.log( 'infinite-scroll getting more' );
                    this.$timeout( scope.callback );
                }
            }.bind( this );

        scope.$watch( 'disabled', ( isDisabled ) => {
            if ( !isDisabled ) onScroll();
        });

        elem.on( 'scroll', onScroll );
        this.$timeout( onScroll );
    }
}

Core.directive( 'infiniteScroll', ( $window, $timeout ) => new InfiniteScroll( $window, $timeout ));
