import Core from '../../../modules/core/core.module';
import landingTemplate from './landing.template.html';

/**
 * @name landingView
 * @memberof CoreBundle.Core
 * @class
 * 
 * @classdesc
 * AngularJS component.
 */
class LandingController {
    constructor( $scope, $state, Session, eventService ) {
        this.$scope = $scope;
        this.$state = $state;
        this.Session = Session;
        this.eventService = eventService;
    }

    $onInit() {
        this.$scope.launchProduct = this.launchProduct.bind( this );
        this.eventService.broadcastViewLoaded();
    }

    launchProduct( product ) {
        console.log( 'LandingController launchProduct called.' );
        console.log( `    product: ${product}` );

        this.Session.currentProduct = product;
        this.$state.go( `mp.${product}` );
    }
}

const LandingView = {
    template: landingTemplate,
    controller: LandingController
};

Core.component( 'landingView', LandingView );
