// Local dependencies.
import Core from '../../../modules/core/core.module';
import template from './announcements.template.html';
import {
    UserAnnouncementsResponse,
    UserAnnouncementResponse
} from '../../../models/core/announcement-response.model';
import { UserAnnouncementCategory } from '../../../models/core/announcement-category.model';
import './announcements.styles.scss';

class AnnouncementsController {
    constructor( $scope, $state, $timeout, eventService, announcementFactory, wsAnnouncement ) {
        this.$scope = $scope;
        this.$state = $state;
        this.$timeout = $timeout;
        this.eventService = eventService;
        this.announcementFactory = announcementFactory;
        this.wsAnnouncement = wsAnnouncement;
    }

    $onInit() {
        this.$scope.loading = true;
        this.$scope.announcementCategories = [];
        this.$scope.announcements = [];
        this.$scope.announcement = null;
        this.$scope.total = 0;
        this.$scope.pages = -1;
        this.$scope.page = 1;
        this.$scope.count = 9;
        this.$scope.sortBy = 'startDate';
        this.$scope.sortDir = 'desc';

        this.$scope.initialize = this.initialize.bind( this );
        this.$scope.handleSelect = this.handleSelect.bind( this );
        this.$scope.handleSortChange = this.handleSortChange.bind( this );
        this.$scope.handlePageChange = this.handlePageChange.bind( this );

        this.eventService.broadcastViewLoaded();
    }

    /**
     * Bootstraps the view by immediately fetching all announcements
     * able to be viewed by the user.
     */
    initialize() {
        this._getUserAnnouncements( this.$scope.sortBy, this.$scope.sortDir,
            this.$scope.page, this.$scope.count );
    }

    /**
     * Menu component callback that handles the selection of an announcement
     * for viewing.
     * 
     * @param {UserAnnouncementBase} announcement 
     */
    handleSelect( announcement ) {
        this._getUserAnnouncement( announcement.announcementId );
    }

    /**
     * Menu component callback that handles a change in the sort direction.
     * 
     * @param {string} sortDir 
     */
    handleSortChange( sortDir ) {
        this.$scope.sortDir = sortDir;
        this._getUserAnnouncements( this.$scope.sortBy, sortDir, this.$scope.page,
            this.$scope.count );
    }

    /**
     * Menu component callback that handles a change in next/prev pagination.
     * 
     * @param {string} pageDir 
     */
    handlePageChange( pageDir ) {
        let { pages, page } = this.$scope;

        let isValid = false,
            newPage = 1;

        if ( pageDir === 'prev' && ( page - 1 ) > 0 ) {
            isValid = true;
            newPage = ( page - 1 );            
        } else if ( pageDir === 'next' && ( page + 1 ) <= pages ) {
            isValid = true;
            newPage = ( page + 1 );
        }

        if ( page !== newPage ) {
            this.$scope.page = newPage;
            this._getUserAnnouncements( this.$scope.sortBy, this.$scope.sortDir,
                newPage, this.$scope.count );
        }
    }

    /**
     * Fetches all announcement metadata records from the API against the
     * provided and optional sort/pagination values.
     * 
     * @param {string} sortBy 
     * @param {string} sortDir 
     * @param {number} page 
     * @param {number} count 
     */
    _getUserAnnouncements( sortBy = null, sortDir = null, page = null, count = null ) {
        this.$scope.loading = true;

        // We use $timeout to ensure that the loading flag has been digested.
        this.$timeout(() => {

            this.announcementFactory.getUserAnnouncements( sortBy, sortDir, page, count )
                .then( response => {
                    let announcements = [],
                        total = 0,
                        pages = 0;

                    if ( response instanceof UserAnnouncementsResponse ) {
                        total = response.totalCount;
                        pages = Math.ceil( total / ( count || this.$state.count ));
                        announcements = response.announcements;
                    }

                    this.$scope.loading = false;
                    this.$scope.total = total;
                    this.$scope.pages = pages;
                    this.$scope.announcements = announcements;
                });
        });
    }

    /**
     * Fetches a single announcement detail from the API.
     * 
     * @param {number} announcementId 
     */
    _getUserAnnouncement( announcementId ) {
        this.$scope.loading = true;

        // We use $timeout to ensure that the loading flag has been digested.
        this.$timeout(() => {

            this.announcementFactory.getUserAnnouncement( announcementId )
                .then( response => {
                    let announcement = null;

                    if ( response instanceof UserAnnouncementResponse )
                        announcement = response.announcement;
                        
                    this.$scope.loading = false;
                    this.$scope.announcement = announcement;
                });
        });
    }
}

const announcementsView = {
    template,
    controller: AnnouncementsController
};

Core.component( 'announcementsView', announcementsView );