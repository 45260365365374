import Core from '../../../modules/core/core.module';
import notificationTrayTemplate from './notification-tray.template.html';
import './notifications.styles.scss';

/**
 * @name NotificationTray
 * @memberof CoreBundle.Core
 * @class
 * 
 * @classdesc
 * AngularJS component.
 */
class NotificationTrayController {
    constructor( $scope, GLOBALS, wsNotification, signalRNotificationHub ) {
        this.$scope = $scope;
        this.GLOBALS = GLOBALS;
        this.wsNotification = wsNotification;
        this.signalRNotificationHub = signalRNotificationHub;
    }

    $onInit() {
        const self = this;

        this.$scope.notifications = [];
        this.$scope.notificationCount = 0;
        this.$scope.isOpen = false;
        this.$scope.visibleCount = this.GLOBALS.NOTIFICATION_TRAY.VISIBLE;
        this.$scope.getMore = this.getMore.bind( this );

        this.$scope.$watch(
            function () { return self.isOpen; },
            function ( isOpen ) { self._onTrayOpen.apply( self, [ isOpen ])}
        );

        this.$scope.$watch(
            function () { return self.notifications; },
            function( notifications ) { self.$scope.notifications = notifications; }
        );
    }

    getMore() {
        console.log( 'notification-tray getting more' );
        //this.wsNotification.getUnseenNotifications();
        //this.signalRNotificationHub.getUnseenNotifications();
    }

    _onTrayOpen( isOpen ) {
        if ( isOpen ) {
            console.log( this.$scope.notifications );
            // Update what we can in the database.
            let userNotificationIds = this.$scope.notifications
                .filter( n => n.isViewable && !n.isViewed && getTypeCheckedValue( n.userNotificationId, 'int', null ) !== null )
                .map( n => n.userNotificationId );
            //this.wsNotification.notificationsSeen( userNotificationIds );
            this.signalRNotificationHub.notificationsSeen( userNotificationIds );
            this._updateNonDatabaseAsSeen();
        }
    }

    /**
     * When the tray opens, all notifications that were held in the database
     * are sent back through the notification service to be marked as "seen",
     * which also has the eventual side effect of reducing the count in the
     * utility nav. For notifications - primary those from PB - that aren't
     * currently held in the database, we need to mark their models as "seen,"
     * and send the count back to utility-nav to be subtracted from the existing
     * count of notifications.
     */
    _updateNonDatabaseAsSeen() {
        let reduceCount = 0;

        // If it's from PB - or a client-side non-database notification - mark them viewed.
        for ( let i = 0; i < this.$scope.notifications.length; i++ ) {
            if ( !this.$scope.notifications[ i ].isViewable && !this.$scope.notifications[ i ].isViewed ) {
                this.$scope.notifications[ i ].isViewed = true;
                reduceCount += 1;
            }
        }

        // Fire it back up to the utility-nav.
        if ( typeof this.onNotificationReduceCount === 'function' ) {
            this.onNotificationReduceCount({ reduceCount });
        }
    }
}

const notificationTray = {
    template: notificationTrayTemplate,
    controller: NotificationTrayController,
    bindings: {
        isOpen: '=?',
        notifications: '<?',
        onNotificationReduceCount: '&'
    }
};

Core.component( 'notificationTray', notificationTray );
