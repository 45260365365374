// Local dependencies.
import SessionStateUserModel from './session-state-user.model';

/**
 * @name SessionStateModel
 * @memberof CoreBundle.Core
 * @class
 * 
 * @classdesc Defines the shape of a user's session state as provided by APIv2.
 */
class SessionStateModel {
    constructor( user, impersonatingUser, claRoles ) {
        this.user = getInstanceCheckedValue( user, SessionStateUserModel, null );
        this.impersonatingUser = getTypeCheckedValue( impersonatingUser, 'object', null );
        this.claRoles = getTypeCheckedValue( claRoles, 'array', [] );
    }

    /**
     * Whether the user session state contains specific product data.
     * 
     * @param {string} productKey Key to check for; e.g., "mm", "pb".
     */
    hasProductData( productKey ) {
        return ( this.user !== null && this.user.hasProductData( productKey ));
    }

    /**
     * Returns a static guest-only session state for PB-only.
     */
    static defaultAsGuest() {
        console.debug( '[session-state.model] :: defaultAsGuest generating default model' );

        // Default user.
        let user = SessionStateUserModel.defaultAsGuest(),
            sessionState = new SessionStateModel(
                user,
                null,
                null
            );

        console.debug( '[session-state.model] :: defaultAsGuest generated default model', sessionState );

        return sessionState;
    }

    /**
     * Helper method to construct a session state model from a JSON object as
     * returned from browser storage or API response.
     * 
     * @param {object} jsonObj JSON object to parse.
     * @returns {SessionStateModel}
     */
    static fromJsonObject( jsonObj ) {
        let sessionState = null;

        try {
            jsonObj = getTypeCheckedValue( jsonObj, 'object', {} );

            let userObj = getTypeCheckedValue( jsonObj.user, 'object', null ),
                user = SessionStateUserModel.fromJsonObject( userObj );

            sessionState = new SessionStateModel(
                user,
                jsonObj.impersonatingUser,
                jsonObj.claRoles
            );
        } catch ( err ) {
            console.warn( 'Unable to extrapolate session state from object.', err );
        }

        return sessionState;
    }
}

export default SessionStateModel;