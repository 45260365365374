import { angularAsyncify, $scopeCallbackWrapper } from '../../utils';
import Core from '../../modules/core/core.module';
import FileUploadModel from '../../models/core/file-upload.model';
import NotificationModel from '../../models/core/notification.model';

class FileUpload {
    constructor(  Core_API ) {
        'ngInject';

        this.debug = true;
        this.$http = null;
        this.$q = null;
        this.Core_API = Core_API;
        this.eventService = null;

        this.subscriptions = {
            onReady: [],
            onUploadEnabled: [],
            onUploadInProgress: [],
            onUploadProgress: [],
            onUploadComplete: [],
            onUploadCancelled: []
        };

        this.currentUpload = null;

        // Whether this channel has reached the maximum failed connectivity threshold.
        this.unavailable = false;

        this.ready = true;
    }

    /**
     * Checks to see whether an upload is already in-flight.
     */
     _canSetUpload() {
        return (
            !this._isFileUploadValid() ||
            !this.currentUpload.uploading
        );
    }

    /**
     * Abstracted method used in numerous other methods as a validation
     * for whether manipulating the prepared or in-flight FileUploadModel
     * instance is possible.
     * 
     * @returns {boolean} Whether the "upload" instance is set to a valid instance of FileUploadModel.
     */
     _isFileUploadValid() {
        return (
            this.currentUpload !== null &&
            this.currentUpload instanceof FileUploadModel &&
            this.currentUpload.valid
        );
    }

    /**
     * Determines if an upload has been initialized, started, and is not
     * in a paused state; whether there is currently an "in-flight" active
     * upload.
     * 
     * @returns {boolean} True if a valid upload model has started, and is not paused.
     */
     _isUploading() {
        return (
            this._isFileUploadValid() &&
            this.currentUpload.uploading &&
            !this.currentUpload.paused
        );
    }

    /**
     * Determines if an upload has been initialized, started, and then cancelled
     * by the user.
     * 
     * @returns {boolean} True if a valid upload model has started, but was cancelled.
     */
     _isCancelled() {
        return (
            this._isFileUploadValid() &&
            this.currentUpload.cancelled
        );
    }

    _handleChunkRead( fileId, chunk, data ) {
        fileId = getTypeCheckedValue( fileId, 'string', null );
        chunk = getTypeCheckedValue( chunk, 'int', null );
        data = getInstanceCheckedValue( data, ArrayBuffer, null );

        if ( fileId !== null &&
            chunk !== null &&
            data !== null ) {
            
            this._log( '_handleChunkRead', 'Starting uploading chunk.', { fileId: fileId, chunk: chunk });    
            this._uploadChunk( fileId, chunk, data );
        }
    }

    _handleFinished( filename ){
        this._log( '_handleFinished', 'Starting file upload finishing process.', { filename: filename });
        
        this._handleSubscription( 'onUploadComplete' );
    }

    _handleChunkUploaded( filename, chunk, chunks ){
        this._log( '_handleChunkUploaded', `Finished uploading chunk: "${chunk}" out of "${chunks}".`);
        
        let progress = ( chunk / chunks ) * 100;
        
        this._handleSubscription( 'onUploadProgress', { progress, filename } );
    }

    _handleSubscription( subscription ) {
        subscription = getTypeCheckedValue( subscription, 'string', null );

        const args = ( arguments.length > 1 )
            ? arguments[ 1 ]
            : null;

        if ( subscription !== null &&
            Object.keys( this.subscriptions ).includes( subscription )) {

            this.subscriptions[ subscription ]
                .filter( func => typeof func.__ng === 'function' )
                .map( func => func.__ng )
                .forEach( function( func ) {
                    func([ args ]);
                }.bind( this ));
        }
    }

    _log( func, message, args ) {
        if ( this.debug ) {
            func = getTypeCheckedValue( func, 'string', 'unknown' );
            message = getTypeCheckedValue( message, 'string', 'Unknown.' );

            const msg = `[FileUpload] :: ${func} :: ${message}`;
            if ( typeof args !== 'undefined' )
                console.debug( msg, args );
            else
                console.debug( msg );
        }
    }

    _getPreflight( upload ) {
        const self = this;
        const defer = this.$q.defer();
        upload = getInstanceCheckedValue( upload, FileUploadModel, null );

        if ( upload === null )
            defer.resolve( null );

        const requestPath = this.Core_API.ROUTE_FILE_UPLOAD_PREFLIGHT
            .replace( '{id}', upload.preflightId );
        this.$http.get( requestPath )
            .then(
                // 200
                ( response ) => {
                    response = getTypeCheckedValue( response, 'object', {} );
                    const data = getTypeCheckedValue( response.data, 'object', null );

                    defer.resolve( data );
                },

                // Err
                ( err ) => {
                    self._log( '_getPreflight', `Failed calling "${requestPath}".`, err );
                    defer.resolve( null );
                }
            );

        return defer.promise;
    }

    _set( userId, orderId, file ) {
        const self = this;

        userId = getTypeCheckedValue( userId, 'int', null );
        orderId = getTypeCheckedValue( orderId, 'int', null );
        file = getInstanceCheckedValue( file, File, null );

        let upload = new FileUploadModel( userId, orderId, file );
        if ( upload.valid ) {
            if( !upload.stopped && !upload.paused )
                this._handleSubscription( 'onUploadEnabled', true );

            this.currentUpload = upload;
            this._getPreflight( upload ).then( response => {
                self._log( '_set', 'Received preflight response.', { userId, orderId, response });
                self.currentUpload.validatePreflightResponse( response );
                if ( self.currentUpload.validAfterPreflight ){
                    self.currentUpload.onChunkRead = self._handleChunkRead.bind( self );
                    self.currentUpload.onChunkUploaded = self._handleChunkUploaded.bind( self );
                    self.currentUpload.onFinished = self._handleFinished.bind( self );
                }
                self._handleSubscription( 'onReady', self.currentUpload.validAfterPreflight );
            });
        }
    }

    _start() {
        if ( this.currentUpload !== null &&
            this.currentUpload.valid &&
            this.currentUpload.validAfterPreflight &&
            !this.currentUpload.uploading &&
            !this.currentUpload.paused &&
            !this.currentUpload.stopped ) {
            
            this._log( '_start', 'Starting upload.', { upload: this.currentUpload });
            this.currentUpload.upload();
        }
    }

    _subscribe( subscription, func ) {
        subscription = getTypeCheckedValue( subscription, 'string', null );
        func = getTypeCheckedValue( func, 'function', null );

        if ( subscription !== null &&
            func !== null &&
            Object.keys( this.subscriptions ).includes( subscription ) &&
            this.subscriptions[ subscription ].indexOf( func ) === -1 ) {

            func.__ng = angularAsyncify( func );
            this.subscriptions[ subscription ].push( func );
        }
    }

    _uploadChunk( fileId, chunk, data ) {
        const self = this;
        const defer = this.$q.defer;
        const requestPath = this.Core_API.ROUTE_FILE_UPLOAD;
        const headers = {
            'Content-Type': 'application/octet-stream',
            'File-Id': fileId,
            'File-Chunk': chunk
        };
        
        //this.$http.post( requestPath, new Uint8Array(data), { headers })
        this.$http({
            method: 'POST',
            url: requestPath,
            data: new Uint8Array( data ),
            headers: {
                'Content-Type': 'application/octet-stream',
                'File-Id': fileId,
                'File-Chunk': chunk
            },  
            transformRequest: [],
        })
            .then(
                ( response ) => {
                    self._log( '_uploadChunk', `Uploading finished "${headers}".` );

                    response = getTypeCheckedValue( response, 'object', {} );
                    const resData = getTypeCheckedValue( response.data, 'object', null );
                    self.currentUpload.validateOperationResponse( resData );
                    defer.resolve();
                },

                ( err ) => {
                    self._log( '_uploadChunk', `Failed calling "${requestPath}".`, err );
                    defer.resolve();
                }
            );

        return defer.promise;
    }

    /**
     * Returns the filename of a set and validated upload's filename.
     * 
     * @return {string|null} Selected upload filename if valid; otherwise null.
     */
     _getFilename() {
        return ( this._isFileUploadValid() )
            ? this.currentUpload.filename
            : null;
    }

    /**
     * Removes an existing persistent visual notification previously generated
     * from the FileUploadNotification service reporting a critical processing
     * event.
     */
    _resetProcessPersistentNotification() {
        this._log( '_resetProcessPersistentNotification called.' );
        this.eventService.broadcastPersistentNotificationReset();
    }

    _injectServiceInstances( $http, $q, eventService ){
        this.$http = $http;
        this.$q = $q;
        this.eventService = eventService;
    }

    $get() {

        return {
            onReady: ( func ) => this._subscribe( "onReady", func ),
            onUploadComplete: ( func ) => this._subscribe( "onUploadComplete", func ),
            onUploadEnabled: ( func ) => this._subscribe( "onUploadEnabled", func ),
            onUploadProgress: ( func ) => this._subscribe( "onUploadProgress", func ),

            injectServiceInstances: ( $http, $q, eventService ) => this._injectServiceInstances( $http, $q, eventService ),
            
            /**
             * Whether this provider has received configuration values from
             * FileUploadNotification's "file_upload" channel needed to start
             * a streaming upload.
             */
            isReady: () => this.ready,

            /**
             * Whether a valid upload has already been set and started. Does not
             * account for a paused upload.
             * 
             * @returns {boolean} True if an existing upload is in-flight.
             */
             canSetUpload: () => this._canSetUpload(),

            /**
             * Whether a valid upload has already been set, started, and actively streaming.
             * 
             * @returns {boolean} True if an existing upload is in-flight, and not paused.
             */
             isUploading: () => this._isUploading(),

            /**
             * Determines if an upload has been initialized, started, and then cancelled
             * by the user.
             * 
             * @returns {boolean} True if a valid upload model has started, but was cancelled.
             */
            isCancelled: () => this._isCancelled(),

            set: ( userId, orderId, file ) =>
                this._set( userId, orderId, file ),

            isUnavailable: () => this.unavailable,

            /**
             * Returns the filename of a set and validated upload's filename.
             * 
             * @return {string|null} Selected upload filename if valid; otherwise null.
             */
             getFilename: () => this._getFilename(),

             /**
             * Begins streaming a previously set upload to the FileUploadNotification's
             * "file_upload" channel.
             */
            start: () => this._start(),

            /**
             * Removes an existing persistent visual notification previously generated
             * from the FileUploadNotification service reporting a critical processing
             * event.
             */
            resetProcessPersistentNotification: () =>
                this._resetProcessPersistentNotification()
        };
    }
}

Core.provider( 'fileUpload', FileUpload );