// Library dependencies.
import angular from 'angular';
import get from 'lodash/get';

/**
 * @name WSChannelBaseModel
 * @memberof CoreBundle.Core
 * @class
 * 
 * @classdesc
 * Base class from which WebSocket channel providers should extend.
 */
class WSChannelBaseModel {
    constructor( channel, GLOBALS, WS_CONSTANTS, WS_EVENTS ) {
        channel = getTypeCheckedValue( channel, 'string', 'Base' );

        this.channel = channel;
        this.GLOBALS = GLOBALS;
        this.WS_CONSTANTS = WS_CONSTANTS;
        this.WS_EVENTS = WS_EVENTS;
        this.eventService = null;

        // The user ID associated with this socket connection.
        this.userId = null;

        // Whether this channel has reached the maximum failed connectivity threshold.
        this.unavailable = false;

        // A connected instance of a WebSocket connection.
        this.socket = null;

        // Core event callback registrations.
        this.eventSubscriptions = {

            // Connectivity state changes.
            onConnectionChanged: [],

            // Maximum failed connectivity.
            onConnectionUnavailable: []
        };
    }

    /**
     * Manually disconnects the socket from the FileUploadNotification service,
     * so long as the socket instance is populated and actually connected.
     */
    disconnect() {
        if ( this.socket !== null && this.socket instanceof window.io.Socket &&
            this.socket.connected ) {
            this._log( 'Manually disconnecting.' );
            this.socket.disconnect();
        }
    }

    /**
     * Initializes a connection to the FileUploadNotification service after
     * heavily validating required fields and data, and populates the
     * socket instance with the result. Also wires up core socket handling
     * events.
     * 
     * @param {number} userId Session user ID to connect as.
     */
    connect( userId ) {
        this.userId = getTypeCheckedValue( userId, 'int', null );

        if ( this.userId === null ) {
            throw new Error( `Unable to connect to "${this.channel}": invalid or missing user ID.` );
        }

        if ( !window.io ) {
            throw new Error( `Unable to connect to "${this.channel}": invalid or missing socket.io-client library.` );
        }

        let channelKey = `CHANNEL_${this.channel.toUpperCase()}`;
        if ( !Object.keys( this.WS_CONSTANTS ).includes( channelKey )) {
            throw new Error( `Unable to connect to "${this.channel}": invalid channel.` );
        }

        if ( this.socket !== null &&
            this.socket instanceof window.io.Socket &&
            this.socket.connected ) {
            console.warn( `Unable to connect to "${this.channel}": already connected.` );
            return;
        }

        this.socket = window.io.connect( this.WS_CONSTANTS[ channelKey ], {
            path: '/fun',
            reconnection: true,
            reconnectionAttempts: this.WS_CONSTANTS.CONNECTION_ATTEMPTS,
            reconnectionDelay: 1000,
            reconnectionDelayMax: 1500,
            query: {
                uid: this.userId
            }
        });

        // Bind to core socket.io events...
        Object.keys( this.WS_EVENTS.Core.Subscribe ).forEach( function( eventKey ) {
            let funcName = `_handle${eventKey}`,
                eventName = this.WS_EVENTS.Core.Subscribe[ eventKey ];

            if ( typeof this[ funcName ] === 'function' ) {
                this.socket.on( eventName, this[ funcName ].bind( this ));
            }
        }.bind( this ));

        // Bind to channel-specific events if we have them...
        let channelEventsKey = this.channel
                .split( '_' )
                .map( part => part.charAt( 0 ).toUpperCase() + part.slice( 1 ))
                .join( '' ),
            channelEvents = get( this.WS_EVENTS, `${channelEventsKey}.Subscribe` ) || {};
        Object.keys( channelEvents ).forEach( function( eventKey ) {
            let funcName = `_handle${eventKey}`,
                eventName = channelEvents[ eventKey ];

            if ( typeof this[ funcName ] === 'function' ) {
                this.socket.on( eventName, this[ funcName ].bind( this ));
            }
        }.bind( this ));

        // Check for channel-specific post-connection actions...
        if ( typeof this._onChannelConnect === 'function' ) {
            this._onChannelConnect();
        }
    }

    /**
     * Whether this channel's socket instance is defined and in a connected
     * state with the FileUploadNotification service.
     * 
     * @returns {boolean} Whether this channel's socket is connected.
     */
    connected() {
        return ( window.io !== undefined &&
            this.socket !== null &&
            this.socket instanceof window.io.Socket &&
            this.socket.connected );
    }

    /**
     * Socket event handler fired when the FileUploadNotification service
     * sends a "ping" client health check. This must emit "pong" back to
     * the service, or the service will auto-disconnect what it views is
     * an absentee client connection.
     */
    _handlePing() {
        this.socket.emit( 'pong' );
    }

    /**
     * Socket event handler fired when an attempt to reconnect to a
     * dropped FileUploadNotification service connection has failed. If
     * the provided attempt count exceeds the configured maximum for
     * the channel, fires subscription callbacks to the onConnectionUnavailable
     * event.
     * 
     * @param {number} attemptNum Attempt count.
     */
    _handleReconnectAttempt( attemptNum ) {
        attemptNum = getTypeCheckedValue( attemptNum, 'int', 0 );

        if ( !this.connected() ) {
            this._log( `Connection lost; reconnect attempt ${attemptNum} failed.` );
            this._handleSubscription( 'onConnectionChanged', this.connected() );

            if ( attemptNum >= this.WS_CONSTANTS.CONNECTION_ATTEMPTS ) {
                this._log( 'Maximum reconnection attempt threshold met; disabling WebSocket functionality.' );
                this.unavailable = true;
                this._handleSubscription( 'onConnectionUnavailable' );
                this.socket.close();
            }
        }
    }

    /**
     * Socket event handler fired after a disconnection from the
     * FileUploadNotification service; fires subscription callbacks
     * to the onConnectionChangedEvent.
     */
    _handleDisconnect() {
        this._log( 'Disconnected.' );
        this._handleSubscription( 'onConnectionChanged', this.connected() );
    }

    /**
     * Socket event handler fired after a successful connection to the
     * FileUploadNotification service; fires subscription callbacks to
     * the onConnectionChanged event.
     */
    _handleConnect() {
        this._log( 'Connected.' );
        this._handleSubscription( 'onConnectionChanged', this.connected() );
    }

    /**
     * Registers a channel event subscription to be fired when a defined
     * channel event occurs.
     * 
     * @param {string} eventName Event to subscribe to.
     * @param {function} callbackFunc Callback function to fire.
     */
    _subscribe( eventName, callbackFunc ) {
        eventName = getTypeCheckedValue( eventName, 'string', null );
        callbackFunc = getTypeCheckedValue( callbackFunc, 'function', null );

        if ( eventName !== null && callbackFunc !== null &&
            Object.keys( this.eventSubscriptions ).includes( eventName ) &&
            this.eventSubscriptions[ eventName ].indexOf( callbackFunc ) === -1 ) {

            callbackFunc.__ng = this._asyncAngularify( callbackFunc );
            this.eventSubscriptions[ eventName ].push( callbackFunc );
        }
    }

    /**
     * Returns the provided callback function wrapped within a 0-delay
     * $timeout in order to immediately apply the original callback
     * function at the earliest possible instant within Angular's digest.
     * 
     * @param {function} callbackFunc Callback function to wrap.
     * @returns {function} $timeout-wrapped callback function.
     */
    _asyncAngularify( callbackFunc ) {
        callbackFunc = getTypeCheckedValue( callbackFunc, 'function', null );

        let $injector = angular.injector([ 'ng' ]),
            $timeout = $injector.get( '$timeout' );

        return ( callbackFunc )
            ? function() {
                let lastIndex = arguments.length - 1,
                    args = arguments[ lastIndex ];
                $timeout( function() {
                    callbackFunc.apply( null, args );
                }, 0 );
            }
            : angular.noop;
    }

    /**
     * Fires all subscription callbacks for the provided subscription event.
     * 
     * @param {string} eventName Name of the subscription event collection to fire.
     */
    _handleSubscription( eventName ) {
        eventName = getTypeCheckedValue( eventName, 'string', null );

        let allArgs = arguments,
            args = ( allArgs.length > 1 ) ? allArgs[ 1 ] : null;

        if ( eventName !== null &&
            Object.keys( this.eventSubscriptions ).includes( eventName )) {
            this.eventSubscriptions[ eventName ]
                .filter( func => typeof func.__ng === 'function' )
                .map( func => func.__ng )
                .forEach( function( func ) {
                    func([ args ]);
                }.bind( this ));
        }
    }

    /**
     * Logs a debug message and - if supplied - additional data to the
     * console, so long as the DEBUG_MODE property of the WS_CONSTANTS
     * configuration is set to true.
     * 
     * @param {string} message Message to log.
     * @param {*} args Additional data to log.
     */
    _log( message, args ) {
        message = getTypeCheckedValue( message, 'string', null );
        args = ( typeof args === 'undefined' ) ? null : args;

        if ( message !== null && this.WS_CONSTANTS.DEBUG_MODE ) {
            message = `[FUN "${this.channel}" Channel] :: ${message}`;

            if ( args !== null ) console.debug( message, args );
            else console.debug( message );
        }
    }

    /**
     * Required method that exposes specific methods and properties
     * of the underlying class as the actual provider definition to
     * AngularJS, and acts as the sole entry point.
     */
    $get( eventService ) {
        'ngInject';

        this.eventService = eventService;

        // An object that will be populated and returned with
        // exposed properties and methods.
        let svc = {};

        // Expose connection state properties and methods.
        svc.isUnavailable = () => this.unavailable;
        svc.isConnected = () => this.connected();
        svc.connect = ( userId ) => this.connect( userId );
        svc.disconnect = () => this.disconnect();        

        // Expose channel-specific properties and methods.
        if ( typeof this._get === 'function' ) {
            let channelSvc = this._get();
            channelSvc = getTypeCheckedValue( channelSvc, 'object', {} );

            Object.keys( channelSvc )
                .filter( channelKey => !Object.keys( svc ).includes( channelKey ))
                .forEach( channelKey => svc[ channelKey ] = channelSvc[ channelKey ]);
        }

        // Expose the available event subscriptions.
        Object.keys( this.eventSubscriptions ).forEach( function( eventName ) {
            svc[ eventName ] = ( callbackFunc ) => this._subscribe( eventName, callbackFunc );
        }.bind( this ));

        // Return, so AngularJS has access.
        return svc;
    }
}

/**
 * 
 * @param {*} $scope 
 * @param {*} funcToWrap 
 * @param {*} that 
 */
export function $scopeCallbackWrapper( $scope, funcToWrap, that = null ) {
    $scope = getTypeCheckedValue( $scope, 'object', null );
    funcToWrap = getTypeCheckedValue( funcToWrap, 'function', null );
    that = getTypeCheckedValue( that, 'object', null );

    if ( $scope !== null && funcToWrap !== null ) {
        return $scope.$apply( function() {
            funcToWrap.bind( that );
        }.bind( that ));
    }
}

export default WSChannelBaseModel;
