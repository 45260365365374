import Core from '../../../modules/core/core.module';
import progressBarTemplate from './progress-bar.template.html';

import './progress-bar.styles.scss';

/**
 * @name progressBar
 * @memberof CoreBundle.Core
 * @class
 * 
 * @classdesc
 * AngularJS component. Displays a visual indicator of progress for a long-running
 * process within the application.
 */
class ProgressBarController {
    constructor( $scope, $timeout ) {
        this.$scope = $scope;
        this.$timeout = $timeout;
    }

    $onInit() {
        this.$scope.percentageRecorded = 0;
        this.$scope.percentageDisplayed = 0;
        this.$scope.maxIncrementAmount = 5;

        this.$scope.$on( 'appProgress-set', this.onAppProgressSet );
        this.$scope.$on( 'appProgress-increment', this.onAppProgressIncrement );
        this.$scope.$on( 'appProgress-reset', this.onAppProgressReset );
    }

    onAppProgressSet( event, args ) {
        this.$scope.percentageRecorded = args.percentage;
        if (args.hasOwnProperty('maxIncrementAmount')){
            this.$scope.maxIncrementAmount = args.maxIncrementAmount;
        }
        updateDisplay();
    }

    onAppProgressIncrement( event, args ) {
        this.$scope.percentageRecorded += args.percentage;
        if (args.hasOwnProperty('maxIncrementAmount')){
            this.$scope.maxIncrementAmount = args.maxIncrementAmount;
        }
        updateDisplay();
    }

    onAppProgressReset() {
        this._reset();
    }

    _updateDisplay() {
        this._checkForComplete();
        	
        if (this.$scope.percentageRecord > 100) { this.$scope.percentageRecord = 100;}
        
        let delta = (this.$scope.percentageRecorded - this.$scope.percentageDisplayed > this.$scope.maxIncrementAmount 
                        ? this.$scope.maxIncrementAmount 
                        : this.$scope.percentageRecorded - this.$scope.percentageDisplayed);
        
        const self = this;
        if (delta != 0){
            this.$timeout(function(){ 
                self.$scope.percentageDisplayed += delta;
                console.log(self.$scope.percentageDisplayed);
                self._updateDisplay();
            }, 50);
        }
    }

    _checkForComplete() {
        const self = this;
        if (this.$scope.percentageDisplayed >= 100){
            this.$timeout(function(){ 
                self._reset();
            }, 1000);
        }
    }

    _reset() {
        this.$scope.percentageRecorded = 0;
        this.$scope.percentageDisplayed = 0;
    }
}

const progressBar = {
    template: progressBarTemplate,
    controller: ProgressBarController
};

Core.component( 'progressBar', progressBar );
