// Local dependencies.
import Core from '../../modules/core/core.module';
import WSChannelBaseModel from '../../models/core/ws-channel-base.model';

/**
 * @name WSAccount
 * @memberof CoreBundle.Core
 * @class
 * 
 * @classdesc
 * AngularJS provider that manages and maintains WebSocket interaction with the
 * FileUploadNotification service's "account" channel using socket.io.
 */
class WSAccount extends WSChannelBaseModel {
    constructor( GLOBALS, WS_CONSTANTS, WS_EVENTS ) {
        'ngInject';
        super( 'account', GLOBALS, WS_CONSTANTS, WS_EVENTS );

        this.hasResumableLPOrders = false;
        this.hasResumableDAOrders = false;
        this.hasResumableOrders = false;
        this.hasSavedSearches = false;

        this.eventSubscriptions.onResumableOrders = [];
        this.eventSubscriptions.onSavedSearches = [];
    }

    /**
     * Channel-specific socket event handler fired when the FileUploadNotification's
     * "account" channel has returned a list of incomplete, resumable orders.
     * 
     * @param {array} payloadArr Array of existing resumable orders.
     */
    _handleResumableOrders( payloadArr ) {
        this._log( '_handleResumableOrders response.', payloadArr );

        payloadArr = getTypeCheckedValue( payloadArr, 'array', [] );
        this.hasResumableOrders = ( payloadArr.length > 0 );
        this.hasResumableDAOrders = ( payloadArr.filter( o => o.orderType === 1 ).length > 0 );
        this.hasResumableLPOrders = ( payloadArr.filter( o => o.orderType === 3 ).length > 0 );
        this._handleSubscription( 'onResumableOrders', payloadArr );
    }

    /**
     * Channel-specific socket event handler fired when the FileUploadNotification's
     * "account" channel has returned a list of saved search identifiers.
     * 
     * @param {array} payloadArr Array of existing resumable orders.
     */
    _handleSavedSearches( payloadArr ) {
        this._log( '_handleSavedSearches response.', payloadArr );

        payloadArr = getTypeCheckedValue( payloadArr, 'array', [] );
        this.hasSavedSearches = ( payloadArr.length > 0 );
        this._handleSubscription( 'onSavedSearches', payloadArr );
    }

    /**
     * Notifies the FileUploadNotification's "account" channel that it
     * should send down a current list of incomplete and resumable orders
     * for the specified product code.
     * 
     * @param {string} product Product code to retrieve; should always be
     * "mm" for now. In the future, the response payload will include the
     * product identifier should a product implement resumable orders.
     */
    _getResumableOrders( product = 'mm', orderType = 'list-purchase,data-append' ) {
        this._log( `_getResumableOrders called for product "${product}" and ordertype(s) "${orderType}".` );

        this.socket.emit( this.WS_EVENTS.Account.Publish.GetResumableOrders, {
            product, orderType
        });
    }

    /**
     * Notifies the FileUploadNotification's "account" channel that it
     * should send down a current list of saved searches for the specified
     * product code and account identifier.
     * 
     * @param {number} accountId Unique identifier of the account associated
     * with the user's saved searches.
     * @param {string} product Product code to retrieve; should always be
     * "mm" for now. In the future, the response payload will include the
     * product identifier should a product implement saved searches.
     */
    _getSavedSearches( accountId, product = 'mm' ) {
        this._log( `_getSavedSearches called for account ${accountId} and product "${product}".` );

        this.socket.emit( this.WS_EVENTS.Account.Publish.GetSavedSearches, {
            product,
            accountId
        });
    }

    /**
     * An overload of the required method that exposes the provider to Angular,
     * consumed by the WSChannelBase parent class that provides the actual
     * $get exposure method... because JavaScript doesn't have a standard
     * means of handling overloads.
     */
    _get() {
        return {

            /**
             * Notifies the FileUploadNotification's "account" channel that it
             * should send down a current list of incomplete and resumable orders
             * for the specified product code.
             * 
             * @param {string} product Product code to retrieve; should always be
             * "mm" for now. In the future, the response payload will include the
             * product identifier should a product implement resumable orders.
             */
            getResumableOrders: ( product = 'mm', orderType='list-purchase,data-append' ) =>
                this._getResumableOrders( product, orderType ),

            /**
             * Notifies the FileUploadNotification's "account" channel that it
             * should send down a current list of saved searches for the specified
             * product code and account identifier.
             * 
             * @param {number} accountId Unique identifier of the account associated
             * with the user's saved searches.
             * @param {string} product Product code to retrieve; should always be
             * "mm" for now. In the future, the response payload will include the
             * product identifier should a product implement saved searches.
             */
            getSavedSearches: ( accountId, product = 'mm' ) =>
                this._getSavedSearches( accountId, product ),

            /**
             * Getter for the hasResumeableDAOrders property
             */
            hasResumableDAOrders: () => { 
                return this.hasResumableDAOrders 
            },

            /**
             * Getter for the hasResumeableLPOrders property
             */
            hasResumableLPOrders: () => { 
                return this.hasResumableLPOrders
            }
        };
    }
}

Core.provider( 'wsAccount', WSAccount );