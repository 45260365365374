/**
 * The base role names required for the ACL to function correctly. All other
 * roles must use one of these as a base for inheritance due to the need for
 * A&R portal to support more than one product, and - by extension - each
 * product's roles. This gives all product roles a common base from which to
 * extend, and for the core product-agnostic module to bootstrap the overall
 * ACL service.
 */
export const ROLE_GUEST = 'ROLE_GUEST';
export const ROLE_USER = 'ROLE_USER';

/**
 * ProspectBase-specific role names for ACL functionality within components,
 * services, and views of the "pb" namespace.
 */
export const MKTPBASE_GUEST = 'MKTPBASE_GUEST';
const MKTPBASE_AGENCY_USER = 'MKTPBASE_AGENCY_USER';
export const MKTPBASE_AGENCY_ADMIN = 'MKTPBASE_AGENCY_ADMIN';

/**
 * Market Magnifier-specific role names for ACL functionality within components,
 * services, and views of the "mm" namespace.
 */
const MMCONSUMER_USER = 'MMCONSUMER_USER';
const MMCONSUMER_SUPERVISOR = 'MMCONSUMER_SUPERVISOR';

/**
 * Product-agnostic role names for ACL functionality within components, services,
 * and views of all namespaces. This includes the legacy sales and support roles,
 * as well as the new sales and support roles in both plain and MBSi-prefixed forms.
 * 
 * Prefix Key
 * ----------
 * MKTPBASE:    Legacy
 * AR:          New (Plain Form)
 * ACQANDRET:   New (MBSi Form)
 */
const MKTPBASE_SALES = 'MKTPBASE_SALES';
const AR_SALES = 'AR_SALES';
const ACQANDRET_AR_SALES = 'ACQANDRET_AR_SALES';
const MKTPBASE_SUPER_ADMIN = 'MKTPBASE_SUPER_ADMIN';
const AR_CUSTOMER_SUPPORT_ROLE = 'AR_CUSTOMER_SUPPORT_ROLE';
const ACQANDRET_AR_CUSTOMER_SUPPORT_ROLE = 'ACQANDRET_AR_CUSTOMER_SUPPORT_ROLE';

/**
 * Defines a collection of roles that are considered to be internal sales roles.
 */
const INTERNAL_SALES = [
    MKTPBASE_SALES,
    AR_SALES,
    ACQANDRET_AR_SALES
];

/**
 * Defines a collection of roles that are considered to be internal admin roles.
 */
const INTERNAL_ADMIN = [
    MKTPBASE_SUPER_ADMIN,
    AR_CUSTOMER_SUPPORT_ROLE,
    ACQANDRET_AR_CUSTOMER_SUPPORT_ROLE
];

/**
 * Defines core role inheritance structure.
 */
export const CORE_ROLE_INHERITANCE = {
    [ROLE_USER]: [ ROLE_USER ],
    [ROLE_GUEST]: [ ROLE_GUEST ]
};

/**
 * Defines ProspectBase role inheritance structure.
 */
export const PB_ROLE_INHERITANCE = {
    [MKTPBASE_GUEST]: [ ROLE_GUEST ],
    [MKTPBASE_AGENCY_USER]: [ ROLE_USER ],
    [MKTPBASE_AGENCY_ADMIN]: [ MKTPBASE_AGENCY_USER ],
    [MKTPBASE_SALES]: [ ROLE_USER ],
    [MKTPBASE_SUPER_ADMIN]: [MKTPBASE_SALES],
    [AR_SALES]: [ MKTPBASE_SALES ],
    [ACQANDRET_AR_SALES]: [ AR_SALES ],
    [AR_CUSTOMER_SUPPORT_ROLE]: [ AR_SALES ],
    [ACQANDRET_AR_CUSTOMER_SUPPORT_ROLE]: [ AR_SALES ]
};

/**
 * Defines Market Magnifier role inheritance structure.
 */
export const MM_ROLE_INHERITANCE = {
    [MMCONSUMER_USER]: [ ROLE_USER ],
    [MMCONSUMER_SUPERVISOR]: [ MMCONSUMER_USER ],
    [AR_SALES]: [ ROLE_USER ],
    [ACQANDRET_AR_SALES]: [ AR_SALES ],
    [AR_CUSTOMER_SUPPORT_ROLE]: [ AR_SALES ],
    [ACQANDRET_AR_CUSTOMER_SUPPORT_ROLE]: [ AR_SALES ]
};

/**
 * Determines whether the provided role is recognized as belonging to
 * the provided array of roles. This is the underlying method called
 * by isSalesRole and isAdminRole.
 * 
 * @param {String} role The role name to check.
 * @param {Array} roles The array of role names to check against.
 * @returns {Boolean} Whether the provided role is found in the provided array.
 */
function _isRole( role, roles ) {
    let isRole = false;

    if ( typeof role === 'string' && role.length > 0 &&
        Array.isArray( roles ) && roles.length > 0 ) {
        role = role.toUpperCase();
        roles = roles
            .filter( r => typeof r === 'string' && r.length > 0 )
            .map( r => r.toUpperCase() );

        isRole = ( roles.includes( role ));
    }

    return isRole;
}

/**
 * Determines whether the provided role is recognized as an internal sales
 * role, and matches the legacy, new (plain), or new (MBSi) sales roles.
 * 
 * @param {String} role The role name to check.
 * @returns {Boolean} Whether the provided role is an internal sales role.
 */
export function isSalesRole( role ) {
    return _isRole( role, INTERNAL_SALES );
}

/**
 * Determines whether the provided role is recognized as an internal admin
 * role, and matches the legacy, new (plain), or new (MBSi) admin roles.
 * 
 * @param {String} role The role name to check.
 * @returns {Boolean} Whether the provided role is an internal admin role.
 */
export function isAdminRole( role ) {
    return _isRole( role, INTERNAL_ADMIN );
}

/**
 * Determines whether the provided role is recognized as an internal role,
 * and matches the legacy, new (plain), or new (MBSi) sales/admin roles.
 * 
 * @param {String} role The role name to check.
 * @returns {Boolean} Whether the provided role is an internal sales/admin role.
 */
export function isInternalRole( role ) {
    return ( _isRole( role, INTERNAL_SALES ) ||
        _isRole( role, INTERNAL_ADMIN ));
}