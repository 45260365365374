// Local dependencies.
import Core from '../../modules/core/core.module';
import WSChannelBaseModel from '../../models/core/ws-channel-base.model';

/**
 * @name WSNotification
 * @memberof CoreBundle.Core
 * @class
 * 
 * @classdesc
 * AngularJS provider that manages and maintains WebSocket interaction with the
 * FileUploadNotification service's "notification" channel using socket.io.
 */
class WSNotification extends WSChannelBaseModel {
    constructor( GLOBALS, WS_CONSTANTS, WS_EVENTS ) {
        'ngInject';
        super( 'notification', GLOBALS, WS_CONSTANTS, WS_EVENTS );

        this.canGetMore = false;
        this.offset = 0;
        this.limit = 5;

        // File upload-specific callback registrations.
        this.eventSubscriptions.onListReceived = [];
        this.eventSubscriptions.onAcknowledgementComplete = [];
        this.eventSubscriptions.onSeenComplete = [];
        this.eventSubscriptions.onNotification = [];
    }

    /**
     * When the channel has connected, request a list of unseen
     * notifications from the FileUploadNotifications service.
     */
    _onChannelConnect() {
        this.socket.emit( this.WS_EVENTS.Notification.Publish.GetList );
    }

    /**
     * Channel-specific socket event handler fired when the
     * FileUploadNotification's "notification" channel has
     * returned a list of unseen notifications.
     * 
     * @param {object} payloadObj Object of count and notifications array.
     */
    _handleList( payloadObj ) {
        this._log( '_handleList response.', payloadObj );
        payloadObj = getTypeCheckedValue( payloadObj, 'object', {} );
        let count = getTypeCheckedValue( payloadObj.count, 'int', 0 ),
            notificationsStr = getTypeCheckedValue( payloadObj.notifications, 'string', '[]' ),
            notifications = JSON.parse( notificationsStr );

        if ( notifications.length >= this.limit ) {
            this.canGetMore = true;
            this.offset += this.limit;
        }

        this._handleSubscription( 'onListReceived', { count, notifications });
    }

    /**
     * Channel-specific socket event handler fired when the
     * FileUploadNotification's "notification" channel has
     * returned a single live notification.
     * 
     * @param {object} payloadObj Object containing a single notification's attributes.
     */
    _handleNotification( payloadObj ) {
        this._log( '_handleNotification response.', payloadObj );

        payloadObj = getTypeCheckedValue( payloadObj, 'object', null );
        if ( payloadObj !== null ) {
            this._handleSubscription( 'onNotification', payloadObj );
        }
    }

    /**
     * Channel-specific socket event handler fired when the
     * FileUploadNotification's "notification" channel has
     * returned confirmation that a notification acknowledgement
     * was handled on the service-side.
     */
    _handleAcknowledgeComplete() {
        this._log( '_handleAcknowledgeComplete response.' );
        this._handleSubscription( 'onAcknowledgementComplete' );
    }

    /**
     * Channel-specific socket event handler fired when the
     * FileUploadNotification's "notification" channel has
     * returned confirmation that one or more notifications
     * have been set as "seen" on the service-side, returning
     * the specific userNotificationIds as additional confirmation.
     * 
     * @param {array} payloadArr Array of processed userNotificationIds.
     */
    _handleSeenComplete( payloadArr ) {
        this._log( '_handleSeenComplete response.', payloadArr );

        payloadArr = getTypeCheckedValue( payloadArr, 'array', [] );
        this._handleSubscription( 'onSeenComplete', payloadArr );
    }
    
    /**
     * Sends one or more userNotificationIds to the FileUploadNotification's
     * "notification" channel to be marked as "seen" in the database.
     * 
     * @param {array|number} userNotificationIds One or more userNotificationIds to set as seen.
     */
    _setNotificationsSeen( userNotificationIds ) {
        if ( Array.isArray( userNotificationIds )) {
            userNotificationIds = userNotificationIds
                .map( unid => getTypeCheckedValue( unid, 'int', null ))
                .filter( unid => unid !== null );
        } else {
            userNotificationIds = getTypeCheckedValue( userNotificationIds, 'int', null );
            userNotificationIds = ( userNotificationIds !== null )
                ? [ userNotificationIds ]
                : [];
        }

        console.log( 'Setting to seen: ', userNotificationIds );

        if ( userNotificationIds.length > 0 ) {
            this.socket.emit( this.WS_EVENTS.Notification.Publish.Seen, { userNotificationIds });
        }
    }

    /**
     * Sends a userNotificationId to the FileUploadNotification's
     * "notification" channel to be marked as "acknowledged" in the
     * database.
     * 
     * @param {number} userNotificationId userNotificationId to set as acknowledged.
     */
    _setNotificationAcknowledged( userNotificationId ) {
        userNotificationId = getTypeCheckedValue( userNotificationId, 'int', null );
        if ( userNotificationId !== null ) {
            this.socket.emit( this.WS_EVENTS.Notification.Publish.Acknowledge, { userNotificationId });
        }
    }

    /**
     * Sends a request to the FileUploadNotification's "notification"
     * channel for unseen user notifications, passing in limit and offset.
     */
    _getUnseenNotifications() {
        if ( this.canGetMore ) {
            console.log( 'wsNotification getting more' );
            this.canGetMore = false;
            this.socket.emit( this.WS_EVENTS.Notification.Publish.GetList, {
                limit: this.limit,
                offset: this.offset
            });
        }
    }

    /**
     * Returns an object when called by the WSChannelBaseModel parent's
     * $get method, which is the primary entry point to expose the
     * provider's properties and methods to Angular.
     */
    _get() {
        return {
            /**
             * Sends a userNotificationId to the FileUploadNotification's
             * "notification" channel to be marked as "seen" in the
             * database.
             * 
             * @param {number} userNotificationId userNotificationId to set as acknowledged.
             */
            notificationSeen: ( userNotificationId ) =>
                this._setNotificationsSeen( userNotificationId ),

            /**
             * Sends an array of userNotificationIds to the FileUploadNotification's
             * "notification" channel to be marked as "seen" in the
             * database.
             * 
             * @param {array} userNotificationIds userNotificationIds to set as acknowledged.
             */
            notificationsSeen: ( userNotificationIds ) =>
                this._setNotificationsSeen( userNotificationIds ),

            /**
             * Sends a userNotificationId to the FileUploadNotification's
             * "notification" channel to be marked as "acknowledged" in the
             * database.
             * 
             * @param {number} userNotificationId userNotificationId to set as acknowledged.
             */
            acknowledgeNotification: ( userNotificationId ) =>
                this._setNotificationAcknowledged( userNotificationId ),

            /**
             * Sends a request to the FileUploadNotification's "notification"
             * channel for unseen user notifications, passing in limit and offset.
             */
            getUnseenNotifications: () => this._getUnseenNotifications()
        };
    }
}

Core.provider( 'wsNotification', WSNotification );
