// Library dependencies.
import get from 'lodash/get';

// Local dependencies.
import { NOTIFICATION_DELAY } from '../../constants/core/variables';
import APIResponseModel from './api-response.model';

const ICON_MAP = {
    'invalid': 'fa-exclamation-triangle',
    'error': 'fa-exclamation-triangle',
    'unauth': 'fa-exclamation-triangle',
    'file-mapped': 'fa-check-circle'
};

/**
 * A notification type with a display affordance of "muted" (semi-transparent gray).
 */
const NOTIFICATION_TYPE_MUTED = 'muted';

/**
 * A notification type with a display affordance of "primary" (red).
 */
const NOTIFICATION_TYPE_PRIMARY = 'primary';

/**
 * A notification type with a display affordance of "success" (green).
 */
const NOTIFICATION_TYPE_SUCCESS = 'success';

/**
 * A notification type with a display affordance of "info" (blue).
 */
const NOTIFICATION_TYPE_INFO = 'info';

/**
 * A notification type with a display affordance of "warning" (yellow).
 */
const NOTIFICATION_TYPE_WARNING = 'warning';

/**
 * A notification type with a display affordance of "danger" (red).
 */
const NOTIFICATION_TYPE_DANGER = 'danger';

/**
 * Defines a collection of recognized notification types, which determines
 * the display affordance of a notification through color (e.g., "success"
 * will force elements to appear green, "warning" yellow, "danger" red).
 */
const NOTIFICATION_TYPES = [
    NOTIFICATION_TYPE_MUTED,
    NOTIFICATION_TYPE_PRIMARY,
    NOTIFICATION_TYPE_SUCCESS,
    NOTIFICATION_TYPE_INFO,
    NOTIFICATION_TYPE_WARNING,
    NOTIFICATION_TYPE_DANGER
];

/**
 * Defines a mapping of database notification type keys to the underlying
 * supported visual affordance.
 */
const NOTIFICATION_TYPES_MAPPING = {
    'success': NOTIFICATION_TYPE_SUCCESS,
    'warning': NOTIFICATION_TYPE_WARNING,
    'error': NOTIFICATION_TYPE_DANGER
};

/**
 * Defines a collection of recognized persistence types, which determines
 * not only whether a notification is persistent, but also additional
 * display handling in the UI. The default is "display", which will
 * simply make a notification persistent within the utility-nav (NOT toast).
 * Progress will show a progress bar element in the persistent notification
 * within the utility-nav.
 */
const PERSISTENCE_TYPES = [
    'display',
    'progress'
];

/**
 * A special action that forces the display of the contact-support modal link.
 */
const SPECIAL_ACTION_CONTACT_SUPPORT = 'contact-support';

/**
 * Defines a collection of special actions that override the typical ui-router
 * names expected of a parsed actionUrl. Special actions should by purely
 * hyphen-delimited strings, so as to visually distinguish them from the
 * dot-notated keys used by ui-router.
 */
const SPECIAL_ACTIONS = [
    SPECIAL_ACTION_CONTACT_SUPPORT
];

/**
 * @name NotificationModel
 * @memberof CoreBundle.Core
 * @class
 * 
 * @classdesc
 * POJO.
 */
class NotificationModel {
    constructor( faIcon, notificationType, titleKey, titleText, titleParams,
        summaryKey, summaryText, summaryParams, detailKey, detailText,
        detailParams, actionUrl, actionKey, actionText, persistenceType, 
        persistenceValue, isViewable = true, isSupport = false, delay = NOTIFICATION_DELAY,
        isSetAllAnnouncementsSeen = false ) {
        
        // All notification displays require a FontAwesome icon.
        this.faIcon = getTypeCheckedValue( faIcon, 'string', 'fa-info-circle' );

        // Notification type determines the accent color.
        this.notificationType = getTypeCheckedValue( notificationType, 'string', 'primary' );
        if ( !NOTIFICATION_TYPES.includes( this.notificationType )) {
            this.notificationType = 'primary';
        }

        // The i18n key used for title. Not required; falls back on titleText.
        this.titleKey = getTypeCheckedValue( titleKey, 'string', null );

        // The plain text display used for title. Not required; falls back on
        // displaying an empty string if both the title key and title string
        // are empty.
        this.titleText = getTypeCheckedValue( titleText, 'string', '' );

        // If present, the JSON object used for translation interpolation of the title.
        this.titleParams = getTypeCheckedValue( titleParams, 'object', null );

        // The i18n key used for the first line of message text. Not required; falls
        // back on summaryText.
        this.summaryKey = getTypeCheckedValue( summaryKey, 'string', null );

        // The plain text display for the first line of message text. Not required;
        // falls back on displaying an empty string if both the summary key and summary
        // string are empty.
        this.summaryText = getTypeCheckedValue( summaryText, 'string', '' );

        // If present, the JSON object used for translation interpolation.
        this.summaryParams = getTypeCheckedValue( summaryParams, 'object', {} );

        // The i18n key used for the second line of message text. Not required; falls
        // back on detail text.
        this.detailKey = getTypeCheckedValue( detailKey, 'string', null );

        // The plain text used for the second line of message text. Not required; not
        // all notifications have a need for two lines of message text.
        this.detailText = getTypeCheckedValue( detailText, 'string', null );

        // If present, the JSON object used for translation interpolation.
        this.detailParams = getTypeCheckedValue( detailParams, 'object', {} );

        // Whether this notification is registered in the DB, and thus have a
        // need to update its viewed field.
        this.isViewable = isViewable;

        // Whether this notification should display a link to launch the
        // support modal.
        this.isSupport = isSupport;

        // Whether this notification should display a link to mark all
        // unseen announcements as seen.
        this.isSetAllAnnouncementsSeen = isSetAllAnnouncementsSeen;

        // If the notification has a clickable redirect, it must have two of three
        // properties: action URL (required), and either the i18n key for the link
        // text OR the plain link text. The link WILL NOT RENDER otherwise.
        this.showActionLink = false;
        this.actionUrl = null;
        this.actionKey = null;
        this.actionText = null;
        this._parseActionUrl( actionUrl, actionKey, actionText );

        // Persistence types are only useful for the display of a persistent
        // notification; for example: file upload progress. These properties are
        // not used by the fixed-position toast-style notifications that appear
        // on-the-fly. They're only used by the persistent notification component,
        // and in limited cases the notification tray component.
        this.hasPersistenceInfo = false;
        this.persistenceType = getTypeCheckedValue( persistenceType, 'string', null );
        this.persistenceValue = ( persistenceValue !== undefined && persistenceValue !== null )
            ? persistenceValue : null;
        if ( this.persistenceType !== null && PERSISTENCE_TYPES.includes( this.persistenceType )) {
            this.hasPersistenceInfo = true;
        }        

        // The delay in milliseconds for how long the notification should appear
        // on-screen; default is 6 seconds. Setting it to false will disable
        // the auto-close.
        this.delay = getTypeCheckedValue( delay, 'boolean', NOTIFICATION_DELAY );
        if ( this.delay === true ) {
            this.delay = NOTIFICATION_DELAY;
        }

        // Automatically generate a timestamp for this notification's creation,
        // for purposes of displaying text like "10 minutes ago". This will be
        // overridden by socket service response parsing.
        this.createTime = new Date().getTime();

        // These are additional properties only set directly via socket service
        // response parsing.
        this.userNotificationId = null;
        this.isViewed = false;
        this.isAck = false;
        this.ackDate = null;
    }

    /**
     * Used for resetting the "persistence" of a ProspectBase account sync
     * notification, which displays as a persistent toast notification on
     * a non-user management screen. If the user entered through the user
     * management screen, the action link should be disabled and the
     * notification itself should disappear after the global delay time
     * has passed. This will only be called if the action URL is the
     * same as the current state name.
     * 
     * @returns {void}
     */
    resetProspectBasePersistence() {
        this.showActionLink = false;
        this.actionUrl = null;
        this.delay = NOTIFICATION_DELAY;
    }

    /**
     * Parses the actionUrl properties as they're passed into the constructor,
     * validates their types, and sets instance properties based on the actionUrl
     * itself for different scenarios.
     * 
     * @param {String} actionUrl The target destination of a notification action click.
     * @param {String} actionKey The i18n key of the notification action link.
     * @param {String} actionText The plain-language text of the notification action link.
     */
    _parseActionUrl( actionUrl, actionKey, actionText ) {
        actionUrl = getTypeCheckedValue( actionUrl, 'string', null );
        actionKey = getTypeCheckedValue( actionKey, 'string', null );
        actionText = getTypeCheckedValue( actionText, 'string', null );

        if ( actionUrl === null ) return;

        // Normal use case.
        if ( !SPECIAL_ACTIONS.includes( actionUrl )) {
            if ( actionKey !== null || actionText !== null ) {
                this.showActionLink = true;
                this.actionUrl = actionUrl;
                this.actionKey = actionKey;
                this.actionText = actionText;
            }

            return;
        }

        // Special actions.
        switch ( actionUrl ) {
            case SPECIAL_ACTION_CONTACT_SUPPORT:
                this.isSupport = true;
                break;
            default:
                break;
        }
    }

    /**
     * Attempts to determine a FontAwesome icon based on the provided i18n
     * title key. If one can't be determined, falls back to 'fa-info-circle'.
     * 
     * @param {string} titleKey The i18n title key for the notification.
     * @return {string} A FontAwesome icon class name.
     */
    static getIconFromTitleKey( titleKey ) {
        let icon = null;

        if ( typeof titleKey === 'string' && titleKey.length > 0 ) {
            Object.keys( ICON_MAP ).forEach( mapKey => {
                if ( icon === null && titleKey.indexOf( mapKey ) !== -1 ) {
                    icon = ICON_MAP[ mapKey ];
                    return;
                }
            });
        }

        if ( icon === null ) {
            icon = 'fa-info-circle';
        }

        return icon;
    }

    /**
     * Attempts to determine the visual notification type based on the provided
     * key. If the key is found in the NOTIFICATION_TYPES array, it can be used
     * as-is. Otherwise, if it's found in the mapping, the mapping value will
     * be used. If both of those fails, defaults to "info".
     * 
     * @param {String} notificationTypeKey The notification type string to parse.
     * @returns {String} The notification's visual type.
     */
    static getNotificationTypeFromKey( notificationTypeKey ) {
        let notificationType = NOTIFICATION_TYPE_INFO;

        if ( typeof notificationTypeKey === 'string' && notificationTypeKey.length > 0 ) {
            if ( NOTIFICATION_TYPES.includes( notificationTypeKey )) {
                notificationType = notificationTypeKey;
            } else if ( Object.keys( NOTIFICATION_TYPES_MAPPING ).includes( notificationTypeKey )) {
                notificationType = NOTIFICATION_TYPES_MAPPING[ notificationTypeKey ];
            }
        }

        return notificationType;
    }

    /**
     * Retrieves a single instance of a notification model from an API
     * response model as payload.
     * 
     * @param {Object} responseModel The API response model to parse.
     * @return {NotificationModel|null} An instance of NotificationModel if valid,
     * otherwise null
     */
    static fromApiResponseModel( responseModel ) {
        if ( responseModel instanceof APIResponseModel ) {
            const faIcon = NotificationModel.getIconFromTitleKey( responseModel.titleKey );
            const notificationType = responseModel.severityKey;
            const titleKey = responseModel.titleKey;
            const titleText = null;
            const titleParams = null;
            const summaryKey = responseModel.messageKey;
            const summaryText = null;
            const summaryParams = null;
            const detailKey = 'core.notification.support-error-code-format';
            const detailText = null;
            const detailParams = { err: responseModel.statusMessage.split( '.' ).pop() };
            const actionUrl = null;
            const actionKey = null;
            const actionText = null;
            const persistenceType = null;
            const persistenceValue = null;
            const isViewable = false; // This isn't registered in the DB.
            const isSupport = true; // If it's based on an error, we're showing the support link.

            return new NotificationModel(
                faIcon,
                notificationType,
                titleKey,
                titleText,
                titleParams,
                summaryKey,
                summaryText,
                summaryParams,
                detailKey,
                detailText,
                detailParams,
                actionUrl,
                actionKey,
                actionText,
                persistenceType,
                persistenceValue,
                isViewable,
                isSupport,
                false
            );
        }
    }

    /**
     * Retrieves a single instance of a notification model from a socket
     * service notification payload.
     * 
     * @param {Object} payload The socket service notification payload to parse.
     * @return {NotificationModel|null} An instance of NotificationModel if valid,
     * otherwise null.
     */
    static fromSocketServiceObject( payload ) {

        let notificationObj = getTypeCheckedValue( payload, 'object', null );
        if ( notificationObj === null ) {
            return null;
        }

        let faIcon = NotificationModel.getIconFromTitleKey( notificationObj.titleKey ),
            notificationType = NotificationModel.getNotificationTypeFromKey( notificationObj.notificationType ),
            titleKey = notificationObj.titleKey || null,
            titleText = notificationObj.title || null,
            titleParams = notificationObj.titleParams || null,
            summaryKey = notificationObj.messageKey || null,
            summaryText = notificationObj.message || null,
            summaryParams = notificationObj.messageParams || null,
            detailKey = null,
            detailText = null,
            detailParams = null,
            actionUrl = notificationObj.actionUrl || null,
            actionKey = 'ui.core.generic.continue',
            actionText = null,
            persistenceType = null,
            persistenceValue = null;

        if ( titleParams !== null || summaryParams !== null || detailParams !== null ) {
            if ( titleParams !== null ) titleParams = tryParseJson( titleParams );
            //if ( summaryParams !== null ) summaryParams = tryParseJson( summaryParams );
            if ( detailParams !== null ) detailParams = tryParseJson( detailParams );
        }

        let notification = new NotificationModel(
            faIcon,
            notificationType,
            titleKey,
            titleText,
            titleParams,
            summaryKey,
            summaryText,
            summaryParams,
            detailKey,
            detailText,
            detailParams,
            actionUrl,
            actionKey,
            actionText,
            persistenceType,
            persistenceValue
        );

        // The following are required for socket-specific
        // notifications. If any are missing, this is an
        // invalid notification (as it's going to be used
        // in the notification tray, and they'll be needed
        // for that functionality).
        const userNotificationId = parseInt( payload.userNotificationId ) || null;
        const createTime = ( payload.hasOwnProperty( 'insertTime' ) && payload.insertTime !== null )
            ? ( payload.insertTime instanceof Date ? payload.insertTime : new Date( payload.insertTime ))
            : null;

        if ( userNotificationId === null || createTime === null ) {
            return null;
        }

        // Set socket notification-specific properties.
        notification.userNotificationId = userNotificationId;
        notification.createTime = createTime;
        notification.isViewed = ( payload.isViewed && payload.isViewed );
        notification.isAck = ( payload.isAck && payload.isAck );
        notification.ackDate = ( payload.hasOwnProperty( 'ackDate' ) && payload.ackDate !== null )
            ? ( payload.ackDate instanceof Date ? payload.ackDate : new Date( payload.ackDate ))
            : null;

        return notification;

        /**
         * Attempts to parse the provided params JSON string into a valid
         * JSON object. Otherwise returns null.
         * 
         * @param {String} jsonStr The stringified JSON to parse.
         * @returns {Object|null} The parsed JSON; otherwise, null.
         */
        function tryParseJson( jsonStr ) {
            let json = null;
            try {
                json = JSON.parse( jsonStr );
            } catch ( err ) {
                console.warn( 'Invalid or malformed JSON.' );
                json = null;
            } finally {
                return json;
            }
        }
    }

    /**
     * Retrieves an array of zero or more notification models from a
     * socket service notification payload.
     * 
     * @param {Array} payload The socket service notification payload to parse.
     * @return {Array} Zero or more NotificationModel objects.
     */
    static fromSocketServiceArray( payload ) {
        if ( !Array.isArray( payload )) {
            return [];
        }

        return payload
            .map( pi => NotificationModel.fromSocketServiceObject( pi ))
            .filter( pi => pi !== null );
    }

    /**
     * Returns an instance of NotificationModel using a properties object
     * containing one or more named properties in order to streamline
     * construction.
     * 
     * @param {object} propsObj 
     */
    static fromPropsObj( propsObj ) {
        propsObj = getTypeCheckedValue( propsObj, 'object', {} );

        return new NotificationModel(
            get( propsObj, 'faIcon' ) || null,
            get( propsObj, 'notificationType' ) || null,
            get( propsObj, 'titleKey' ) || null,
            get( propsObj, 'titleText' ) || null,
            get( propsObj, 'titleParams' ) || null,
            get( propsObj, 'summaryKey' ) || null,
            get( propsObj, 'summaryText' ) || null,
            get( propsObj, 'summaryParams' ) || null,
            get( propsObj, 'detailKey' ) || null,
            get( propsObj, 'detailText' ) || null,
            get( propsObj, 'detailParams' ) || null,
            get( propsObj, 'actionUrl' ) || null,
            get( propsObj, 'actionKey' ) || null,
            get( propsObj, 'actionText' ) || null,
            get( propsObj, 'persistenceType' ) || null,
            get( propsObj, 'persistenceValue' ) || null,
            get( propsObj, 'isViewable' ) || true,
            get( propsObj, 'isSupport' ) || false,
            get( propsObj, 'delay' ) || NOTIFICATION_DELAY
        );
    }

    /**
     * A helper method to determine whether the provided object is an instance of
     * NotificationModel. Useful when passing notifications around $rootScope
     * because AngularJS seems to do object assignment, which destroys ES6 class
     * signatures. As we still need to ensure that notifications are really
     * notifications, we'll check object prototype signatures.
     * 
     * @param {Object} obj The object to check the instance of.
     * @returns {Boolean} Whether the provided object is an instance of NotificationModel.
     */
    static isInstanceOf( obj ) {
        if ( typeof obj === 'object' && obj !== null ) {
            let objPrototype = Object.getPrototypeOf( obj ),
                objKeys = Object.keys( objPrototype ).sort(),
                modelPrototype = NotificationModel.prototype,
                modelKeys = Object.keys( modelPrototype ).sort();

            return ( objPrototype === modelPrototype ||
                ( JSON.stringify( objKeys ) === JSON.stringify( modelKeys )) ||
                obj instanceof NotificationModel );
        }

        return false;
    }
}

export default NotificationModel;