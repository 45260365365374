import Core from '../../modules/core/core.module';

/**
 * @name Analytics
 * @memberof CoreBundle.Core
 * @class
 * 
 * @classdesc
 * AngularJS service.
 */
class AnalyticsService {
    constructor( $q, $translate, $timeout, Session) {
        this.$q = $q;
        this.$translate = $translate;
        this.$timeout = $timeout;
        this.Session = Session;
    }
    
    create( page ) {

        let self = this;
        self.page = page;

        this.$translate([`global.products.${this.Session.defaultApp}`]).then( function( trans ) {
           
            // Get the application name, if available
            let applicationName = "Default";
            if ( trans[ `global.products.${self.Session.defaultApp}` ] !== undefined )
            {
                applicationName = trans[ `global.products.${self.Session.defaultApp}` ];
            } 

            var data = [];

            data.current_time = (new Date()).toUTCString().replace(/^\w+, /, "").replace(/GMT$/, "+0000");
        
            if (page === undefined)
            {
                page = "UNKNOWN";
            }

            // Parse the user's account info, if we have it.
            data.site_hierarchy = self.page.split("/");
            //this.layout_type = "desktop";
            data.site_region = "US";
            data.user_ip = window.analytics.client_ip;
            self.sitecore_page_id = self.page;
            self.application_type = applicationName;
            data.site_events = {"virtual_page_view": "true"};

            // call Stratigent/AdobeDTM
            if ( typeof _trackAnalytics != "undefined" ) {
                
                try {
                    console.log( "Analytics :: Calling _trackAnalytics for " + page );
                    _trackAnalytics(data);
                } catch(e){
                    console.log(e);
                };

            } else {
                console.log( "Analytics :: _trackAnalytics undefined" );
            }

            
            // call a11yCAT after letting everything load
            self.$timeout( function() {
                if ( typeof window.a11yCAT.checkA11y != "undefined" ) {
                
                    try {
                        console.log( "a11yCAT :: Calling _checkAlly for A&R Portal :: " + self.application_type + " :: " + self.page );
                        window.a11yCAT.checkA11y("A&R Portal :: " + self.application_type, self.page);
                    } catch(e){
                        console.log(e);
                    };
    
                } else {
                    console.log( "a11yCAT :: window._checkAlly undefined" );
                }        
            }, 1000 );                
        });
    }

}

Core.service( 'analyticsService', AnalyticsService );
