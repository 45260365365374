/**
 * @name ResponseCodeSeverity
 * @memberof CoreBundle.Core
 * @constant
 * 
 * @description
 * A simple mapping of status codes to generic severity keys. This
 * is useful in determining how to display a response to the user
 * if necessary.
 */
const RESPONSE_CODE_SEVERITY = {
    200: 'success',
    201: 'success',
    400: 'warning',
    401: 'danger',
    500: 'danger'
};

/**
 * @name ResponseDefaults
 * @memberof CoreBundle.Core
 * @constant
 * 
 * @description
 * Defines the fallback response code and key in the absence of same in a
 * response object. These are used to construct the title and message i18n
 * keys - and severity indicators - when showing a notification.
 */
const RESPONSE_DEFAULTS = {
    CODE: 500,
    KEY: 'general-error'
};

/**
 * @name APIResponseModel
 * @memberof CoreBundle.Core
 * @class
 * 
 * @classdesc
 * A standardized model for erroneous or simple acknowledgement API
 * responses that will likely need to be shown to the user.
 */
class APIResponseModel {
    constructor( statusCode = RESPONSE_DEFAULTS.CODE, statusMessage = RESPONSE_DEFAULTS.KEY ) {
        this.statusCode = getTypeCheckedValue( statusCode, 'int', RESPONSE_DEFAULTS.CODE );
        this.statusMessage = getTypeCheckedValue( statusMessage, 'string', RESPONSE_DEFAULTS.KEY );
        this.severityKey = 'info';
        this.titleKey = null;
        this.messageKey = null;

        // Set a severity key based on the status code mapping.
        if ( this.statusCode !== null ) {
            if ( RESPONSE_CODE_SEVERITY[ this.statusCode ]) {
                this.severityKey = RESPONSE_CODE_SEVERITY[ this.statusCode ];
            }
        }

        // Check the statusMessage for dot notation, which tells us
        // whether it's a translate key. If it is, prepend it with
        // "response-code." to ensure that it lives in the right
        // spot in our i18n files.
        if ( this.statusMessage !== null ) {
            if ( this.statusMessage.match( /^[A-Za-z0-9\.\-]+$/ )) {
                this.titleKey = `core.notification.${statusMessage}-title`;
                this.messageKey = `core.notification.${statusMessage}-message`;
            }
        }
    }

    /**
     * Helper method to quickly create an instance of APIResponseModel based
     * on a failed or caught HTTP response object.
     */
    static fromHttpResponse( statusCode, statusMessage ) {
        statusCode = getTypeCheckedValue( statusCode, 'int', RESPONSE_DEFAULTS.CODE );
        statusMessage = getTypeCheckedValue( statusMessage, 'string', RESPONSE_DEFAULTS.KEY );

        return new APIResponseModel( statusCode, statusMessage );
    }
}

export default APIResponseModel;