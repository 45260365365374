import AccountOrderModel from './account-order.model';

/**
 * @name NotificationModel
 * @memberof CoreBundle.Core
 * @class
 * 
 * @classdesc
 * POJO.
 */
class AccountModel {
    constructor( account_name, account_num, active_order, is_active_account,
        is_session_account, node_id, pb_account_id, pb_user_account_id, primary_sales_person,
        user_account_is_active, error, orders ) {
        
        this.account_name = getTypeCheckedValue( account_name, 'string', null );
        this.account_num = getTypeCheckedValue( account_num, 'string', null );
        this.active_order = getTypeCheckedValue( active_order, 'object', null );
        this.is_active_account = getTypeCheckedValue( is_active_account, 'boolean', false );
        this.is_session_account = getTypeCheckedValue( is_session_account, 'boolean', false );
        this.node_id = getTypeCheckedValue( node_id, 'string', null );
        this.pb_account_id = getTypeCheckedValue( pb_account_id, 'int', null );
        this.pb_user_account_id = getTypeCheckedValue( pb_user_account_id, 'int', null );
        this.primary_sales_person = getTypeCheckedValue( primary_sales_person, 'string', null );
        this.user_account_is_active = getTypeCheckedValue( user_account_is_active, 'boolean', false );
        this.error = getTypeCheckedValue( error, 'string', null );
        this.orders = getTypeCheckedValue( orders, 'array', [] );
        this.isSystemAccount = ( this.account_num !== null &&
            [ 'ADMIN_ACCOUNT', 'SALES_ACCOUNT' ].includes( this.account_num ));

        this.isValid = this.isValidAccount();
    }

    /**
     * Fetches the ProspectBase account's MBSI suffix, but
     * as we only use account suffix in Market Magnifier
     * this should always return an empty string.
     * 
     * @returns {string}
     */
    getAccountSuffix() {
        return '';
    }

    /**
     * Whether this account:
     * - has an account name
     * - has an account num
     * - has a node ID or is a system account
     * - has a pb account id
     * - has a pb user account id
     * - has at least 1 order
     * - has at least 1 order that is valid
     * 
     * @returns {boolean}
     */
    isValidAccount() {
        console.debug( '[pb/account.model] :: isValid() :: Checking that product account is valid.' );
        let valid = false;

        // These are required.
        if (( this.node_id !== null || this.isSystemAccount ) &&
            this.account_name !== null &&
            this.account_num !== null &&
            this.pb_account_id !== null &&
            this.pb_user_account_id !== null &&
            this.orders.length >= 1 ) {
            
            // Fetch orders that are instances of AccountOrderModel.
            let orders = this.orders.filter( o => o instanceof AccountOrderModel );
            console.debug( `[pb/account.model] :: isValid() :: "${this.account_name}" has ${orders.length} orders.` );

            // There should be at least one.
            if ( orders.length > 0 ) {

                // Fetch orders that are valid.
                let validOrders = orders.filter( o => o.isValidOrder() );
                console.debug( `[pb/account.model] :: isValid() :: "${this.account_name}" has ${validOrders.length} valid orders.` );

                // There should be only one.
                if ( validOrders.length === 1 ) {
                    console.debug( `[pb/account.model] :: isValid() :: "${this.account_name}" is valid; returning true.` );
                    valid = true;
                }

                // Otherwise...
                else
                    console.debug( `[pb/account.model] :: isValid() :: "${this.account_name}" has 0 or many active orders; returning false.` );
            }

            // Otherwise...
            else
                console.debug( `[pb/account.model] :: isValid() :: "${this.account_name}" has 0 orders; returning false.` );
        }

        // Otherwise...
        else
            console.debug( `[pb/account.model] :: isValid() :: "${this.account_name || 'UNKNOWN'}" is missing required properties; returning false.` );

        return valid;
    }

    /**
     * Fetches all orders that are contractually valid (is_active),
     * and whose start and stop date is valid for use (is_valid).
     * 
     * @returns {AccountOrderModel[]}
     */
    getActiveValidOrders() {
        return this.orders.filter( o => o.is_active && o.is_valid );
    }

    /**
     * Fetches the current contractually active and usable order
     * for this account, as there can be only one. If there is
     * not exactly one, returns null.
     * 
     * @returns {AccountOrderModel|null}
     */
    getActiveValidOrder() {
        let activeValidOrders = this.getActiveValidOrders();
        return ( activeValidOrders.length === 1 )
            ? activeValidOrders[ 0 ]
            : null;
    }

    getAccountName(){
        return this.account_name;
    }

    getAccountNum(){
        return this.account_num;
    }

    /**
     * Returns a default account instance configured for guest use. Values are
     * taken from AR.Core's global.json config file.
     * 
     * @returns {AccountModel}
     */
    static defaultAsGuest() {
        let guestOrder = AccountOrderModel.defaultAsGuest();

        return new AccountModel(
            'Guest Account',
            'GUEST_ACCOUNT',
            guestOrder,
            true,
            true,
            'PBGUEST',
            -1,
            -1,
            null,
            true,
            null,
            [ guestOrder ]
        );
    }

    static fromJsonObj( jsonObj ) {
        if ( typeof jsonObj !== 'object' || jsonObj === null ) {
            return null;
        }

        const account_name = getTypeCheckedValue( jsonObj.account_name, 'string', null );
        const account_num = getTypeCheckedValue( jsonObj.account_num, 'string', null );
        const is_active_account = getTypeCheckedValue( jsonObj.is_active_account, 'boolean', false );
        const is_session_account = getTypeCheckedValue( jsonObj.is_session_account, 'boolean', false );
        const node_id = getTypeCheckedValue( jsonObj.node_id, 'string', null );
        const pb_account_id = getTypeCheckedValue( jsonObj.pb_account_id, 'int', null );
        const pb_user_account_id = getTypeCheckedValue( jsonObj.pb_user_account_id, 'int', null );
        const primary_sales_person = getTypeCheckedValue( jsonObj.primary_sales_person, 'string', null );
        const user_account_is_active = getTypeCheckedValue( jsonObj.user_account_is_active, 'boolean', false );
        const error = getTypeCheckedValue( jsonObj.error, 'string', null );
        const active_order = AccountOrderModel.fromJsonObj( jsonObj.active_order );
        const orders = AccountOrderModel
            .fromJsonArray( jsonObj.orders )
            .filter( order => order !== null );

        return new AccountModel( account_name, account_num, active_order, is_active_account,
            is_session_account, node_id, pb_account_id, pb_user_account_id, primary_sales_person,
            user_account_is_active, error, orders );
    }

    static fromJsonArray( jsonArr ) {
        if ( !Array.isArray( jsonArr )) {
            return [];
        }

        return jsonArr
            .map( jsonObj => AccountModel.fromJsonObj( jsonObj ));
    }
}

export default AccountModel;