// Local dependencies.
import Core from '../../../modules/core/core.module';

class StickyElem {
    constructor( $window ) {
        this.$window = $window;
        this.restrict = 'A';
        this.scope = {
            scrollOffset: '@',
            scrollClass: '@',
            footerVisibleClass: '@?'
        };
    }

    link( scope, element, attrs ) {
        angular.element( this.$window ).bind( 'scroll', function() {

            let scrolled = ( this.pageYOffset >= parseInt( scope.scrollOffset, 10 )),
                prevScrolled = ( element.hasClass( scope.scrollClass )),
                footerVisible = false;

            if ( scope.footerVisibleClass ) {
                let windowElem = angular.element( window ),
                    footerTop = angular.element( 'footer.footer' ).offset().top,
                    viewportBottom = windowElem.scrollTop() + windowElem.height();

                if ( viewportBottom >= footerTop ) {
                    element.addClass( scope.footerVisibleClass );
                    footerVisible = true;
                } else {
                    element.removeClass( scope.footerVisibleClass );
                }
            }

            if ( footerVisible && !prevScrolled && scrolled ) {
                // Nada.
            } else if ( scrolled ) {
                element.addClass( scope.scrollClass );
            } else {
                element.removeClass( scope.scrollClass );
            }
        });
    }
}

Core.directive( 'stickyElem', ( $window ) => new StickyElem( $window ));