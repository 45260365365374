import Core from '../../../modules/core/core.module';

/**
 * @name AllowedRole
 * @memberof CoreBundle.Core
 * @class
 * 
 * @classdesc
 * AngularJS directive. Elements with this attribute will be removed if a user's
 * role is not included in the comma-delimited attribute value. This is a
 * product-agnostic global directive within the Acquisition & Retention portal.
 * 
 * @example
 * <a href="protected.html" data-allowed-role="MOCK_USER,MOCK_SUPER_USER">Protected</a>
 */
class AllowedRole {
    constructor( aclService, eventService ) {
        this.aclService = aclService;
        this.eventService = eventService;
        this.restrict = 'A';
    }

    link( scope, element, attrs, ctrl, $transclude ) {
        const self = this;
        const watchSession = ( typeof attrs.watchSession === 'string' && attrs.watchSession !== '' && attrs.watchSession.toLowerCase() === 'true' );

        const checkLink = () => {
            if ( typeof attrs.allowedRole === 'string' && attrs.allowedRole !== '' ) {
                let allowedRoles = attrs.allowedRole.split( ',' ),
                    allowedAccess = false;
                
                allowedRoles.forEach( allowedRole => {
                    const productKeys = this.aclService.getProductKeysFromRole( allowedRole );      
                    
                    productKeys.forEach( productKey => {
                        if ( productKey !== null && this.aclService.getRole( productKey ) === allowedRole ) {
                            allowedAccess = true;
                            return;
                        }
                    }, this);

                    if (allowedAccess) {
                        return;
                    }
                    
                }, this );
                
                if ( !allowedAccess ) {
                    element.hide();
                } else {
                    element.show();
                }
            }
        };

        if ( watchSession ) {
            this.eventService.listenSessionChanged( checkLink );
        }

        checkLink();
    }
}

Core.directive( 'allowedRole', ( aclService, eventService ) => new AllowedRole( aclService, eventService ));
