// Library dependencies.
import angular from 'angular';
import get from 'lodash/get';

// Local dependencies.
import Core from '../../modules/core/core.module';

/**
 * @name sessionTimeoutService
 * @memberof CoreBundle.Core
 * @class
 * 
 * @classdesc
 * AngularJS provider that manages and maintains session timeout state across
 * products.
 */
class SessionTimeout {
    constructor( GLOBALS ) {
        'ngInject';

        this.GLOBALS = GLOBALS;

        // Stores component callback subscriptions, which are fired when
        // the warning timeout fires.
        this.warningSubscribers = [];

        // Stores component callback subscriptions, which are fired when
        // the warning interval has expired.
        this.timeoutSubscribers = [];

        // Keep-alive timer is the window timer that - once the timer has been
        // completed, sets the keepAlive flag to true; the auth interceptor
        // checks this flag to determine whether it needs to call "extend" to
        // keep an active session alive.
        this.keepAliveTimer = null;

        // Warning timeout is the window timer that - once the timer has been
        // completed - broadcasts an event to begin showing the timeout warning
        // bar above the utility-nav.
        this.warningTimer = null;

        // Warning interval is the window interval that counts down with the
        // remaining seconds until total session expiration, and each second
        // broadcasts an event to update the displayed message or to show
        // the expired session modal.
        this.expiringTimer = null;

        // Warning threshold is the configured number of milliseconds BEFORE
        // the prime expiration, at which point the timeout UI is shown.
        this.warningThreshold = this.GLOBALS.SESSION.WARNING_THRESHOLD;

        // Warning timestamp is calculated when the start method is called,
        // and is the number of milliseconds before showing the timeout UI.
        this.warningTimestamp = null;

        // Keep-alive threshold is the configured number of milliseconds to
        // allow to elapse before an active user session is eligible to be
        // kept active via the auth interceptor. Currently: 6 minutes.
        this.keepAliveThreshold = this.GLOBALS.SESSION.REFRESH_THRESHOLD;

        // Only one timeout expiration is available, and this is it.
        this.expirationTimestamp = null;

        // A simple flag set when the keep-alive timeout fires - or when the
        // extend method returns successfully. Used by auth interceptor to
        // determine when - periodically - to inject a session refresh into
        // an active session to keep it active.
        this.keepAlive = false;

        // A simple flag set when a valid session expiration has been set
        // that's checked when "start" is called.
        this.canStart = false;
    }

    /**
     * Adds a subscription callback to the warning or timeout subscriber arrays.
     * 
     * @param {String} subscription The subscription type; either "warning" or "timeout".
     * @param {Function} callback The function to add as callback.
     */
    subscribe( subscription, callback ) {
        subscription = getTypeCheckedValue( subscription, 'string', null );
        callback = getTypeCheckedValue( callback, 'function', null );

        if ( subscription !== null && callback !== null &&
            this.hasOwnProperty( `${subscription}Subscribers` )) {
            this[ `${subscription}Subscribers` ].push( callback );
        }
    }

    /**
     * Removes a subscription callback from the warning or timeout subscriber arrays.
     * 
     * @param {string} subscription 
     * @param {function} callback 
     */
    unsubscribe( subscription, callback ) {
        subscription = getTypeCheckedValue( subscription, 'string', null );
        callback = getTypeCheckedValue( callback, 'function', null );

        if ( subscription !== null &&
            callback !== null &&
            this.hasOwnProperty( `${subscription}Subscribers` )) {
            
            let index = this[ `${subscription}Subscribers` ].indexOf( callback );
            if ( index > -1 ) {
                this[ `${subscription}Subscribers` ].splice( index, 1 );
            }
        }
    }

    /**
     * Stores the current expiration timestamp as taken from an access JSON
     * web token, and if valid sets the flag allowing sessionTimeout to
     * start.
     * 
     * @param {number} expirationTimestamp 
     */
    setExpiration( expirationTimestamp ) {
        expirationTimestamp = getTypeCheckedValue( expirationTimestamp, 'int', null );

        console.debug( '[session-timeout internal] :: setExpiration starting', expirationTimestamp );

        if ( expirationTimestamp === null )
            console.warn( 'sessionTimeout expected to receive a Unix timestamp for setExpiration; null received instead.' );
        else {
            this.expirationTimestamp = expirationTimestamp;
            this.warningTimestamp = ( expirationTimestamp - this.warningThreshold );
            this.canStart = true;

            console.debug( '[session-timeout internal] :: setExpiration finished; canStart set to true' );
        }
    }

    /**
     * Begins a single-fire countdown to the point where the auth interceptor should
     * inject a keep-alive session refresh call to the API. The only thing
     * this timer does before dying is setting a flag to true. Auth interceptor
     * will check this flag on every eligible request.
     */
    countdownToKeepAlive() {

        // Kill it with fire.
        this._destroyTimer( 'keepAlive' );

        let now = new Date().getTime(),
            diff = new Date( this.warningTimestamp ) - new Date();

        // Only do this if we're within the operational window (no need to create
        // another timer if we're already showing the warning ribbon on screen).
        if ( diff > 0 ) {
            console.debug( '[session-timeout] ::  Keep-Alive Eligible:', `${Math.floor( this.keepAliveThreshold / 1000 / 60 )} m (${this.keepAliveThreshold} ms)` );
            this.keepAliveTimer = window.setTimeout( function() {
                console.debug( '[session-timeout] ::  Keep-Alive Eligible: Next API call or navigation transition.' );
                this.keepAlive = true;
            }.bind( this ), this.keepAliveThreshold );
        }

        // Otherwise, set keepAlive to true.
        else {
            console.debug( '[session-timeout] ::  Keep-Alive Eligible: Next API call or navigation transition.' );
            this.keepAlive = true;
        }
    }

    /**
     * Begins the by-second countdown to session expiration, and taking an
     * optional parameter to override the countdown and skip straight to the
     * timed-out state (this would be done if the product extension(s) fail).
     * 
     * @param {Boolean} override Whether to override the normal calculations, and skip straight to the modal.
     */
    countdownToTimeout( override = false ) {

        // Kill it with fire.
        this._destroyTimer( 'expiring' );

        let now = new Date().getTime(),
            diff = this.expirationTimestamp - now;

        if ( diff > 0 && !override ) {
            this.expiringTimer = window.setInterval( function() {
                // Fetch an object of minutes/seconds remaining before total expiration.
                // This will be passed around to any warning subscribers.
                let timeRemaining = this._getTimeRemaining();

                // We're in the end-game now; no need to track keep-alive.
                this._destroyTimer( 'keepAlive' );

                // We have time remaining; show the warning ribbon.
                if ( timeRemaining !== null ) {
                    this.warningSubscribers.forEach( subscription => subscription( true, timeRemaining ));
                }
                
                // Outtatime... destroy all the stuff, but show the modal.
                else {
                    this.destroy( true );
                }
            }.bind( this ), 1000 );
        } else {

            // We're completely timed out.
            this.timeoutSubscribers.forEach( subscription => subscription( true ));

            // Destroy all the stuff, but show the modal.
            this.destroy( true );
        }
    }

    /**
     * Attempts to begin the single-fire countdown to the display of the timeout
     * warning ribbon.
     */
    countdownToWarning() {

        // Kill it with fire.
        this._destroyTimer( 'warning' );

        let now = new Date().getTime(),
            diff = this.warningTimestamp - now;

        if ( diff > 0 ) {
            console.debug( '[session-timeout] ::   UI Warning Display:', `${Math.floor( diff / 1000 / 60 )} m (${diff} ms)` );
            
            this.warningTimer = window.setTimeout( function() {
                
                // User has allowed enough time to lapse that a warning is necessary; no more keep-alive.
                this._destroyTimer( 'keepAlive' );

                // Start showing the countdown. One might say... it's the fiiinalll countdooooown.
                this.countdownToTimeout();
            }.bind( this ), diff );
        } else {
            console.debug( '[session-timeout] ::   UI Warning Display: Already expiring.' );
            this.countdownToTimeout();
        }
    }

    /**
     * Called after a successful refresh from core.session's refreshSession
     * method.
     * 
     * @param {int} expirationTimestamp
     */
    extend( expirationTimestamp ) {
        console.debug( '[session-timeout internal] :: extend starting; killing timers', expirationTimestamp );

        // Kill them with fire.
        this._destroyTimer( 'keepAlive' );
        this._destroyTimer( 'warning' );
        this._destroyTimer( 'expiring' );

        // Reset keep-alive eligibility.
        this.keepAlive = false;

        console.debug( '[session-timeout internal] :: extend timers killed; keep-alive reset; setting expiration' );

        // Set the expiration.
        this.setExpiration( expirationTimestamp );

        console.debug( '[session-timeout internal] :: extend calling warning/timeout subs for reset' );

        try {
            // Let all subscribers know that the timers have been reset
            // for warnings (e.g., first param lets the sub know that
            // session is not over, and everything is still usable).
            this.warningSubscribers.forEach( sub => {
                console.debug( '[session-timeout internal] :: extend calling warning sub for reset', sub );
                sub( false, null );
            });

            // And do the same for timeouts...
            this.timeoutSubscribers.forEach( sub => {
                console.debug( '[session-timeout internal] :: extend calling timeout sub for reset', sub );
                sub( false );
            });
        } catch ( err ) {
            console.warn( '[session-timeout internal] :: extend encountered an error when firing subscriptions', err );
        }

        console.debug( '[session-timeout internal] :: extend finished' );
    }

    /**
     * Server session is already nuked at this point, and regardless of the
     * outcome we've irreversibly destroyed session state in the browser. All
     * we're doing here is ending session timeout countdowns.
     * 
     * @param {Boolean} timedOut Whether timeout subscribers should act on the timeout; passed to _destroyBrowser.
     */
    destroy( timedOut = false ) {
        timedOut = getTypeCheckedValue( timedOut, 'boolean', false );

        console.debug( '[session-timeout internal] :: destroy starting', timedOut );

        // Kill them with fire.
        this._destroyTimer( 'keepAlive' );
        this._destroyTimer( 'warning' );
        this._destroyTimer( 'expiring' );

        try {
            // Get rid of any ribbons or modals we might have. The party is
            // over, and can't be restarted.
            this.warningSubscribers.forEach( sub => {
                console.debug( '[session-timeout] :: Notifying warning subscriber of destroy.', sub );
                sub( false, null );
            });

            this.timeoutSubscribers.forEach( sub => {
                console.debug( '[session-timeout] :: Notifying timeout subscriber of destroy.', sub );
                sub( timedOut );
            });
        } catch ( err ) {
            console.warn( '[session-timeout internal] :: destroy encountered an error when firing subscriptions', err );
        }

        console.debug( '[session-timeout internal] :: destroy finished' );
    }

    /**
     * Fetches an object containing 0-padded minutes and seconds based
     * on the time remaining in session; null if the session has expired.
     * 
     * @returns {Object} Returns the minutes and seconds remaining; or null if fully expired.
     */
    _getTimeRemaining() {
        let timeRemaining = null,
            minutes = null,
            seconds = null,
            now = new Date().getTime(),
            diff = this.expirationTimestamp - now;

        if ( diff > 0 ) {
            minutes = parseInt(( diff / ( 1000 * 60 )) % 60 );
            seconds = parseInt(( diff / 1000 ) % 60 );

            if ( minutes < 0 ) minutes = 0;
            if ( seconds < 0 ) seconds = 0;

            minutes = minutes.toString();
            seconds = ( `0${seconds}` ).slice( -2 );

            timeRemaining = {
                diff,
                minutes,
                seconds
            };
        }

        return timeRemaining;
    }

    /**
     * When a timer must be absolutely nuked from orbit...
     * 
     * @param {String} timerType The type of timer to destroy. Only "warning" and "expiring" are supported.
     */
    _destroyTimer( timerType = null ) {
        timerType = getTypeCheckedValue( timerType, 'string', null );
        if ( timerType !== null && this.hasOwnProperty( `${timerType}Timer` )) {
            if ( this[ `${timerType}Timer` ] !== null ) {
                let func = ( timerType === 'warning' || timerType === 'keepAlive' )
                    ? 'clearTimeout'
                    : 'clearInterval';
                window[ func ]( this[ `${timerType}Timer` ]);
                delete this[ `${timerType}Timer` ];
                this[ `${timerType}Timer` ] = null;
            }
        }
    }

    /**
     * Primary entry point for provider interaction.
     */
    $get( $transitions ) {
        'ngInject';

        let timeout = {};

        /**
         * Subscribes to a session timeout event when the warning should
         * be shown, its value changed, or it should disappear.
         * 
         * @param {Function} callback The function to call when warning state has changed.
         */
        timeout.onWarningChanged = ( callback ) => {
            this.subscribe( 'warning', callback );
        };

        /**
         * Unsubscribes from a session timeout warning event.
         * 
         * @param {function} callback 
         */
        timeout.unsubscribeWarningChanged = ( callback ) => {
            this.unsubscribe( 'warning', callback );
        };

        /**
         * Subscribes to a session timeout event when the session has
         * timed out, been extended, or the timeout modal should disappear.
         * 
         * @param {Function} callback The function to call when the timeout state has changed.
         */
        timeout.onTimeoutChanged = ( callback ) => {
            this.subscribe( 'timeout', callback );
        };

        /**
         * Unsubscribes from a session timeout event.
         * 
         * @param {function} callback 
         */
        timeout.unsubscribeTimeoutChanged = ( callback ) => {
            this.unsubscribe( 'timeout', callback );
        };

        /**
         * Public method for updating the timeout's expiration using the expiration
         * date from the access JSON web token. This should only be called from
         * core.session, and nowhere else.
         * 
         * @param {Date} expirationDate Updated date-time taken from.
         * @returns {void} This method has no return.
         */
        timeout.setExpiration = ( expirationDate ) => {
            expirationDate = getInstanceCheckedValue( expirationDate, Date, null );

            if ( expirationDate === null )
                console.warn( 'Unable to set expiration; argument must be a valid date.' );
            else
                this.setExpiration( expirationDate.getTime() );
        };

        /**
         * Public method for determining whether enough time has elapsed that
         * the auth interceptor should inject itself to manually initiate a
         * token refresh action.
         */
        timeout.shouldKeepAlive = () => {
            return this.keepAlive;
        };

        /**
         * Public method fired after all products have bootstrapped their
         * session services. This begins a timeout.
         */
        timeout.start = () => {
            console.group( '[ SESSION-TIMEOUT.PROVIDER :: start ]');

            if ( this.canStart ) {
                console.debug( '[session-timeout] :: Timeout is startable.' );                
                console.debug( '[session-timeout] ::           Expiration:', this.expirationTimestamp );
                console.debug( '[session-timeout] ::   Warning Expiration:', this.warningTimestamp );
                console.debug( '[session-timeout] :: Current Browser Time:', Date.now() );

                this.countdownToKeepAlive();
                this.countdownToWarning();
            }
            console.groupEnd();
        };

        /**
         * Called from core.session after receiving a new token
         * expiration from APIv3.
         * 
         * @param {Date} expirationDate
         */
        timeout.extend = ( expirationDate ) => {
            expirationDate = getInstanceCheckedValue( expirationDate, Date, null );

            console.debug( '[session-timeout] :: Extending timeout.', expirationDate );

            if ( expirationDate === null )
                console.warn( 'Unable to set expiration; argument must be a valid date.' );

            this.extend( expirationDate.getTime() );
            timeout.start();

            console.debug( '[session-timeout] :: Timeout extended.' );
        };

        /**
         * Public method fired from core.session when a user has opted
         * to end their session immediately or timed out.
         * 
         * @param {boolean} isTimedOut
         */
        timeout.destroy = ( isTimedOut = false ) => {
            console.debug( '[session-timeout] :: Destruction. Timeout?', isTimedOut );
            this.destroy( isTimedOut );
        };

        return timeout;
    }
}

Core.provider( 'sessionTimeoutService', SessionTimeout );