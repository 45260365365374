import Core from '../../../modules/core/core.module';

/**
 * @name FieldMatch
 * @memberof CoreBundle.Core
 * @class
 * 
 * @classdesc
 * AngularJS directive. Form input elements with this attribute will validate
 * that their value matches the value of a sibling input element whose name
 * is defined by the attribute value. This is a product-agnostic global directive
 * within the Acquisition & Retention portal.
 * 
 * @example
 * <form>
 *  <input type="text" name="this" data-field-match="that" />
 *  <input type="text" name="that" data-field-match="this" />
 * </form>
 */
class FieldMatch {
    constructor() {
        this.require = 'ngModel';
    }

    link( scope, element, attrs, ngModel ) {
        const validate = ( value ) => {
            const isValid = scope.$eval( attrs.fieldMatch ) == value;
            ngModel.$setValidity( 'field-match', isValid );
            return isValid ? value : undefined;
        }

        ngModel.$parsers.unshift( validate );

        scope.$watch( attrs.fieldMatch, () => {
            validate( ngModel.$viewValue );
        });
    }
}

Core.directive( 'fieldMatch', () => new FieldMatch );
