import Core from '../../../modules/core/core.module';

/**
 * @name ActionItemLink
 * @memberof CoreBundle.Core
 * @class
 * 
 * @classdesc
 * AngularJS directive. Elements with this attribute will have an extended ngClick 
 * event that hides the action dropdown elements
 * 
 */
class ActionItemLink {
    constructor( $compile ) {
        this.restrict = 'A';
        this.compile = ( element, attrs ) => {
            attrs.$set( 'href', '' );    

            let callback = '';
            if ( attrs.ngClick ){
                callback = attrs.ngClick;
            }
            attrs.$set( 'ngClick', '$event.currentTarget.blur(); ' + callback );
        }
    }
}

Core.directive( 'actionItemLink', ( $compile ) => new ActionItemLink( $compile ));
