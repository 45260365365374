import Core from '../../modules/core/core.module';

/**
 * Defines a storage constant for localStorage.
 */
const LOCAL_STORAGE = 'localStorage';

/**
 * Defines a storage constant for sessionStorage.
 */
const SESSION_STORAGE = 'sessionStorage';

/**
 * Defines a storage constant for cookie storage.
 */
const COOKIE_STORAGE = 'cookie';

/**
 * A collection of supported browser storage methods.
 */
const SUPPORTED_STORAGE = [
    LOCAL_STORAGE,
    SESSION_STORAGE,
    COOKIE_STORAGE
];

/**
 * @name Storage
 * @memberof CoreBundle.Core
 * @class
 * 
 * @classdesc
 * AngularJS service.
 */
class Storage {

    /**
     * A helper function to fetch an item from storage using the provided
     * storageKey. First attempts to retrieve from localStorage (unless
     * isSession is passed as true), then from cookies.
     * 
     * @param {string} storageKey The storage key to fetch.
     * @param {boolean} jsonParse Whether to pre-parse the JSON result fetched from storage.
     * @param {boolean} isSession Whether this should be set in sessionStorage; default is false.
     * @return {*} The value (if found), or null otherwise.
     */
    get( storageKey, jsonParse = false, isSession = false ) {
        let storageMechanism = ( isSession === true )
            ? 'sessionStorage'
            : 'localStorage';

        if ( window[ storageMechanism ].getItem( storageKey )) {
            return ( jsonParse === true )
                ? JSON.parse( window[ storageMechanism ].getItem( storageKey ))
                : window[ storageMechanism ].getItem( storageKey );
        }

        let domainCookies = document.cookie,
            cookieCollect = domainCookies.split( ';' ).map( cookie => {
                return {
                    name: cookie.split( '=' )[ 0 ],
                    value: cookie.split( '=' )[ 1 ]
                };
            });

        const cookie = cookieCollect.find( cookie => cookie.name === storageKey );
        if ( cookie !== undefined ) {
            return ( jsonParse )
                ? JSON.parse( cookie.value )
                : cookie.value;
        }
        
        return null;
    }

    /**
     * A helper function to place/overwrite a key->value pair in localStorage. If
     * localStorage is not available, it will be placed into a cookie instead.
     * 
     * @param {string} storageKey The key accessor by which the value can be fetched.
     * @param {*} storageValue The value being stored. SHOULD already be stringified if JSON.
     * @param {boolean} isSession Whether this should be set in sessionStorage; default is false.
     */
    set( storageKey, storageValue, isSession = false ) {
        if ( this._canUseStorage( isSession )) {
            let storageMechanism = ( isSession === true )
                ? 'sessionStorage'
                : 'localStorage';
            window[ storageMechanism ].setItem( storageKey, storageValue );
            return;
        }

        const cookieExpires = new Date( new Date().getTime() + ( 60000 * 30 ));
        document.cookie = `${storageKey}=${storageValue}; expires=${cookieExpires.toGMTString()}; path=/ `;
    }

    /**
     * A helper function that removes a single key-value from browser storage. This is
     * a granular removal, and should not be used to clear the browser of all data. As
     * the goal here is to make a piece of data no longer available to the app for any
     * reason, we're going to attempt to remove it from everything.
     * 
     * @param {string} storageKey The key accessor by which the value is stored.
     */
    remove( storageKey ) {
        try {
            // Remove it from localStorage.
            window.localStorage.removeItem( storageKey );

            // Remove it from sessionStorage.
            window.sessionStorage.removeItem( storageKey );

            // Remove it from cookies.
            let docCookie = document.cookie;
            if ( typeof docCookie === 'string' ) {
                let cookies = docCookie.split( ';' );
                cookies.forEach( cookie => {
                    let cookieKey = cookie.split( '=' );
                    if ( cookieKey[ 0 ] === storageKey ) {
                        document.cookie = cookieKey[ 0 ] + '=; expires=Thu, 01 Jan 1970 00:00:00 UTC';
                    }
                });
            }
        } catch ( err ) {
            console.warn( err );
        }
    }

    /**
     * Iterates through all stored keys in the various browser storage
     * mechanisms, and removes them unless they're included in the
     * optional exemptions array.
     * 
     * @param {String} productKey The two-/three-letter product key prefix to delete.
     * @param {Array} exemptKeys A collection of full storage keys to ignore while deleting.
     * @param {Array} forcedKeys A collection of full storage keys to remove that don't match the product key.
     * @returns {void}
     */
    destroyByProduct( productKey, exemptKeys = [], forcedKeys = [] ) {
        productKey = getTypeCheckedValue( productKey, 'string', null );
        exemptKeys = getTypeCheckedValue( exemptKeys, 'array', [] );
        forcedKeys = getTypeCheckedValue( forcedKeys, 'array', [] );

        if ( productKey !== null ) {
            try {
                SUPPORTED_STORAGE.forEach( storageType => {
                    let storageKeys = this._getStorageKeys( storageType )
                        .filter( storageKey => forcedKeys.includes( storageKey ) ||
                            ( storageKey.indexOf( productKey ) !== -1 &&
                            !exemptKeys.includes( storageKey )));

                    console.log( `Destroying ${productKey} stored values`, storageKeys );
                    storageKeys.forEach( storageKey => this._destroyKey( storageType, storageKey ));
                })
            } catch ( err ) {
                console.warn( err );
            }
        }
    }

    /**
     * Iterates through the various browser storage mechanisms, and removes each
     * of the provided storage keys.
     * 
     * @param {Array} storageKeys A collection of full storage keys to delete.
     * @returns {void}
     */
    destroyByKeys( storageKeys ) {
        storageKeys = getTypeCheckedValue( storageKeys, 'array', [] );

        try {
            SUPPORTED_STORAGE.forEach( storageType => {
                storageKeys = storageKeys
                    .filter( storageKey => typeof storageKey === 'string' && storageKey.trim().length > 0 )
                    .map( storageKey => storageKey.trim() );

                console.log( `Destroying stored values`, storageKeys );
                storageKeys.forEach( storageKey => this._destroyKey( storageType, storageKey ));
            });
        } catch ( err ) {
            console.warn( err );
        }
    }

    /**
     * A helper function to completely remove all stored data from the browser. This
     * is an indescriminate removal, and should not be used to remove individual pieces
     * of data.
     */
    destroy() {
        try {
            // Clear localStorage.
            window.localStorage.clear();

            // Clear sessionStorage.
            window.sessionStorage.clear();

            // "Cookies" are children of the overall domain "cookie". To remove all
            // cookies, we must split it apart into its children, and set each
            // individual child cookie key->value. For more information, see
            // https://www.quirksmode.org/js/cookies.html
            let docCookie = document.cookie;
            if ( typeof docCookie === 'string' ) {
                let cookies = docCookie.split( ';' );
                cookies.forEach( cookie => {
                    let cookieKey = cookie.split( '=' );
                    document.cookie = cookieKey[ 0 ] + '=; expires=Thu, 01 Jan 1970 00:00:00 UTC';
                });
            }
        } catch ( err ) {
            console.warn( err );
        }
    }

    /**
     * A helper function that attempts to remove the provided key from
     * the provided browser storage type.
     * 
     * @param {String} storageType The browser storage type; must be one of: "localStorage", "sessionStorage", or "cookie".
     * @param {String} storageKey The storage key to destroy.
     * @returns {void}
     */
    _destroyKey( storageType = LOCAL_STORAGE, storageKey ) {
        storageType = getTypeCheckedValue( storageType, 'string', LOCAL_STORAGE );
        storageKey = getTypeCheckedValue( storageKey, 'string', null );

        try {
            if ( SUPPORTED_STORAGE.includes( storageType ) && storageKey !== null ) {
                switch ( storageType ) {
                    case LOCAL_STORAGE:
                    case SESSION_STORAGE:
                        window[ storageType ].removeItem( storageKey );
                        break;

                    // "Cookies" are children of the overall domain "cookie". To remove a
                    // single property, we must first break apart the cookie into its
                    // constituent children along the semicolon separator. We then loop
                    // the cookies, break them into key-value pairs along the equals
                    // separator, and check whether the key matches what we want to
                    // delete. If it matches, we set the cookie using the new key-value
                    // pair (in this case, we set it to nothing). For more information,
                    // see https://www.quirksmode.org/js/cookies.html.
                    case COOKIE_STORAGE:
                        let cookie = getTypeCheckedValue( document.cookie, 'string', '' ),
                            cookies = cookie.split( ';' );
                        cookies.forEach( cookieProp => {
                            let propParts = cookieProp.split( '=' ),
                                propKey = getTypeCheckedValue( propParts[ 0 ], 'string', null );
                            if ( propKey !== null && propKey.trim() === storageKey ) {
                                document.cookie = `${propKey.trim()}=;expires=Thu, 01 Jan 1970 00:00:00 UTC;`;
                            }
                        });
                        break;

                    default:
                        break;
                }
            }
        } catch ( err ) {
            console.warn( err );
        }
    }

    /**
     * A helper function that attempts to parse the provided storage type,
     * and retrieve all of the keys currently held within it... if valid.
     * 
     * @param {String} storageType The browser storage type; must be one of: "localStorage", "sessionStorage" or "cookie".
     * @returns {Array} A collection of the keys held in the browser storage store.
     */
    _getStorageKeys( storageType = LOCAL_STORAGE ) {
        storageType = getTypeCheckedValue( storageType, 'string', LOCAL_STORAGE );

        let storageKeys = [];

        try {
            if ( SUPPORTED_STORAGE.includes( storageType )) {
                switch ( storageType ) {
                    case LOCAL_STORAGE:
                    case SESSION_STORAGE:
                        for ( let i = 0; i < window[ storageType ].length; i++ ) {
                            storageKeys.push( window[ storageType ].key( i ));
                        }
                        break;

                    case COOKIE_STORAGE:
                        let cookie = getTypeCheckedValue( document.cookie, 'string', '' ),
                            cookies = cookie.split( ';' );
                        cookies.forEach( cookieProp => {
                            let propParts = cookieProp.split( '=' ),
                                propKey = getTypeCheckedValue( propParts[ 0 ], 'string', null );
                            if ( propKey !== null ) {
                                storageKeys.push( propKey.trim() );
                            }
                        });
                        break;

                    default:
                        break;
                }
            }
        } catch ( err ) {
            console.warn( err );
        } finally {
            return storageKeys;
        }
    }

    /**
     * A helper function to determine whether the browser's localStorage can be
     * used. This is needed as browsers like Safari will not allow putting
     * anything into localStorage when the user is browsing privately.
     * 
     * @return Boolean Whether localStorage can be used.
     */
    _canUseStorage( isSession = false ) {
        let canUse = false,
            storageMechanism = ( isSession === true )
                ? 'sessionStorage'
                : 'localStorage';
        const testKey = 'test';

        try {
            window[ storageMechanism ].setItem( testKey, testKey );
            window[ storageMechanism ].removeItem( testKey );
            canUse = true;
        } catch ( err ) {
            console.warn( `${storageMechanism} not supported; falling back to cookies.` );
            canUse = false;
        } finally {
            return canUse;
        }
    }
}

Core.service( 'storageService', Storage );