import Core from '../../modules/core/core.module';
import APIResponseModel from '../../models/core/api-response.model';
import NotificationModel from '../../models/core/notification.model';

/**
 * @name errorInterceptor
 * @memberof CoreBundle.Core
 * @class
 * 
 * @classdesc
 * AngularJS factory.
 */
class ErrorInterceptorFactory {
    constructor( eventService, Core_ResponseNotifications ) {
        this.eventService = eventService;
        this.Core_ResponseNotifications = Core_ResponseNotifications;

        this.request = this.request.bind( this );
        this.response = this.response.bind( this );
        this.responseError = this.responseError.bind( this );
        this._handleResponseModel = this._handleResponseModel.bind( this );
    }

    /**
     * Simple passthrough for the request itself; we don't care about this.
     * 
     * @param {Object} config The HTTP request configuration.
     * @returns {Object} The HTTP request configuration.
     */
    request( config ) {
        return config;
    }

    /**
     * The HTTP response handler; we'll take a look in here to make sure we
     * didn't pass back a specific error code or message. In the end, we're
     * going to pass back the response as-is.
     * 
     * @param {Object} res The HTTP response object.
     * @returns {Object} The HTTP response object.
     */
    response( res ) {
        if ( res instanceof APIResponseModel ) {
            this._handleResponseModel( res );
        }

        return res;
    }

    /**
     * The HTTP response error handler; we should expect one of two things
     * here: either an object containing status code and text, or an instance
     * of APIResponseModel. In the case of the first, we'll construct a new
     * instance of APIResponseModel before continuing; both cases will pass
     * on the APIResponseModel to be broadcast and turned into a notification.
     * 
     * @param {Object} res
     */
    responseError( res ) {
        let resObj = getTypeCheckedValue( res, 'object', {} ),
            status = resObj.status || null,
            statusText = resObj.statusText || null;

        status = getTypeCheckedValue( status, 'int', null );
        statusText = getTypeCheckedValue( statusText, 'string', null );

        if ( status !== null && statusText !== null &&
            this.Core_ResponseNotifications.includes( statusText )) {
            let apiRes = APIResponseModel.fromHttpResponse( status, statusText );
            this._handleResponseModel( apiRes );
        }

        return res;
    }

    /**
     * General handler for HTTP responses resulting in the construction of an
     * APIResponseModel.
     * 
     * @param {APIResponseModel} apiResponse An instance of APIResponseModel.
     */
    _handleResponseModel( apiResponse ) {
        if ( apiResponse instanceof APIResponseModel ) {
            let notificationModel = NotificationModel.fromApiResponseModel( apiResponse );
            this.eventService.broadcastHttpResponseNotification( notificationModel );
        }
    }
}

Core.factory( 'errorInterceptor', ( eventService, Core_ResponseNotifications ) => new ErrorInterceptorFactory( eventService, Core_ResponseNotifications ));