import Core from '../../../modules/core/core.module';

/**
 * @name EnabledRole
 * @memberof CoreBundle.Core
 * @class
 * 
 * @classdesc
 * AngularJS directive. Elements with this attribute will appear as disabled
 * if a user's role is not included in the comma-delimited attribute value.
 * This is a product-agnostic global directive within the Acquisition & Retention portal.
 * 
 * @example
 * <a href="protected.html" data-enabled-role="MOCK_USER,MOCK_SUPER_USER">Protected</a>
 */
class EnabledRole {
    constructor( aclService, $animate, $compile ) {
        this.aclService = aclService;
        this.$animate = $animate;
        this.$compile = $compile;

        this.restrict = 'A';
    }

    link( scope, element, attrs, $transclude, ctrl ) {
        if ( typeof attrs.enabledRole === 'string' && attrs.enabledRole !== '' ) {
            let enabledRoles = attrs.enabledRole.split( ',' ),
                suppliedProductKey = null,
                enabledAccess = false;

            if ( attrs.enabledRoleProductKey ){
                suppliedProductKey = attrs.enabledRoleProductKey;
            }

            enabledRoles.forEach( enabledRole => {
                const productKeys = ( suppliedProductKey !== null ) ? [ suppliedProductKey ] : this.aclService.getProductKeysFromRole( enabledRole );
                
                productKeys.forEach( productKey => {
                    if ( productKey !== null && this.aclService.getRole( productKey ) === enabledRole ) {
                        enabledAccess = true;
                        return;
                    }
                }, this);                
               
            }, this );
            
            if ( !enabledAccess ) {
                attrs[ 'ngClick' ] = false;
                attrs[ 'dataNgClick' ] = false;
                attrs.$set( 'disabled', 'disabled' );
                element.toggleClass( 'disabled' );
                element.on( 'click', ( event ) => {
                    event.preventDefault();
                });
            }
        }

        this.$compile( element.contents() )( scope );
    }
}

Core.directive( 'enabledRole', ( aclService, $animate, $compile ) => new EnabledRole( aclService, $animate, $compile ));
