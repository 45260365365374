import Core from '../../../modules/core/core.module';
import orderFlowStepIndicatorTemplate from './order-flow-step-indicator.template.html';

import './order-flow-step-indicator.styles.scss';

/**
 * @name orderFlowStepIndicator
 * @memberof CoreBundle.Core
 * @class
 * 
 * @classdesc
 * AngularJS component. Displays the list of order flow steps
 */
class OrderFlowStepIndicatorController {
    constructor( $scope ) {
        this.$scope = $scope;   
    }

    $onInit() {
        this.$scope.changeStep = this.handleStepChange.bind( this );
        this.$scope.currentStepOrdinal = this.currentStepOrdinal;
        this.$scope.maxReachedStepOrdinal = this.maxReachedStepOrdinal;
        this.$scope.steps = this.steps;
        this.$scope.isDisabled = getTypeCheckedValue( this.isDisabled, 'boolean', false );
    }

    $onChanges( changes ){
        if ( changes.currentStepOrdinal && !changes.currentStepOrdinal.isFirstChange() ){
            this.$scope.currentStepOrdinal = changes.currentStepOrdinal.currentValue;
        }
        if ( changes.maxReachedStepOrdinal && !changes.maxReachedStepOrdinal.isFirstChange() ){
            this.$scope.maxReachedStepOrdinal = changes.maxReachedStepOrdinal.currentValue;
        }
        if ( changes.steps && ! changes.steps.isFirstChange() ){
            this.$scope.steps = changes.steps.currentValue;
        }
        if ( changes.isDisabled ) {
            this.$scope.isDisabled = getTypeCheckedValue( changes.isDisabled.currentValue, 'boolean', false );
        }
    }

    /**
     * Handles the click event for an order flow step. Ensures that the
     * changeStep property is set, and fires it as long as the flow step
     * indicator is not currently disabled.
     * 
     * @param {object} stepChangeData Object containing the step ordinal
     * being navigated to, and whether to allow progression of the order.
     */
    handleStepChange( stepChangeData ) {
        stepChangeData = getTypeCheckedValue( stepChangeData, 'object', null );

        if ( typeof this.changeStep === 'function' &&
            stepChangeData !== null &&
            !this.$scope.isDisabled ) {
            this.changeStep( stepChangeData );
        }
    }
}

const orderFlowStepIndicator = {
    template: orderFlowStepIndicatorTemplate,
    controller: OrderFlowStepIndicatorController,
    bindings: {
        changeStep: '&',
        currentStepOrdinal: '<',
        maxReachedStepOrdinal: '<',
        steps: '<',
        isDisabled: '<'
    }
};

Core.component( 'orderFlowStepIndicator', orderFlowStepIndicator );
