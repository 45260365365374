import angular from 'angular';

/**
 * Wraps a function in a 0ms $timeout in order to immediately apply the
 * function at the earliest possible resolution point within Angular.js's
 * digest.
 * 
 * @param {Function} func 
 * @returns {Function}
 */
export function angularAsyncify( func ) {
    func = getTypeCheckedValue( func, 'function', null );
    if ( func === null )
        return angular.noop;

    let $injector = angular.injector([ 'ng' ]),
        $timeout = $injector.get( '$timeout' );

    return function() {
        const lastIdx = arguments.length - 1;
        const args = arguments[ lastIdx ];

        $timeout( function() {
            func.apply( null, args );
        }, 0 );
    }
}