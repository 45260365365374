import angular from 'angular';
import Core from '../../../modules/core/core.module';

/**
 * @name ToggleClassOnScroll
 * @alias toggleClassOnScroll
 * @memberof CoreBundle.Core
 * @class
 * 
 * @classdesc
 * AngularJS directive. Elements with this attribute have the provided class
 * toggled on or off depending on the provided offset.
 * 
 * @example
 * <div class="session-timeout" data-toggle-class-on-scroll scroll-offset="0" scroll-class="sticky" data-ng-if="isVisible"></div>
 */
class ToggleClassOnScroll {
    constructor( $window ) {
        this.$window = $window;
        this.restrict = 'A';
        this.scope = {
            scrollOffset: '@',
            scrollClass: '@'
        };
    }

    link( scope, element, attrs ) {
        angular.element( this.$window ).bind( 'scroll', function() {
            if ( this.pageYOffset >= parseInt( scope.scrollOffset )) {
                element.addClass( scope.scrollClass );
            } else {
                element.removeClass( scope.scrollClass );
            }
        });
    }
}

Core.directive( 'toggleClassOnScroll', ( $window ) => new ToggleClassOnScroll( $window ));
