import Core from '../../../../modules/core/core.module';
import chatTemplate from './chat.template.html';
let self = null;

/**
 * @name chatModal
 * @memberof CoreBundle.Core
 * @class
 * 
 * @classdesc
 * AngularJS component. Displays a visual indicator to the user that their
 * global application session is about to - or has already - expired.
 */
class ChatModalController {
    constructor( $scope, $window, $filter ) {
        this.$scope = $scope;
        this.$window = $window;
        this.$filter = $filter;

        this.containerElementId = 'chat-link-container';
        this.infoElementId = 'chat-link-info';
        this.linkElementId = 'chat-link';
        this.instanceId = 'sccl_0';
        this.module = 'ConditionalChatLink';

        this.rightNow = this.$window.RightNow || null;
        self = this;
    }

    $onInit() {
        this.$scope.chatLoading = true;
        this.$scope.chatEnabled = false;
        this.$scope.chatText = '';
        this.$scope.doChat = this.doChat.bind( this );
        this.$scope.doCancel = this.doCancel.bind( this );

        this.rightNow.Client.Controller.addComponent({
            instance_id: this.instanceId,
            container_element_id: this.containerElementId,
            info_element_id: this.infoElementId,
            link_element_id: this.linkElementId,
            module: this.module,
            type: 7,
            label_available_immediately_template: this.$filter( 'translate' )( 'components.global.chat.availability.immediate' ),
            label_unavailable_busy_template: this.$filter( 'translate' )( 'components.global.chat.availability.unavailable_busy' ),
            label_unavailable_hours: this.$filter( 'translate' )( 'components.global.chat.availability.unavailable_hours' )
        }, 'https://insurancesolutions.widget.custhelp.com/ci/ws/get' );

        this.rightNow.Client.Event.evt_widgetLoaded.subscribe( this.widgetLoaded );
    }

    $onDestroy() {
        this.rightNow.Client.Event.evt_conditionalChatLinkAvailabilityResponse.unsubscribe();
        this.rightNow.Client.Event.evt_conditionalChatLinkOffered.unsubscribe();
        this.rightNow.Client.Event.evt_widgetLoaded.unsubscribe();
    }

    doChat() {
        if ( this.$scope.chatEnabled ) {
            window[ this.instanceId ].chatLinkClicked();
            this.close({ $value: true });
        }
    }

    doCancel() {
        this.dismiss({ $value: 'cancel' });
    }
    
    widgetLoaded( eventName, data ) {
        const applyScope = ( isLoading, isEnabled, chatText ) => {
            isLoading = ( isLoading && isLoading );
            isEnabled = ( isEnabled && isEnabled );
            chatText = chatText || '';

            self.$scope.$apply(() => {
                self.$scope.chatLoading = isLoading;
                self.$scope.chatEnabled = isEnabled;
                self.$scope.chatText = chatText;
            });
        };

        const updateInterface = ( result ) => {
            result = result || null;

            let container = window[ self.instanceId ]._containerElement || null,
                link = window[ self.instanceId ]._chatLink || null,
                enabled = ( container !== null && container.classList.contains( 'rn_ChatAvailable' )),
                text = ( link !== null ) ? link.innerText : '';

            applyScope(
                false,
                enabled,
                text
            );
        };

        if ( data[ 0 ].id === self.instanceId ) {

            // This is the first possible instance in which we'll know the status.
            self.rightNow.Client.Event.evt_conditionalChatLinkOffered.subscribe( function( type, args, instance ) {
                updateInterface();
            });

            // Every subsequent status update happens here.
            self.rightNow.Client.Event.evt_conditionalChatLinkAvailabilityResponse.subscribe( function( type, args, instance ) {
                let result = args[ 0 ].data,
                    scope = args[ 1 ].scope,
                    eventScope = args[ 1 ];

                updateInterface( result );
            });

            self.rightNow.Client.Event.evt_conditionalChatLinkOffered.subscribers.reverse();
            self.rightNow.Client.Event.evt_conditionalChatLinkAvailabilityResponse.subscribers.reverse();
        }
    }
}

const chatModal = {
    template: chatTemplate,
    controller: ChatModalController,
    bindings: {
        close: '&',
        dismiss: '&'
    }
};

Core.component( 'chatModal', chatModal );
