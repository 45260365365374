import Core from '../../modules/core/core.module';
import { SignalRHubBaseModel } from '../../models/core';

class SignalRProcessingHub extends SignalRHubBaseModel {
    constructor( SIGNALR_CONSTANTS ) {
        'ngInject';
        super( SIGNALR_CONSTANTS, 'processing' );

        this.subscriptions.onAcknowledgementComplete = [];
        this.subscriptions.onListReceived = [];
        this.subscriptions.onNotification = [];
        this.subscriptions.onSeenComplete = [];
        this.subscriptions.onMappingComplete = [];
        this.subscriptions.onValidationComplete = [];
        this.subscriptions.onValidationError = [];
    }

    /**
     * Callback fired once connection has been established with the hub. In this
     * instance we immediately fetch a list of unseen notifications.
     */
    _onConnected() {
        this.log( '_onConnected', 'Requesting notification list.' );
        //this._list();
    }

    _handleMappingComplete( reply ){
        this.log( '_handleMappingComplete', 'Received OrderId:', reply );

        reply = getTypeCheckedValue( reply, 'int', null );
        if ( reply !== null ) {
            this._handleSubscription( 'onMappingComplete', reply );
        }
    }

    _handleValidationComplete( reply ){
        this.log( '_handleValidationComplete', 'Received OrderId:', reply );

        reply = getTypeCheckedValue( reply, 'int', null );
        if ( reply !== null ) {
            this._handleSubscription( 'onValidationComplete', reply );
        }
    }

    
    _get() {
        return {
            /*acknowledgeNotification: ( userNotificationId ) =>
                this._acknowledge( userNotificationId ),

            getUnseenNotifications: () =>
                this._getUnseenNotifications(),

            notificationSeen: ( userNotificationId ) =>
                this._viewNotifications( userNotificationId ),

            notificationsSeen: ( userNotificationIds ) =>
                this._viewNotifications( userNotificationIds )*/
        };
    }
}

Core.provider( 'signalRProcessingHub', SignalRProcessingHub );