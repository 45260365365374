import Core from '../../modules/core/core.module';

/**
 * @name browserService
 * @memberof CoreBundle.Core
 * @class
 * 
 * @classdesc
 * AngularJS service.
 */
class BrowserService {
    constructor( $window ) {
        this.$window = $window;

        this.browsers = {
            chrome: /chrome/i,
            safari: /^(?!.*(Chrome\/|Chromium\/)).*(Safari\/).*/gi,
            firefox: /firefox/i,
            ie: /internet explorer/i
        };

        this.userAgent = this.getUserAgent();
        this.isChrome = this._checkBrowser( this.browsers.chrome );
        this.isSafari = this._checkBrowser( this.browsers.safari );
        this.isFirefox = this._checkBrowser( this.browsers.firefox );
        this.isIE = this._checkBrowser( this.browsers.ie );
    }

    getUserAgent() {
        return this.$window.navigator.userAgent;
    }

    _checkBrowser( browserRegex ) {
        return browserRegex.test( this.userAgent );
    }
}

Core.service( 'browserService', BrowserService );
