import Core from '../../modules/core/core.module';

/**
 * @name eventService
 * @memberof CoreBundle.Core
 * @class
 * 
 * @classdesc
 * AngularJS service.
 */
class EventService {
    constructor( $rootScope ) {
        this.$rootScope = $rootScope;

        this.eventKeys = {
            ACCOUNT_CHANGE: 'accountChange',
            CRITERIA_CHANGE: 'criteriaChange',
            MODAL_LOADED: 'modal.stack.is-loaded',
            PB_RECENT_HISTORY_UPDATE: 'pb_recentHistoryUpdate',
            TITLE_CHANGE: 'titleChange',
            VIEW_LOADED: 'viewLoaded',
            SESSION_CHANGED: 'sessionChanged',
            PERSISTENT_NOTIFICATION: 'persistentNotification',
            PERSISTENT_NOTIFICATION_RESET: 'persistentNotificationReset',
            HTTP_RESPONSE_NOTIFICATION: 'httpResponseNotification',

            // Notification that does not impact the utility nav icon.
            GENERIC_NOTIFICATION: 'genericNotification',

            // Core session has been bootstrapped.
            GLOBAL_SESSION_LOADED: 'globalSessionLoaded'
        };
    }

    broadcastTitleChange( titleObj ) {
        //console.log( 'Broadcast: title change' );
        this.$rootScope.$broadcast( this.eventKeys.TITLE_CHANGE, titleObj );
    }

    listenTitleChange( callback ) {
        //console.log( 'Listener: title change' );
        this.$rootScope.$on( this.eventKeys.TITLE_CHANGE, callback );
    }

    broadcastSessionChanged() {
        this.$rootScope.$broadcast( this.eventKeys.SESSION_CHANGED );
    }

    listenSessionChanged( callback ) {
        this.$rootScope.$on( this.eventKeys.SESSION_CHANGED, callback );
    }

    broadcastAccountChange( redirectUser ) {
        //console.log( 'Broadcast: account change.' );
        this.$rootScope.$broadcast( this.eventKeys.ACCOUNT_CHANGE, redirectUser );
    }

    listenForAccountChange( callback ) {
        //console.log( 'Listener: account change.' );
        this.$rootScope.$on( this.eventKeys.ACCOUNT_CHANGE, callback );
    }

    broadcastModalLoaded() {
        //console.log( 'Broadcast: modal loaded.' );
        this.$rootScope.$broadcast( this.eventKeys.MODAL_LOADED );
    }

    broadcastPbRecentHistoryUpdate() {
        //console.log( 'Broadcast: recent history update.' );
        this.$rootScope.$broadcast( this.eventKeys.PB_RECENT_HISTORY_UPDATE );
    }

    listenForRecentHistoryUpdate( callback ) {
        //console.log( 'Listener: recent history update.' );
        this.$rootScope.$on( this.eventKeys.PB_RECENT_HISTORY_UPDATE, callback );
    }

    broadcastViewLoaded() {
        //console.log( 'Broadcast: view loaded.' );
        this.$rootScope.$broadcast( this.eventKeys.VIEW_LOADED );
    }

    listenViewLoaded( callback ) {
        //console.log( 'Listener: view loaded.' );
        this.$rootScope.$on( this.eventKeys.VIEW_LOADED, callback );
    }

    broadcastPersistentNotification( notification ) {
        this.$rootScope.$broadcast( this.eventKeys.PERSISTENT_NOTIFICATION, notification );
    }

    listenPersistentNotification( callback ) {
        this.$rootScope.$on( this.eventKeys.PERSISTENT_NOTIFICATION, callback );
    }

    broadcastPersistentNotificationReset() {
        this.$rootScope.$broadcast( this.eventKeys.PERSISTENT_NOTIFICATION_RESET );
    }

    listenPersistentNotificationReset( callback ) {
        this.$rootScope.$on( this.eventKeys.PERSISTENT_NOTIFICATION_RESET, callback );
    }

    broadcastHttpResponseNotification( notification ) {
        this.$rootScope.$broadcast( this.eventKeys.HTTP_RESPONSE_NOTIFICATION, notification );
    }

    listenHttpResponseNotification( callback ) {
        this.$rootScope.$on( this.eventKeys.HTTP_RESPONSE_NOTIFICATION, callback );
    }

    broadcastGenericNotification( notification ) {
        this.$rootScope.$broadcast( this.eventKeys.GENERIC_NOTIFICATION, notification );
    }

    listenGenericNotification( callback ) {
        this.$rootScope.$on( this.eventKeys.GENERIC_NOTIFICATION, callback );
    }

    broadcastGlobalSessionLoaded() {
        this.$rootScope.$broadcast( this.eventKeys.GLOBAL_SESSION_LOADED );
    }

    listenGlobalSessionLoaded( callback ) {
        this.$rootScope.$on( this.eventKeys.GLOBAL_SESSION_LOADED, callback );
    }
}

Core.service( 'eventService', EventService );
