import { CORE_ROLE_INHERITANCE } from '../../constants/core/roles';
import Core from './core.module';
import statesData from './data/states.json';
import monthsData from './data/months.json';

const signalREndpointBase = window.arconfig.endpoint_signalr;
const wsEndpointBase = window.arconfig.endpoint_ws;
const storageKeySession = window.arconfig.storagekey_session;
const storageKeyPB = window.arconfig.storagekey_pb;
const storageKeyMM = window.arconfig.storagekey_mm;
const apiEndpointCommon = window.arconfig.endpoint_api_common;
const apiEndpointProspectBase = window.arconfig.endpoint_api_prospectbase;
const apiEndpointMarketMagnifier = window.arconfig.endpoint_api_market_magnifier;

Core.constant( 'SIGNALR_CONSTANTS', {
    DEBUG: true,
    HOST: signalREndpointBase,
    PRODUCTS_ACCOUNT_HUB: [ 'mm' ],
    PRODUCTS_ANNOUNCEMENT_HUB: [ '*' ],
    PRODUCTS_NOTIFICATION_HUB: [ '*' ],
    PRODUCTS_PROCESSING_HUB: [ '*' ],
});

/**
 * @name WS_CONSTANTS
 * @memberof CoreBundle.Core
 * @constant
 * 
 * @description
 * Core application module web socket configuration constants.
 */
Core.constant( 'WS_CONSTANTS', {
    DEBUG_MODE: true,
    CONNECTION_ATTEMPTS: 10,
    CHANNEL_ACCOUNT: `${wsEndpointBase}/account`,
    CHANNEL_FILE_UPLOAD: `${wsEndpointBase}/file_upload`,
    CHANNEL_NOTIFICATION: `${wsEndpointBase}/notification`,
    CHANNEL_ANNOUNCEMENT: `${wsEndpointBase}/announcement`,
    CONNECT_ACCOUNT: [ 'mm' ],
    CONNECT_FILE_UPLOAD: [ 'mm' ],
    CONNECT_NOTIFICATION: [ '*' ],
    CONNECT_ANNOUNCEMENT: [ '*' ]
});

/**
 * @name WS_EVENTS
 * @memberof CoreBundle.Core
 * @constant
 * 
 * @description
 * Core application module web socket event constants.
 */
Core.constant( 'WS_EVENTS', {
    Core: {
        Subscribe: {
            Connect: 'connect',
            ReconnectAttempt: 'reconnect_attempt',
            Disconnect: 'disconnect',
            Ping: 'ping'
        },

        Publish: {}
    },

    Account: {
        Subscribe: {
            ResumableOrders: 'resumable_orders',
            SavedSearches: 'saved_searches'
        },

        Publish: {
            GetResumableOrders: 'get_resumable_orders',
            GetSavedSearches: 'get_saved_searches'
        }
    },

    FileUpload: {
        Subscribe: {
            Config: 'file_upload_config',
            UploadReady: 'file_upload_ready',
            UploadComplete: 'file_upload_complete',
            UploadProgress: 'file_upload_progress',
            UploadErrorAppend: 'file_upload_error_append',
            UploadErrorMove: 'file_upload_error_move',
            MappingComplete: 'file_mapping_complete',
            MappingError: 'file_mapping_error',
            ReadyToValidateComplete: 'file_ready_to_validate_complete',
            ReadyToValidateError: 'file_ready_to_validate_error',
            ValidationComplete: 'file_validation_complete',
            ValidationError: 'file_validation_error',
            OrderComplete: 'batchr3_complete',
        },

        Publish: {
            GetConfig: 'get_config',
            UploadInit: 'file_upload_init',
            Upload: 'file_upload',
            UploadPause: 'file_upload_pause',
            UploadComplete: 'file_upload_complete',
            ReadyToValidate: 'ready_to_validate',
        }
    },

    Notification: {
        Subscribe: {
            List: 'list',
            Notification: 'notification',
            AcknowledgeComplete: 'acknowledge_complete',
            SeenComplete: 'seen_complete'
        },

        Publish: {
            GetList: 'get_list',
            Acknowledge: 'acknowledge',
            Seen: 'set_seen'
        }
    },

    Announcement: {
        Subscribe: {
            UnseenCount: 'unseen_count',
            SeenComplete: 'seen_complete',
            AllSeenComplete: 'all_seen_complete'
        },

        Publish: {
            GetUnseenCount: 'get_unseen_count',
            Seen: 'set_seen',
            AllSeen: 'set_all_seen'
        }
    }
});

/**
 * @name Core_API
 * @memberof CoreBundle.Core
 * @constant
 * 
 * @description
 * AngularJS constants object for the few potential cross-product API calls that exist.
 */
Core.constant( 'Core_API', {
    ROUTE_BASE_COMMON: apiEndpointCommon,
    ROUTE_BASE_MARKETMAGNIFIER: apiEndpointMarketMagnifier,
    ROUTE_BASE_PROSPECTBASE: apiEndpointProspectBase,
    ROUTE_INTERCEPT_IGNORE: `${apiEndpointCommon}/auth`,
    ROUTE_TOKEN_REFRESH: `${apiEndpointCommon}/auth/refresh`,
    ROUTE_SESSION_ACCOUNT: `${apiEndpointCommon}/auth/account`,
    ROUTE_SESSION_LOGOUT: `${apiEndpointCommon}/auth/logout`,
    ROUTE_LEGAL_ACCEPTANCE: `${apiEndpointCommon}/legal`,
    ROUTE_SUPPORT_CONTACT: `${apiEndpointCommon}/support/contact`,
    ROUTE_SESSION_ERROR: `${apiEndpointCommon}/support/session-error`,
    ROUTE_ANNOUNCEMENTS: `${apiEndpointCommon}/announcement`,
    ROUTE_ANNOUNCEMENT: `${apiEndpointCommon}/announcement/{announcementId}`,
    ROUTE_FILE_UPLOAD: `${apiEndpointCommon}/file-upload`,
    ROUTE_FILE_UPLOAD_PREFLIGHT: `${apiEndpointCommon}/file-upload/{id}`,
    ROUTE_FILE_UPLOAD_BEGIN_VALIDATE: `${apiEndpointCommon}/file-upload/validate/{orderId}`
});

/**
 * @name Core_ResponseNotifications
 * @memberof CoreBundle.Core
 * @constant
 * 
 * @description
 * An array of explicitly defined API response status message keys to
 * show a notification for when caught. This only deals with unanticipated
 * responses with a status of >= 400.
 */
Core.constant( 'Core_ResponseNotifications', [
    'generic-error',
    'auth.token-invalid',
    'auth.refresh-invalid',
    'auth.session-invalid',
    'auth.multi-account-invalid',
    'auth.multi-account-unauth',
    'auth.multi-account-invalid-account',
    'mm.order.purchase-invalid',
    'mm.order.purchase-prev',
    'mm.order.purchase-proc-err',
    'mm.order.purchase-inv-preappend',
    'mm.order.purchase-inv-campaign',
    'mm.order.purchase-inv-validation',
    'mm.order.type-step-invalid',
    'mm.order.step-load-invalid',
    'mm.order.step-save-error',
    'mm.order.step-save-invalid',
    'support.request-invalid',
    'support.request-general-failure',
    'support.request-send-failure'
]);


/**
 * @name GLOBALS
 * @memberof CoreBundle.Core
 * @constant
 * 
 * @description
 * Core application module constants object for all shared or centralized immutable
 * configurations.
 */
Core.constant( 'GLOBALS', {
    SUPPORTED_PRODUCTS: [
        'mm',
        'pb'
    ],

    ROLE_PRODUCT_KEY_MAP: {
        mm: [ 'MMCONSUM', 'ACQANDRET', 'AR' ],
        pb: [ 'MKTPBASE', 'ACQANDRET', 'AR' ]
    },

    SUPPORTED_PRODUCT_STORAGE_KEYS: {
        session: storageKeySession,
        pb: storageKeyPB,
        mm: storageKeyMM,
        mmAccount: `${storageKeyMM}_account`,
        pbAccount: `${storageKeyPB}_account`,
        defaultApp: 'ar_default',
        mpTimeout: 'mpTimeout'
    },

    PRODUCT_RETURNTO: {
        mm: {
            orderHistory: /^\/mm\/history\/[0-9]+$/m
        }
    },

    PERSISTENT_LOAD_STATES: [
        'mp.announcements'
    ],

    PRODUCT_LANDINGS: {
        pb: 'mp.pb',
        mm: 'mp.mm.home',
        fromBreadcrumb: {
            pb: 'mp.pb.search',
            mm: 'mp.mm.home'
        },
        fromUtility: {
            pbSearch: 'mp.pb.search',
            pbAdmin: 'mp.pb.manageUsers',
            mmDefault: 'mp.mm.home'
        }
    },

    FALLBACK_BUNDLE: {
        productKey: 'pb',
        productRoute: 'mp.pb.guest'
    },

    PERSISTENT_NOTIFICATION_STATES: [
        'mp.mm.dataAppend'
    ],

    SPLASH_OPTIONS: {
        IS_MESSAGES_SHOWN: true,   // show loading messages?
        IS_MESSAGES_RANDOM: true,  // show loading messages randomly?
        MESSAGE_ROTATE_TIME: 3.0,  // in seconds
    },

    ALERT_TYPES: {
        SUCCESS: 'success',
        WARNING: 'warning',
        DANGER: 'danger'
    },

    UNAUTH_ROUTE: 'mp.pb.search',

    CLA_ROLES: {
        Admin: 'IDS_USER_ADMIN',
        SuperAdmin: 'IDS_SUPR_ADMIN',
        SupportAdmin: 'IDS_SUPP_ASSO'
    },

    ROLES: CORE_ROLE_INHERITANCE,
    
    OPTION_TEXT: {
        YesNo: [
            'Yes',
            'No'
        ],
        Boolean: [
            'True',
            'False'
        ]
    },

    HELP: {
        pb: 'resources/help/pb/index.html',
        mm: 'resources/help/mm/index.html'
    },

    // Auth routes.
    ROUTE_AUTH: window.arconfig.endpoint_api + '/common/auth',
    ROUTE_MONITOR_PING: window.arconfig.endpoint_api + '/common/monitor/ping',
    // Geo Routes
    ROUTE_GEO_STATE_DATA: window.arconfig.endpoint_apiv2 + '/common/geo',

    // Chat Customer Support routes
    CUSTOMER_SUPPORT_ROUTE_PB: 'https://lnrs.my.site.com/CustomerSupportHub/s/contactsupport/prospect-base-support',
    CUSTOMER_SUPPORT_ROUTE_MM: 'https://lnrs.my.site.com/CustomerSupportHub/s/contactsupport/ip-market-magnifier-support',


    GEO_STATE_LIST: statesData,
    MONTH_LIST: monthsData,

    LOCAL_STORAGE_KEYS: {
        DEFAULT_ACCOUNT: 'defaultAccount'
    },

    GOOGLE_MAPS: {
        EXTERNAL_VIEW_URL: '//maps.google.com/?q='
    },

    ALERT_DISMISS_TIMEOUT: '3500',
    ANGULAR_SESSION_LENGTH: '1800', //1800s = 30m
    ANGULAR_SESSION_WARNING: '1500', //1500s = 25m
    USE_IDS_SOCKETSERVICE: window.arconfig.use_ids_socketservice,
    ENDPOINTS: {
        dashboard: window.arconfig.endpoint_dashboard,
        login: window.arconfig.endpoint_login,
        logout: window.arconfig.endpoint_logout,
        profile: window.arconfig.endpoint_profile,
        workspace: window.arconfig.endpoint_workspace,
        usermanagement: window.arconfig.endpoint_usermanagement,
        socketservice: window.arconfig.endpoint_socketservice
    },

    NOTIFICATION_TRAY: {
        VISIBLE: 5
    },

    SESSION: {
        WARNING_THRESHOLD: 300000,
        REFRESH_THRESHOLD: 360000,
        REFRESH_BUFFER: 5000,
    },

    LEGAL_ACCEPTANCE_STORAGE_KEY: 'LEGAL_ACCEPTANCE',

    MODAL: {
        CONTACT_SUPPORT: {
            DEFAULTS: {
                component: 'contactSupportModal',
                size: 'lg'
            },
            OPTIONS: {}
        },

        CHAT: {
            DEFAULTS: {
                component: 'chatModal',
                size: 'lg'
            },
            OPTIONS: {
                closeButtonText:    'ui.pb.buttons.cancel',
                actionButtonText:   'components.global.modal.chat.default.primaryButton',
                headerText:         'components.global.modal.chat.default.title'
            }
        }
    }
});

/**
 * @name LOCALES
 * @module Core
 * @kind AngularJS constant
 * 
 * @description
 * The base AngularJS constant object for all shared or centralized immutable
 * i18n configurations within the Acquisition & Retention portal.
 */
Core.constant( 'LOCALES', {
    locales: {
        en_US: 'English'
    },
    preferredLocale: 'en_US'
});
