// Library dependencies.
import get from 'lodash/get';

class APIv3ResponseStatus {

    constructor( responseData ) {
        responseData = getTypeCheckedValue( responseData, 'object', {} );

        this.code = getTypeCheckedValue( get( responseData, 'status.code' ), 'int', -1 );
        this.message = getTypeCheckedValue( get( responseData, 'status.message' ), 'string', 'system-error' );
    }
}

class APIv3ResponseModel {
    constructor( responseData ) {
        this.status = new APIv3ResponseStatus( responseData );
    }
}

export default APIv3ResponseModel;