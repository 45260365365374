import Core from '../../../modules/core/core.module';
import notificationHistoryTemplate from './notification-history.template.html';
import './notification-history.styles.scss';

/**
 * @name notificationHistory
 * @memberof CoreBundle.Core
 * @class
 * 
 * @classdesc
 * AngularJS component.
 */
class NotificationHistoryController {
    constructor( $scope, $state, eventService ) {
        this.$scope = $scope;
        this.$state = $state;
        this.eventService = eventService;
    }

    $onInit() {
        this.eventService.broadcastViewLoaded();
    }
}

const notificationHistoryView = {
    template: notificationHistoryTemplate,
    controller: NotificationHistoryController
};

Core.component( 'notificationHistoryView', notificationHistoryView );
