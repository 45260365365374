import Core from '../../../modules/core/core.module';

/**
 * @name ValidationNumericRange
 * @memberof CoreBundle.Core
 * @class
 * 
 * @classdesc
 * AngularJS directive. Ensures the input is within the provided range
 */
class ValidationNumericRange {
    constructor() {
        this.restrict = 'A';
        this.require = 'ngModel';
        this.scope = true;
    }

    link( scope, element, attrs, ctrl ) {

        // Validate the range minimum.
        ctrl.$validators.validMinimum = function( modelValue, viewValue ) {
            let value = modelValue || viewValue;
            if ( value === null || value === undefined || value === '' ){
                return true;
            }

            let isValid = ( attrs.rangeMin ) ? ( value >= attrs.rangeMin ) : true;
            return isValid;
        };

        // Validate the range maximum.
        ctrl.$validators.validMaximum = function( modelValue, viewValue ) {
            let value = modelValue || viewValue;
            if ( value === null || value === undefined || value === '' ){
                return true;
            }

            let isValid = ( attrs.rangeMax ) ? ( value <= attrs.rangeMax ) : true;
            return isValid;
        };
    }
}

Core.directive( 'validationNumericRange', () => new ValidationNumericRange());
