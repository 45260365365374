import angular from 'angular';

class ModuleLoader {
    constructor() {
        this._initializedModules = {};
    }

    /**
     * The only function intended to be called by the core app. It will push the
     * core bundle module to the root module's list of required modules, initialize
     * any config/run blocks, and run them.
     */
    loadModule( moduleName ) {
        this._rootApplicationModule.requires.push( moduleName );
        this._initializeModule( moduleName );
        this._executeRunsConfigs();
    }

    setRootApplicationModule( module ) {
        this._setChildModulesInitialized( module );
        this._rootApplicationModule = angular.module( module );
    }

    _executeRunsConfigs() {
        const $injector = angular.element( document.querySelector( '[data-ng-app]' )).injector();
        this._configBlocks.forEach( this._executeInvocation.bind( this ));
        this._runBlocks.forEach( $injector.invoke );
        delete this._configBlocks;
        delete this._runBlocks;
    }

    _initializeModule( moduleName ) {
        const module = angular.module( moduleName );
        module._invokeQueue.reverse().forEach( this._executeInvocation.bind( this ));
        this._configBlocks = this._configBlocks
            ? this._configBlocks.concat( module._configBlocks )
            : module._configBlocks;
        this._runBlocks = this._runBlocks
            ? this._runBlocks.concat( module._runBlocks )
            : module._runBlocks;
        this._initializedModules[ moduleName ] = true;
        module.requires.forEach( nestedModule => {
            if ( !this._initializedModules[ nestedModule ]) {
                this._initializeModule( nestedModule );
            }
        });
    }
    _executeInvocation([ providerName, providerMethod, construct ]) {
        const provider = this.providers[ providerName ];
        provider[ providerMethod ].apply( provider, construct );
    }

    _setChildModulesInitialized( module ) {
        if ( !this._initializedModules[ module ]) {
            this._initializedModules[ module ] = true;

            const angularModule = angular.module( module );
            angularModule.requires.forEach( key => {
                this._setChildModulesInitialized( key );
            });
        }
    }
}

export default new ModuleLoader();