import Core from '../../../modules/core/core.module';

/**
 * @name RuntimeScript
 * @memberof CoreBundle.Core
 * @class
 * 
 * @classdesc
 * AngularJS directive. Script elements of type "text/javascript-dynamic" will
 * be further parsed and loaded at runtime, and if a "module" attribute is
 * provided, will be loaded as an AngularJS module and added to the Core's
 * dependencies.
 */
class RuntimeScript {
    constructor( $productModules ) {
        this.$productModules = $productModules;
        this.restrict = 'E';
        this.scope = {
            src: '@',
            module: '@'
        };
    }

    link( scope, element, attrs ) {
        if ( attrs.type === 'text/javascript-dynamic' ) {
            let elem = document.createElement( 'script' ),
                src = scope.src || element.attr( 'src' ),
                moduleName = scope.module || element.attr( 'module' );

            elem.type = 'text/javascript';
            if ( src !== undefined ) {
                if ( moduleName !== undefined ) {
                    elem.onload = () => this.$productModules.registerModule( moduleName );
                }

                elem.src = src;
            } else {
                elem.text = element.text();
            }

            document.body.appendChild( elem );
            element.remove();
        }
    }
}

Core.directive( 'script', ( $productModules ) => new RuntimeScript( $productModules ));
