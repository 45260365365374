import angular from 'angular';
import get from 'lodash/get';
import Core from './core.module';

/**
 * @name coreRun
 * @memberof CoreBundle.Core
 * @kind function
 * 
 * @description
 * AngularJS functionality that executes during first-run of the Core module.
 */
function coreRun(
    $rootScope,
    $transitions,
    $translate,
    $location,
    $window,
    Session,
    eventService,
    sessionTimeoutService,
    indexedDbService ) {

    let timeoutTranslated = '';

    /**
     * Initialize IndexedDB.
     */
    indexedDbService.open();

    /**
     * Pre-translate the timeout string; doesn't work well with timers inline. We'll
     * do the token replacement in-line.
     */
    $translate( 'global.title.timingout' ).then( function( trans ) {
        timeoutTranslated = trans;
    });

    eventService.listenTitleChange( ( event, args ) => {
        if ( angular.isString( args )) {
            $translate( args ).then( trans => {
                document.title = trans;
            });
        } else {
            if ( args.key && args.value && args.key.indexOf( 'timingout' ) !== -1 ) {
                const minutes = args.value.minutes || 0;
                const seconds = args.value.seconds || 0;
                document.title = timeoutTranslated.replace( ':', `${minutes}:${seconds}` );
            }
        }
    });

    eventService.listenViewLoaded( () => {
        if ( $window.splashScreen !== null ) {
            if ( $window.splashMessageRefresh !== null ) {
                clearInterval( $window.splashMessageRefresh );
            }

            if ( !$window.splashScreen.finishing ) {
                $window.splashScreen.finish();
            }
        }
    });

    /**
     * We have a lot of complex routing conditions, particularly for PB. The
     * following transition hooks are here for debugging purposes. If we're
     * having issues with routing - for example, account switching - start
     * here.
     */
    $transitions.onBefore( {}, ( transition ) => console.debug( `[$transitions.onBefore]: From "${transition.from().name}" to "${transition.to().name}".` ));
    $transitions.onExit( {}, ( transition ) => console.debug( `[$transitions.onExit]: From "${transition.from().name}" to ${transition.to().name}".` ));
    $transitions.onRetain( {}, ( transition ) => console.debug( `[$transitions.onRetain]: From "${transition.from().name}" to ${transition.to().name}".` ));
    $transitions.onEnter( {}, ( transition ) => console.debug( `[$transitions.onEnter]: From "${transition.from().name}" to ${transition.to().name}".` ));

    // Moved from session-timeout.provider.
    $transitions.onStart( {}, ( transition ) => {
        console.debug( `[$transitions.onStart]: From "${transition.from().name}" to "${transition.to().name}".` );
        let transitionState = transition.to(),
            requiresAuth = get( transitionState, 'data.requireLogin' ) || false;
        if ( requiresAuth && sessionTimeoutService.shouldKeepAlive() ) {
            console.debug( '[session-timeout] :: Transition start. Eligible for refresh. Refreshing.' );
            return new Promise(( resolve ) => {
                Session.refreshSession()
                    .then( newExpirationDate => {
                        newExpirationDate = getTypeCheckedValue( newExpirationDate, 'object', null );
                        console.debug( '[core.run] :: $transitions.onStart() :: Completed core.session.refreshSession(); received new session expiration.', newExpirationDate );
                        if ( newExpirationDate !== null )
                            resolve( true );
                        else
                            resolve( false );
                    });
            });
        } else {
            return true;
        }
    });

    $transitions.onFinish( {}, ( transition ) => {
        console.debug( `[$transitions.onFinish]: From "${transition.from().name}" to ${transition.to().name}".` );

        let transitionState = transition.to(),
            transitionData = getTypeCheckedValue( transitionState.data, 'object', {} ),
            bodyClass = getTypeCheckedValue( transitionData.bodyClass, 'string', '' );
        $rootScope.bodyClass = bodyClass;
        return true;
    });

    $transitions.onSuccess( {}, ( transition ) => console.debug( `[$transitions.onSuccess]: From "${transition.from().name}" to ${transition.to().name}".` ));
    $transitions.onError( {}, ( transition ) => console.debug( `[$transitions.onError]: From "${transition.from().name}" to ${transition.to().name}".` ));
}

Core.run( coreRun );
