import Core from '../../../modules/core/core.module';
import paginationTemplate from './pagination.template.html';

import './pagination.styles.scss';

/**
 * @name pagination
 * @memberof CoreBundle.Core
 * @class
 * 
 * @classdesc
 * AngularJS component. Displays a pagination navigation for large grids or tables
 * of data.
 */
class PaginationController {
    constructor( $scope ) {
        this.$scope = $scope;   
    }

    $onInit() {
        this.$scope.settings = this.settings;
        this.$scope.changeCallback = this.changeCallback;

        this.$scope.setPageLength = this.setPageLength.bind( this );
        this.$scope.setPage = this.setPage.bind( this );
    }

    setPageLength() {
        this.$scope.changeCallback({ action: 'pageLength', val: this.$scope.settings.pageLength });
    }

    setPage( direction ) {
        direction = direction || 'current';
        this.$scope.changeCallback({ action: 'page', val: direction });
    }
}

const pagination = {
    template: paginationTemplate,
    controller: PaginationController,
    bindings: {
        settings: '=',
        changeCallback: '&'
    }
};

Core.component( 'pagination', pagination );
