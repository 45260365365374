
/**
 * @name FileStreamModel
 * @memberof CoreBundle.Core
 * @class
 * 
 * @classdesc
 * Handles a Response File Stream
 */
class FileStreamModel {
    constructor( filename, contentType, contentLength, contentModified, data ) {
        
        this.filename = filename || null;
        this.data = data || null;
        this.contentType = null;
        this.meta = {
            size: contentLength || 0,
            modified: ( contentModified !== null )
                ? new Date( contentModified * 1000 )
                : null,
            charset: null,
            mimeType: null,
            mimeSubtype: null
        };

        if ( contentType !== null ) {
            if ( contentType.indexOf( ';' ) !== -1 ) {
                var ctParts = contentType.split( ';' ),
                    self = this;
                ctParts.forEach( function( ctPart ) {
                    
                    var csPattern = 'charset=',
                        ctText = ctPart.toLowerCase();
                    
                    // Look for character set.
                    if ( ctText.indexOf( csPattern ) !== -1 && self.meta.charset === null ) {
                        self.meta.charset = ctText.substr( ctText.indexOf( csPattern ) + csPattern.length );
                    }

                    // Look for mime type/subtype.
                    if ( ctText.indexOf( '/' ) !== -1 && ( self.meta.mimeType === null || self.meta.mimeSubtype === null )) {
                        var mimeParts = ctText.split( '/' );
                        if ( mimeParts.length === 2 ) {
                            self.contentType = ctText;
                            self.meta.mimeType = mimeParts[ 0 ].toLowerCase();
                            self.meta.mimeSubtype = mimeParts[ 1 ].toLowerCase();
                        }
                    }
                });
            }
                
            // Look for mime type/subtype.
            if ( contentType.indexOf( '/' ) !== -1 && ( this.meta.mimeType === null || this.meta.mimeSubtype === null )) {
                var mimeParts = contentType.split( '/' );
                if ( mimeParts.length === 2 ) {
                    this.contentType = contentType;
                    this.meta.mimeType = mimeParts[ 0 ].toLowerCase();
                    this.meta.mimeSubtype = mimeParts[ 1 ].toLowerCase();
                }
            }

        }        
    }

    /**
     * Attempts to determine a FontAwesome icon based on the provided i18n
     * title key. If one can't be determined, falls back to 'fa-info-circle'.
     * 
     * @param {string} titleKey The i18n title key for the notification.
     * @return {string} A FontAwesome icon class name.
     */
    isValid() {
        return (
            this.filename !== null &&
            this.meta !== null &&
            this.data !== null
        );
    }

    asEncodedUri() {
        return "data:" + this.contentType + "," + this.data;
    }

    static fromResponse( responseObj ) {
        var filename = null,
            contentType = null,
            contentLength = 0,
            contentModified = null,
            data = null;

        if ( responseObj && typeof responseObj.headers === 'function' ) {
            var headers = responseObj.headers(),
                disposition = headers[ 'content-disposition' ];
            contentType = headers[ 'content-type' ];
            contentLength = headers[ 'content-length' ];
            contentModified = headers[ 'content-modified' ];
            
            // Get the filename.
            var filenamePattern = 'filename="',
                filenameStartIdx = disposition.indexOf( filenamePattern ) + filenamePattern.length;
            filename = disposition.substr( filenameStartIdx, disposition.lastIndexOf( '"' ) - filenameStartIdx );

            data = responseObj.data || null;
        }

        return new FileStreamModel( filename, contentType, contentLength, contentModified, data );
    }
}

export default FileStreamModel;