import Core from '../../modules/core/core.module';

/**
 * @name geocodeService
 * @memberof CoreBundle.Core
 * @class
 * 
 * @classdesc
 * AngularJS factory.
 */
class GeocodeFactory {
    constructor( $q, $timeout ) {
        this.$q = $q;
        this.$timeout = $timeout;
    }

    /**
     * 
     * @param {String} address 
     * @param {Int} maxResults 
     * @param {String[]} limitStatesTo 
     */
    getValidatedAddresses( address, maxResults, limitStatesTo ) {
        maxResults = maxResults || 100;
        limitStatesTo = limitStatesTo || [];

        let completeResults = [],
            completeGeocodes = 0,
            defer = this.$q.defer(),
            data = {
                returnedAddresses: [],
                maximumAddressCount: maxResults,
                queryStates: limitStatesTo,
                queryStateGroupSize: 5,
                queryDelay: 5000,
                queriedStateCount: 0,
                returnedStateCount: 0
            };

        let searchData = {
            'address': address,
            'componentRestrictions': {
                'country': 'us',
            }
        };        

        if ( typeof google === 'object' && typeof google.maps === 'object' ) {
            const geocoder = new google.maps.Geocoder();

            const onFinish = ( results, status ) => {
                completeGeocodes++;
                if ( status == google.maps.GeocoderStatus.OK ) {
                    if ( results.length > 0 ) {
                        completeResults = completeResults.concat(results);
                    }
                } else {
                    defer.reject( status );
                }
    
                if (completeGeocodes == todoGeocodes){
                    if (completeResults.length > maxResults){
                        completeResults.length = maxResults;
                    }
    
                    defer.resolve( completeResults );
                }
            };

            const onComplete = ( results, status ) => {
                data.returnedStateCount++;
    
                let overLimit = false;
                if ( status == google.maps.GeocoderStatus.OK ) {
                    angular.forEach( results, ( suggestedAddress, key ) => {
                        if ( suggestedAddress.types.indexOf( 'street_address' ) > -1 ){
                            data.returnedAddresses.push( suggestedAddress );
                        };
                    });
                } else if ( status == google.maps.GeocoderStatus.OVER_QUERY_LIMIT) {
                    console.log( '%ccaught OVER_QUERY_LIMIT', 'color:red');
                }
    
                if ( data.queriedStateCount === data.returnedStateCount ){
                    if ( data.returnedAddresses.length >= data.maximumAddressCount ){
                        data.returnedAddresses.length = data.maximumAddressCount;
                        defer.resolve( data.returnedAddresses );        
                    } else if ( data.queriedStateCount == data.queryStates.length ){
                        defer.resolve( data.returnedAddresses );
                    } else if ( data.returnedAddresses.length < data.maximumAddressCount && data.queriedStateCount < data.queryStates.length){
                        this.$timeout( () => {
                            getGeoResults();
                        }, data.queryDelay );
                    } else {
                        defer.reject( 'geocode getValidatedAddresses error' );
                    }
                }
            };

            getGeoResults();

            return defer.promise;

            function getGeoResults() {
                let todoGeocodes = data.queryStateGroupSize;
                if ( data.queryStates.length === 0 ) {
                    todoGeocodes = 1;
                } else if ( data.queryStateGroupSize > data.queryStates.length - data.queriedStateCount ) {
                    todoGeocodes = data.queryStates.length - data.queriedStateCount;
                }

                for ( let i = 0; i < todoGeocodes; i++ ) {
                    let querySearchData = angular.copy( searchData );
                    if ( limitStatesTo.length > 0 ) {
                        querySearchData.componentRestrictions.administrativeArea = limitStatesTo[ data.queriedStateCount + i ];
                    }
                    geocoder.geocode( querySearchData, onComplete );
                }

                data.queriedStateCount += todoGeocodes;
            }
        }
    }

    /**
     * 
     * @param {String} searchValue 
     * @param {Int} maxResults 
     * @param {String[]} limitStatesTo 
     */
    getValidatedPlaces( searchValue, maxResults, limitStatesTo ) {
        maxResults = maxResults || 100;
        limitStatesTo = limitStatesTo || [];

        let completeResults = [],
            completeGeocodes = 0,
            defer = this.$q.defer(),
            data = {
                searchValue: searchValue,
                returnedAddresses: [],
                maximumAddressCount: maxResults,
                queryStates: limitStatesTo,
                queryStateGroupSize: 10,
                queryDelay: 100,
                queriedStateCount: 0,
                returnedStateCount: 0,
                isNationalQuery: false
            },
            service;

        if ( typeof google === 'object' && typeof google.maps === 'object' ) {
            service = new google.maps.places.AutocompleteService();
            getPlaces( this );
            return defer.promise;
        }

        function getPlaces( self = this ) {
            var todoGeocodes = data.queryStateGroupSize;
            if ( data.queryStateGroupSize > data.queryStates.length - data.queriedStateCount ){
                todoGeocodes = data.queryStates.length - data.queriedStateCount;
            }

            if ( data.queryStates.length > 30 ){
                data.isNationalQuery = true;
                todoGeocodes = 1;
            }

            data.queriedStateCount += todoGeocodes;

            var coords = null;
            var stateAbbr = '';
            for ( var i = 0; i < todoGeocodes; i++ ){
                if ( !data.isNationalQuery ){
                    stateAbbr = limitStatesTo[data.returnedStateCount+i];
                    coords = self._getStateCoordinates( stateAbbr );
                } else {
                    coords = {
                        state: '',
                        radius: 2000000,
                        latitude: '0',
                        longitude: '0'
                    }
                }
                //console.log('search term: ' + data.searchValue + ' ' + coords.state );
                if ( angular.isObject( coords ) ){
                    service.getPlacePredictions({
                        input: data.searchValue + ' ' + coords.state,
                        types: [ 'geocode' ],
                        componentRestrictions: {
                            country: [ 'us' ]
                        },
                        location: new google.maps.LatLng(coords.latitude,coords.longitude),
                        radius: coords.radius.toString()
                    }, onComplete );
                } else {
                    //console.log( stateAbbr + ' coordinates not found' );
                }                    
            }

            function onComplete( results, status ) {
                data.returnedStateCount++;

                console.log ( data.returnedStateCount + ' of ' +  data.queriedStateCount );
                var overLimit = false;

                if ( status == google.maps.places.PlacesServiceStatus.OK ) {
                    //console.log( '%cOK', 'color:green');
                    angular.forEach(results, function( prediction, key ){
                        var predState = prediction.terms[prediction.terms.length - 2].value;
                        //console.log('   result state:' + predState);
                        if (data.queryStates.indexOf(predState) !== -1) {
                            data.returnedAddresses.push(prediction);
                        };
                    });
                } else if ( status == google.maps.places.PlacesServiceStatus.OVER_QUERY_LIMIT) {
                    //console.log( '%ccaught OVER_QUERY_LIMIT', 'color:red');
                    //defer.resolve( data.returnedAddresses );
                } else {
                    //console.log( '%c' + status, 'color:orange');
                }

                if (data.queriedStateCount == data.returnedStateCount){
                    if ( data.returnedAddresses.length >= data.maximumAddressCount ){
                        console.log( 'hit max results' );
                        data.returnedAddresses.length = data.maximumAddressCount;
                        console.log( data );
                        defer.resolve( data.returnedAddresses );        
                    } else if ( data.queriedStateCount == data.queryStates.length || data.isNationalQuery ){
                        console.log( 'exhausted state list' );
                        console.log( data );
                        defer.resolve( data.returnedAddresses );
                    } else if ( data.returnedAddresses.length < data.maximumAddressCount && data.queriedStateCount < data.queryStates.length){
                        console.log( 'query more states' );
                        $timeout(function(){
                            getPlaces();
                        }, data.queryDelay);
                    } else {
                        console.log('fell through');
                        console.log(data);
                        defer.reject( 'geocode getValidatedAddresses error' );
                    }
                }
            }

            function onFinish( results, status ) {
                completeGeocodes++;
                if ( status == google.maps.places.PlacesServiceStatus.OK ) {
                    if ( results.length > 0 ) {
                        completeResults = completeResults.concat(results);
                    }
                } else {
                    defer.reject( status );
                }

                if (completeGeocodes == todoGeocodes){
                    if (completeResults.length > maxResults){
                        completeResults.length = maxResults;
                    }

                    defer.resolve( completeResults );
                    //console.log(completeResults);     
                }
            }
        }
    }

    /**
     * 
     * @param {String} address 
     */
    getLatLonFromAddress( address ) {
        var deferred = this.$q.defer();

        if ( typeof google === 'object' && typeof google.maps === 'object' ) {
            var geocoder = new google.maps.Geocoder();

            var addressData = {
                'address': address
            };

            geocoder.geocode( addressData, onReturn );

            return deferred.promise;
        } else {
            deferred.reject( 'Google Maps API is not present.' );
        }

        function onReturn( results, status ) {
            if ( status == google.maps.GeocoderStatus.OK ) {
                if ( status != google.maps.GeocoderStatus.ZERO_RESULTS ) {
                    deferred.resolve( results[ 0 ].geometry.location );
                }
            } else {
                deferred.reject( status );
            }
        }
    }

    /**
     * 
     * @param {String} placeId 
     */
    geocodePlaceId( placeId ) {
        var geocoder = new google.maps.Geocoder();
        var deferred = this.$q.defer();

        geocoder.geocode( {'placeId': placeId}, onFinish );
        return deferred.promise;

        function onFinish(results, status ){
            if ( status == google.maps.GeocoderStatus.OK ) {
                deferred.resolve( results );  
            } else {
                deferred.resolve( null );
            }

            
        }
    }

    _getStateCoordinates( abbr ) {
        let stateGeographicCenters = [
            { state: 'AL', latitude: '32.7794', longitude: '-86.8287', radius: 260000},
            { state: 'AK', latitude: '64.0685', longitude: '-152.2782', radius: 2000000},
            { state: 'AZ', latitude: '34.2744', longitude: '-111.6602', radius: 325000},
            { state: 'AR', latitude: '34.8938', longitude: '-92.4426', radius: 225000},
            { state: 'CA', latitude: '37.1841', longitude: '-119.4696', radius: 885000},
            { state: 'CO', latitude: '38.9972', longitude: '-105.5478', radius: 325000},
            { state: 'CT', latitude: '41.6219', longitude: '-72.7273', radius: 100000},
            { state: 'DE', latitude: '38.9896', longitude: '-75.5050', radius: 100000},
            { state: 'DC', latitude: '38.9101', longitude: '-77.0147', radius: 10000},
            { state: 'FL', latitude: '28.6305', longitude: '-82.4497', radius: 425000},
            { state: 'GA', latitude: '32.6415', longitude: '-83.4426', radius: 250000},
            { state: 'HI', latitude: '20.2927', longitude: '-156.3737', radius: 3000000},
            { state: 'ID', latitude: '44.3509', longitude: '-114.6130', radius: 10000},
            { state: 'IL', latitude: '40.0417', longitude: '-89.1965', radius: 315000},
            { state: 'IN', latitude: '39.8942', longitude: '-86.2816', radius: 410000},
            { state: 'IA', latitude: '42.0751', longitude: '-93.4960', radius: 260000},
            { state: 'KS', latitude: '38.4937', longitude: '-98.3804', radius: 365000},
            { state: 'KY', latitude: '37.5347', longitude: '-85.3021', radius: 325000},
            { state: 'LA', latitude: '31.0689', longitude: '-91.9968', radius: 325000},
            { state: 'ME', latitude: '45.3695', longitude: '-69.2428', radius: 300000},
            { state: 'MD', latitude: '39.0550', longitude: '-76.7909', radius: 300000},
            { state: 'MA', latitude: '42.2596', longitude: '-71.8083', radius: 175000},
            { state: 'MI', latitude: '44.3467', longitude: '-85.4102', radius: 810000},
            { state: 'MN', latitude: '46.2807', longitude: '-94.3053', radius: 365000},
            { state: 'MS', latitude: '32.7364', longitude: '-89.6678', radius: 300000},
            { state: 'MO', latitude: '38.3566', longitude: '-92.4580', radius: 300000},
            { state: 'MT', latitude: '47.0527', longitude: '-109.6333', radius: 490000},
            { state: 'NE', latitude: '41.5378', longitude: '-99.7951', radius: 365000},
            { state: 'NV', latitude: '39.3289', longitude: '-116.6312', radius: 435000},
            { state: 'NH', latitude: '43.6805', longitude: '-71.5811', radius: 175000},
            { state: 'NJ', latitude: '40.1907', longitude: '-74.6728', radius: 175000},
            { state: 'NM', latitude: '34.4071', longitude: '-106.1126', radius: 325000},
            { state: 'NY', latitude: '42.9538', longitude: '-75.5268', radius: 300000},
            { state: 'NC', latitude: '35.5557', longitude: '-79.3877', radius: 400000},
            { state: 'ND', latitude: '47.4501', longitude: '-100.4659', radius: 300000},
            { state: 'OH', latitude: '40.2862', longitude: '-82.7937', radius: 200000},
            { state: 'OK', latitude: '35.5889', longitude: '-97.4943', radius: 300000},
            { state: 'OR', latitude: '43.9336', longitude: '-120.5583', radius: 400000},
            { state: 'PA', latitude: '40.8781', longitude: '-77.7996', radius: 250000},
            { state: 'RI', latitude: '41.6762', longitude: '-71.5562', radius: 50000},
            { state: 'SC', latitude: '33.9169', longitude: '-80.8964', radius: 250000},
            { state: 'SD', latitude: '44.4443', longitude: '-100.2263', radius: 325000},
            { state: 'TN', latitude: '35.8580', longitude: '-86.3505', radius: 375000},
            { state: 'TX', latitude: '31.4757', longitude: '-99.3312', radius: 650000},
            { state: 'UT', latitude: '39.3055', longitude: '-111.6703', radius: 325000},
            { state: 'VT', latitude: '44.0687', longitude: '-72.6658', radius: 175000},
            { state: 'VA', latitude: '37.5215', longitude: '-78.8537', radius: 400000},
            { state: 'WA', latitude: '47.3826', longitude: '-120.4472', radius: 400000},
            { state: 'WV', latitude: '38.6409', longitude: '-80.6227', radius: 200000},
            { state: 'WI', latitude: '44.6243', longitude: '-89.9941', radius: 300000},
            { state: 'WY', latitude: '42.9957', longitude: '-107.5512', radius: 325000}
        ];
        for ( var i = stateGeographicCenters.length - 1; i >= 0; i-- ){
            if ( stateGeographicCenters[i].state.toUpperCase() == abbr.toUpperCase() ){
                return stateGeographicCenters[i];
                break;
            }
        }
        return null;
    }
}

Core.factory( 'geocodeService', ( $q, $timeout ) => new GeocodeFactory( $q, $timeout ));
