import Core from '../../modules/core/core.module';

/**
 * @name authInterceptor
 * @memberof CoreBundle.Core
 * @class
 * 
 * @classdesc
 * AngularJS factory.
 */
class AuthInterceptorFactory {
    constructor( $q, $injector, Core_API ) {
        this.$q = $q;
        this.$injector = $injector;
        this.Core_API = Core_API;

        this.isKeepAliveInFlight = false;
        this.Session = null;
        this.sessionTimeoutService = null;
        this.jwtService = null;
        this.ignoreRequests = [
            this.Core_API.ROUTE_INTERCEPT_IGNORE
        ];
        this.requestBuffer = [];

        this.request = this.request.bind( this );
        this.response = this.response.bind( this );
        this.responseError = this.responseError.bind( this );
    }

    /**
     * Catches the $http request prior to execution, allowing us to manipulate
     * it as needed. We're going to check the sessionTimeoutService for whether
     * to buffer the request in order to keep the session alive automatically.
     * 
     * @param {Object} config The initial $http request config object.
     * @returns {Object} The original $http request config object, or a promise to resolve the same.
     */
    request( config ) {
        console.debug( '[auth-interceptor] :: request() :: Starting.' );

        this.Session = this.Session || this.$injector.get( 'Session' );
        this.jwtService = this.jwtService || this.$injector.get( 'jwtService' );
        this.sessionTimeoutService = this.sessionTimeoutService || this.$injector.get( 'sessionTimeoutService' );

        let self = this,
            defer = this.$q.defer(),
            isValidRequest = ( config.url.indexOf( '/api/' ) !== -1 &&
                this.ignoreRequests.find( ignorePrefix =>
                    config.url.indexOf( ignorePrefix ) === 0 ) === undefined ),
            isKeepAliveEligible = ( this.sessionTimeoutService.shouldKeepAlive() );

        console.debug( '[auth-interceptor] :: request() :: Interceptable?', isValidRequest );
        console.debug( '[auth-interceptor] :: request() :: Keep-alive?', isKeepAliveEligible );

        // Only intercept if it's a watchable request, and the timeout service
        // has identified that the session is eligible for a keep-alive.
        if ( isValidRequest && isKeepAliveEligible ) {
            console.debug( '[auth-interceptor] :: request() :: Keep-alive needed; buffering original request.', config );

            this._addToRequestBuffer( config, defer );

            // Only attempt to extend if we're not in-flight already. Doing
            // so will cause collisions in product sessions that rely on JWTs.
            if ( !this.isKeepAliveInFlight ) {                
                this.isKeepAliveInFlight = true;
                console.debug( '[auth-interceptor] :: request() :: Keep-alive not already in-flight; flag set.', this.isKeepAliveInFlight );
                console.debug( '[auth-interceptor] :: request() :: Calling core.session.refreshSession()' );

                this.Session.refreshSession()
                    .then( newExpirationDate => {
                        newExpirationDate = getTypeCheckedValue( newExpirationDate, 'object', null );

                        console.debug( '[auth-interceptor] :: request() :: Completed core.session.refreshSession(); received new session expiration.', newExpirationDate );

                        // We should have a Date object if successful.
                        if ( newExpirationDate !== null ) {
                            this.isKeepAliveInFlight = false;
                            console.debug( '[auth-interceptor] :: request() :: Session keep-alive successful; flipping the flag back.', this.isKeepAliveInFlight );
                            console.debug( '[auth-interceptor] :: request() :: Retrying previously buffered requests.' );

                            this._retryRequestBuffer();
                        }

                        // Otherwise...
                        else {
                            this.isKeepAliveInFlight = false;
                            console.debug( '[auth-interceptor] :: request() :: Session keep-alive failed; flipping the flag back.', this.isKeepAliveInFlight );
                            console.debug( '[auth-interceptor] :: request() :: Rejecting previously buffered requests.' );

                            this._rejectRequestBuffer( failureResponses );
                        }
                    });
            } else {
                console.debug( '[auth-interceptor] :: request() :: Keep-alive already in-flight.' );
            }
        }

        // Otherwise, act as a passthrough by simply resolving the request config.
        else {

            // We eventually want all of the template files to be imported,
            // so let's log any that we find in order to track them down.
            if ( config.url && config.url.indexOf( '.html' ) !== -1 )
                console.debug( '[auth-interceptor] :: request() :: Template file requested; resolving.', config.url );
            else
                console.debug( '[auth-interceptor] :: request() :: Keep-alive not needed; resolving request.', config );
                
            defer.resolve( config );
        }

        console.debug( '[auth-interceptor] :: request() :: Returning promise.' );

        return defer.promise;
    }

    /**
     * Catches $http response events as they occur, allowing us to examine or
     * manipulate them. Which we do here. As the response may not yet be
     * resolved, we return either the response itself or a promise to resolve
     * it later.
     * 
     * @param {Object} response The $http response, if it's a proper response.
     * @returns {Object|Function} The $http response, or a promise to resolve to a $http response.
     */
    response( response ) {
        console.debug( '[auth-interceptor] :: response() :: Received response.', response );
        return response || this.$q.when( response );
    }

    /**
     * Catches $http response error events as they occur, allowing us to examine
     * them. Which we do here. This is primarily for debugging purposes.
     * 
     * @param {Object} response The $http rejection response.
     * @returns {Object} The $http rejection response. 
     */
    responseError( response ) {
        console.debug( '[auth-interceptor] :: responseError() :: Received response.', response );
        return response;
    }

    /**
     * Adds an $http request configuration object and $q promise mapping to
     * an array used to keep track of any API attempts that have occurred
     * while attempting to keep the session alive. This buffer will be
     * parsed - and each one either resolved or rejected - based on the
     * result of the keep-alive operation.
     * 
     * @param {Object} config The buffered $http request configuration.
     * @param {Object} defer The $q Promise object associated with the request.
     */
    _addToRequestBuffer( config, defer ) {
        console.debug( '[auth-interceptor] :: _addToRequestBuffer() :: Adding request config/defer to buffer.', config, defer );

        this.requestBuffer.push({
            config,
            defer
        });

        console.debug( `[auth-interceptor] :: _addToRequestBuffer() :: Buffer has ${this.requestBuffer.length} pending requests.` );
    }

    /**
     * This will loop the pending requests in the request buffer, and reject
     * them all. Should only be called when an attempt to keep session alive
     * has failed. Uses the provided failure response array to figure out
     * 
     * @param {Array} failureResponses An array of one or more product-based session extend failure responses.
     */
    _rejectRequestBuffer( failureResponses ) {
        failureResponses = getTypeCheckedValue( failureResponses, 'array', [] );
        console.debug( `[auth-interceptor] :: _rejectRequestBuffer() :: Rejecting ${this.requestBuffer.length} buffered requests with failure responses.`, failureResponses );

        for ( let i = 0; i < this.requestBuffer.length; i++ ) {
            let bufferedRequest = this.requestBuffer[ i ],
                bufferedConfig = bufferedRequest.config || {},
                rejectedResponse = this._getRejectionResponse( bufferedConfig, failureResponses ),
                defer = bufferedRequest.defer;

            console.debug( `[auth-interceptor] :: _rejectRequestBuffer() :: Rejecting buffered request ${i} with config and response.`, bufferedConfig, rejectedResponse );
            defer.reject( rejectedResponse );

            this.requestBuffer.splice( i, 1 );
            console.debug( `[auth-interceptor] :: _rejectRequestBuffer() :: Buffer has ${this.requestBuffer.length} requests remaining to reject.` );
        }

        if ( this.requestBuffer.length > 0 )
            console.debug( `[auth-interceptor] :: _rejectRequestBuffer() :: Warning! Buffer should contain 0 requests, but still has ${this.requestBuffer.length}.` );

        // We should have none remaining, but flush it just in case.
        this.requestBuffer = [];

        console.debug( '[auth-interceptor] :: _rejectRequestBuffer() :: Finished rejecting buffered requests.' );
    }

    /**
     * This will loop the pending requests in the request buffer, and attempt
     * to retry the initial request. Special care is given to requests meant for
     * API v2. When we find a V2 request, fetch the new access token from the
     * JWT provider, and attach it as a bearer token manually (we've subverted
     * the default JWT interceptor, so the buffered requests are unaware that
     * a new JWT should be used).
     */
    _retryRequestBuffer() {
        console.debug( `[auth-interceptor] :: _retryRequestBuffer() :: Retrying ${this.requestBuffer.length} buffered requests.` );

        for ( let i = 0; i < this.requestBuffer.length; i++ ) {
            let request = this.requestBuffer[ i ],
                config = request.config || {},
                headers = config.headers || {},
                defer = request.defer;

            // Attach the JWT?
            if ( headers.hasOwnProperty( 'Authorization' )) {
                let accessToken = this.jwtService.getAccessToken();
                headers.Authorization = `Bearer ${accessToken}`;
                config.headers = headers;

                console.debug( `[auth-interceptor] :: _retryRequestBuffer() :: Updated buffered request ${i} with new access token.`, config.headers.Authorization );
            }

            console.debug( `[auth-interceptor] :: _retryRequestBuffer() :: Retrying buffered request ${i} with config.`, config );
            defer.resolve( config );

            this.requestBuffer.splice( i, 1 );
            console.debug( `[auth-interceptor] :: _retryRequestBuffer() :: Buffer has ${this.requestBuffer.length} requests remaining to retry.` );
        }

        if ( this.requestBuffer.length > 0 )
            console.debug( `[auth-interceptor] :: _retryRequestBuffer() :: Warning! Buffer should contain 0 requests, but still has ${this.requestBuffer.length}.` );

        // We should have none remaining, but flush it just in case.
        this.requestBuffer = [];

        console.debug( '[auth-interceptor] :: _retryRequestBuffer() :: Finished retrying buffered requests.' );
    }

    /**
     * Attempts to find the appropriate keep-alive rejection response to
     * use for the provided $http request configuration. In short, if we
     * buffered calls to V1 during keep-alive, we need to reject that call
     * with the response of the V1 keep-alive. We parse the buffered config
     * URL for its appropriate base (either /api/v2 or /api), and then
     * find the rejected keep-alive response that starts with that base.
     * If we can't find one, we return a generic generated response object.
     * 
     * @see https://docs.angularjs.org/api/ng/service/$http#$http-returns
     * 
     * @param {Object} config The buffered $http request configuration.
     * @param {Array} responses The keep-alive rejection responses returned from session-timeout's "extend" resolve.
     * @returns {String\null} The version-specific API route base.
     */
    _getRejectionResponse( config, responses ) {
        config = getTypeCheckedValue( config, 'object', {} );
        responses = getTypeCheckedValue( responses, 'array', [] );

        console.debug( '[auth-interceptor] :: _getRejectionResponse() :: Parsing rejection response for config with responses.', config, responses );

        let response = null,
            routeBase = null,
            configUrl = getTypeCheckedValue( config.url, 'string', null );

        if ( configUrl !== null ) {

            // Check for product-specific API hosts.
            if ( configUrl.indexOf( this.Core_API.ROUTE_BASE_COMMON ) !== -1 )
                routeBase = this.Core_API.ROUTE_BASE_COMMON;
            else if ( configUrl.indexOf( this.Core_API.ROUTE_BASE_MARKETMAGNIFIER ) !== -1 )
                routeBase = this.Core_API.ROUTE_BASE_MARKETMAGNIFIER;
            else if ( configUrl.indexOf( this.Core_API.ROUTE_BASE_PROSPECTBASE ) !== -1 )
                routeBase = this.Core_API.ROUTE_BASE_PROSPECTBASE;

            console.debug( '[auth-interceptor] :: _getRejectionResponse() :: Parsed route base.', routeBase );

            if ( routeBase !== null ) {
                let filteredResponses = responses.filter( res => {
                    res = getTypeCheckedValue( res, 'object', {} );

                    let resConfig = getTypeCheckedValue( res.config, 'object', {} ),
                        resRoute = getTypeCheckedValue( resConfig.url, 'string', null );

                    return (
                        resRoute !== null &&
                        resRoute.indexOf( routeBase ) !== -1
                    );
                });

                if ( filteredResponses.length > 0 )
                    response = filteredResponses[ 0 ];
            }
        }

        if ( response === null ) {
            response = {
                data: null,
                status: 401,
                config,
                statusText: 'auth.refresh-invalid',
                xhrStatus: 'complete'
            };
        }

        console.debug( '[auth-interceptor] :: _getRejectionResponse() :: Returning parsed rejection response.', response );
        return response;
    }
}

Core.factory( 'authInterceptor', ( $q, $injector, Core_API ) => new AuthInterceptorFactory( $q, $injector, Core_API ));
