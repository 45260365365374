const SEP = '__';

/**
 * Object model facilitating file upload preparation, streaming,
 * and validation.
 */
class FileUploadModel {

    /**
     * Constructor.
     * 
     * @param {number} userId 
     * @param {number} orderId 
     * @param {File} file 
     */
    constructor( userId, orderId, file ) {
        this.userId = getTypeCheckedValue( userId, 'int', null );
        this.orderId = getTypeCheckedValue( orderId, 'int', null );
        this.file = getInstanceCheckedValue( file, File, null );
        this.reader = null;

        this.size = null;
        this.modified = null;
        this.filename = null;
        this.preflightId = null;
        this.fileId = null;
        this.chunk = 1;
        this.chunks = 0;
        this.chunkSize = 1048576;
        this.productType = null;

        this.valid = false;
        this.validAfterPreflight = false;
        this.uploading = false;
        this.stopped = false;
        this.paused = false;

        this.onChunkRead = ( fileId, chunk, data ) => {};
        this.onChunkUploaded = ( filename, chunk, chunks ) => {};
        this.onFinished = ( filename ) => {};
        
        if ( this.userId === null ||
            this.orderId === null ||
            this.file === null ) {
            
            console.warn( 'File upload model requires userId, orderId, and file.' );
            return;
        }

        this.size = this.file.size;
        this.modified = this.file.lastModified;
        this.filename = decodeURIComponent( this.file.name );
        this.preflightId = `${this.orderId}${SEP}${this.filename}${SEP}${this.size}${SEP}${this.modified}`;

        this.reader = new FileReader();
        this.reader.onabort = this.onReaderAbort.bind( this );
        this.reader.onerror = this.onReaderError.bind( this );
        this.reader.onload = this.onReaderLoad.bind( this );

        this.valid = (
            this.size !== null &&
            this.modified !== null &&
            this.filename !== null &&
            this.preflightId !== null
        );
    }

    upload() {
        if ( !this.uploading ) {
            this.uploading = true;
            this.read();
        }
    }

    validateOperationResponse( response ) {
        console.log( 'validateOperationResponse: Starting', response );
        if ( response !== undefined &&
            response !== null &&
            response.fileId === `${this.fileId}${SEP}${this.chunk}` ) {
            
            // Send the next chunk.
            if ( response.complete && !response.finished ) {
                console.log( 'validateOperationResponse: Calling onChunkUploaded', { filename: this.filename, chunk: this.chunk, chunks: this.chunks } );

                this.onChunkUploaded( this.filename, this.chunk, this.chunks );

                this.chunk += 1;
                this.read();
            }

            // Otherwise.
            else if ( response.finished ) {
                console.log( 'validateOperationResponse: response.finished == true', response );

                this.uploading = false;
                this.stopped = false;
                this.paused = false;
                this.reader.removeEventListener( 'load', this.onReaderLoad );

                if ( this.onFinished !== undefined &&
                    this.onFinished !== null ){
                        console.log( 'validateOperationResponse: Calling onFinished', this.filename );
                        this.onFinished( this.filename );
                    }
            }
        }
    }

    validatePreflightResponse( response ) {
        if ( response === undefined ||
            response === null ||
            response.userId === null ||
            response.userId !== this.userId ||
            response.orderId === null ||
            response.orderId !== this.orderId ||
            response.productType === null ||
            response.preflightId === null ||
            response.preflightId !== this.preflightId ||
            response.fileId === null ||
            response.filename === null ||
            response.filename !== this.filename ) {
            
            this.validAfterPreflight = false;
            return;
        }

        this.productType = response.productType;
        this.fileId = response.fileId;
        this.chunk = response.chunk;
        this.chunks = response.chunks;
        this.chunkSize = response.chunkSize;
        this.validAfterPreflight = true;
    }

    onReaderAbort( e ) {

    }

    onReaderError( e ) {

    }

    onReaderLoad( e ) {
        if ( this.stopped )
            return;

        if ( e.target && e.target.result ) {
            const data = e.target.result;
            const chunk = this.chunk;
            const fileId = `${this.fileId}${SEP}${chunk}`;
            
            if ( this.onChunkRead !== undefined &&
                this.onChunkRead !== null ){
                    console.log('onReaderLoad: Calling onChunkRead', { fileId: fileId, chunk: chunk, data: data });
                    this.onChunkRead( fileId, chunk, data );
                }
        }
    }

    read() {
        if ( this.paused ||
            this.stopped ||
            this.reader.readyState === 1 )
            return;

        const chunk = this.file.slice(
            this.startByte(),
            this.endByte()
        );
        this.reader.readAsArrayBuffer( chunk );
    }

    endByte() {
        return Math.min(
            this.startByte() + this.chunkSize,
            this.size
        );
    }

    startByte() {
        return ( this.chunk > 1 )
            ? ( this.chunk - 1 ) * this.chunkSize
            : 0;
    }
}

export default FileUploadModel;