import Core from '../../modules/core/core.module';

// Name of the IndexedDB instance.
const DB_NAME = 'AR';

class IndexedDb {

    constructor() {
        this.db = null;
    }

    addRecord( table, key, value ) {
        const self = this;

        return new Promise(( resolve, reject ) => {
            console.debug( `[IndexedDB] :: addRecord :: Opening...` );

            let response = this._open( false );

            // Handle error...
            response.onerror = ( event ) => {
                console.error( `[IndexedDB] :: addRecord :: Open error.`, event );
                reject( event );
            };

            // Handle success...
            response.onsuccess = ( event ) => {
                console.debug( `[IndexedDB] :: addRecord :: Opened. Adding key "${key}" to table "${table}".` );
                self.db = event.target.result;

                let txn = self.db.transaction( table, 'readwrite' );
                let store = txn.objectStore( table );
                let query = store.put( value, key );

                query.onsuccess = ( _ ) => {
                    console.debug( `[IndexedDB] :: addRecord :: Added key "${key}" to table "${table}".` );
                };

                query.onerror = ( err ) => {
                    console.error( `[IndexedDB] :: addRecord :: Error adding key "${key}" to table "${table}".`, err );
                };

                txn.oncomplete = () => self.db.close();
                resolve();
            };
        });
    }

    clearAllRecords( table ) {
        const self = this;

        return new Promise(( resolve, reject ) => {
            console.debug( `[IndexedDB] :: clearAllRecords :: Opening...` );

            let response = this._open( false );

            // Handle error...
            response.onerror = ( event ) => {
                console.error( `[IndexedDB] :: clearAllRecords :: Open error.`, event );
                reject( event );
            };

            // Handle success...
            response.onsuccess = ( event ) => {
                console.debug( `[IndexedDB] :: clearAllRecords :: Opened. Clearing table "${table}".` );
                self.db = event.target.result;

                let txn = self.db.transaction( table, 'readwrite' );
                let store = txn.objectStore( table );
                let query = store.clear();

                query.onsuccess = ( _ ) => {
                    console.debug( `[IndexedDB] :: clearAllRecords :: Cleared table "${table}".` );
                };

                query.onerror = ( err ) => {
                    console.error( `[IndexedDB] :: clearAllRecords :: Error clearing table "${table}".`, err );
                };

                txn.oncomplete = () => self.db.close();
                resolve();
            };
        });
    }

    getByKey( table, keys ) {
        const self = this;

        return new Promise(( resolve, reject ) => {
            self._log( 'getByKey', 'Connecting to database...' );

            let conn = this._open( false );

            // Handle connection error...
            conn.onerror = ( event ) =>
                self._onOpenError( 'getByKey', event, reject );

            // Handle success...
            conn.onsuccess = ( event ) => {
                self._log( 'getRowCount', `Database connected. Fetching by keys for table "${table}".`, false, { keys });
                self.db = event.target.result;

                let txn = self.db.transaction( table, 'readonly' );
                let store = txn.objectStore( table );

                Promise
                    .all( keys.map( key => new Promise(( res, rej ) => {
                        const req = store.get( key );
                        req.onsuccess = ({ target: { result }}) => res( result );
                        req.onerror = ({ target: { error }}) => rej( error );
                    })))
                    .then( values => {
                        let results = [];
                        values.forEach(( key, value ) =>
                            results = results.concat( key ));

                        resolve( results );
                    });
            };
        });
    }

    getRowCount( table ) {
        const self = this;

        return new Promise(( resolve, reject ) => {
            self._log( 'getRowCount', 'Connecting to database...' );

            let conn = this._open( false );

            // Handle connection error...
            conn.onerror = ( event ) =>
                self._onOpenError( 'getRowCount', event, reject );

            // Handle success...
            conn.onsuccess = ( event ) => {
                self._log( 'getRowCount', `Database connected. Fetching row count for table "${table}".` );
                self.db = event.target.result;

                let txn = self.db.transaction( table, 'readwrite' );
                let store = txn.objectStore( table );
                let query = store.count();

                query.onsuccess = ( _ ) => {
                    const count = query.result;
                    self._log( 'getRowCount', `Received row count of ${count} for table "${table}".` );
                    resolve( count );
                };

                query.onerror = ( err ) => {
                    self._log( 'getRowCount', `Failed fetching row count for table "${table}".`, true, err );
                    reject( err );
                };

                txn.oncomplete = () => self.db.close();
            };
        });
    }

    open() {
        const self = this;

        return new Promise(( resolve, reject ) => {
            self._log( 'open', 'Connecting to database...' );

            let conn = this._open( true );

            // Handle upgrade requirement...
            conn.onupgradeneeded = ( event ) => {  
                self._log( 'open', 'Upgrade needed...' );  
                self.db = event.target.result;

                // Geo FIPS (County) Store
                let fipsStore = self.db.createObjectStore( 'geoFips', { autoIncrement: false });
                fipsStore.createIndex( 'geoFips', 'geoFips', { unique: true });

                // Geo City, State, Zip Store
                let cszStore = self.db.createObjectStore( 'geoCSZ', { autoIncrement: false });
                cszStore.createIndex( 'geoCSZ', 'geoCSZ', { unique: true });
            };

            // Handle connection error...
            conn.onerror = ( event ) =>
                self._onOpenError( 'open', event, reject );

            // Handle connection success...
            conn.onsuccess = ( event ) => {
                self._log( 'open', 'Connected to database.' );
                self.db = event.target.result;
                self.db.close();
                resolve();
            };
        });
    }

    _onOpenError( func, err, callback ) {
        this._log( func, 'Error opening database.', true, err );
        callback( err );
    }

    _log( func, message, err = false, args = undefined ) {
        const msg = `[IndexedDB] :: ${func} :: ${message}`;
        const log = err ? 'error' : 'debug';

        if ( args !== undefined )
            console[ log ]( msg, args );
        else
            console[ log ]( msg );
    }

    _open( incrementVersion ) {
        if ( this.db === null || !incrementVersion )
            return window.indexedDB.open( DB_NAME );

        let version = 1;
        if ( this.db !== null )
            version = this.db.version + 1;
        
        return window.indexedDB.open( DB_NAME, version );
    }
}

Core.service( 'indexedDbService', IndexedDb );