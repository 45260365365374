import Core from '../../../modules/core/core.module';
import languageSelectTemplate from './language-select.template.html';

/**
 * @name LanguageSelect
 * @memberof CoreBundle.Core
 * @class
 * 
 * @classdesc
 * AngularJS directive. Facilitates switching between supported locales for content display via
 * internationalization.
 */
class LanguageSelectController {
    constructor( $scope, i18nProvider ) {
        this.$scope = $scope;
        this.i18nProvider = i18nProvider;

        this.$scope.currentLocaleDisplayName = this.i18nProvider.getLocaleDisplayName();
        this.$scope.localesDisplayNames = this.i18nProvider.getLocalesDisplayNames();
        this.$scope.visible = this.$scope.localesDisplayNames && this.$scope.localesDisplayNames.length > 1;
        this.$scope.changeLanguage = this.changeLanguage.bind( this );
    }

    changeLanguage( locale ) {
        this.i18nProvider.setLocaleByDisplayName( locale );
    }
}

const languageSelect = {
    template: languageSelectTemplate,
    controller: LanguageSelectController
};

Core.component( 'languageSelect', languageSelect );
