import Core from '../../../modules/core/core.module';

/**
 * @name DisableAnimate
 * @memberof CoreBundle.Core
 * @class
 * 
 * @classdesc
 * AngularJS directive. An element that - when clicked - launches and displays
 * live chat functionality using the RightNow service for the purposes of user
 * help and support. This is a product-agnostic global directive within the
 * Acquisition & Retention portal.
 * 
 * @example
 * <span data-disable-animate>Don't animate me.</span>
 */
class DisableAnimate {
    constructor( $animate ) {
        this.$animate = $animate;

        this.restrict = 'A';
        this.scope = false;
    }

    link( scope, element ) {
        this.$animate.enabled( element, false );
    }
}

Core.directive( 'disableAnimate', ( $animate ) => new DisableAnimate( $animate ));
