import Core from '../../modules/core/core.module';

/**
 * @name i18n
 * @memberof CoreBundle.Core
 * @class
 * 
 * @classdesc
 * AngularJS factory.
 */
class i18nFactory {
    constructor( $translate, LOCALES ) {
        this.$translate = $translate;
        this.LOCALES = LOCALES;

        this.localeKey = LOCALES.preferredLocale;
        this.initialLocales = [ 'en_US' ];
    }

    changeLocale( localeKey ) {
        if ( typeof localeKey == 'string' ) {
            const localeMatch = Object.keys( this.LOCALES.locales ).find( lk => lk === localeKey );
            if ( localeMatch !== undefined ) {
                this.$translate.use( localeKey );
                this._initializeLocaleFile( localeKey );
                this.localeKey = localeKey;
            }
        }
    }

    getLocale() {
        return this.localeKey;
    }

    _initializeLocaleFile( locale ) {
        if ( this.initialLocales.indexOf( locale ) === -1 && locale.length >= 2 ) {
            this.initialLocales.push( locale );
            let body = document.body || document.getElementsByTagName( 'body' )[ 0 ],
                script = document.createElement( 'script' );

            script.src = `./locale/${locale}.js`;
            body.appendChild( script );
        }
    }
}

Core.factory( 'i18n', i18nFactory );
