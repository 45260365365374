import Core from '../../../modules/core/core.module';
import notificationItemTemplate from './notification-item.template.html';
import NotificationModel from '../../../models/core/notification.model';
import './notifications.styles.scss';

/**
 * @name NotificationItemController
 * @memberof CoreBundle.Core
 * @class
 * 
 * @classdesc
 * AngularJS component.
 */
class NotificationItemController {
    constructor( $scope, GLOBALS, modalService, eventService ) {
        this.$scope = $scope;
        this.GLOBALS = GLOBALS;
        this.modalService = modalService;
        this.eventService = eventService;
    }

    $onInit() {
        const self = this;

        this._parseNotification = this._parseNotification.bind( this );
        this.$scope.launchContactSupport = this.launchContactSupport.bind( this );
        this.$scope.dismissPersistentNotification = this.dismissPersistentNotification.bind( this );
        
        this.$scope.showPersistence = ( this.showPersistence && this.showPersistence );
        this._parseNotification( this.notificationObj );

        this.$scope.$watch(
            function() { return self.notificationObj; },
            function( notificationObj ) { self._parseNotification( notificationObj ); }
        );
    }

    dismissPersistentNotification() {
        if ( this.$scope.showPersistence ) {
            this.eventService.broadcastPersistentNotification( null );
        }
    }

    launchContactSupport() {
        this.modalService.showModal(
            this.GLOBALS.MODAL.CONTACT_SUPPORT.DEFAULTS,
            this.GLOBALS.MODAL.CONTACT_SUPPORT.OPTIONS
        );
    }

    _parseNotification( notification ) {
        const self = this;

        if ( NotificationModel.isInstanceOf( notification )) {
            Object.keys( notification ).forEach( propKey => {
                self.$scope[ propKey ] = notification[ propKey ];
            });
        }
    }
}

const notificationItem = {
    template: notificationItemTemplate,
    controller: NotificationItemController,
    bindings: {
        showPersistence: '<?',
        notificationObj: '<?'
    }
};

Core.component( 'notificationItem', notificationItem );