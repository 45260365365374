import Core from '../../../modules/core/core.module';
import splashContentTemplate from './splash-content.template.html';

/**
 * @name splashContent
 * @memberof CoreBundle.Core
 * @class
 * 
 * @classdesc
 * AngularJS component. A configurable splash screen that appears until the DOM
 * and AngularJS is ready.
 */
class SplashContentController {
    constructor( $scope, $window, $translate, GLOBALS ) {
        this.$scope = $scope;
        this.$window = $window;
        this.$translate = $translate;
        this.GLOBALS = GLOBALS;
    }

    $onInit() {
        this.$scope.display = {
            isMessagesShown: this.GLOBALS.SPLASH_OPTIONS.IS_MESSAGES_SHOWN,
            isMessagesRandom: this.GLOBALS.SPLASH_OPTIONS.IS_MESSAGES_RANDOM,
            messageRotateTime: ( this.GLOBALS.SPLASH_OPTIONS.MESSAGE_ROTATE_TIME * 1000 ),
            availableMessages: [],
            currentMessageIndex: 0
        };

        this.$scope.initialize = this.initialize.bind( this );
    }

    initialize() {
        if ( this.$scope.display.isMessagesShown ) {
            this.$translate( 'global.loading.splash.messages' ).then( function( messageStr ) {
                this.$scope.display.availableMessages = messageStr.split( '##' );
                this.$window.splashMessageRefresh = setInterval( this.refreshLoadingMessage, this.$scope.display.messageRotateTime );
            });
        }

        function onFail( translateId ) {
            console.log( translateId );
        }
    }

    refreshLoadingMessage() {
        this.$scope.display.currentMessageIndex =
            ( this.$scope.display.isMessagesRandom )
            ? Math.round( Math.random() * ( this.$scope.display.availableMessages.length - 1 ))
            : (
                ( this.$scope.display.currentMessageIndex === this.$scope.display.availableMessages.length - 1 ) 
                ? 0 
                : this.$scope.display.currentMessageIndex + 1 
            );
    }
}

const splashContent = {
    template: splashContentTemplate,
    controller: SplashContentController
};

Core.component( 'splashContent', splashContent );
