import Core from '../../modules/core/core.module';
import APIResponseModel from '../../models/core/api-response.model';
import AuthResponseModel from '../../models/core/auth-response.model';

/**
 * @name authService
 * @memberof CoreBundle.Core
 * @class
 * 
 * @classdesc
 * AngularJS factory.
 */
class AuthFactory {
    constructor( $http, $q, Core_API ) {
        this.$http = $http;
        this.$q = $q;
        this.Core_API = Core_API;
    }

    /**
     * Refreshes (extends) an existing user session.
     * 
     * @param {string} accessToken 
     * @param {string} refreshToken 
     */
    refresh( accessToken, refreshToken ) {
        accessToken = getTypeCheckedValue( accessToken, 'string', null );
        refreshToken = getTypeCheckedValue( refreshToken, 'string', null );

        let defer = this.$q.defer(),
            headers = {
                'X-Access-Token': accessToken,
                'Authorization-Refresh': refreshToken
            };

        // To make things cleaner, we're not pre-validating. The refresh
        // route should be called every time, so there's only one place
        // to look for issues.
        this.$http
            .get( this.Core_API.ROUTE_TOKEN_REFRESH, {
                headers: headers
            })
            .then(
                // Success...
                ( response ) => {
                    response = getTypeCheckedValue( response, 'object', {} );

                    if ( response.status === 401 ) {
                        defer.resolve( new APIResponseModel( response.status, response.statusText ));
                    } else {
                        let authResponse = AuthResponseModel.fromObj( response.data );
                        defer.resolve( authResponse );
                    }
                },

                // Failure...
                ( err ) => {
                    console.warn( err );
                    defer.resolve( new APIResponseModel( response.status, response.statusText ));
                }
            );
        
        return defer.promise;
    }

    /**
     * Revokes an existing session by access token.
     * 
     * @param {string} accessToken 
     */
    logout( accessToken ) {
        accessToken = getTypeCheckedValue( accessToken, 'string', null );

        let defer = this.$q.defer(),
            headers = {
                'X-Access-Token': accessToken
            };;

        // To make things cleaner, we're not pre-validating. The refresh
        // route should be called every time, so there's only one place
        // to look for issues.
        this.$http
            .get( this.Core_API.ROUTE_SESSION_LOGOUT, {
                headers: headers
            })
            .then(
                // Success...
                ( response ) => {
                    response = getTypeCheckedValue( response, 'object', {} );

                    if ( response.status === 401 ) {
                        defer.resolve( new APIResponseModel( response.status, response.statusText ));
                    } else {
                        let authResponse = AuthResponseModel.fromObj( response.data );
                        defer.resolve( authResponse );
                    }
                },

                // Failure...
                ( err ) => {
                    console.warn( err );
                    defer.resolve( new APIResponseModel( response.status, response.statusText ));
                }
            );

        return defer.promise;        
    }

    /**
     * For users with multi-account product access, this calls the
     * appropriate API route to change the "session" (active) account. The
     * API is responsible for making whatever changes are necessary, and
     * returning acknowledgement (legacy API) or fresh tokens (v2).
     * 
     * @param {string} productId The internal product identifier (e.g., "pb", "mm").
     * @param {int} accountId The unique account identifier to set as the session account.
     */
    setAccount( productId, accountId ) {
        let defer = this.$q.defer(),
            data = {
                product: productId,
                accountId: accountId
            };

        this.$http.post( this.Core_API.ROUTE_SESSION_ACCOUNT, data )
            .then(
                // Success...
                ( response ) => {
                    response = getTypeCheckedValue( response, 'object', {} );

                    if ( response.status === 401 ) {
                        defer.resolve( new APIResponseModel( response.status, response.statusText ));
                    } else {
                        let authResponse = AuthResponseModel.fromObj( response.data );
                        defer.resolve( authResponse );
                    }
                },

                // Failure...
                ( err ) => {
                    console.warn( err );
                    defer.resolve(new APIResponseModel(err.status, err.statusText));
                }
            );

        return defer.promise;
    }

    acceptLegal( userId, userName, firstName, lastName ){
        let defer = this.$q.defer(),
            payload = {
                userId: userId,
                userName: userName,
                firstName: firstName,
                lastName: lastName
            };

        this.$http.post( this.Core_API.ROUTE_LEGAL_ACCEPTANCE, payload)
            .then( 
                ( response ) => {
                    defer.resolve( true );
                },
                ( err ) => {
                    defer.resolve( false );
                }
            );

        return defer.promise;
    }
}

Core.factory( 'authService', ( $http, $q, Core_API ) => new AuthFactory( $http, $q, Core_API ));
