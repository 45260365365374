import Core from '../../modules/core/core.module';
import { SignalRHubBaseModel } from '../../models/core';

class SignalRAccountHub extends SignalRHubBaseModel {
    constructor( SIGNALR_CONSTANTS ) {
        'ngInject';
        super( SIGNALR_CONSTANTS, 'account' );

        this.hasResumableLPOrders = false;
        this.hasResumableDAOrders = false;
        this.hasResumableOrders = false;
        this.hasSavedSearches = false;

        this.subscriptions.onResumableOrders = [];
        this.subscriptions.onSavedSearches = [];
    }

    /**
     * Callback fired once connection has been established with the hub. In this
     * instance we immediately fetch a list of Resumable orders and Saved Searches.
     */
    _onConnected() {
        this.log( '_onConnected', 'Requesting notification list.' );
        this._getResumableOrders();
        this._getSavedSearches();
    }

    //
    // CLIENT-TO-SERVER HANDLERS
    //
    _getResumableOrders() {
        this.log( '_getResumableOrders', 'Calling...' );
        //this.client.invoke( 'PendingResumableOrders' );
    }

    _getSavedSearches() {
        this.log( '_getSavedSearches', 'Calling...' );
        //this.client.invoke( 'SavedSearches' );
    }

    //
    // SERVER-TO-CLIENT HANDLERS
    //
    _handleResumableOrders( reply ) {
        this.log( '_handleResumableOrders', 'Received.', reply );

        reply = getTypeCheckedValue( reply.resumable, 'array', {} );
        this.hasResumableOrders = ( reply.length > 0 );
        this.hasResumableDAOrders = ( reply.filter( o => o.orderType === 1 ).length > 0 );
        this.hasResumableLPOrders = ( reply.filter( o => o.orderType === 3 ).length > 0 );
        this._handleSubscription( 'onResumableOrders', reply );
    }

    _handleSavedSearches( reply ) {
        this.log( '_handleSavedSearches', 'Received.', reply );

        reply = getTypeCheckedValue( reply.savedSearches, 'object', {} );
        let count = getTypeCheckedValue( Object.keys( reply ).length, 'int', 0 );
        this.hasSavedSearches = ( count > 0 );
        this._handleSubscription( 'onSavedSearches', reply );
    }

    _get() {
        return {
            getResumableOrders: () => this._getResumableOrders(),
            getSavedSearches: () => this._getSavedSearches(),
            hasResumableDAOrders: () => this.hasResumableDAOrders,
            hasResumableLPOrders: () => this.hasResumableLPOrders
        };
    }
}

Core.provider( 'signalRAccountHub', SignalRAccountHub );