import Core from '../../../modules/core/core.module';

/**
 * @name DisableAutocomplete
 * @memberof CoreBundle.Core
 * @class
 * 
 * @classdesc
 * AngularJS directive. Form elements with this attribute will attempt some
 * scandalous trickery to disable the browser's auto-complete functionality,
 * primarily because Google has added some things to make an end-run around
 * developers. This is a product-agnostic global directive within the
 * Acquisition & Retention portal.
 * 
 * @example
 * <input type="text" data-disable-autocomplete />
 */
class DisableAutocomplete {
    constructor() {
        this.restrict = 'A';
    }

    link( scope, element, attrs ) {
        // In Firefox, it tries to guess on password fields if the one above it is the same.
        let type = element.attr( 'type' ) || 'text';

        // Chrome attempts to be slick by guessing on the name, so replicate a shadow name.
        let name = element.attr( 'name' ) || '';

        // Let's boogie.
        let shadowName = `${name}_shadow`,
            shadowElem = angular.element( `<input type="${type}" name="${shadowName}" aria-label="${shadowName}" style="display:none">` );
        element.parent()[ 0 ].insertBefore( shadowElem[ 0 ], element[ 0 ]);
    }
}

Core.directive( 'disableAutocomplete', () => new DisableAutocomplete());
