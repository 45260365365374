import Core from '../../../modules/core/core.module';
import fontAwesomeCheckboxTemplate from './font-awesome-checkbox.template.html';

import './font-awesome-checkbox.styles.scss';

/**
 * @name fontAwesomeCheckbox
 * @memberof CoreBundle.Core
 * @class
 * 
 * @classdesc
 * AngularJS component. Displays the list of order flow steps
 */
class FontAwesomeCheckboxController {
    constructor( $scope ) {
        this.$scope = $scope;   
    }

    $onInit() {
        const self = this;

        this.$scope.model = this.model;
        this.$scope.onChange = this.onChange;
        
        this.$scope.isDisabled = angular.isDefined(this.isDisabled) ? this.isDisabled : false;
        this.$scope.onChangePayload = angular.isDefined(this.onChangePayload) ? this.onChangePayload : null;

        this.$scope.$watch(
            function () { return angular.isDefined( self.isDisabled ) ? self.isDisabled : false; },
            function ( isDisabled ) { self.$scope.isDisabled = isDisabled }
        );
    }
}

const fontAwesomeCheckbox = {
    template: fontAwesomeCheckboxTemplate,
    controller: FontAwesomeCheckboxController,
    bindings: {
        isDisabled: '=?',
        model: '=',
        onChange: '&',
        onChangePayload: '=?',
    }
};

Core.component( 'fontAwesomeCheckbox', fontAwesomeCheckbox );
