// Local dependencies.
import Core from '../../modules/core/core.module';
import WSChannelBaseModel from '../../models/core/ws-channel-base.model';
import FileUploadModel from '../../models/core/file-upload.model';
import NotificationModel from '../../models/core/notification.model';

/**
 * @name wsFileUpload
 * @memberof CoreBundle.Core
 * @class
 * 
 * @classdesc
 * AngularJS provider that manages and maintains WebSocket interaction with the
 * FileUploadNotification service using socket.io.
 */
class WSFileUpload extends WSChannelBaseModel {
    constructor( GLOBALS, WS_CONSTANTS, WS_EVENTS ) {
        'ngInject';
        super( 'file_upload', GLOBALS, WS_CONSTANTS, WS_EVENTS );

        this.ready = false;
        this.fragmentSize = null;
        this.hasResumableOrders = false;
        this.productKey = null;
        this.productType = null;
        this.upload = null;

        // File upload-specific callback registrations.
        this.eventSubscriptions.onReady = [];
        this.eventSubscriptions.onUploadEnabled = [];
        this.eventSubscriptions.onUploadInProgress = [];
        this.eventSubscriptions.onUploadErrorReady = [];
        this.eventSubscriptions.onUploadErrorAppend = [];
        this.eventSubscriptions.onUploadErrorMove = [];
        this.eventSubscriptions.onUploadProgress = [];
        this.eventSubscriptions.onUploadComplete = [];
        this.eventSubscriptions.onUploadCancelled = [];
        this.eventSubscriptions.onMappingComplete = [];
        this.eventSubscriptions.onMappingError = [];
        this.eventSubscriptions.onReadyToValidateComplete = [];
        this.eventSubscriptions.onReadyToValidateError = [];
        this.eventSubscriptions.onValidationComplete = [];
        this.eventSubscriptions.onValidationError = [];
        this.eventSubscriptions.onOrderComplete = [];
    }

    /**
     * When the channel has connected, request the service-side configuration
     * for streaming file uploads between client and service.
     */
    _onChannelConnect() {
        this.socket.emit( this.WS_EVENTS.FileUpload.Publish.GetConfig );
    }

    /**
     * Channel-specific socket event handler fired when the FileUploadNotification's
     * "file_upload" channel has returned the service-side configuration for
     * streaming file uploads between client and service.
     * 
     * @param {object} payloadObj Object containing streaming upload configuration.
     */
    _handleConfig( payloadObj ) {
        this._log( '_onConfig response.', payloadObj );

        payloadObj = getTypeCheckedValue( payloadObj, 'object', {} );
        this.fragmentSize = getTypeCheckedValue( payloadObj.fragmentSize, 'int', null );
        this.ready = ( this.fragmentSize !== null );
        this._handleSubscription( 'onReady', this.ready );
    }

    /**
     * Channel-specific socket event handler fired when the FileUploadNotification's
     * "file_upload" channel has returned upload initialization data for whether
     * a proposed upload can occur.
     * 
     * @param {object} payloadObj Initialization data for a proposed upload.
     */
    _handleUploadReady( payloadObj ) {
        this._log( '_handleUploadReady response.', payloadObj );

        payloadObj = getTypeCheckedValue( payloadObj, 'object', {} );
        let productType = getTypeCheckedValue( payloadObj.productType, 'string', null ),
            filenameSource = getTypeCheckedValue( payloadObj.filenameSource, 'string', null );

        if ( productType !== null &&
            filenameSource !== null &&
            this._isFileUploadValid() ) {
                
            if ( this.upload.productType === productType &&
                this.upload.name === filenameSource ) {
                this.upload.handleUploadReady( payloadObj );
            } else {
                this._handleSubscription( 'onUploadErrorReady' );
                this._inFlightErrorNotification( 'ready' );
            }
        } else {
            this._handleSubscription( 'onUploadErrorReady' );
            this._inFlightErrorNotification( 'ready' );
        }
    }

    /**
     * Channel-specific socket event handler fired when the FileUploadNotification's
     * "file_upload" channel has returned acceptance and progress of an in-flight
     * upload's stream.
     * 
     * @param {object} payloadObj Progress data for an in-flight upload.
     */
    _handleUploadProgress( payloadObj ) {
        payloadObj = getTypeCheckedValue( payloadObj, 'object', {} );

        if ( this._isFileUploadValid() ) {
            let progress = getTypeCheckedValue( payloadObj.progress, 'int', 0 );
            this._handleSubscription( 'onUploadProgress', {
                progress,
                filename: this.upload.name
            });

            this._inProcessPersistentNotification( 'file-uploading', progress );
            this.upload.handleNextFragment();
        }
    }

    /**
     * Channel-specific socket event handler fired when the FileUploadNotification's
     * "file_upload" channel has returned acknowledgement of either a completed or
     * cancelled file upload.
     * 
     * @param {object} payloadObj Acknowledgement data for a complete or cancelled upload.
     */
    _handleUploadComplete( payloadObj ) {
        this._log( '_handleUploadComplete response.', payloadObj );

        payloadObj = getTypeCheckedValue( payloadObj, 'object', {} );
        let cancelled = getTypeCheckedValue( payloadObj.cancelled, 'boolean', false );
        if ( cancelled ) {
            this.upload = null;
            this._handleSubscription( 'onUploadCancelled' );
            this._resetProcessPersistentNotification();
        } else {
            this._handleSubscription( 'onUploadComplete' );
        }
    }

    /**
     * Channel-specific socket event handler fired when the FileUploadNotification's
     * "file_upload" channel has encountered an error when attempting to append the
     * in-flight upload's last fragment to the file system.
     */
    _handleUploadErrorAppend() {
        if ( this._isFileUploadValid() ) {
            this.upload.cancel();
            this._inFlightErrorNotification( 'append' );
            this._log( 'Upload append error.', { offset: this.upload.offset });
            this._handleSubscription( 'onUploadErrorAppend' );
        }
    }

    /**
     * Channel-specific socket event handler fired when the FileUploadNotification's
     * "file_upload" channel has encountered an error when attempting to move the
     * completed upload from its temporary location within the file system.
     */
    _handleUploadErrorMove() {
        this._inFlightErrorNotification( 'move' );
        this._log( 'Upload move error.' );
        this._handleSubscription( 'onUploadErrorMove' );
    }

    /**
     * Channel-specific socket event handler fired when the FileUploadNotification's
     * "file_upload" channel has successfully noted a completed FileProcessor file
     * mapping within the file system.
     */
    _handleMappingComplete() {
        this._log( 'File mapping complete.' );
        this._handleSubscription( 'onMappingComplete' );
        this._resetProcessPersistentNotification();
    }

    /**
     * Channel-specific socket event handler fired when the FileUploadNotification's
     * "file_upload" channel has noted a completed - but erroneous - FileProcessor file
     * mapping within the file system.
     */
    _handleMappingError() {
        this._log( 'File mapping failure.' );
        this._handleSubscription( 'onMappingError' );
    }

    /**
     * Channel-specific socket event handler fired when the FileUploadNotification's
     * "file_upload" channel acknowledges that preparation for file validation
     * has completed successfully.
     */
    _handleReadyToValidateComplete() {
        this._log( 'File validation starting.' );
        this._handleSubscription( 'onReadyToValidateComplete' );

        // TODO: Set persistent file-validating notification.
    }

    /**
     * Channel-specific socket event handler fired when the FileUploadNotification's
     * "file_upload" channel reports that preparation for file validation
     * was not completed successfully due to an error.
     */
    _handleReadyToValidateError() {
        this._log( 'File validation could not be started.' );
        this._handleSubscription( 'onReadyToValidateError' );
        this._resetProcessPersistentNotification();
    }

    /**
     * Channel-specific socket event handler fired when the FileUploadNotification's
     * "file_upload" channel has noted a completed FileProcessor file validation
     * within the file system.
     */
    _handleValidationComplete( payloadObj ) {
        payloadObj = getTypeCheckedValue( payloadObj, 'object', {} );
        let orderId = getTypeCheckedValue( payloadObj.orderId, 'int', null );

        this._log( 'File validation complete.' );
        this._handleSubscription( 'onValidationComplete', orderId );

        // TODO: Set persistent file-validated notification.
    }

    /**
     * Channel-specific socket event handler fired when the FileUploadNotification's
     * "file_upload" channel has noted a completed - but erroneous - FileProcessor
     * file validation within the file system.
     */
    _handleValidationError() {
        this._log( 'File validation failure.' );
        this._handleSubscription( 'onValidationError' );
        this._resetProcessPersistentNotification();
    }

    /**
     * Channel-specific socket event handler fired when the FileUploadNotification's
     * "file_upload" channel has noted a completed BatchR3 order within the
     * file system.
     */
    _handleOrderComplete() {
        this._log( 'Order complete.' );
        this._handleSubscription( 'onOrderComplete' );
    }

    /**
     * Generates a visual notification when the FileUploadNotification service
     * has encountered a streaming upload issue.
     * 
     * @param {string} action Type of issue encountered; one of: ready, append, move 
     */
    _inFlightErrorNotification( action = 'append' ) {
        action = getTypeCheckedValue( action, 'string', null );

        if ( action === null || ![ 'ready', 'append', 'move' ].includes( action )) {
            return;
        }

        action = `file-upload-err-${action}`;

        let notification = NotificationModel.fromPropsObj({
                faIcon: 'fa-exclamation-triangle',
                notificationType: 'warning',
                titleKey: `core.notification.${this.currentProduct}.${action}-title`,
                summaryKey: `core.notification.${this.currentProduct}.${action}-message`,
                summaryParams: { filename: this.upload.name },
                detailKey: 'core.notification.support-error-code-format',
                detailParams: { err: action },
                isViewable: false
            });

        this.eventService.broadcastHttpResponseNotification( notification );
    }

    /**
     * Generates a visual notification that persists between application
     * navigation changes when the FileUploadNotification service
     * has reported a critical processing event.
     * 
     * @param {string} action Type of event encountered; one of: file-uploading, file-validating, file-validated
     * @param {number} uploadProgress Progress percentage if action is "file-uploading"
     */
    _inProcessPersistentNotification( action = 'file-uploading', uploadProgress = null ) {
        this._log( '_inProcessPersistentNotification called.' );
        action = getTypeCheckedValue( action, 'string', null );
        uploadProgress = getTypeCheckedValue( uploadProgress, 'int', 0 );

        if ( action === null || ![ 'file-uploading', 'file-validating', 'file-validated' ].includes( action )) {
            return;
        }

        let uploadNotification = ( action.indexOf( 'upload' ) !== -1 ),
            faIcon = ( uploadNotification )
                ? 'fa-refresh'
                : 'fa-check',
            persistenceType = ( uploadNotification )
                ? 'progress'
                : 'display',
            persistenceValue = ( uploadNotification )
                ? uploadProgress
                : null,
            actionUrl = `mp.${this.productKey}.${this.productType}`,
            notification = NotificationModel.fromPropsObj({
                faIcon,
                notificationType: 'primary',
                titleKey: `core.notification.${this.productKey}.${this.productType}-${action}-title`,
                summaryKey: `core.notification.${this.productKey}.${action}-message`,
                summaryParams: { filename: this.upload.name },
                actionUrl,
                actionKey: 'ui.core.generic.continue',
                persistenceType,
                persistenceValue
            });

        this.eventService.broadcastPersistentNotification( notification );
    }

    /**
     * Removes an existing persistent visual notification previously generated
     * from the FileUploadNotification service reporting a critical processing
     * event.
     */
    _resetProcessPersistentNotification() {
        this._log( '_resetProcessPersistentNotification called.' );
        this.eventService.broadcastPersistentNotificationReset();
    }

    /**
     * Abstracted method used in numerous other methods as a validation
     * for whether manipulating the prepared or in-flight FileUploadModel
     * instance is possible.
     * 
     * @returns {boolean} Whether the "upload" instance is set to a valid instance of FileUploadModel.
     */
    _isFileUploadValid() {
        return (
            this.upload !== null &&
            this.upload instanceof FileUploadModel &&
            this.upload.valid
        );
    }

    /**
     * Checks whether required conditions are met to enable the FileUpload
     * UI component's "Upload" button, and publishes the result to any
     * subscribers of "onUploadEnabled".
     */
    _validateUploadReady() {
        let fragmentSizeValid = ( this.fragmentSize !== null ),
            uploadModelValid = this._isFileUploadValid(),
            cancelled = ( uploadModelValid && this.upload.cancelled ),
            paused = ( uploadModelValid && this.upload.paused ),
            valid = (
                fragmentSizeValid &&
                uploadModelValid &&
                !cancelled &&
                !paused
            );

        this._handleSubscription( 'onUploadEnabled', valid );
    }

    /**
     * A local callback method given to an instantiated FileUploadModel that
     * it uses to access the connected socket in order to emit a streaming
     * file upload fragment.
     * 
     * @param {string} uuid Unique ID provided by FUN in the upload initialization response.
     * @param {number} offset Ending byte offset from which the fragment should be appended.
     * @param {number} start Starting byte of the fragment to be appended.
     * @param {number} end Ending byte of the fragment to be appended.
     * @param {string} data Base64-encoded fragment to be appended.
     */
    _sendUploadFragment( uuid, offset, start, end, data ) {
        this.socket.emit( this.WS_EVENTS.FileUpload.Publish.Upload, {
            uuid,
            offset,
            start,
            end,
            data
        });
    }

    /**
     * A local callback method given to an instantiated FileUploadModel that
     * it uses to access the connected socket in order to emit a notification
     * that no more fragments will be sent due to either EoF or a user-initiated
     * cancelled upload.
     * 
     * @param {string} uuid Unique ID provided by FUN in the upload initialization response.
     * @param {boolean} cancelled Whether this completion is due to user cancellation.
     */
    _sendUploadComplete( uuid, cancelled = false ) {
        this.socket.emit( this.WS_EVENTS.FileUpload.Publish.UploadComplete, {
            uuid,
            cancelled
        });
    }

    /**
     * Checks to see whether an upload is already in-flight.
     */
    _canSetUpload() {
        return (
            !this._isFileUploadValid() ||
            !this.upload.started
        );
    }

    /**
     * Prepares an instance of FileUploadModel, and - if valid, and an
     * existing product type upload is not already in-flight - associates
     * it with the product type in the "uploads" property.
     * 
     * @param {string} productKey The product key to which this upload belongs.
     * @param {string} productType The product type being uploaded.
     * @param {number} orderId Unique order ID associated with this upload.
     * @param {File} file An instance of File from the browser's file picker.
     */
    _setUpload( productKey = 'mm', productType = 'dataAppend', orderId, file ) {
        this.productKey = getTypeCheckedValue( productKey, 'string', null );
        this.productType = getTypeCheckedValue( productType, 'string', null );
        orderId = getTypeCheckedValue( orderId, 'int', null );
        file = getInstanceCheckedValue( file, File, null );

        this._handleSubscription( 'onUploadEnabled', false );

        if ( this.productKey !== null &&
            this.GLOBALS.SUPPORTED_PRODUCTS.includes( this.productKey ) &&
            this.productType !== null &&
            orderId !== null &&
            file !== null ) {
            
            let upload = new FileUploadModel( this.fragmentSize, this.productType, orderId, file );
            if ( upload.valid ) {
                upload.onReady = this._validateUploadReady.bind( this );
                upload.onFragment = this._sendUploadFragment.bind( this );
                upload.onComplete = this._sendUploadComplete.bind( this );

                this.socket.emit( this.WS_EVENTS.FileUpload.Publish.UploadInit, {
                    productType: upload.productType,
                    orderId: upload.orderId,
                    name: upload.name,
                    size: upload.size,
                    modified: upload.modified
                });

                this._log( `Initializing upload for "${productType}".`, upload );
                this.upload = upload;
            }
        }
    }

    /**
     * Unsets the existing upload, product key, and product type, and fires
     * the _validateUploadReady method which will in turn fire any subscriber
     * callbacks for "onUploadReady" with "false" as the return value.
     */
    _unsetUpload() {
        this.upload = null;
        this._validateUploadReady();
    }

    /**
     * Returns the filename of a set and validated upload's filename.
     * 
     * @return {string|null} Selected upload filename if valid; otherwise null.
     */
    _getFilename() {
        return ( this._isFileUploadValid() )
            ? this.upload.name
            : null;
    }

    /**
     * Begins streaming a validated upload to the FileUploadNotification's
     * "file_upload" channel to be appended by-fragment to the remote file
     * system.
     */
    _upload() {
        if ( this._isFileUploadValid() ) {
            this._log( 'Starting upload.', { upload: this.upload });
            this.upload.start();
        }
    }

    /**
     * Determines if an upload has been initialized, started, and is not
     * in a paused state; whether there is currently an "in-flight" active
     * upload.
     * 
     * @returns {boolean} True if a valid upload model has started, and is not paused.
     */
    _isUploading() {
        return (
            this._isFileUploadValid() &&
            this.upload.started &&
            !this.upload.paused
        );
    }

    /**
     * Pauses an in-flight streaming upload to the FileUploadNotification's
     * "file_upload" channel.
     */
    _pause() {
        if ( this._isFileUploadValid() ) {
            this.upload.pause();
        }
    }

    /**
     * Determines if an upload has been initialized, started, and is in a
     * paused state; whether there is currently an "in-flight" active
     * upload.
     * 
     * @returns {boolean} True if a valid upload model has started, and is paused.
     */
    _isPaused() {
        return (
            this._isFileUploadValid() &&
            this.upload.paused
        );
    }

    /**
     * Resumes a paused in-flight streaming upload to the FileUploadNotification's
     * "file_upload" channel.
     */
    _resume() {
        if ( this._isFileUploadValid() ) {
            this.upload.resume();
        }
    }

    /**
     * Cancels an in-flight streaming upload to the FileUploadNotification's
     * "file_upload" channel.
     */
    _cancel() {
        if ( this._isFileUploadValid() ) {
            this.upload.cancel();
        }
    }

    /**
     * Determines if an upload has been initialized, started, and then cancelled
     * by the user.
     * 
     * @returns {boolean} True if a valid upload model has started, but was cancelled.
     */
    _isCancelled() {
        return (
            this._isFileUploadValid() &&
            this.upload.cancelled
        );
    }

    /**
     * Notifies the FileUploadNotification's "file_upload" channel that the
     * provided user and order ID combination is ready to be validated, and
     * that the existing mapped file should be moved accordingly.
     * 
     * @param {number} userId Unique ID of the user for the file to be validated.
     * @param {number} orderId Unique ID of the order for the file to be validated.
     */
    _readyToValidate( userId, orderId ) {
        this._log( '_readyToValidate called.', { userId, orderId });

        userId = getTypeCheckedValue( userId, 'int', null );
        orderId = getTypeCheckedValue( orderId, 'int', null );

        if ( userId !== null && orderId !== null ) {
            this.socket.emit( this.WS_EVENTS.FileUpload.Publish.ReadyToValidate, {
                userId,
                orderId
            });
        }
    }

    /**
     * An overload of the required method that exposes the provider to Angular,
     * consumed by the WSChannelBase parent class that provides the actual
     * $get exposure method... because JavaScript doesn't have a standard
     * means of handling overloads.
     */
    _get() {
        return {

            /**
             * Whether this provider has received configuration values from
             * FileUploadNotification's "file_upload" channel needed to start
             * a streaming upload.
             */
            isReady: () => this.ready,

            /**
             * Whether a valid upload has already been set and started. Does not
             * account for a paused upload.
             * 
             * @returns {boolean} True if an existing upload is in-flight.
             */
            canSetUpload: () => this._canSetUpload(),

            /**
             * Sets the properties needed for a streaming upload, validates them, and
             * initializes the upload against the FileUploadNotification's "file_upload"
             * channel.
             * 
             * @param {string} productKey A&R product to which this upload applies; must be: "mm"
             * @param {string} productType A&R sub-product to which this upload applies; must be: "dataAppend", "suppressions"
             * @param {number} orderId Unique ID of the order associated with this upload.
             * @param {File} file An instance of File from the browser's file picker.
             */
            setUpload: ( productKey, productType, orderId, file ) =>
                this._setUpload( productKey, productType, orderId, file ),

            /**
             * Unsets the existing upload, product key, and product type, and fires
             * the _validateUploadReady method which will in turn fire any subscriber
             * callbacks for "onUploadReady" with "false" as the return value.
             */
            unsetUpload: () => this._unsetUpload(),

            /**
             * Returns the filename of a set and validated upload's filename.
             * 
             * @return {string|null} Selected upload filename if valid; otherwise null.
             */
            getFilename: () => this._getFilename(),

            /**
             * Begins streaming a previously set upload to the FileUploadNotification's
             * "file_upload" channel.
             */
            upload: () => this._upload(),

            /**
             * Whether a valid upload has already been set, started, and actively streaming.
             * 
             * @returns {boolean} True if an existing upload is in-flight, and not paused.
             */
            isUploading: () => this._isUploading(),

            /**
             * Pauses an in-flight streaming upload to the FileUploadNotification's
             * "file_upload" channel.
             */
            pause: () => this._pause(),

            /**
             * Determines if an upload has been initialized, started, and is in a
             * paused state; whether there is currently an "in-flight" active
             * upload.
             * 
             * @returns {boolean} True if a valid upload model has started, and is paused.
             */
            isPaused: () => this._isPaused(),

            /**
             * Resumes a paused in-flight streaming upload to the FileUploadNotification's
             * "file_upload" channel.
             */
            resume: () => this._resume(),

            /**
             * Cancels an in-flight streaming upload to the FileUploadNotification's
             * "file_upload" channel.
             */
            cancel: () => this._cancel(),

            /**
             * Determines if an upload has been initialized, started, and then cancelled
             * by the user.
             * 
             * @returns {boolean} True if a valid upload model has started, but was cancelled.
             */
            isCancelled: () => this._isCancelled(),

            /**
             * Notifies the FileUploadNotification's "file_upload" channel that the
             * provided user and order ID combination is ready to be validated, and
             * that the existing mapped file should be moved accordingly.
             * 
             * @param {number} userId Unique ID of the user for the file to be validated.
             * @param {number} orderId Unique ID of the order for the file to be validated.
             */
            readyToValidate: ( userId, orderId ) =>
                this._readyToValidate( userId, orderId ),

            /**
             * Removes an existing persistent visual notification previously generated
             * from the FileUploadNotification service reporting a critical processing
             * event.
             */
            resetProcessPersistentNotification: () =>
                this._resetProcessPersistentNotification()
        };
    }
}

Core.provider( 'wsFileUpload', WSFileUpload );