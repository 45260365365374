import { isAdminRole, isSalesRole, isInternalRole, ROLE_GUEST } from '../../constants/core/roles';
import angular from 'angular';
import Core from '../../modules/core/core.module';

/**
 * @name aclService
 * @memberof CoreBundle.Core
 * @class
 * 
 * @classdesc
 * AngularJS provider.
 */
class ACL {
    constructor( GLOBALS ) {
        'ngInject';

        this.GLOBALS = GLOBALS;

        this.isAuthorized = this.isAuthorized.bind( this );
        this.roleProductKeyMap = this.GLOBALS.ROLE_PRODUCT_KEY_MAP;
        this.roles = GLOBALS.ROLES;
        this.reservedRoles = Object.keys( this.roles );
        this.redirect = GLOBALS.UNAUTH_ROUTE;
        this.userRole = {};
    }

    isAuthorized( userRole, stateRoles ) {
        let authorized = false;

        // Is this a recognized role?
        if (( userRole in this.roles ) === false ) {
            throw `"${userRole}" is not a valid role.`;
        }

        // Primary check: state roles include user's role.
        authorized = ( stateRoles.indexOf( userRole ) !== -1 );
        
        // Secondary check: child roles include user's role.
        for ( let role in this.roles[ userRole ]) {
            if ( stateRoles.indexOf( this.roles[ userRole ][ role ]) !== -1 ) {
                authorized = true;
            }
        }

        return authorized;
    }

    $get( $transitions ) {
        'ngInject';

        let acl = {};

        acl.setInheritanceRoles = ( roles ) => {
            angular.forEach( roles, ( inheritance, roleName ) => {
                if ( this.reservedRoles.includes( roleName.toUpperCase() )) {
                    throw `The role "${roleName}" is reserved, and cannot be overridden.`;
                }
                this.roles[ roleName ] = inheritance;
            })
        };

        acl.setRedirect = ( redirectState ) => {
            this.redirect = redirectState;
        };

        acl.setRole = ( productKey, role ) => {
            if ( !this.userRole[ productKey ]) {
                this.userRole[ productKey ] = null;
            }

            this.userRole[ productKey ] = role;
        };

        acl.setProspectBaseRole = ( role ) =>
            acl.setRole( 'pb', role );

        acl.setMarketMagnifierRole = ( role ) =>
            acl.setRole( 'mm', role );

        acl.getRole = ( productKey ) => {
            productKey = productKey || null;

            if ( productKey === null || !this.userRole[ productKey ]) {
                return ROLE_GUEST;
            }

            return this.userRole[ productKey ];
        };

        acl.getProspectBaseRole = () => acl.getRole( 'pb' );

        acl.getMarketMagnifierRole = () => acl.getRole( 'mm' );

        acl.getProductKeysFromRole = ( roleCode ) => {
            let productKeys = [];

            Object.keys( this.roleProductKeyMap ).forEach( pk => {
                if ( Array.isArray( this.roleProductKeyMap[ pk ])) {
                    let foundRole = this.roleProductKeyMap[ pk ].find( pkPrefix => roleCode.indexOf( pkPrefix ) === 0 );
                    
                    if ( foundRole != undefined ) {
                        productKeys.push(pk);
                    }
                } else if ( roleCode.indexOf( this.roleProductKeyMap[ pk ]) === 0 ) {
                    productKeys.push(pk);
                }

            }, this );

            return productKeys;
        };

        /**
         * Determines whether the user has one of the system roles reserved for
         * salespeople. This is product-agnostic (as all system roles are
         * product-agnostic), and will automatically check all products for
         * sales system roles.
         * 
         * @returns {Boolean} Whether the user has one of the sales roles.
         */
        acl.isSales = () => {
            let isSales = false;

            Object.keys( this.userRole ).forEach( productKey => {
                let productRole = getTypeCheckedValue( this.userRole[ productKey ], 'string', null );
                if ( productRole !== null && isSalesRole( productRole )) {
                    isSales = true;
                    return;
                }
            });

            return isSales;
        };

        /**
         * Determines whether the user has one of the system roles reserved for
         * administrators. This is product-agnostic (as all system roles are
         * product-agnostic), and will automatically check all products for
         * admin system roles.
         * 
         * @returns {Boolean} Whether the user has one of the admin roles.
         */
        acl.isAdmin = () => {
            let isAdmin = false;

            Object.keys( this.userRole ).forEach( productKey => {
                let productRole = getTypeCheckedValue( this.userRole[ productKey ], 'string', null );
                if ( productRole !== null && isAdminRole( productRole )) {
                    isAdmin = true;
                    return;
                }
            });

            return isAdmin;
        };

        /**
         * Determines whether the user has one of the system roles reserved for
         * salespeople or administrators. This is product-agnostic (as all system
         * roles are product-agnostic), and will automatically check all products
         * for sales or admin system roles.
         * 
         * @returns {Boolean} Whether the user has one of the internal system roles.
         */
        acl.isInternal = () => {
            return ( acl.isAdmin() || acl.isSales() );
        };

        const self = this;
        $transitions.onStart( {}, ( transition ) => {
            const transitionState = transition.to();
            const transitionData = ( transitionState && typeof transitionState.data === 'object' && transitionState.data !== null )
                ? transitionState.data
                : null;

            if ( transitionData === null || !transitionData.allowedRoles || !Array.isArray( transitionData.allowedRoles )) {
                return true;
            }

            if ( transitionData.allowedRoles.find( ar => ar === '*' ) !== undefined ) {
                return true;
            }

            // Now that we're accommodating multiple endpoints, we need to
            // figure out where they're going in order to fetch the correct
            // product key to feed "getRole". We'll split the route name
            // segments, and as long as the first segment is "mp" (they all
            // should be), then the second segment should be our product key.
            let routeNameSegments = transitionState.name
                .split( '.' )
                .filter( seg => seg.length > 0 );
            let productKey = ( routeNameSegments.length >= 2 )
                ? routeNameSegments[ 1 ]
                : '';

            // We have a product key, so let's use it to check authorization.
            const isAuthorized = self.isAuthorized( acl.getRole( productKey ), transitionData.allowedRoles );
            if ( !isAuthorized && self.redirect !== false ) {
                if ( self.redirect !== transition.to().name ) {
                    return transition.router.stateService.target( self.redirect );
                }

                return false;
            }

            return true;
        });
        
        return acl;
    }
}

Core.provider( 'aclService', ACL );
